<script setup>
import { reactive, onMounted, watch, ref } from 'vue';

const emit = defineEmits(['update:emailContent']);

const props = defineProps({
    automationId: {
        type: Number,
        required: false
    },
    presetEmailContent: {
        type: Object,
        default: {
            subject: '',
            body: []
        }
    }
})

const state = reactive({
    emailContent: props.presetEmailContent,
    contentChanged: false,
})

const copyState = ref('')
const lastCopiedTag = ref('')

const availableTags = [
    { tag: '[customer_name]', description: 'Customer\'s full name' },
    { tag: '[customer_firstname]', description: 'Customer\'s first name only' },
    { tag: '[customer_title]', description: 'Customer\'s professional title' },
    { tag: '[customer_company]', description: 'Customer\'s company name' },
    { tag: '[customer_linkedin]', description: 'Customer\'s LinkedIn profile URL' },
]

const copyTag = async (tag) => {
    try {
        await navigator.clipboard.writeText(tag);
        copyState.value = 'success';
        lastCopiedTag.value = tag;

        // Display a temporary success message or effect
        setTimeout(() => {
            copyState.value = '';
        }, 1500);
    } catch (err) {
        copyState.value = 'error';
        console.error('Failed to copy tag: ', err);
    }
}

const updateContent = (content) => {
    state.emailContent.body = content;
}

watch(state.emailContent, (newValue) => {
    state.contentChanged = true;
    emit('update:emailContent', newValue);
})

onMounted(async () => {
    state.emailContent = props.presetEmailContent;
    setTimeout(function() {
        state.contentChanged = false;
    }, 500);
})

defineExpose({
    state
})

</script>
<template>
    <div>
        <h2>{{ _mft('communication:composer.title.emailContent') }}</h2>

        <expandable-section
            class="mb-4"
        >
            <template v-slot:title>
                Tags
            </template>
            <template v-slot:body>

                <div class="grid grid-cols-1 gap-3 mb-5 md:grid-cols-2">
                    <div v-for="(tagInfo, index) in availableTags" :key="index"
                         class="flex items-center p-2 transition-colors bg-gray-100 rounded-lg hover:bg-gray-200">
                        <div class="flex-grow">
                            <div class="font-medium">{{ tagInfo.tag }}</div>
                            <div class="text-sm text-gray-600">{{ tagInfo.description }}</div>
                        </div>
                        <button @click="copyTag(tagInfo.tag)"
                                class="p-1 ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                title="Copy to clipboard">
                                <i v-if="copyState === 'success' && lastCopiedTag === tagInfo.tag"
                                    class="text-myflowGreen-900 fa-light fa-check copy-animation"></i>
                                <i v-else class="fa-light fa-copy"></i>
                        </button>
                    </div>
                </div>
            </template>
        </expandable-section>


        <input-text
            :label="_mft('communication:composer.subjectLine')"
            v-model:content="state.emailContent.subject"
            class="mt-4"
        />

        <input-block-editor
            name="block_editor_content"
            :content="state.emailContent.body"
            :block-groups="[
                'email'
            ]"
            :showVisibilityTools="false"
            @update:content="updateContent"
        >
        </input-block-editor>

        <div id="teleport-target-block-editor-toolbar"></div>

        <div class="my-4 text-center">
            <a
                v-if="! state.contentChanged && props.automationId"
                :href="route('crm.communications.automations.preview', props.automationId)"
                class="myflow-basic-button--secondary"
                target="_blank"
            >
                {{ _mft('communication:composer.preview') }}
            </a>
            <span class="text-gray-400" v-else>{{ _mft('communication:composer.preview.saveToEnable') }}</span>
        </div>

    </div>
</template>

<style scoped>
.copy-animation {
    animation: pulse 0.5s ease-in-out;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
}
</style>