<script setup>

import {ref} from "vue";

const wantsMoreLeadsInitially = ref(null)
const wantsMoreLeadsOnFollowup = ref(null)

</script>

<template>
    <div class="max-w-3xl mx-auto">
        <p class="mb-1 text-lg text-gray-600">
            Good news! <br>
            Your landing page and quote are ready to go - you can start using them right away.
        </p>
        <p class="mb-4 text-gray-600">
            If you want to make any changes, you can always edit them later.
        </p>

        <card-basic class="mt-8 max-w-2xl mx-auto">
            <p class="text-lg max-w-sm px-6 mx-auto">
                Do you have enough leads to send your new landing page to?
            </p>
            <div class="flex justify-center space-x-4 my-6">
                <button-base
                    :style-type="wantsMoreLeadsInitially === false ? 'secondary' : 'base'"
                    size="lg"
                    class="w-24 justify-center"
                    @click="wantsMoreLeadsInitially = false"
                >
                    Yes
                </button-base>

                <button-base
                    :style-type="wantsMoreLeadsInitially === true ? 'secondary' : 'base'"
                    size="lg"
                    class="w-24 justify-center"
                    @click="wantsMoreLeadsInitially = true"
                >
                    No
                </button-base>
            </div>

            <div
                v-if="wantsMoreLeadsInitially === false"
            >
                <p class="text-lg  mx-auto">
                    Do you want more leads? <br>Like, a steady flow of them.<br>All the time. <em>With zero effort.</em>
                </p>
                <div class="flex justify-center space-x-4 my-6">

                    <button-base
                        :style-type="wantsMoreLeadsOnFollowup === true ? 'secondary' : 'base'"
                        size="lg"
                        class="justify-center leading-tight"
                        @click="wantsMoreLeadsOnFollowup = true"
                    >
                        Yes!<br>
                        <span class="text-sm">That does sounds amazing</span>
                    </button-base>
                    <button-base
                        :style-type="wantsMoreLeadsOnFollowup === false ? 'secondary' : 'base'"
                        size="lg"
                        class="justify-center leading-tight"
                        @click="wantsMoreLeadsOnFollowup = false"
                    >
                        No<br>
                        <span class="text-sm">I've got leads to last me for years</span>
                    </button-base>
                </div>
            </div>

            <div
                v-if="wantsMoreLeadsInitially === true || wantsMoreLeadsOnFollowup"
            >
                <p
                    class="mb-6 text-lg text-gray-700"
                >
                    <span class="font-medium">Upgrade to Pro</span> and let our AI find you a steady stream of perfect leads on the interwebs, because let's face it - even the most beautiful landing page needs visitors, and quotes need someone who wants to buy.
                </p>

                <a
                    :href="route('settings.company.settings')"
                    class="inline-block"
                >
                    <button-primary
                        size="xl"
                    >
                        <span class="mr-2">⭐</span>
                        Read more about myflow Pro & upgrade
                    </button-primary>
                </a>

                <p class="text-lg mt-4">
                    or continue to your <a class="mf-link" :href="route('pages.index', 'landingpage')">landing page</a> and <a class="mf-link" :href="route('expert-contracts.index')">quote</a> without Pro.
                </p>
            </div>

            <div
                v-if="wantsMoreLeadsOnFollowup === false"
                class="mt-8"
            >
                <h2>Alright! You're all set</h2>
                <p class="text-lg">
                    Enjoy your new <a class="mf-link" :href="route('pages.index', 'landingpage')">landing page</a> and <a class="mf-link" :href="route('expert-contracts.index')">quote</a>, nothing can stop you now!
                </p>
            </div>
        </card-basic>
    </div>
</template>

<style scoped>

</style>
