<script setup>

import {ref} from "vue";

const deltaTasksMarkedAsSent = ref(0);

const taskMarkedSent = () => {
    deltaTasksMarkedAsSent.value++;
}

</script>

<template>
    <div class="w-full">

        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>Weekly Assistant</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'daily-assistant'"></story-lane-modal>
                </div>
            </div>
        </frosted-bar>

        <assistant-daily-stats
            class="mt-5"
            :delta-tasks-marked-as-sent="deltaTasksMarkedAsSent"
        ></assistant-daily-stats>

        <assistant-daily
            @marked-sent="taskMarkedSent"
        ></assistant-daily>
    </div>
</template>

<style scoped>

</style>
