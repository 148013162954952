<script setup>
</script>

<template>
    <div
        class="p-4 border border-gray-200 rounded-wl-inner bg-gray-50"
    >
        <slot />
    </div>
</template>

<style scoped>
</style>
