<script setup>
import { computed } from 'vue';

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    blockUuid: {
        type: String,
        require: true
    },
    blockContent: {
        type: Object,
        required: true
    },
    propertyName: {
        type: String,
        required: true
    },
    mediaBankEntryId: {
        type: Number,
        required: false
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: false
    },
    altText: {
        type: String,
        required: false,
        default: ''
    },
    videoPosterMediaBankEntryId: {
        type: Number,
        required: false,
        default: null
    }
})

const dataUrl = computed(() => {
    if (!props.blockContent[props.propertyName]) {
        return null;
    }

    return route('block-editor-container.blocks.media-bank-entry', {
        containerRef: props.containerRef,
        blockUuid: props.blockUuid,
        propertyName: props.propertyName,
        mediaBankEntryId: props.mediaBankEntryId,
    })
});

const posterDataUrl = computed(() => {
    if (!props.videoPosterMediaBankEntryId) {
        return null;
    }
    if (!props.blockContent[props.propertyName]) {
        return null;
    }

    return route('block-editor-container.blocks.media-bank-entry', {
        containerRef: props.containerRef,
        blockUuid: props.blockUuid,
        propertyName: 'thumbnail_media_bank_entry_id',
        mediaBankEntryId: props.videoPosterMediaBankEntryId,
    })
});
</script>
<template>
    <media-bank-entries-display
        v-if="dataUrl"
        :data-url="dataUrl"
        :autoplay="autoplay"
        :alt-text="altText"
        :video-poster-url="posterDataUrl"
    >
        <template v-if="$slots['entry']" #entry="{ entry }">
            <slot name="entry" :entry="entry"></slot>
        </template>
        <template v-if="$slots['spinner']" #spinner>
            <slot name="spinner"></slot>
        </template>
        <template v-if="$slots['cloudflareVideoEntry']" #cloudflare-video-entry="{entry, attrs}">
            <slot name="cloudflare-video-entry" :entry="entry" :attrs="attrsForEntry"></slot>
        </template>
    </media-bank-entries-display>
</template>
