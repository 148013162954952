<script setup>
import {ref, onMounted, computed} from 'vue';
import axios from 'axios';

const props = defineProps({
    deltaTasksMarkedAsSent: {
        type: Number,
        required: false,
        default: 0,
    }
})

const stats = ref({
    remainingEmails: 0,
    totalContacts: 0,
    streak: 0,
});

const fetchContracts = async () => {
    try {
        const response = await axios.get(route('assistant.dailyStats'));
        stats.value = response.data;
    } catch (error) {
        console.error('Error fetching contracts:', error);
    }
};

const dailyActions = computed(() => {
    if (isNaN(stats.value.remainingEmails - props.deltaTasksMarkedAsSent)) {
        return null;
    }

    if (stats.value.remainingEmails - props.deltaTasksMarkedAsSent > 0) {
        return stats.value.remainingEmails - props.deltaTasksMarkedAsSent
    }

    return 0;
});

const newContacts = computed(() => {
    if (isNaN(stats.value.totalContacts + props.deltaTasksMarkedAsSent)) {
        return 0;
    }

    return stats.value.totalContacts + props.deltaTasksMarkedAsSent;
});

onMounted(fetchContracts);


</script>

<template>
    <div>
        <div class="grid-cols-3 gap-2 md:grid">
            <card-basic>
                <template v-slot:body>
                    <div class="flex items-end justify-between">
                        <h2 class="shrink-0">Weekly actions</h2>
                        <div class="text-right">
                            <h1 class="2xl">{{ dailyActions }}</h1>
                        </div>
                    </div>
                    <h6 v-if="dailyActions > 0">Remaining. You'll be done in no time!</h6>
                    <h6 v-else>Nothing left to do, good job!</h6>
                </template>
            </card-basic>
            <card-basic>
                <template v-slot:body>
                    <div class="flex items-end justify-between">
                        <h2 class="shrink-0">New contacts</h2>
                        <div class="text-right">
                            <h1 class="2xl">{{ newContacts }}</h1>
                        </div>
                    </div>
                    <h6>Since the start of using your <i>Weekly Assistant</i></h6>
                </template>
            </card-basic>
            <card-basic>
                <template v-slot:body>
                    <div class="flex items-end justify-between">
                        <h2 class="shrink-0">Streak</h2>
                        <div class="text-right">
                            <h1 class="2xl">{{ stats.streak }}</h1>
                        </div>
                    </div>
                    <h6>Consecutive weeks of taking action</h6>
                </template>
            </card-basic>
        </div>
    </div>
</template>
