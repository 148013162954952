<template>
    <button-base style-type="primary" size="sm" class="mb-3 mr-2" @click.prevent="open">
        <template #icon>
            <i class="mr-1 fa-light fa-upload"></i>
        </template>
        {{ _mft('crm:leadImport.title') }}
    </button-base>
    <Teleport to="body">
        <basic-slideout v-model:open="isOpen">
            <template #header>
                <h1>{{ _mft('crm:leadImport.importContacts') }}</h1>
            </template>

            <template #body>
                <div class="mb-12" v-if="errorMessage">
                    <h5>{{errorMessage}}</h5>

                    <ul class="list-disc" v-if="errors">
                        <li v-for="(error, errorIdx) in errors" :key="errorIdx">
                            {{error}}
                        </li>
                    </ul>
                </div>
                <lead-import-select-tag
                    @tag-selected="tagSelected"
                />

                <form class="mb-12" @submit.prevent="submit" ref="form">
                    <fieldset class="block mb-3">
                        <label class="mb-1" :for="`${uuid}-file-upload`">{{ _mft('crm:leadImport.uploadFile') }}</label>
                        <input ref="inputfile" :id="`${uuid}-file-upload`" name="leads" type="file" accept="*.xlsx" required @change="capture($event)">
                    </fieldset>

                    <input v-model="tagId" type="hidden" name="tag_id" />

                    <toggle-switch
                        label="Exclude duplicates (Based on emails)"
                        v-model:content="excludeDuplicates"
                    ></toggle-switch>

                    <button-primary type="submit">
                        <i class="mr-1 fa-light fa-upload"></i>
                        {{ _mft('crm:leadImport.uploadAndImport') }}
                    </button-primary>
                </form>

                <p class="text-sm italic">
                    {{ _mft('crm:leadImport.templateFileDownload.text') }} <a :href="route('crm.importTemplate')" target="_blank" class="font-semibold underline">{{ _mft('crm:leadImport.templateFileDownload.linkText') }}</a>.
                </p>
            </template>
        </basic-slideout>
    </Teleport>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import LeadImportSelectTag from "./leadImportSelectTag.vue";

export default {
    components: {LeadImportSelectTag},
    props: {
        templateUrl: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            uuid: uuidv4(),
            isOpen: false,
            file: null,
            errorMessage: null,
            errors: null,
            tagId: null,
            excludeDuplicates: false,
        };
    },
    computed: {
        hasFile() {
            return this.file != null;
        }
    },
    methods: {
        open() {
            this.isOpen = true;
            this.file = null;
            this.errorMessage = null;
            this.errors = null;
        },
        capture(ev) {
            this.file = ev.target.files[0];
        },
        async submit() {
            const data = new FormData(this.$refs['form']);
            try {
                const response = await axios.post(route('crm.leads.import'), data);
                this.errorMessage = null;
                this.errors = null;

                this.isOpen = false;

                setTimeout(() => {
                    alert(_mft('crm:leadImport.importSuccessPleaseWait'));
                    this.$document.locationAssign(route('crm.leads'));
                }, 1000);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    this.errorMessage = _mft('crm:leadImport.fileNotImportedError') + ':';
                    this.errors = error.response.data.errors.flat();
                } else {
                    this.errorMessage = _mft('error:error.generalTryAgainLater');
                    this.errors = [];
                }
            }
        },
        tagSelected(tagId) {
            this.tagId = tagId;
        }
    }
}
</script>
