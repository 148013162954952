<script setup>

import {onMounted, ref, watch} from "vue";

const props = defineProps({
    currentSetting: {
        type: String,
        required: true
    },
    selectedAgent: {
        type: String,
        required: false,
        default: ''
    }
})

const selectedSetting = ref(props.currentSetting);
const agentEmail = ref(props.selectedAgent)
const agentEmailValid = ref(false)


const validateAgentEmail = (event) => {
    setTimeout(function(){
        agentEmailValid.value = true
    }, 50)
}

watch(() => agentEmail , (newVal) => {
    agentEmailValid.value = false
}, {deep: true})

</script>
<template>
    <div>
        <input type="hidden" name="closing_type" v-model="selectedSetting" />


        <div class="grid-cols-3 gap-4 mb-8 space-y-4 xl:grid xl:space-y-0">
            <div class="col-span-3 text-center">
                <h2 class="mb-2">The next step for your invites</h2>
                <p class="col-span-2">
                    You know your network the best. How would you like us to approach your invited friends?
                </p>
            </div>
            <div
                class="justify-center col-span-1 p-4 border-2 rounded-lg"
                :class="selectedSetting === 'marketing-automation' ? 'border-myflowPurple-900 border-2' : 'border-gray-200'"
                v-on:click="selectedSetting = 'marketing-automation'"
            >
                <div class="mb-2 text-center">
                    <i class="text-2xl fa-regular fa-envelopes"></i><br>
                    <b>Marketing Automation - E-mail sequence</b><br>
                </div>
                <p><i class="text-xs">Our advanced marketing automation ensures that your invited persons get the best experience possible, showing them (via a bunch of e-mails) why upgrading to Pro is the smartest move for their business.</i></p>

                <p><i class="text-xs">You get 40% of the recurring revenue generated from invited customers.</i></p>

            </div>

            <div
                class="justify-center col-span-1 p-4 border-2 rounded-lg"
                :class="selectedSetting === 'round-robin' ? 'border-myflowPurple-900 border-2' : 'border-gray-200'"
                v-on:click="selectedSetting = 'round-robin'"
            >
                <div class="mb-2 text-center">
                    <i class="text-2xl fa-regular fa-calendars"></i><br>
                    <b>Digitize 1-1 Meeting – First Available Agent</b><br>
                </div>
                <p><i class="text-xs">Let us suggest a personal digital meeting to your invitees. We activate ALL our Digitize Agents, allowing your invitees to enable to faster schedule a 1-1 meeting. We’ll guide them through myflow and showcase the benefits of upgrading to Pro.</i></p>

                <p><i class="text-xs">You get 20% of the recurring revenue generated from invited customers. The Digitize Agent responsible for the meeting and closing gets 20% as well.</i></p>
            </div>

            <div
                class="justify-center col-span-1 p-4 border-2 rounded-lg"
                :class="selectedSetting === 'selected-agent' ? 'border-myflowPurple-900 border-2' : 'border-gray-200'"
                v-on:click="selectedSetting = 'selected-agent'"
            >
                <div class="mb-2 text-center">
                    <i class="text-2xl fa-regular fa-user"></i><br>
                    <b>Digitize 1-1 Meeting – Choose Agent</b><br>
                </div>
                <p><i class="text-xs">Know a myflow Digitize Agent personally? Choose this option to activate the Digitize Agent you prefer to host the meeting with your invitees. Just type in the e-mail adress below and we’ll connect them.</i></p>


                <p><i class="text-xs">You get 20% of the recurring revenue generated from invited customers. The Digitize Agent responsible for the meeting and closing gets 20% as well.</i></p>


                <div
                    v-if="selectedSetting === 'selected-agent'"
                    class="p-2 mt-2 bg-gray-100 rounded-lg"
                >
                    <label>Enter the email for the selected agent:</label>
                    <div class="flex items-center space-x-2">
                        <input-text
                            type="email"
                            name="selected_agent"
                            v-model:content="agentEmail"
                            inputtype="email"
                            autofocus
                            @validates-as="validateAgentEmail"
                            class="!mb-0 w-full"
                        />
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>
<style>
</style>
