<script setup>

import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:colors'])

const props = defineProps({
    primaryBackgroundColor: {
        type: String,
        required: true,
    },
    primaryForegroundColor: {
        type: String,
        required: true,
    },
    secondaryBackgroundColor: {
        type: String,
        required: true,
    },
    secondaryForegroundColor: {
        type: String,
        required: true,
    },
    pinstripeColor: {
        type: String,
        required: true,
    },
})

const _primaryBackgroundColor = ref(props.primaryBackgroundColor)
const _primaryForegroundColor = ref(props.primaryForegroundColor)

const _secondaryBackgroundColor = ref(props.secondaryBackgroundColor)
const _secondaryForegroundColor = ref(props.secondaryForegroundColor)

const _pinstripeColor = ref(props.pinstripeColor)

const colors = computed(() => {
    return {
        primaryBackgroundColor: _primaryBackgroundColor.value,
        secondaryBackgroundColor: _secondaryBackgroundColor.value,
        pinstripeColor: _pinstripeColor.value,
        primaryForegroundColor: _primaryForegroundColor.value,
        secondaryForegroundColor: _secondaryForegroundColor.value
    }
})

watch(colors, (newColors) => {
    emit('update:colors', newColors)
}, {deep: true})

</script>

<template>
    <div>
        <div>
            <card-tabbed>
                <card-tabbed-tab
                    title="Primary"
                    value="primary-color"
                    :active="true"
                >
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <input-label label="Primary background"></input-label>
                            <color-picker
                                :color="_primaryBackgroundColor"
                                :debounce="10"
                                :show-palette="true"
                                format="hex"
                                @colorChanged="_primaryBackgroundColor = $event"
                            ></color-picker>
                        </div>
                        <div>
                            <input-label label="Primary text"></input-label>
                            <color-picker
                                :color="_primaryForegroundColor"
                                :debounce="10"
                                :show-palette="true"
                                format="hex"
                                @colorChanged="_primaryForegroundColor = $event"
                            ></color-picker>
                        </div>
                    </div>
                </card-tabbed-tab>
                <card-tabbed-tab
                    title="Secondary"
                    value="secondary-color"
                >
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <input-label label="Secondary background"></input-label>
                            <color-picker
                                :color="_secondaryBackgroundColor"
                                :debounce="10"
                                :show-palette="true"
                                format="hex"
                                @colorChanged="_secondaryBackgroundColor = $event"
                            ></color-picker>
                        </div>
                        <div>
                            <input-label label="Secondary text"></input-label>
                            <color-picker
                                :color="_secondaryForegroundColor"
                                :debounce="10"
                                :show-palette="true"
                                format="hex"
                                @colorChanged="_secondaryForegroundColor = $event"
                            ></color-picker>
                        </div>
                    </div>
                </card-tabbed-tab>

                <card-tabbed-tab
                    title="Borders"
                    value="pinstripe-color"
                >
                    <input-label label="Border color"></input-label>
                    <color-picker
                        :color="_pinstripeColor"
                        :debounce="10"
                        :show-palette="true"
                        format="hex"
                        @colorChanged="_pinstripeColor = $event"
                    ></color-picker>
                </card-tabbed-tab>


            </card-tabbed>
        </div>
    </div>
</template>

<style scoped>

</style>
