<script setup>
import {computed, ref, watch} from 'vue';

const props = defineProps({
    required: Boolean,
    content: Array,
    error: { type: String, default: '' },
    label: String,
    name: String,
    blockTypes: { type: Array, default: null },
    blockGroups: { type: Array, default: null },
    includedBlockTypes: { type: Array, default: () => [] },
    excludedBlockTypes: { type: Array, default: () => [] },
    includedBlockGroups: { type: Array, default: () => [] },
    excludedBlockGroups: { type: Array, default: () => [] },
    showVisibilityTools: { type: Boolean, default: true },
    fonts: Array
});

const emit = defineEmits(['update:content']);

const _content = ref([]);
const inputClass = ref('');

const requiredCrmBlocks = [
    'forms-input-crm-contact-name',
    'forms-input-crm-contact-email'
];
const optionalCrmBlocks = [
    'forms-input-crm-contact-title',
    'forms-input-crm-contact-phone',
    'forms-input-crm-contact-company-name',
    'forms-input-crm-contact-company-billing-email',
    'forms-input-crm-contact-company-website',
];

const captureValue = (newValue) => {
    _content.value = newValue;
    emit('update:content', _content.value);
};

const presentCrmBlocks = computed((content) => {
    if (!_content.value) {
        return []
    }
    let presentBlocktypes = [];
    _content.value.forEach((block) => {
        presentBlocktypes.push(block.type);
    })

    return presentBlocktypes
});

const _disabledBlockTypes = computed(() => {
    let _blockTypes = [];

    if (!requiredCrmBlocks.every((block) => presentCrmBlocks.value.includes(block))) {
        _blockTypes.push(...optionalCrmBlocks);
    }

    requiredCrmBlocks.forEach((block) => {
        if (presentCrmBlocks.value.includes(block)) {
            _blockTypes.push(block);
        }
    });

    optionalCrmBlocks.forEach((block) => {
        if (presentCrmBlocks.value.includes(block)) {
            _blockTypes.push(block);
        }
    });

    return _blockTypes
});

const _protectedBlockTypes = computed(() => {
    let _blockTypes = [];

    optionalCrmBlocks.forEach((block) => {
        if (presentCrmBlocks.value.includes(block)) {
            _blockTypes = requiredCrmBlocks;
        }
    });

    return _blockTypes
})

watch(() => props.error, (error) => {
    inputClass.value = error ? 'border-red-700' : 'border-gray';
});

watch(() => props.content, (content) => {
    _content.value = content
}, { immediate: true });

</script>

<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <block-editor
            :modelValue="_content"
            @update:modelValue="captureValue"
            :block-types="blockTypes"
            :block-groups="blockGroups"
            :included-block-types="includedBlockTypes"
            :excluded-block-types="excludedBlockTypes"
            :protected-block-types="_protectedBlockTypes"
            :disabled-block-types="_disabledBlockTypes"
            :included-block-groups="includedBlockGroups"
            :excluded-block-groups="excludedBlockGroups"
            :showVisibilityTools="showVisibilityTools"
        ></block-editor>
        <input-error v-if="error" :error="error"/>

        <input v-if="name" type="hidden" :name="name" :value="JSON.stringify(value)"/>
    </div>
</template>
