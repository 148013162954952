<script setup>
    import { computed, onMounted, reactive, watch } from 'vue'
    import { ChevronDownIcon, MinusSmIcon } from '@heroicons/vue/solid'
    import WidgetFolderItem from './widgetFolderItem.vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()
    const emit = defineEmits('folderAdded')
    const props = defineProps({
        currentFolderId: {
            type: String,
            required: false,
            default: null
        },
        showRouteName: {
            type: String,
            required: true,
        },
        showRouteParams: {
            type: Array,
            required: false,
            default: () => ['id']
        },
        indexRouteName: {
            type: String,
            required: true,
        },
        addRouteName: {
            type: String,
            required: false,
            default: null

        },
        listAlignment: {
            type: String,
            required: false,
            default: 'right'
        },
        routeParameters: {
            type: Object,
            required: false,
            default: () => ({})
        }
    })

    const state = reactive({
        showFolders: false,
        folders: [],
        smartFolders: [],
        addingFolder: false,
        showType: 'folders',
    })

    const newFolder = reactive({
        name: '',
    })

    const loadFolders = async () => {
        const response = await axios.get(route(props.indexRouteName, props.routeParameters))
        state.folders = response.data.folders
        state.smartFolders = response.data.smart_folders
    }

    const toggleShowFolders = () => {
        state.showFolders = !state.showFolders
    }

    const saveNewFolder = async () => {
        if (newFolder.name.length === 0) {
            return
        }
        await axios.post(route(props.addRouteName, props.routeParameters), newFolder)
        state.addingFolder = false
        state.showFolders = false
        newFolder.name = ''
        await loadFolders()
        emit('folderAdded')
        notificationStore.addNotification({
            type: 'success',
            title: _mft('page:folder.added'),
            dismissAfter: 5000,
        })
    }

    const currentFolder = computed(() => {
        if (['smart:all', 'smart:published', 'smart:private'].includes(props.currentFolderId)) {
            return state.smartFolders.find(folder => folder.id === props.currentFolderId)
        }

        return state.folders.find(folder => folder.id == props.currentFolderId)
    })

    onMounted(() => {
        loadFolders()
    })


</script>
<template>
    <div
        class="relative transition-all shadow-md"
        :class="state.showFolders ? 'rounded-b-none rounded-t-lg' : ''"
    >
        <button
            @click="toggleShowFolders"
            class="relative z-10 flex items-center justify-between w-full px-4 text-gray-black hover:text-gray-800"
        >
            <span
                class="whitespace-nowrap flex items-center overflow-hidden overflow-ellipsis"
                v-if="currentFolder"
            >
                <span class="relative mr-2 text-lg">
                    <i class="fa-regular fa-folder"></i>
                    <i
                        v-if="currentFolder.is_smart"
                        :class="currentFolder.icon_class"
                        class="absolute transform scale-50 -translate-x-1/2 translate-y-1/2 fa-regular left-1/2 bottom-1/2"
                    ></i>
                </span>
                <span class="overflow-hidden whitespace-nowrap overflow-ellipsis text-xs">
                    <span v-if="currentFolder.is_smart">{{ _mft(currentFolder.name) }}</span>
                    <span v-else>{{ currentFolder.name }}</span>
                </span>
            </span>
            <span
                v-else
                class="text-xs flex items-center"
            >
                <i class="mr-2 text-lg fa-regular fa-folder"></i>
                {{ _mft('folder:domainNamePlural') }}
            </span>
            <MinusSmIcon v-if="state.showFolders" class="w-4 h-4" />
            <ChevronDownIcon v-if="!state.showFolders" class="w-4 h-4" />
        </button>
        <div
            class="absolute left-0 right-0 z-50 transition-all -translate-y-0 bg-white shadow-md rounded-b-md top-full"
            :class="state.showFolders ? 'h-auto mt-0' : 'overflow-hidden h-0 -mt-4 opacity-0'"
        >
            <div class="relative">
                <div class="flex justify-center">
                    <button
                        class="w-1/2 px-2 py-1 text-xs rounded-none"
                        :class="state.showType === 'folders' ? 'bg-white' : 'bg-gray-100 text-gray-600'"
                        @click="state.showType = 'folders'"
                    >{{ _mft('folder:myFolders') }}</button>
                    <button
                        class="w-1/2 px-2 py-1 text-xs rounded-none"
                        :class="state.showType === 'smartFolders' ? 'bg-white' : 'bg-gray-100 text-gray-600'"
                        @click="state.showType = 'smartFolders'"
                    >{{ _mft('folder:smartFolders') }}</button>
                </div>
                <div class="overflow-y-scroll max-h-52">
                    <ul class="">
                        <li
                            v-if="state.showType === 'folders' && state.smartFolders[0]"
                            class="py-1 pr-2 pl-4"
                        >
                            <WidgetFolderItem
                                :isCurrent="'smart:all' == props.currentFolderId"
                                :folder="state.smartFolders[0]"
                                :showRouteName="props.showRouteName"
                                :showRouteParams="props.showRouteParams"
                                :routeParams="props.routeParameters"
                                @folder-favorited="loadFolders"
                            />
                        </li>
                        <li
                            v-if="state.showType === 'folders'"
                            v-for="(folder, f) in state.folders"
                            :key="f"
                            class="py-1 pr-2 pl-4"
                        >
                            <WidgetFolderItem
                                :isCurrent="folder.id == props.currentFolderId"
                                :folder="folder"
                                :showRouteName="props.showRouteName"
                                :showRouteParams="props.showRouteParams"
                                :routeParams="props.routeParameters"
                                @folder-favorited="loadFolders"
                            />
                        </li>

                        <li
                            v-if="state.showType === 'smartFolders'"
                            v-for="(folder, f) in state.smartFolders"
                            :key="f"
                            class="py-1 pr-2 pl-4"
                        >
                            <WidgetFolderItem
                                :isCurrent="folder.id == props.currentFolderId"
                                :folder="folder"
                                :showRouteName="props.showRouteName"
                                :showRouteParams="props.showRouteParams"
                                :routeParams="props.routeParameters"
                            />
                        </li>
                    </ul>
                </div>
                <div
                    v-if="addRouteName && state.showType === 'folders'"
                    class="flex justify-end pt-2 pl-4 pr-2 mb-2 border-t"
                >
                    <button-secondary
                        v-if="!state.addingFolder"
                        size="sm"
                        @click="state.addingFolder = true"
                    >
                        {{ _mft('folder:addNewFolder') }}
                    </button-secondary>

                    <div
                        v-if="state.addingFolder"
                        class="w-full"
                    >
                        <input-text
                            v-model:content="newFolder.name"
                            :autofocus="true"
                            @keyup.enter="saveNewFolder"
                            class="w-full !mb-0 -ml-1"
                        ></input-text>
                        <div class="flex items-center justify-end w-full space-x-2 mt-1">
                            <button-secondary
                                size="sm"
                                @click="state.addingFolder = false"
                            >{{ _mft('shared:cancel') }}</button-secondary>
                            <button-base
                                size="sm"
                                :style-type="newFolder.name.length === 0 ? 'disabled' : 'success'"
                                @click="saveNewFolder"
                            >{{ _mft('shared:action.save') }}</button-base>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="state.showFolders"
            @click="state.showFolders = false"
            class="fixed inset-0 z-10"
        ></div>
    </div>
</template>
