<script setup>
import { fill } from 'lodash';
import { ref, computed } from 'vue'

const option = ref(null)

const searchParameters = ref(null)

const props = defineProps({
    timeSpentPerDay: {
        type: Number,
    },
    dealSize: {
        type: Number,
    },
    option: {
        type: Object,
    }
})

const submit = (parameters) => {
    searchParameters.value = parameters
}

</script>

<template>
    <div>
        <div class="grid grid-cols-4 gap-8 mb-8">
            <div class="col-span-4">
                <lead-hunter-search
                    class="mb-8"
                    @submitSearch="submit"
                >
                </lead-hunter-search>

                {{ searchParameters }}
            </div>

        </div>

        <assistant-prompt
            v-if="searchParameters"
            :timeSpentPerDay="searchParameters.timeSpentPerDay"
            :dealSize="searchParameters.dealSize"
            :option="option"
            :parameters="searchParameters"
        ></assistant-prompt>
    </div>
</template>

<style scoped>
.myflow-basic-input {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    width: 100%;
}
</style>
