<script setup>
import {onMounted, ref} from 'vue'

const widgets = ref([])

const loadLinks = async () => {
    const res = await axios.get(route('api.external-widgets.index'))
    widgets.value = res.data.widgets
}

onMounted(() => {
    loadLinks()
})

</script>

<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('externalWidget:domainNamePlural') }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'external-widgets-v1'"></story-lane-modal>

                    <a :href="route('external-widgets.create')">
                        <button-primary>
                            <mf-translate domainkey="externalWidget:newWidget"></mf-translate>
                        </button-primary>
                    </a>
                </div>
            </div>
        </frosted-bar>

        <div class="mt-8">
            <list-basic>
                <list-basic-item
                    v-if="widgets?.length === 0"
                >
                    {{ _mft('externalWidget:noWidgetsToShow') }}
                </list-basic-item>
                <list-basic-item
                    v-for="(widget, i) in widgets"
                    :key="i"
                >
                    <template #title>
                        {{ widget.name }}
                    </template>
                    <template #actions>
                        <a :href="route('external-widgets.edit', widget.id)">
                            <button-primary
                                size="sm"
                            >
                                {{ _mft('shared:action.edit') }}
                            </button-primary>
                        </a>
                    </template>
                </list-basic-item>
            </list-basic>
        </div>
    </div>
</template>

<style scoped>

</style>
