<template>
    <div
        class="flex justify-end space-x-4 align-center items-center"
        v-if="availableLayouts.length > 1 && false"
    >
        <button
            @click.prevent="block.layout = 'half'"
            :class="block.layout === 'half' ? 'underline' : ''"
            v-if="availableLayouts.includes('half')"
        >{{ _mft('blockEditor:halfWidth') }}</button>
        <button
            @click.prevent="block.layout = 'full'"
            :class="[block.layout === 'full' ? 'underline' : '', !block.layout ? 'underline' : '']"
            v-if="availableLayouts.includes('full')"
        >{{ _mft('blockEditor:fullWidth') }}</button>
    </div>
</template>
<script>
export default {
    props: {
        block: {
            type: Object,
            required: true
        },
        availableLayouts: {
            type: Array,
            required: true
        }
    }
}
</script>
