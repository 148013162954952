<template>
    <template v-if="entry">
        <slot v-if="$slots['entry']" name="entry" :entry="entry" attrs="attrsForEntry"></slot>
        <slot v-else-if="entry.hls_url" name="hls-video-entry" :entry="entry" :attrs="attrsForEntry">
            <video-player
                v-if="! videoPosterUrl"
                v-bind="attrsForEntry"
                :hls="entry.hls_url"
                :autoplay="autoplay"
            ></video-player>
            <video-player
                v-else-if="posterUrl"
                v-bind="attrsForEntry"
                :hls="entry.hls_url"
                :autoplay="autoplay"
                :poster="posterUrl"
            ></video-player>
        </slot>

        <slot v-else-if="entry.cloudflare_video_meta" name="cloudflare-video-entry" :entry="entry" :attrs="attrsForEntry">
            <cloudflare-player
                v-if="entry.cloudflare_video_meta.playable"
                v-bind="attrsForEntry"
                :video="entry.cloudflare_video_meta"
                :autoplay="autoplay"
            />
            <div
                v-else
                class="w-full aspect-video relative bg-black"
            >
                <div
                    class="bg-cover bg-center w-full aspect-video opacity-30"
                    :style="{backgroundImage: 'url('+ entry.cloudflare_video_meta.thumbnail_url +')'}"
                ></div>
                <div class="absolute inset-0 flex flex-col justify-center items-center text-center text-xxs text-white px-4">
                    <div>
                        <mf-spinner-medium class="w-8 h-4"></mf-spinner-medium>
                        <div>{{ _mft('mediaBank:optimizingForStreaming') }}</div>
                    </div>
                </div>
            </div>
        </slot>
        <slot v-else-if="entry.url && entry.type == 'video'" name="video-entry" :entry="entry" :attrs="attrsForEntry" >
            <video
                v-bind="attrsForEntry"
                :src="entry.url"
                :title="entry.title"
                :controls="controls"
                :autoplay="autoplay"
            ></video>
        </slot>
        <slot v-else-if="entry.url && entry.type == 'audio'" name="audio-entry" :entry="entry" :attrs="attrsForEntry" >
            <audio
                class="w-full"
                v-bind="attrsForEntry"
                :src="entry.url"
                :title="entry.title"
                controls
            ></audio>
        </slot>
        <slot v-else-if="entry.url && entry.type == 'image'" name="img-entry" :entry="entry" :attrs="attrsForEntry">
            <img
                v-bind="attrsForEntry"
                :src="entry.url"
                :title="entry.title"
                :alt="altText"
            >
        </slot>
        <slot v-else-if="entry.url && entry.type == 'file'" name="file-entry" :attrs="attrsForEntry">
            <div v-bind="attrsForEntry">
                <a
                    :href="entry.url"
                    download
                    target="_blank"
                    class="flex items-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2 lg:w-10 lg:h-10">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                    {{ _mft('mediaBank:download') }} {{entry.title}}</a>
            </div>
        </slot>
    </template>
    <slot v-else name="spinner" :attrs="attrsForSpinner">
        <mf-spinner-medium
            v-if="!loadingFailed"
            v-bind="attrsForSpinner"
            class="w-24 h-12"
        ></mf-spinner-medium>
        <span
            v-if="loadingFailed"
            class="bg-white/70 text-lg flex justify-center items-center max-w-96 mx-auto rounded-wl py-8 text-gray-500"
        >
            {{ _mft('mediaBank:mediaMissing') }}
        </span>
    </slot>
</template>

<style scoped>
</style>

<script>
const KNOWN_ENTRY_TYPES = ['image', 'file', 'video', 'audio'];
export default {
    props: {
        dataUrl: { type: String, default: null },
        data: { type: Object, default: null },
        autoplay: { type: Boolean, default: false },
        controls: {type: Boolean, default: true },
        altText: {type: String, required: false, default: ''},
        videoPosterUrl: { type: String, required: false, default: null},
    },
    data() {
        return {
            entry: null,
            posterEntry: null,
            loadingFailed: false,
        }
    },
    computed: {
        attrsForEntry() {
          const res = {};
          for (const [key, value] of Object.entries(this.$attrs)) {
            if (key.substring(0, 8) !== 'spinner-') {
              res[key] = value;
            }
          }
          return res;
        },
        attrsForSpinner() {
          const res = {};
          for (const [key, value] of Object.entries(this.$attrs)) {
            if (key.substring(0, 8) === 'spinner-') {
              res[key.substring(8)] = value;
            }
          }
          return res;
        },
        posterUrl() {
            if (this.posterEntry && this.posterEntry.url) {
                return this.posterEntry.url;
            }
            return null;
        }
    },
    async mounted() {
        if (this.dataUrl) {
            await this.loadEntry();
        } else if (this.data) {
            this.entry = this.data;
        } else {
            throw "Either `dataUrl` or `data` must be specified!";
        }

        if (this.videoPosterUrl) {
            await this.loadPoster();
        }
    },
    watch: {
        async dataUrl() {
            this.entry = null;
            await this.loadEntry();
        },
        async videoPosterUrl() {
            this.posterEntry = null;
            await this.loadPoster();
        }
    },
    methods: {
        async loadEntry() {
            try {
                const response = await axios.get(this.dataUrl)
                const entry = response.data;

                this.entry = entry;

                if (KNOWN_ENTRY_TYPES.indexOf(entry.type) === -1) {
                    throw "Unknown entry type format: " + entry.type;
                }

                if (!entry.url && !(entry.type == "video" && entry.cloudflare_video_meta)) {
                    throw "Unknown entry data format!";
                }

                this.entry = entry;
                this.loadingFailed = false
            } catch (e) {
                this.loadingFailed = true
            }
        },
        async loadPoster() {
            try {
                const response = await axios.get(this.videoPosterUrl)
                this.posterEntry = response.data;
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>
