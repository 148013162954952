<script setup>
import {computed, onMounted, ref, watch} from "vue";

const availableTools = [
    {
        title: _mft('helper:utilityUrlShortener'),
        component: 'utility-url-shortener',
        toolName: 'url-shortener',
        options: {paginate: true},
        icon: 'fa-light fa-link',
        openIn: 'popup'
    },
    {
        title: _mft('helper:utilityPasswordGenerator'),
        component: 'utility-password-generator',
        toolName: 'password-generator',
        icon: 'fa-light fa-key',
        openIn: 'popup'
    },
    {
        title: _mft('helper:utilityBase64Coder'),
        component: 'utility-base64-coder',
        toolName: 'base64-coder',
        icon: 'fa-light fa-code',
        openIn: 'popup'
    },
    {
        title: _mft('helper:utilityQrGenerator'),
        component: 'utility-qr-generator',
        toolName: 'qr-generator',
        icon: 'fa-light fa-qrcode',
        openIn: 'popup'
    },
    {
        name: 'Time Tracking',
        component: 'time-tracking-form',
        toolName: 'time-tracking',
        icon: 'fa-light fa-clock',
        openIn: 'popup'
    },
    {
        name: 'Media Upload',
        component: 'media-upload',
        toolName: 'media-upload',
        icon: 'fa-light fa-upload',
        openIn: 'popup'
    },
    {
        name: 'Add contact',
        component: 'toolbar-add-lead',
        toolName: 'toolbar-add-lead',
        icon: 'fa-light fa-user-plus',
        openIn: 'sidebar'
    },
    {
        name: 'Tasks',
        component: 'utility-manage-tasks',
        toolName: 'utility-manage-tasks',
        icon: 'fa-light fa-list-check',
        openIn: 'sidebar'
    },
]
const intercomOpen = ref(false)
const showingComponent = ref(null)
const transitioned = ref(false)
const pinnedHelpers = ref([])
const minimized = ref(false)
const openIntercom = () => {
    window.Intercom('show')
}

const closeIntercom = () => {
    window.Intercom('hide')
}

const toggleTool = (tool) => {
    if (showingComponent.value?.toolName === tool.toolName) {
        showingComponent.value = null
        return
    }
    showingComponent.value = tool
}

const modalOpen = computed(() => {
    return showingComponent.value !== null
})

const displayedTools = computed(() => {
    return availableTools.filter(tool => pinnedHelpers.value.includes(tool.toolName))
})

const minimizedClass = computed(() => {
    return minimized.value ? 'translate-x-full' : ''
})

watch(modalOpen, async (value) => {
    if (value) {
        setTimeout(function(){
            transitioned.value = true
        },1)
    } else {
        transitioned.value = false
    }
})

watch(intercomOpen, (newVal) => {
    if (newVal) {
        openIntercom()
    } else {
        closeIntercom()
    }
})

watch(minimized, (newVal) => {
    localStorage.setItem('toolbarMinimized', newVal);
})

onMounted(() => {
    _mfPrefGet('pinned-toolbar-items').then(data => {
        pinnedHelpers.value = data.split(',')
    })

    minimized.value = localStorage.getItem('toolbarMinimized') === 'true'
})

</script>
<template>
    <div
        class="fixed flex space-x-2 transform bottom-4 right-4 z-modal"
        :class="minimizedClass"
    >
        <div class="flex items-center">
            <button-base
                @click="minimized = !minimized"
                size="sm"
                :class="minimized ? '-translate-x-full' : ''"
            >
                <i
                    class="fa-light fa-chevron-right text-wlPrimary"
                    :class="minimized ? 'rotate-180' : ''"
                ></i>
            </button-base>
        </div>

        <button-base
            v-for="(tool, t) in displayedTools"
            :key="t"
            :style-type="showingComponent?.toolName === tool.toolName ? 'primary' : 'secondary'"
            @click="toggleTool(tool)"
            size="sm"
            class="justify-center w-10"
        >
            <i
                class="text-xl"
                v-bind:class="tool.icon"
            ></i>
        </button-base>

        <button-secondary
            @click="intercomOpen = !intercomOpen"
            size="sm"
            class="ml-2 !bg-myflowPurple !text-white !border-wlPrimary"
        >
            <i class="text-xl text-white fa-light fa-message"></i>
        </button-secondary>

        <div
            v-if="modalOpen && showingComponent.openIn === 'popup'"
            class="fixed inset-0 flex items-end justify-end pl-8 pr-5 z-modal-backdrop md:pr-5 pb-9"
        >
            <div
                class="fixed inset-0 transition-opacity z-modal-backdrop"
                :class="transitioned ? 'opacity-20' : 'opacity-0'"
                @click="showingComponent = null"
            ></div>
            <card-basic
                class="relative w-full max-w-sm transition-all origin-bottom-right z-modal min-w-72"
                :class="transitioned ? '' : 'opacity-0 scale-50'"
            >
                <div class="pt-2">
                    <h2 v-if="showingComponent.title">
                        {{ showingComponent.title }}
                    </h2>
                    <component
                        :is="showingComponent.component"
                        :options="showingComponent.options ? showingComponent.options : {}"
                        @close="showingComponent = null"
                    ></component>
                </div>
            </card-basic>
        </div>
        <slideout-with-slots
            v-if="modalOpen && showingComponent.openIn === 'sidebar'"
            @close="showingComponent = null"
        >
            <template #title>
                <h2>{{ showingComponent.name }}</h2>
            </template>

            <template #body>
                <component
                    :is="showingComponent.component"
                    :options="showingComponent.options ? showingComponent.options : {}"
                    @close="showingComponent = null"
                ></component>
            </template>
        </slideout-with-slots>
    </div>
</template>
