<script setup>
    import previewButton from './_previewButton.vue';
    import {reactive, computed, onMounted, ref, watch, nextTick} from 'vue';

    const emit = defineEmits(['update:activateAt', 'submitAutomation', 'submitAndPreviewAutomation', 'submitAndPreviewAutomationToTestEmail']);

    const props = defineProps({
        composition: {
            type: Object,
            required: true,
            default: null
        },
        compositionValidates: {
            type: Boolean,
            required: false,
            default: false
        },
        currentUserContact: {
            type: Object,
            required: false,
            default: null
        },
        recipientCounts: {
            type: Object,
            required: true,
        }
    })

    const dateSelected = ref(false);

    const state = reactive({
        activateAt: props.composition.automationOptions.activateAt,
        smsCost: null,
    })

    const presetDatetime = computed(() => {
        if (props.composition.automationOptions.activateAt) {
            dateSelected.value = true;
            return props.composition.automationOptions.activateAt
        }
        return null;
    })

    const deactivateAndSubmitAutomation = () => {
        emit('update:activateAt', null);
        emit('submitAutomation');
        dateSelected.value = false;
    }

    const updateDatetime = (date) => {
        if (date === null) {return;}
        dateSelected.value = true;
        state.activateAt = date;
    }

    const scheduleAutomation = () => {
        emit('update:activateAt', state.activateAt);
        emit('submitAutomation');
    }

    const loadSmsCost = async () => {
        const res = await axios.post(route('crm.communications.automation.cost.sms'), {
            includedTagIds: props.composition.includedTags.map(tag => tag.id),
            excludedTagIds: props.composition.excludedTags.map(tag => tag.id),
        });

        state.smsCost = res.data;
    }

    onMounted(() => {
        if (props.composition.automationOptions.selectedDeliveryTypes.includes('sms')) {
            loadSmsCost();
        }
    })

</script>
<template>
    <div>
        <div class="grid gap-4 lg:grid-cols-2 lg:gap-8">
            <div>
                <h2>{{ _mft('communication:composer.summary.title') }}</h2>
                <div class="space-y-4">
                    <p
                        v-if="props.composition.automationOptions.activateAtDisplay"
                        class="font-bold"
                    >
                        <span
                            v-if="props.composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'"
                        >
                            {{ _mft('communication:composer.summary.automation.activeFrom') }} {{ props.composition.automationOptions.activateAtDisplay }}
                        </span>
                        <span
                            v-else
                        >
                            {{ _mft('communication:composer.summary.automation.scheduledFor') }} {{ props.composition.automationOptions.activateAtDisplay }}
                        </span>
                    </p>
                    <p>
                        <span class="font-bold">{{ _mft('communication:sendRuleType') }}</span><br>
                        <span v-if="props.composition.automationOptions.selectedSendRuleType === 'once_to_all'">
                            {{ _mft('communication:sendRuleTypes.onceToAll') }}
                        </span>
                        <span v-if="props.composition.automationOptions.selectedSendRuleType === 'once_assigned_in_time_range'">
                            {{ _mft('communication:sendRuleTypes.onceAssignedInTimeRange') }}<br>
                            <span class="text-xs text-gray-400">
                                {{ new Date(props.composition.automationOptions.onceAssignedInTimeRangeStart).toLocaleDateString('sv-SE', { year: 'numeric', month: 'numeric', day: 'numeric' }) }} - {{ new Date(props.composition.automationOptions.onceAssignedInTimeRangeEnd).toLocaleDateString('sv-SE', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
                            </span>
                        </span>
                        <span v-if="props.composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'">
                            {{ _mft('communication:sendRuleTypes.recurringAfterTagAssigned') }} ({{ props.composition.automationOptions.recurringAfterTagAssignedDelayInHours }} {{ _mft('shared:date.hour', {count: props.composition.automationOptions.recurringAfterTagAssignedDelayInHours}) }})
                        </span>
                    </p>
                    <div class="mb-3">
                        <span class="font-bold">{{ _mft('communication:selectedTags') }}</span><br>
                        <ul class="flex items-center mt-2">
                            <li v-if="props.composition.includedTags.length === 0" class="text-gray-400">{{ _mft('communication:noTagsSelected') }}</li>
                            <li
                                v-for="(tag, i) in props.composition.includedTags"
                                class="mr-2 text-xs"
                            >
                                <pill size="sm" class="bg-gray-200">
                                    {{ tag.name }}
                                </pill>
                            </li>
                        </ul>
                    </div>
                    <div
                        v-if="props.composition.excludedTags.length > 0"
                        class="mb-3"
                    >
                        <span class="font-bold">{{ _mft('communication:tags.excluded') }}</span><br>
                        <ul class="flex items-center mt-2">
                            <li
                                v-for="(tag, i) in props.composition.excludedTags"
                                class="mr-2 text-xs"
                            >
                                <pill size="sm" class="bg-gray-200">
                                    {{ tag.name }}
                                </pill>
                            </li>
                        </ul>
                    </div>
                    <p>
                        <span class="font-bold">{{ _mft('communication:sendVia') }}</span>
                        <span
                            v-if="props.composition.automationOptions.selectedDeliveryTypes.includes('email')"
                        >
                            <br>{{ _mft('communication:email') }}
                            <span class="text-gray-400">({{ props.recipientCounts.email }} {{ _mft('communication:recipient', {count: props.recipientCounts.email}) }})</span>
                        </span>
                        <span v-if="props.composition.automationOptions.selectedDeliveryTypes.includes('sms')">
                            <br>{{ _mft('communication:sms') }}
                            <span class="text-gray-400">({{ props.recipientCounts.phone }} {{ _mft('communication:recipient', {count: props.recipientCounts.phone}) }})</span>
                        </span>
                    </p>
                    <p
                        v-if="props.composition.automationOptions.selectedDeliveryTypes.includes('sms') && state.smsCost && props.recipientCounts.phone > 0">
                        <span class="font-bold">{{ _mft('communication:smsCost') }}:</span> {{ state.smsCost.cost_display }}
                    </p>
                </div>

                <previewButton
                    class="mt-4"
                    v-if="props.currentUserContact && compositionValidates"
                    :automationId="props.composition.automationId"
                    :userContact="props.currentUserContact"
                    :emailEnabled="props.composition.automationOptions.selectedDeliveryTypes.includes('email')"
                    :smsEnabled="props.composition.automationOptions.selectedDeliveryTypes.includes('sms')"
                    @submitAndPreviewAutomation="emit('submitAndPreviewAutomation')"
                    @submitAndPreviewAutomationToTestEmail="emit('submitAndPreviewAutomationToTestEmail', $event)"
                />

            </div>
            <div class="flex flex-col items-center">
                <h2>{{ _mft('communication:composer.summary.automation.schedule.title') }}</h2>
                <input-datepicker
                    name="date"
                    :date="presetDatetime"
                    :rangeMode="false"
                    :showTime="dateSelected"
                    @update:date="updateDatetime"
                    class="mt-1 w-full mb-4 !block"
                />

                <div class="space-y-2">
                    <button-success
                        v-if="state.activateAt && state.activateAt !== composition.automationOptions.activateAt"
                        @click="scheduleAutomation"
                        :disabled="!props.compositionValidates"
                        class="justify-center w-full"
                    >
                        <span v-if="props.composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'">
                            {{ _mft('communication:composer.summary.automation.activateAt') }}
                        </span>
                        <span v-else>
                            {{ _mft('communication:composer.summary.automation.schedule') }}
                        </span>
                    </button-success>
                    <button-secondary
                        v-if="!props.composition.automationOptions.activateAt"
                        @click="deactivateAndSubmitAutomation"
                        :disabled="!props.compositionValidates"
                        class="justify-center w-full"
                    >
                        <span v-if="props.composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'">
                            {{ _mft('communication:composer.summary.automation.saveWithoutActivating') }}
                        </span>
                        <span v-else>
                            {{ _mft('communication:composer.summary.automation.saveWithoutScheduling') }}
                        </span>
                    </button-secondary>

                    <button-danger
                        v-else
                        @click="deactivateAndSubmitAutomation"
                        :disabled="!props.compositionValidates"
                        class="justify-center w-full"
                    >
                        <span>
                            <span v-if="props.composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'">
                                {{ _mft('communication:composer.summary.automation.disable') }}
                            </span>
                            <span v-else>
                                {{ _mft('communication:composer.summary.automation.removeScheduling') }}
                            </span>
                        </span>
                    </button-danger>

                </div>
            </div>
        </div>
    </div>
</template>
