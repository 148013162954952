<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({
    formId: {
        type: Number,
        required: false,
        default: null,
    }
})

const formLoaded = ref(false)
const fieldsLoaded = ref(false)
const form = ref(null)
const blockEditorContent = ref([])
const savingState = ref('')
const onSubmitAction = ref('showMessage')

const onSubmitActionOptions = [
    { label: 'Show a message', name: 'showMessage' },
    { label: 'Show a page', name: 'redirect' },
]

const loadForm = async () => {
    if (! props.formId) {
        formLoaded.value = true
        fieldsLoaded.value = true
        return
    }
    const res = await axios.get(route('api.form.show', props.formId))
    form.value = res.data.form
    onSubmitAction.value = res.data.form.submit_complete_action
    formLoaded.value = true
    await loadFormFields()
}

const loadFormFields = async () => {
    const res = await axios.get(route('api.form.fields', props.formId))
    blockEditorContent.value = res.data.fields
    setTimeout(function(){
        fieldsLoaded.value = true
    }, 1000)
}

const onTagLeadTagsSelected = (tags) => {
    form.value.tag_leads_tag_ids = tags.map((tag) => tag.id)
}

const setRedirectPageUrlFromPage = (page) => {
    form.value.submit_complete_redirect_url = siteRoute('landing-pages.show', page.slug)
}

const updateContent = (content) => {
    blockEditorContent.value = content
}

const setOnSubmitAction = (action) => {
    onSubmitAction.value = action.name
    form.value.submit_complete_action = action.name
}

const update = async () => {
    if (savingState.value === 'waiting') {
        return
    }
    savingState.value = 'waiting'
    const res = await axios.put(route('api.form.update', props.formId), {
        form: form.value,
        fields: blockEditorContent.value,
    }).catch((e) => {
        savingState.value = 'error'
    })

    if (res.data.success) {
        savingState.value = 'success'
    } else {
        savingState.value = 'error'
    }
}

onMounted(() => {
    loadForm()
})

</script>
<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>{{ formLoaded && form ? form.title : _mft('form:createForm') }}</h1>

                    <div>
                        <div class="flex items-center justify-end space-x-2">
                            <a
                                v-if="form"
                                :href="route('forms.statistics', form.id)"
                                class="mf-link"
                            >
                                <button-secondary>
                                    {{ _mft('form:statistics.title') }}
                                </button-secondary>
                            </a>

                            <a
                                v-if="form"
                                :href="siteRoute('form-submissions.create', form.slug)"
                                target="_blank"
                            >
                                <button-secondary>{{ _mft('shared:action.show') }}</button-secondary>
                            </a>

                            <button-success
                                @click="update"
                                :state="savingState"
                            >{{ _mft('shared:action.save') }}</button-success>
                        </div>
                    </div>
                </div>

                <template #navigation>
                    <a
                        :href="route('forms.index')"
                        class="mf-link text-xs"
                    >
                        {{ _mft('form:form.domainNamePlural') }}
                    </a>
                </template>
            </frosted-bar>
        </div>

        <ui-tabbed
            class="mt-8"
        >
            <ui-tabbed-tab
                :title="_mft('form:form.tab.settings')"
                value="tab-settings"
                :active="true"
            >
                <div
                    v-if="form"
                >
                    <div class="lg:grid grid-cols-2 gap-8">
                        <div>
                            <input-text
                                v-model:content="form.title"
                                :label="_mft('form:form.title')"
                                :class="! props.formId ? '!mb-0' : ''"
                            />
                            <p
                                v-if="! props.formId"
                                class="text-xs text-gray-500"
                            >
                                {{ _mft('form:form.titleUrlCannotBeChanged')}}
                            </p>
                            <toggle-switch
                                v-model:content="form.published"
                                :label="_mft('form:form.published')"
                            />
                        </div>
                        <div>
                            <div class="mb-1 font-medium">
                                <label>When a user submits the form</label>
                            </div>
                            <button-bar
                                size="sm"
                                :options="onSubmitActionOptions"
                                :selected-option-name="onSubmitAction"
                                @select="setOnSubmitAction"
                            />

                            <input-editor
                                v-if="onSubmitAction === 'showMessage'"
                                :label="'Message'"
                                v-model:content="form.submit_complete_message"
                            />

                            <input-text
                                v-if="onSubmitAction === 'redirect'"
                                v-model:content="form.submit_complete_redirect_url"
                                :label="_mft('form:form.redirect.label')"
                                :placeholder="_mft('form:form.redirect.placeholder')"
                                class="!mb-0"
                            ></input-text>

                            <div class="flex items-start">
                                <div class="ml-3 w-3 h-6 border-l border-b">

                                </div>
                                <page-search
                                    v-if="onSubmitAction === 'redirect'"
                                    class="w-full"
                                    :route="route('api.invoice-links.purchase-completed-page-candidates')"
                                    :placeholder="_mft('form:form.redirect.search.placeholder')"
                                    @page-selected="setRedirectPageUrlFromPage"
                                />
                            </div>

                            <toggle-switch
                                v-model:content="form.notifications_enabled"
                                :label="_mft('form:form.notificationsEnabled')"
                            />

                            <toggle-switch
                                v-model:content="form.send_copy_to_expert"
                                :label="_mft('form:form.sendCopyToExpert')"
                            />

                            <toggle-switch
                                v-model:content="form.send_copy_to_user"
                                :label="_mft('form:form.sendCopyToUser')"
                            />
                            <p class="-mt-3 text-gray-500 text-xxs">{{ _mft('form:form.sendCopyToUser.disclaimer') }}</p>

                            <toggle-switch
                                v-model:content="form.tag_leads"
                                :label="_mft('form:form.tagLeadOnSubmit')"
                            />
                            <p class="-mt-3 text-gray-500 text-xxs">{{ _mft('form:form.tagLeadOnSubmit.disclaimer') }}</p>

                            <tags-multi-select
                                v-if="form.tag_leads"
                                :pre-selected-tags="Object.values(form.tag_leads_tags)"
                                @tagsSelected="onTagLeadTagsSelected"
                                :allow-creation="true"
                                :select-multiple="true"
                            ></tags-multi-select>

                        </div>
                    </div>
                </div>
            </ui-tabbed-tab>

            <ui-tabbed-tab
                :title="_mft('form:form.tab.fields')"
                value="tab-fields"
                :active="false"
            >
                <p
                    v-if="form && ! form.editable"
                    class="text-center italic bg-gray-200 p-4 rounded-wl max-w-3xl mx-auto"
                >
                    {{ _mft('form:form.cannotBeEditedDueToSubmission') }}
                </p>

                <div :class="form && ! form.editable ? 'opacity-50 pointer-events-none relative' : ''">
                    <div
                        v-if="form && ! form.editable"
                        class="absolute inset-0"
                    ></div>
                    <input-block-editor
                        v-if="blockEditorContent"
                        name="block_editor_content"
                        :content="blockEditorContent"
                        @update:content="updateContent"
                        :block-groups="[
                            'forms-layout',
                            'forms-input',
                            'forms-crm',
                            'content',
                            'media'
                        ]"
                    ></input-block-editor>
                </div>

                <div id="teleport-target-block-editor-toolbar" class="md:ml-4"></div>
            </ui-tabbed-tab>
        </ui-tabbed>
    </div>
</template>
