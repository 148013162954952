<script setup>
import InputDatepicker from "../forms/inputDatepicker.vue";
import {ref} from "vue";

const emit = defineEmits(['update:due-date', 'update:start-date'])

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    dueDate: {
        type: String,
        required: true,
    }
})

const _startDate = ref(props.startDate)
const _dueDate = ref(props.dueDate)

const updateStartDate = async (dateTime) => {
    emit('update:start-date', dateTime)
}

const updateDueDate = async (dateTime) => {
    emit('update:due-date', dateTime)
}

</script>

<template>
    <div class="grid grid-cols-2 gap-4">
        <div>
            <label
                class="text-xs"
            >
                Start date
            </label>
            <input-datepicker
                :date="_startDate"
                @update:date="updateStartDate($event)"
                :show-inline="false"
            />
        </div>
        <div>
            <label
                class="text-xs"
            >
                Due date
            </label>
            <input-datepicker
                :date="_dueDate"
                @update:date="updateDueDate($event)"
                :show-inline="false"
            />
        </div>
    </div>
</template>
