<script setup>

import {onMounted, ref} from "vue";

const props = defineProps({
    channelId: {
        type: Number,
        required: true,
    }
})

const memberScoreboard = ref([]);
const badgeScoreboard = ref([]);

const loadStats = async () => {
    const res = await axios.get(route('community.member.channel.statistics', props.channelId));
    memberScoreboard.value = Object.values(res.data.member_scores).sort((a, b) => b.score - a.score);
    badgeScoreboard.value = Object.values(res.data.badge_scores).sort((a, b) => b.score - a.score);
}

onMounted(() => {
    loadStats();
})

</script>

<template>
<div>
    <div class="grid md:grid-cols-2 gap-12">
        <div>
            <ul class="divide-y">
                <li
                    class="flex justify-between space-x-4 py-1 font-bold"
                >
                    <div class="text-xs w-2/5 whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {{ _mft('community:community.scoreboard.team.name') }}
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ _mft('community:community.scoreboard.team.engagement') }}
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ _mft('community:community.scoreboard.team.averageScore') }}
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ _mft('community:community.scoreboard.team.score') }}
                    </div>
                </li>
                <li
                    v-for="(badge, i) in badgeScoreboard"
                    :key="i"
                    class="flex justify-between space-x-4 py-1"
                >
                    <div class="text-xs w-2/5 whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {{ badge.name }}
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ badge.engagement }}%
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ badge.average_score }}
                    </div>
                    <div class="text-xs w-1/5 text-right">
                        {{ badge.score }}
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <ul class="divide-y">
                <li
                    class="flex justify-between space-x-4 py-1 font-bold"
                >
                    <div class="text-xs">
                        {{ _mft('community:community.scoreboard.member.name') }}
                    </div>
                    <div class="text-xs text-right">
                        {{ _mft('community:community.scoreboard.member.score') }}
                    </div>
                </li>
                <li
                    v-for="(member, i) in memberScoreboard"
                    :key="i"
                    class="flex justify-between space-x-4 py-1"
                >
                    <div class="text-xs">
                        {{ member.name }}
                    </div>
                    <div class="text-xs">
                        {{ member.score }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
