<script setup>

import {computed, onMounted, ref, watch} from "vue";
import ToggleSwitch from "../forms/toggleSwitch.vue";
import ContainerWithTooltip from "../tooltips/containerWithTooltip.vue";

const emit = defineEmits(['updateConditions'])

const props = defineProps({
    block: {
        type: Object,
        required: true,
    },
    blockIndex: {
        type: Number,
        required: true,
    },
    blocks: {
        type: Object,
        required: true,
    },
    conditions: {
        type: Object,
        required: true,
    }
})

const _conditions = ref({
    or: [],
})
const conditionalDisplayActive = ref(false)

const toggleAlternative = (inputUuid, alternative) => {
    let altObj = {
        inputUuid: inputUuid,
        alternative: alternative.title
    }

    if (! _conditions.value.or) {
        _conditions.value.or = []
    }

    if (_conditions.value.or.find(_altObj => JSON.stringify(_altObj) === JSON.stringify(altObj))) {
        _conditions.value.or = _conditions.value.or.filter(_altObj => JSON.stringify(_altObj) !== JSON.stringify(altObj))
    } else {
        _conditions.value.or.push(altObj)
    }

    emit('updateConditions', _conditions.value)
}

const clearConditions = () => {
    _conditions.value.or = []
    emit('updateConditions', _conditions.value)
}

const conditionableBlocks = computed(() => {
    const conditionableTypes = ['forms-input-radio', 'forms-input-checkbox', 'forms-input-select']

    let conditionableBlocksWithIndex = {};

    props.blocks.forEach((_block, index) => {
        if (conditionableTypes.includes(_block.type) && _block.uuid !== props.block.uuid) {
            let cb = _block
            cb.index = index
            conditionableBlocksWithIndex[_block.uuid] = cb
        }
    })

    return conditionableBlocksWithIndex
})

watch(() => _conditions, (value) => {
    if (value.value.or?.length > 0) {
        conditionalDisplayActive.value = true
    }
}, {deep: true})

watch(() => conditionalDisplayActive.value, (value) => {
    if (value === false) {
        clearConditions()
    }
})

onMounted(() => {
    if (props.conditions.or) {
        _conditions.value = JSON.parse(JSON.stringify(props.conditions))
    }
})

</script>
<template>
    <div>
        <container-with-tooltip
            tooltipClass="bg-white text-xs px-1 py-0.5 rounded shadow-md max-w-md"
        >
            <template #tooltip>
                {{ conditionalDisplayActive ? '' : _mft('form:field.enableConditionalDisplay.tooltip') }}
            </template>
            <toggle-switch
                v-model:content="conditionalDisplayActive"
                :label="_mft('form:field.enableConditionalDisplay')"
            />
        </container-with-tooltip>
        <div :class="conditionalDisplayActive ? 'block' : 'hidden'">
            <ul class="divide-y">
                <li
                    v-for="(block, b) in conditionableBlocks"
                    class="space-y py-2"
                >
                    <div class="grid grid-cols-5 font-bold">
                        <div class="col-span-2">
                            {{ _mft('form:field.title.label') }}
                        </div>
                        <div class="col-span-3">
                            {{ _mft('form:field.options.label') }}
                        </div>
                    </div>
                    <div class="grid grid-cols-5">
                        <div class="col-span-2">
                            {{ block.content.title }}
                        </div>
                        <ul class="col-span-3 space-y-1">
                            <li
                                v-for="(alternative, a) in block.content.alternatives"
                                :key="a"
                                class="flex justify-between"
                            >
                                <div>
                                    {{ alternative.title }}
                                </div>
                                <div class="-mt-0.5">
                                    <button-base
                                        size="line"
                                        @click="toggleAlternative(block.uuid, alternative)"
                                    >
                                        <span v-if="_conditions.or?.find(_altObj => JSON.stringify(_altObj) === JSON.stringify({inputUuid: block.uuid, alternative: alternative.title}))">
                                            <i class="fa-regular fa-square-check text-xl"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fa-regular fa-square text-xl"></i>
                                        </span>
                                    </button-base>
                                </div>

                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div class="italic">
                <div
                    v-if="conditionableBlocks.length === 0"
                >
                    {{ _mft('form:field.conditionalDisplay.noConditionableFieldsAvailable') }}
                </div>
                <p
                    v-else-if="! props.conditions.or || props.conditions.or?.length === 0"
                >
                    {{ _mft('form:field.conditionalDisplay.alwaysVisible') }}
                </p>
                <div
                    v-else
                >
                    <div class="">
                        <span>
                            {{ _mft('form:field.conditionalDisplay.onlyVisibleIf') }}
                        </span>
                        <span
                            v-for="(condition, c) in props.conditions.or"
                            :key="c"
                        >
                            <div class="flex space-x-1">
                                <span v-if="c > 0">{{ _mft('form:field.conditionalDisplay.orHasSelected') }}</span>
                                <span v-else>{{ _mft('form:field.conditionalDisplay.hasSelected') }}</span>
                                <span class="font-medium">{{ condition.alternative }}</span>
                                <span>{{ _mft('form:field.conditionalDisplay.inField') }}</span>
                                <span class="font-medium">
                                    {{ blocks.find(_block => _block.uuid === condition.inputUuid).content.title }}
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
