<template>
    <div v-if="value">
        <input-text
            :label="_mft('form:field.title.label')"
            :required="true"
            v-model:content="value.title"
        />

        <div
            v-for="(alternative, alternativeIdx) in value.alternatives"
            :key="alternativeIdx"
            class="flex items-center justify-between space-x-2"
        >
            <input-text
                :label="_mft('form:field.option.number', {number: alternativeIdx + 1})"
                :required="true"
                v-model:content="alternative.title"
                class="flex-grow"
            />
            <button-danger
                v-if="alternativeIdx > 0 || value.alternatives.length > 1"
                class="mt-2"
                size="sm"
                @click.prevent="removeAlternative(alternativeIdx)"
            ><i class="fa-light fa-trash"></i></button-danger>
        </div>
        <div
            v-if="value.allowCustomAnswer"
            class="flex items-center justify-between space-x-2"
        >
            <input-text
                :label="_mft('form:field.option.number', {number: value.alternatives.length + 1})"
                disabled
                :placeholder="_mft('form:field.title.other.placeholder')"
                class="flex-grow"
            />
        </div>
        <div class="flex justify-end items-center space-x-4 mb-1">
            <div class="w-52">
                <toggle-switch
                    :label="_mft('form:field.allowCustomAnswer')"
                    v-model:content="value.allowCustomAnswer"
                ></toggle-switch>
            </div>
            <button-primary
                size="sm"
                @click.prevent="addAlternative()"
                class="mb-3"
            >{{ _mft('form:field.addOption') }}</button-primary>
        </div>

        <toggle-switch
            :label="_mft('form:required')"
            v-model:content="value.required"
        ></toggle-switch>

        <toggle-switch
            class="mt-4"
            :label="_mft('form:field.showMoreInfo')"
            v-model:content="value.showInstructions"
        ></toggle-switch>

        <Instructions
            :model-value="value"
        />
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import Instructions from "./_instructions.vue";

export default {
    components: {Instructions},
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.uuid) { value.uuid = uuidv4(); }
            if (!value.title) { value.title = null; }
            if (!value.allowCustomAnswer) { value.allowCustomAnswer = false; }
            if (!value.showInstructions) { value.showInstructions = false; }
            if (!value.metaText) { value.metaText = null; }
            if (!value.metaImage) { value.metaImage = null; }
            if (!value.metaVideo) { value.metaVideo = null; }
            if (!value.required) { value.required = false; }
            if (!value.alternatives) { value.alternatives = [{title: null}]; }
            this.value = value;
        },
        addAlternative() {
            this.value.alternatives.push({title: null});
        },
        removeAlternative(idx) {
            this.value.alternatives.splice(idx, 1);
        }
    },
}
</script>
