<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import ButtonWithConfirmation from "../../../components/buttons/buttonWithConfirmation.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import { useAppStateStore } from '@/stores/AppStateStore.js'

const notificationStore = useNotificationStore()
const appStateStore = useAppStateStore();

const companyHasValidInvoiceSettings = ref(false)
const entries = ref([])
const filterTerm = ref('')
const selectedEntries = ref([])
const billedLead = ref(null)
const billingDetails = ref({
    name: '',
    companyName: '',
    streetAddress: '',
    zipcode: '',
    city: '',
    country: '',
    email: '',
})
const currency = ref('')
const creatingPaymentLink = ref(false)
const viewingPaymentLink = ref('')
const selectedFilter = ref('')
const editingEntry = ref(null)
const editingEntrySlideout = ref(null)
const exportInProgress = ref(false)

const filterOptions = [
    { label: _mft('timeTracking:filter.all.label'), name: '' },
    { label: _mft('timeTracking:filter.billed.label'), name: 'billed' },
    { label: _mft('timeTracking:filter.unbilled.label'), name: 'unbilled' },
]

const loadEntries = async () => {
    const res = await axios.get(route('api.time-tracking.entries.index'))
    entries.value = res.data.entries
}

const filteredEntries = computed(() => {
    let filteredEntries = entries.value.filter(entry => {
        return entry.title.toLowerCase().includes(filterTerm.value.toLowerCase())
    })

    if (selectedFilter.value !== '') {
        filteredEntries = filteredEntries.filter(entry => {
            if (selectedFilter.value === 'billed') {
                return entry.billed
            }
            if (selectedFilter.value === 'unbilled') {
                return !entry.billed && entry.billable
            }
            return true
        })
    }

    return filteredEntries
})

const selectEntry = (entry) => {
    selectedEntries.value.push(entry)
}

const deselectEntry = (entry) => {
    selectedEntries.value = selectedEntries.value.filter(selectedEntry => {
        return selectedEntry !== entry
    })
}

const createPaymentLink = () => {
    if (selectedEntries.value.length === 0) {
        return
    }
    creatingPaymentLink.value = true
}

const onPaymentLinkCreated = (paymentLink) => {
    history.replaceState({}, document.title, " ");
    creatingPaymentLink.value = false
    const entryIds = selectedEntries.value.map(entry => entry.id)
    markEntriesBilled(entryIds, paymentLink)
    selectedEntries.value = []
    loadEntries()
}

const markEntriesBilled = async (entryIds, paymentLink) => {
    await axios.post(route('api.time-tracking.billing.entries.mark-billed'), {
        entries: entryIds,
        paymentLinkUuid: paymentLink.uuid
    })
    await loadEntries()
}

const deleteEntry = async (entry) => {
    await axios.delete(route('api.time-tracking.entries.delete', {id: entry.id}))
    await loadEntries()
}

const setFilter = (filter) => {
    selectedFilter.value = filter.name
    _mfPrefSet('time-tracking-billed-filter', filter.name)
}

const loadBilledLead = async (leadId) => {
    const res = await axios.get(route('api.crm.lead.show', {id: leadId}))
    billedLead.value = res.data.lead
    billingDetails.value.name = billedLead.value.name
    billingDetails.value.companyName = billedLead.value.company
    billingDetails.value.streetAddress = billedLead.value.billing_street_address
    billingDetails.value.zipcode = billedLead.value.billing_zipcode
    billingDetails.value.city = billedLead.value.billing_city
    billingDetails.value.country = billedLead.value.billing_country
    billingDetails.value.email = billedLead.value.invoice_email
}

const exportEntries = async () => {
    const res = await axios.post(route('api.time-tracking.entries.export'), {
        entryIds: filteredEntries.value.map(entry => entry.id)
    })

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('timeTracking:export.exportInitiated.title'),
            message: _mft('timeTracking:export.exportInitiated.message'),
            dismissAfter: 5000,
        })
    }

    exportInProgress.value = true
    setTimeout(function(){
        exportInProgress.value = false
    }, 5000)
}

const exportEntriesFromPaymentLink = async () => {
    const res = await axios.post(route('api.time-tracking.entries.export-from-invoice-link', viewingPaymentLink.value))

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('timeTracking:export.exportInitiated.title'),
            message: _mft('timeTracking:export.exportInitiated.message'),
            dismissAfter: 5000,
        })
    }

    exportInProgress.value = true
    setTimeout(function(){
        exportInProgress.value = false
    }, 5000)
}

const paymentLinkProducts = computed(() => {
    return selectedEntries.value.map(entry => {
        return {
            title: entry.title,
            price: entry.product.price ? entry.product.price : 0,
            quantity: (entry.minutes / 60),
            description: entry.product.description,
            linkableId: entry.product.id,
            linkableType: 'custom',
            quantity_is_fixed: false,
            unit: 'h',
        }
    })
})

const presetTitle = computed(() => {
    if (selectedEntries.value.length === 1) {
        return selectedEntries.value[0].title + ' - Invoice ' + new Date().toISOString().split('T')[0];
    } else {
        return billingDetails.value.companyName ? billingDetails.value.companyName + ' - ' : '' + 'Invoice ' + new Date().toISOString().split('T')[0];
    }
})

const presetPrice = computed(() => {
    return selectedEntries.value.reduce((acc, entry) => {
        return acc + (entry.minutes / 60) * entry.product.price
    }, 0)
})

const selectedHours = computed(() => {
    return selectedEntries.value.reduce((acc, entry) => {
        return parseFloat(acc) + parseFloat(entry.hours)
    }, 0)
})

const selectedMoney = computed(() => {
    return selectedEntries.value.reduce((acc, entry) => {
        return acc + (entry.minutes / 60) * entry.product.price
    }, 0)
})

const selectedCompany = computed(() => {
    let lead_id = null
    selectedEntries.value.forEach(entry => {
        if (entry.lead_id === null) {
            return null
        }
        lead_id = entry.lead_id
    })

    return lead_id
})

const selectedCompanyName = computed(() => {
    let name = ''
    selectedEntries.value.forEach(entry => {
        if (entry.lead_id === null) {
            return null
        }
        name = entry.lead.name
    })

    return name
})

const billableHours = computed(() => {
    return entries.value.reduce((acc, entry) => {
        return ! entry.billable || entry.billed ? acc : acc + entry.minutes
    }, 0) / 60
})

const billableMoney = computed(() => {
    return entries.value.reduce((acc, entry) => {
        return ! entry.billable || entry.billed ? acc : acc + (entry.minutes / 60) * entry.product.price
    }, 0)
})

const billingDetailsValidate = computed(() => {
    return billingDetails.value.companyName &&
        billingDetails.value.streetAddress &&
        billingDetails.value.zipcode &&
        billingDetails.value.city &&
        billingDetails.value.country &&
        billingDetails.value.email
})

const entriesByline = computed(() => {
    const domainName = filteredEntries.length === 1 ? _mft('timeTracking:entry.domainNameSingular') : _mft('timeTracking:entry.domainNamePlural')
    if (selectedFilter.value === '') {
        return domainName.toLowerCase()
    }
    if (selectedFilter.value === 'billed') {
        return domainName.toLowerCase() + ' ' + _mft('timeTracking:filter.billed.label').toLowerCase()
    }
    if (selectedFilter.value === 'unbilled') {
        return domainName.toLowerCase() + ' ' +  _mft('timeTracking:filter.unbilled.label').toLowerCase()
    }
})

watch(selectedCompany, (newVal) => {
    if (newVal === null) {
        billedLead.value = null
        billingDetails.value.name = ''
        billingDetails.value.companyName = ''
        billingDetails.value.streetAddress = ''
        billingDetails.value.zipcode = ''
        billingDetails.value.city = ''
        billingDetails.value.country = ''
        billingDetails.value.email = ''
        return
    }

    loadBilledLead(newVal)
})

watch(() => appStateStore.reloadTimeTrackingEntriesFlag, () => {
    loadEntries();
});

onMounted(() => {
    loadEntries()
    _mfProp('company:default-currency').then(data => {
        currency.value = data
    })
    _mfProp('company:invoicing-configured').then(data => {
        companyHasValidInvoiceSettings.value = data

        if (data !== true) {
            _mfPropForget('company:invoicing-configured')
        }
    })
    _mfPrefGet('time-tracking-billed-filter').then(data => {
        selectedFilter.value = data
    })

})
</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex flex-col items-center justify-between space-x-4 md:flex-row">
                    <h1>{{ _mft('timeTracking:title') }}</h1>

                    <div class="flex items-center justify-end w-full pt-2 pr-4 space-x-4 md:pt-0 md:pr-0 md:w-auto">
                        <div
                            class="h-full py-px pl-3 pr-6 -mr-8 text-xs leading-3 text-right text-gray-700 transition-all border rounded-l-wl"
                            :class="selectedEntries.length === 0 ? 'opacity-0 translate-x-8' : ''"
                        >
                            <div
                                v-if="selectedCompanyName"
                                class="mb-0.5 pb-0.5 border-b max-w-32 overflow-hidden overflow-ellipsis whitespace-nowrap"
                            >{{ selectedCompanyName }}</div>
                            <div class="flex justify-between space-x-4">
                                <span>{{ selectedHours }} h</span>
                                <span>{{ selectedMoney}} {{ currency }}</span>
                            </div>
                        </div>
                        <button-base
                            :style-type="selectedEntries.length === 0 ? 'disabled' : 'primary'"
                            :disabled="selectedEntries.length === 0"
                            @click="createPaymentLink"
                        >
                            {{ _mft('timeTracking:createInvoice') }}
                        </button-base>
                        <story-lane-modal :module="'time-tracking'"></story-lane-modal>
                    </div>
                </div>
            </frosted-bar>
        </div>


        <div class="flex justify-center mt-8">
            <div class="grid grid-cols-3 gap-8">
                <widget-single-value
                    v-if="billableHours > 0"
                    :main-value="new Intl.NumberFormat('sv-SE', {maximumFractionDigits: 2}).format(billableHours)"
                    :main-byline="'billable ' + _mft('shared:date.hour', {count: billableHours})"
                ></widget-single-value>
                <widget-single-value
                    v-if="billableHours > 0"
                    :main-value="new Intl.NumberFormat('sv-SE', {}).format(billableMoney)"
                    :main-byline="currency + ' ' +_mft('timeTracking:billable.asSuffix')"
                ></widget-single-value>
                <widget-single-value
                    v-if="filteredEntries.length > 0"
                    :main-value="new Intl.NumberFormat('sv-SE', {}).format(filteredEntries.length)"
                    :main-byline="entriesByline"
                    class="relative overflow-visible"
                >
                    <div
                        class="absolute -bottom-2 right-1"
                    >
                        <button-secondary
                            size="xs"
                            @click="exportEntries"
                        >
                            {{ _mft('shared:export') }}
                        </button-secondary>
                    </div>
                </widget-single-value>
            </div>
        </div>

        <div class="space-y-2 md:space-y-0 md:flex space-x-2 w-full items-center @container">
            <div class="flex flex-col items-center justify-end w-full space-y-2 md:space-y-0 md:space-x-4 md:flex-row md:items-center">
                <div>
                    <button-bar
                        :options="filterOptions"
                        :selected-option-name="selectedFilter"
                        @select="setFilter"
                        :responsive="false"
                        size="xs"
                        class="!mb-0"
                    />
                </div>
                <input-text
                    v-model:content="filterTerm"
                    :placeholder="_mft('shared:search')"
                    class="w-full md:w-48 !mb-0 -mt-1"
                    input-class="text-xs"
                />
            </div>
        </div>

        <div class="mt-2 overflow-hidden bg-white shadow-xl rounded-wl">
            <p
                class="pt-6 pb-4 text-center text-gray-500"
                v-if="filteredEntries.length === 0"
            >
                {{ _mft('timeTracking:entry.noneToShow') }}
            </p>

            <list-basic>
                <list-basic-item
                    v-for="(entry, index) in filteredEntries"
                    :key="index"
                >
                    <template #title>
                        <div
                            v-if="entry.lead_id"
                            class="text-xs text-gray-400"
                        >
                            {{ entry.lead?.name }}
                        </div>
                        <span
                            @click="! entry.billed ? editingEntry = entry : null"
                            :class="! entry.billed ? 'mf-link' : ''"
                        >
                            {{ entry.title }}
                        </span>
                    </template>
                    <template #status>
                        <div class="w-4 h-4 mr-2">
                            <i
                                v-if="entry.billed"
                                class="fa-regular fa-circle-check text-myflowGreen"
                            />
                        </div>
                    </template>
                    <div class="space-y-1">
                        <div v-if="entry.start_time" class="space-x-4 text-xs text-gray-400">
                            <span v-if="entry.start_date">{{ entry.start_date }}</span>
                            <span v-if="entry.end_date && entry.end_date !== entry.start_date"> - {{ entry.end_date }}</span>
                            <span>
                                <span v-if="entry.start_time">{{ entry.start_time }}</span>
                                <span v-if="entry.end_time"> - {{ entry.end_time }}</span>
                            </span>
                        </div>
                        <div v-else class="space-x-4 text-xs text-gray-400">
                            {{ entry.date }}
                        </div>
                        <div class="space-x-1 text-xs text-gray-600">
                            <span>{{ entry.hours }} {{ _mft('shared:date.hour', {count: parseInt(entry.hours, 10)}) }}</span>
                            <span v-if="entry.product?.price">&times; {{ entry.product.price }} {{ currency }} = {{ (entry.minutes / 60) * entry.product.price }} {{ currency }}</span>
                        </div>
                        <div
                            v-if="!entry.billable"
                            class="text-xs font-bold text-gray-400"
                        >
                            {{ _mft('timeTracking:notBillable') }}
                        </div>
                    </div>
                    <template #actions>
                        <span
                            v-if="! entry.billed && (selectedCompany === null || entry.lead_id === selectedCompany || entry.lead_id === null)"
                            class="flex justify-end space-x-1"
                        >
                            <button-with-confirmation
                                v-if="selectedEntries.length === 0"
                                @confirmed="deleteEntry(entry)"
                                size="sm"
                                modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-10"
                                buttonClass="text-wlPrimaryContrast"
                                :confirmButtonLabel="_mft('shared:action.delete')"
                                confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                                :confirm-text="_mft('timeTracking:delete.areYouSure')"
                            ><i class="fa-light fa-trash"></i></button-with-confirmation>

                            <button-base
                                v-if="!selectedEntries.includes(entry) && entry.billable"
                                size="sm"
                                @click="selectEntry(entry)"
                            >{{ _mft('timeTracking:select') }}</button-base>
                            <button-primary
                                v-if="selectedEntries.includes(entry)"
                                size="sm"
                                @click="deselectEntry(entry)"
                            >{{ _mft('timeTracking:deselect') }}</button-primary>
                        </span>
                        <div
                            v-if="entry.billed && selectedEntries.length === 0"
                            class="flex flex-col items-end space-y-2 mf-link"
                        >
                            <div
                                class="flex space-x-1 text-xs text-right text-gray-400 cursor-pointer"
                                @click="viewingPaymentLink = entry.invoicing_link_uuid"
                            >
                                <i class="fa-solid fa-file-invoice mt-0.5"></i>
                                <span class="overflow-hidden whitespace-nowrap max-w-24 overflow-ellipsis">{{ entry.invoicing_link_title }}</span>
                            </div>
                        </div>
                    </template>
                </list-basic-item>

            </list-basic>
        </div>
        <slideout-with-slots
            v-if="creatingPaymentLink"
            @close="creatingPaymentLink = false"
            max-width-class="max-w-xl"
            :title="_mft('timeTracking:createInvoice')"
        >
            <template #body>
                <ui-attention
                    v-if="! companyHasValidInvoiceSettings"
                    class="text-center"
                >
                    <h5 class="mb-2">
                        {{ _mft('invoiceLink:noValidInvoicingSettings.title') }}
                    </h5>
                    <div>
                        <a
                            :href="route('settings.company.invoicing.show')"
                            target="_blank"
                        >
                            <button-primary>
                                {{ _mft('invoiceLink:noValidInvoicingSettings.link') }}
                            </button-primary>
                        </a>
                        <p class="mt-2 text-xs text-gray-400">
                            {{ _mft('invoiceLink:noValidInvoicingSettings.link.byline') }}
                        </p>
                    </div>
                </ui-attention>

                <div
                    v-if="companyHasValidInvoiceSettings"
                >
                    <expandable-section
                        :state="billingDetailsValidate ? 'info' : 'error'"
                        :inital-collapsed="billingDetailsValidate"
                    >
                        <template v-slot:title>
                            {{ _mft('timeTracking:billingDetails') }}
                        </template>
                        <template v-slot:body>
                            <div class="relative z-30 grid grid-cols-2 mb-4 gap-x-4 gap-y-2">
                                <input-text
                                    :label="'timeTracking:billingDetails.name'"
                                    v-model:content="billingDetails.name"
                                ></input-text>
                                <input-text
                                    :label="'timeTracking:billingDetails.company.name'"
                                    v-model:content="billingDetails.companyName"
                                    required
                                ></input-text>
                                <input-text
                                    :label="'timeTracking:billingDetails.company.invoiceEmail'"
                                    v-model:content="billingDetails.email"
                                    required
                                ></input-text>
                                <input-text
                                    :label="'timeTracking:billingDetails.company.billingStreetAddress'"
                                    v-model:content="billingDetails.streetAddress"
                                    required
                                ></input-text>
                                <input-text
                                    :label="'timeTracking:billingDetails.company.billingZipcode'"
                                    v-model:content="billingDetails.zipcode"
                                    required
                                ></input-text>
                                <input-text
                                    :label="'timeTracking:billingDetails.company.billingCity'"
                                    v-model:content="billingDetails.city"
                                    required
                                ></input-text>
                                <input-language
                                    :label="_mft('timeTracking:billingDetails.company.billingCountry')"
                                    name="country"
                                    required
                                    v-model:country="billingDetails.country"
                                    @selected="billingDetails.country = $event.label"
                                />
                                <input-text
                                    :label="'timeTracking:billingDetails.company.billingReference'"
                                    v-model:content="billingDetails.reference"
                                ></input-text>
                            </div>
                        </template>
                    </expandable-section>

                    <div class="relative">
                        <div
                            v-if="! billingDetailsValidate"
                            class="absolute inset-0 z-20 bg-white/80"
                        ></div>

                        <invoice-link-form
                            v-if="creatingPaymentLink"
                            :preset-products="paymentLinkProducts"
                            :preset-price="presetPrice"
                            :preset-title="presetTitle"
                            :preset-type="'sendable-invoice'"
                            :preset-create-account="false"
                            :redirect="false"
                            :show-title="false"
                            form-style="ui"
                            :billing-details="billingDetails"
                            :hidden-features="['backlink', 'active', 'invoiceLinkType', 'instalments', 'validFrom', 'validThrough', 'createAccount', 'maximumNumberOfPurchases', 'redirectAfter']"
                            class="container"
                            @payment-link-created="onPaymentLinkCreated"
                        ></invoice-link-form>
                    </div>
                </div>
            </template>
        </slideout-with-slots>

        <slideout-with-slots
            v-if="editingEntry !== null"
            @close="editingEntry = null"
            ref="editingEntrySlideout"
            max-width-class="max-w-lg"
        >
            <template #body>
                <time-tracking-form
                    :editing-entry="editingEntry"
                    @close="editingEntrySlideout.close()"
                    @reload="loadEntries"
                ></time-tracking-form>
            </template>
        </slideout-with-slots>

        <slideout-with-slots
            v-if="viewingPaymentLink"
            @close="viewingPaymentLink = ''"
            max-width-class="max-w-xl"
        >
            <template #body>
                <invoice-link-form
                    :redirect="false"
                    :invoice-link-uuid="viewingPaymentLink"
                    :show-title="false"
                    form-style="ui"
                    :hidden-features="['backlink', 'active', 'invoiceLinkType', 'instalments', 'validFrom', 'validThrough', 'createAccount', 'maximumNumberOfPurchases', 'redirectAfter', 'navigation']"
                    class="container"
                ></invoice-link-form>

                <div
                    class="flex justify-center mt-4"
                >
                    <button-secondary
                        @click="exportEntriesFromPaymentLink"
                    >
                        {{ _mft('timeTracking:exportTimeEntries') }}
                    </button-secondary>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>
