<script setup>

import {computed, onMounted, ref} from "vue";
import {shuffle} from "lodash";

const emit = defineEmits(['completed']);
const fadeIn = ref(false);
const messageStrings = shuffle([
    'Generating content',
    'Optimizing potential',
    'Briefly traversing spacetime',
    'Coordinating resources',
    'Scouring the web for inspiration',
    'Assembling bits and bytes',
    'Finding Waldo',
    'Checking the instructions',
    'Baking cookies',
    'Creating layout',
    'Doing a few pushups',
    'Writing texts',
    'Measuring twice',
    'Wielding wands',
    'Looking for perfect images',
    'Adding kittens',
    'Making sure everything is in place',
    'Removing excess kittens',
    'Considering different rules of thirds',
    'Waiting for the light to be just perfect',
    'Adjusting contrast ratios',
    'Sharpening pixels',
    'Tuning the quantum flux',
    'Compiling good vibes',
    'Teaching robots to dance',
    'Searching for missing Oxford commas',
    'Crafting digital magic',
    'Consulting the algorithmic oracle',
    'Syncing with parallel universes',
    'Herding internet hamsters',
    'Creating memes',
    'Searching for the “Any” key',
    'Turning it off and on again',
    'Deploying squirrels',
    'Running a background check on Murphy’s Law',
    'Firing up the flux capacitor',
    'Ordering pizza',
    'Building Rome in less than a day',
    'Tweaking margins',
    'Polishing the final product',
    'Making sure everything is just right',
    'Filling up empty coffee cups',
]);

const messages = computed(() => {
    const msgs = [];

    messageStrings.forEach((label, index) => {
        msgs.push({
            label: label,
            duration: index === 0 ? 1000 : Math.floor(Math.random() * (2500 - 1500 + 1)) + 1500,
        });
        msgs.push({
            label: '',
            duration: 1200,
        });

        if (index === 5) {
            msgs.push({
                label: 'You look great by the way',
                duration: 2500,
            });
            msgs.push({
                label: '',
                duration: 1200,
            });
        }

    });

    return msgs
});
const currentMessage = ref(null);

onMounted(() => {
    setTimeout(function(){
        fadeIn.value = true;
    }, 2000)

    let i = 0;
    messages.value.forEach((message, index) => {
        setTimeout(() => {
            currentMessage.value = index;
        }, i);
        i += message.duration;
        if (index === messages.value.length - 1) {
            i = 0
        }
    });
});

</script>

<template>
    <div
        class="text-center transition-opacity duration-1000"
        :class="fadeIn ? 'opacity-100' : 'opacity-0'"
    >
        <div>
            <div class="mb-2 text-center">
                <i class="w-16 h-16 text-5xl scale-150 fa-light fa-hat-wizard text-myflowPurple" aria-hidden="true"></i>
            </div>
            <div class="flex flex-col items-center justify-center">
                <p class="text-xl text-center"></p>
            </div>
        </div>
        <ul class="relative h-4 w-full text-center text-md mb-8">
            <li
                v-for="(message, i) in messages"
                class="absolute inset-0 pointer-events-none transition-all duration-700"
                :class="i === currentMessage ? 'opacity-100' : currentMessage > i ? 'opacity-0 -translate-x-24' : 'opacity-0 translate-x-24'"
            >
                {{ message.label }}
            </li>
        </ul>
        <p class="max-w-sm text-center text-gray-500 text-xs">
            This should only take a couple of minutes. Treat yourself to a few moments of just leaning back and doing nothing.<br><br>
            <em>You deserve it.</em>
        </p>
    </div>
</template>

<style scoped>

</style>
