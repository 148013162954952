<script setup>

import {computed, onMounted, reactive, ref, watch} from "vue";
import ToggleSwitch from "../../forms/toggleSwitch.vue";

const emit = defineEmits(['options-updated'])

const props = defineProps({
    baseColor: {
        type: String,
        required: false,
        default: '#ebebeb'
    },
    presetTemplateOptions: {
        type: Object,
        required: false,
        default: null,
    }
})

const useDefaults = ref(false)
const enableVideo = ref(false)
const presetColor = ref('hsl(0,0%,45%)');
const colors = reactive({
    primary: {
        background: 'hsl(0,0%,30%)',
        text: 'hsl(0,0%,94%)',
    },
    secondary: {
        background: 'hsl(0,0%,45%)',
        text: 'hsl(0,0%,94%)',
    },
    light: {
        background: 'hsl(0,0%,45%)',
        text: 'hsl(0,0%,94%)',
    },
    dark: {
        background: 'hsl(0,0%,30%)',
        text: 'hsl(0,0%,94%)',
    }
})

const hexToHsl = (hex) => {
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
    let result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
    if (!result) {
        return null;
    }
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    r /= 255, g /= 255, b /= 255;
    let max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
        h = s = 0;
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return {
        h: h,
        s: s,
        l: l
    };
}

const updateColors = (color) => {
    const toHSLArray = color.match(/\d+/g).map(Number);
    const lightness = toHSLArray[2];

    let isLight
    let secondaryLightness
    let primaryTextcolor

    if (lightness >= 50) {
        isLight = true
        secondaryLightness = lightness - 30
        primaryTextcolor = 'hsl(0, 100%, 5%)'
    } else {
        isLight = false
        secondaryLightness = lightness + 30
        primaryTextcolor = 'hsl(0, 100%, 94%)'
    }

    colors.primary.background = color;
    colors.primary.text = primaryTextcolor;

    colors.secondary.background = `hsl(${toHSLArray[0]}, ${toHSLArray[1]}%, ${secondaryLightness}%)`;
    colors.secondary.text = `hsl(0, 100%, ${secondaryLightness < 50 ? '94' : '5'}%)`;

    if (isLight) {
        colors.light = colors.primary
        colors.dark = colors.secondary
    } else {
        colors.light = colors.secondary
        colors.dark = colors.primary
    }
}

const setColorFromColor = (initialColor) => {
    // Basecolor is expected to be a hex color
    const color = hexToHsl(initialColor)
    try {
        presetColor.value = ['hsl('+color.h+', '+color.s+'%, '+color.l+'%)'].join('');
    } catch (e) {
        presetColor.value = 'hsl(0,0%,45%)';
    }
}

const options = computed(() => {
    return {
        enableVideo: enableVideo.value,
        colors: colors,
    }
})

watch(() => options.value, () => {
    emit('options-updated', options.value)
}, {deep: true})

onMounted(() => {
    if (props.presetTemplateOptions) {
        useDefaults.value = true
        presetColor.value = props.presetTemplateOptions.colors.primary.background
        return
    }

    setColorFromColor(props.baseColor)
})
</script>

<template>
    <div class="pt-12">
        <div class="mb-8 text-center">
            <h2 class="mb-4 text-2xl md:text-3xl">
                Pick a look
            </h2>
            <p class="md:text-lg">This is just to get you started, you can tinker with how you look later<br>
                <span class="text-sm text-gray-400">(if you want to)</span></p>
        </div>
        <div
            v-if="useDefaults"
        >
            <div class="grid grid-cols-2 gap-6 max-w-md mx-auto">
                <div
                    class="h-12 rounded-lg flex items-center justify-center"
                    :style="{backgroundColor: presetTemplateOptions.colors.light.background, color: presetTemplateOptions.colors.light.text}"
                >
                    Text
                </div>
                <div
                    class="h-12 rounded-lg flex items-center justify-center"
                    :style="{backgroundColor: presetTemplateOptions.colors.dark.background, color: presetTemplateOptions.colors.dark.text}"
                >
                    Text
                </div>
            </div>
            <div class="flex justify-center mt-2 mb-8">
                <button-base
                    size="sm"
                    @click="useDefaults = false"
                >Change color</button-base>
            </div>
        </div>
        <div v-else>
            <div class="p-4 mb-8 -mx-4 bg-white rounded-lg">
                <p class="mb-0 text-lg">Pick your brand color, or just a color you like</p>
                <p class="text-gray-400">We'll make sure to add a complementary shade</p>
                <div class="flex mb-4 space-x-4">
                    <div
                        :style="{backgroundColor: colors.primary.background}"
                        :class="colors.primary.background ? 'h-16' : 'h-0'"
                         class="flex items-center justify-center w-full transition-all rounded-md"
                    >
                        <span :style="{color: colors.primary.text}">Text</span>
                    </div>
                    <div
                        :style="{backgroundColor: colors.secondary.background}"
                        :class="colors.secondary.background ? 'h-16' : 'h-0'"
                         class="flex items-center justify-center w-full transition-all rounded-md"
                    >
                        <span :style="{color: colors.secondary.text}">Text</span>
                    </div>
                </div>
                <color-picker
                    @color-changed="updateColors"
                    @clear-color="console.log('clear color')"
                    :color="presetColor"
                    :debounce="150"
                    class="overflow-hidden bg-white rounded-t-md"
                />
            </div>
        </div>
        <div class="space-y-12">
            <div>
                <p class="mb-0 text-lg">
                    Got a video to show how awesome you are?
                </p>
                <p class="mb-1 text-sm text-gray-400">Don't worry, you can always remove it later.</p>
                <toggle-switch
                    v-model:content="enableVideo"
                    label="Add a video"
                    class="text-base"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
