<script setup>

import {onMounted, ref, watch} from "vue";

const props = defineProps({
    inputs: {
        type: Object,
        required: true,
    },
    formula: {
        type: String,
        required: true,
    },
    options: {
        type: Object,
        required: true,
    },
})

const resultValidates = ref(true)
const showResult = ref(false)
const result = ref(null)
const _inputs = ref({})

const compileInputs = () => {
    _inputs.value = {}
    Object.values(props.inputs).forEach((value, key) => {
        _inputs.value[value.name] = {
            name: value.name,
            label: value.label,
            inputValue: value.defaultValue
        }
    })
}

const calculateResult = () => {
    result.value = 0
    const variables = {};
    try {
        for (const key in props.inputs) {
            variables[key] = _inputs.value[key].inputValue;
        }

        const formulaFunction = new Function(...Object.keys(variables), `return ${props.formula};`);

        showResult.value = true
        resultValidates.value = true
        result.value = formulaFunction(...Object.values(variables)).toFixed(props.options.decimals);
    } catch (e) {
        console.error('Calculation failed')
        showResult.value = true
        resultValidates.value = false
        return null
    }
}

watch(() => props.inputs, () => {
    compileInputs()
}, {deep: true})

onMounted(() => {
    compileInputs()
})

</script>

<template>
    <div class="bg-white rounded-wl p-8">
        <div v-if="! showResult">
            <div>
                <div
                    v-for="(input, i) in _inputs"
                    :key="i"
                >
                    <input-number
                        :label="input.label"
                        v-model:content="input.inputValue"
                    ></input-number>
                </div>
            </div>
            <div class="flex justify-center">
                <button-primary
                    :disabled="!Object.keys(_inputs).length"
                    @click="calculateResult"
                >
                    {{ props.options.buttonLabelCalculate }}
                </button-primary>
            </div>
        </div>
        <div v-if="showResult">
            <div
                v-if="! isNaN(result) && resultValidates"
                class="text-center"
            >
                <p
                    v-if="props.options.title"
                    class="text-lg"
                >
                    {{ props.options.title }}
                </p>
                <div class="text-xl font-bold mb-4">
                    {{ props.options.prefix}}
                    {{ result }}
                    {{ props.options.suffix}}
                </div>
                <p
                    v-if="props.options.byline"
                >
                    {{ props.options.byline }}
                </p>
            </div>
            <div
                v-else
                class="mb-4 text-center"
            >
                Calculation error, check your values and formula.
            </div>
            <div class="flex justify-center">
                <button-primary
                    @click="showResult = false"
                >
                    {{ props.options.buttonLabelBack }}
                </button-primary>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
