<script setup>
import {computed, onMounted, ref} from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const url = computed(() => {
    if (! props.content.text) {
        return '';
    }
    return props.content.text.trim() + '?embed_domain=improved-nordic-ab.myflow.se&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1'
});
const loaded = ref(false);

onMounted(() => {
    setTimeout(function(){
        loaded.value = true
    }, 1000)
});

</script>

<template>
    <div>
        <mf-spinner-medium v-if="!loaded" class="w-24 h-12"></mf-spinner-medium>
        <iframe
            :src="url"
            width="100%"
            height="100%"
            style="border: 0; height: 700px;"
            title="Select a Date &amp; Time - Calendly"
            class="transition-opacity duration-500"
            :class="! loaded ? 'opacity-0' : 'opacity-100'"
        ></iframe>
    </div>
</template>

