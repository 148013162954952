<script setup>
import {computed, inject, ref, watch} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    block: {
        type: Object,
        required: true
    },
})

let anchorableId = null
try {
    anchorableId = props.block.uuid?.substring(props.block.uuid?.length - 12)
} catch (e) {
    console.error(e)
}

const formDatastore = inject('formDatastore');
const fieldValue = ref(null)
watch(() => fieldValue.value, (value) => {
    formDatastore.fields[props.uuid] = value;
});

const displayField = computed(() => {
    let display = true
    if (props.block.conditions && props.block.conditions.or && props.block.conditions.or.length > 0) {
        display = false

        if (Object.keys(formDatastore.fields).length > 0) {
            props.block.conditions.or.forEach(condition => {
                if (formDatastore.fields[condition.inputUuid]) {
                    if (formDatastore.fields[condition.inputUuid].includes(condition.alternative)) {
                        display = true
                    }
                }
            })
        }
    }

    return display
});


</script>
<template>
    <div
        class="styleable-block anchorable"
        :id="anchorableId"
        :class="displayField ? '' : 'hidden'"
    >
        <component
            :is="'page-builder-block-view-' + props.block.type"
            :container-ref="containerRef"
            v-bind="props.block"
            :data-blocktype="props.block.type"
        ></component>
    </div>
</template>
