<script setup>

import {computed, onMounted, ref, watch} from "vue";
import PillBase from "../../pills/pillBase.vue";

const props = defineProps({
    community: {
        type: Object,
        required: true,
    },
    member: {
        type: Object,
        required: true,
    }
})

const managingBadges = ref(false)
const assignedBadges = ref([])
const availableBadges = ref([])
const badgesLoaded = ref(false)
const searchTerm = ref('')

const loadMemberBadges = async () => {
    const res = await axios.get(route('community.admin.api.memberBadges', [props.community.id, props.member.id]));
    assignedBadges.value = res.data.assignedBadges
    availableBadges.value = res.data.availableBadges
    badgesLoaded.value = true
}

const assignBadge = async (badge) => {
    await axios.post(route('community.admin.api.assignBadge', [props.community.id, props.member.id, badge.id]));
    await loadMemberBadges();
}

const unassignBadge = async (badge) => {
    await axios.delete(route('community.admin.api.unassignBadge', [props.community.id, props.member.id, badge.id]));
    await loadMemberBadges();
}

const createBadge = async () => {
    if (searchTerm.value === '') {
        return;
    }
    const res = await axios.post(route('community.admin.api.createBadge', [props.community.id]), {name: searchTerm.value});
    await assignBadge(res.data.badge);
    searchTerm.value = ''
}

const unassignedBadges = computed(() => {
    if (searchTerm.value === '') {
        return availableBadges.value.filter(badge => !assignedBadges.value.find(assignedBadge => assignedBadge.id === badge.id))
    }

    return availableBadges.value.filter(badge => !assignedBadges.value.find(assignedBadge => assignedBadge.id === badge.id) && badge.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
})

watch(() => managingBadges.value, (newValue) => {
    if (newValue) {
        loadMemberBadges();
    }
})

onMounted(() => {
    assignedBadges.value = props.member.badges
})

</script>

<template>
<div>
    <ul class="flex items-center flex-wrap space-x-1">
       <li
           v-for="(badge, b) in assignedBadges"
           :key="b"
           class="block bg-wlPrimary text-wlPrimaryContrast rounded-wl cursor-default text-xxs px-1.5 py-px"
       >
           {{ badge.name }}
       </li>
        <button-base
            size="xs"
            @click="managingBadges = true"
        >
            Manage badges
        </button-base>
    </ul>
    <slideout-with-slots
        v-if="managingBadges"
        @close="managingBadges = false"
    >
        <template #title>
            <h2>
                {{ _mft('community:badges.title') }}
            </h2>
        </template>
        <template #body>
            <p
                class="text-gray-400 text-xs"
                v-html="_mft('community:badges.intro')"
            ></p>
            <div class="flex space-x-1 items-center mb-4">
                <input-text
                    v-model:content="searchTerm"
                    :placeholder="_mft('community:badge.search.placeholder')"
                    class="!mb-0 w-full"
                />
                <button-base
                    :style-type="searchTerm === '' ? 'disabled' : 'primary'"
                    size="sm"
                    class="mt-1"
                    @click="createBadge"
                >
                    {{ _mft('community:badge.create') }}
                </button-base>
            </div>
            <ul class="flex flex-wrap">
                <li
                    v-for="badge in assignedBadges"
                    :key="badge.id"
                    class="mb-2 mr-2"
                >
                    <button-primary
                        size="sm"
                        @click="unassignBadge(badge)"
                    >
                        {{ badge.name }}
                    </button-primary>
                </li>
            </ul>
            <p
                v-if="badgesLoaded && unassignedBadges.length === 0"
                class="text-gray-400 italic"
            >
                {{ _mft('community:badge.noBadgesFound') }}
            </p>
            <ul class="flex flex-wrap">
                <li
                    v-for="badge in unassignedBadges"
                    :key="badge.id"
                    class="mb-2 mr-2"
                >
                    <button-secondary
                        size="sm"
                        @click="assignBadge(badge)"
                    >
                        {{ badge.name }}
                    </button-secondary>
                </li>
            </ul>

        </template>
    </slideout-with-slots>
</div>
</template>

<style scoped>

</style>
