<script setup>
import {computed, onMounted, reactive, ref} from 'vue'

const loading = ref(true);
const widget = reactive({
    id: null,
    name: '',
    uuid: '',
    inputs: {},
    formula: '',
    options: {
        title: '',
        byline: '',
        decimals: 0,
        prefix: '',
        suffix: '',
        buttonLabelCalculate: 'Calculate',
        buttonLabelBack: 'Back',
    },
})
const workingState = ref('')

const props = defineProps({
    widgetId: {
        type: Number,
        required: true,
    }
})

const loadWidget = async () => {
    const res = await axios.get(route('api.external-widgets.edit', props.widgetId))

    widget.id = res.data.widget.id
    widget.name = res.data.widget.name
    widget.uuid = res.data.widget.uuid
    widget.inputs = Object.assign({}, res.data.widget.inputs)
    widget.formula = res.data.widget.formula
    widget.options.title = res.data.widget.options?.title ?? ''
    widget.options.byline = res.data.widget.options?.byline ?? ''
    widget.options.decimals = res.data.widget.options?.decimals ?? 0
    widget.options.prefix = res.data.widget.options?.prefix ?? ''
    widget.options.suffix = res.data.widget.options?.suffix ?? ''
    widget.options.buttonLabelCalculate = res.data.widget.options.buttonLabelCalculate ?? 'Calculate'
    widget.options.buttonLabelBack = res.data.widget.options.buttonLabelBack ?? 'Back'

    loading.value = false
}

const update = async () => {
    workingState.value = 'waiting'
    try {
        const res = await axios.put(route('api.external-widgets.update', props.widgetId), widget)
        if (res.data.success) {
            workingState.value = 'success'
        } else {
            workingState.value = 'error'
        }
    } catch (e) {
        workingState.value = 'error'
    }
}

const addValue = () => {
    const items = Object.keys(widget.inputs)
    let itemCount = items.length

    if (itemCount >= 26) {
        return;
    }

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let i = 0;
    let valueId = 'valueA'

    while (items.includes(valueId)) {
        i++
        valueId = 'value'+characters[i]
    }

    widget.inputs[valueId] = {
        name: valueId,
        label: '',
        defaultValue: null,
    }
}

const removeValue = (name) => {
    widget.inputs = Object.fromEntries(
        Object.entries(widget.inputs).filter(([key, value]) => key !== name)
    )
}

const inputs = computed(() => {
    return Object.values(widget.inputs)
})

onMounted(() => {
    loadWidget()
})

</script>
<template>
    <div>
        <frosted-bar>
            <template #navigation>
                <a :href="route('external-widgets.index')">
                    {{ _mft('externalWidget:domainNamePlural') }}
                </a>
            </template>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ widget?.name || _mft('externalWidget:domainNameSingular') }}</h1>

                <div class="flex items-center space-x-4">
                    <button-success
                        @click="update"
                        :state="workingState"
                    >
                        {{ _mft('shared:action.save') }}
                    </button-success>
                </div>
            </div>
        </frosted-bar>

        <div class="mt-12">
            <div
                v-if="loading"
                class="flex justify-center py-12"
            >
                <mf-spinner-medium class="w-24 h-12"></mf-spinner-medium>
            </div>

            <div v-if="!loading && widget">
                <input-text
                    :label="_mft('externalWidget:input.name.label')"
                    v-model:content="widget.name"
                    class="!mb-0"
                />
                <div class="text-gray-500 text-xs mb-4 pl-0.5">{{ _mft('externalWidget:input.name.label.byline') }}</div>

                <div class="grid grid-cols-2 gap-4">
                    <card-tabbed>
                        <card-tabbed-tab
                            title="Calculation"
                            value="inputs"
                            :active="true"
                        >
                            <div class="space-y-2">
                                <card-sub
                                    v-for="(input, i) in inputs"
                                    :key="i"
                                >
                                    <div class="flex items-end justify-between">
                                        <div class="pr-2">
                                            <div class="font-bold min-h-6">
                                                {{ input.label }}
                                            </div>
                                            <div class="flex items-center space-x-4">
                                                <div>
                                                    <input-text
                                                        label="Field label"
                                                        v-model:content="input.label"
                                                        placeholder="Label"
                                                        class="!mb-0"
                                                    ></input-text>
                                                </div>
                                                <div>
                                                    <input-number
                                                        label="Default value"
                                                        v-model:content="input.defaultValue"
                                                        placeholder="Default value"
                                                        class="!mb-0"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button-danger
                                            size="sm"
                                            @click="removeValue(input.name)"
                                            class="mb-1"
                                        >
                                            <i class="fa-regular fa-trash"></i>
                                        </button-danger>
                                    </div>
                                    <div class="mt-2 text-xs">Field name in formula: <strong class="select-all">{{ input.name }}</strong></div>
                                </card-sub>
                            </div>
                            <div class="flex justify-end my-4">
                                <button-secondary
                                    size="sm"
                                    @click="addValue"
                                    :disabled="Object.keys(widget.inputs).length >= 26"
                                >
                                    Add value
                                </button-secondary>
                            </div>

                            <hr class="mb-4">

                            <div>
                                <input-text
                                    label="Formula"
                                    v-model:content="widget.formula"
                                ></input-text>
                            </div>
                            <div>
                                <input-text
                                    label="Calculate button label"
                                    v-model:content="widget.options.buttonLabelCalculate"
                                ></input-text>
                            </div>
                        </card-tabbed-tab>
                        <card-tabbed-tab
                            title="Result"
                            value="result"
                        >
                            <div class="@container">
                                <input-text
                                    label="Title"
                                    v-model:content="widget.options.title"
                                ></input-text>

                                <input-text
                                    label="Byline"
                                    v-model:content="widget.options.byline"
                                ></input-text>

                                <input-number
                                    label="Number of decimals"
                                    required
                                    v-model:content="widget.options.decimals"
                                ></input-number>

                                <div class="grid grid-cols-2 gap-4">
                                    <input-text
                                        label="Prefix"
                                        v-model:content="widget.options.prefix"
                                    ></input-text>

                                    <input-text
                                        label="Suffix"
                                        v-model:content="widget.options.suffix"
                                    ></input-text>
                                </div>

                                <div>
                                    <input-text
                                        label="Back button label"
                                        v-model:content="widget.options.buttonLabelBack"
                                    ></input-text>
                                </div>
                            </div>
                        </card-tabbed-tab>
                    </card-tabbed>
                    <div>
                        <external-widget-preview
                            :inputs="widget.inputs"
                            :formula="widget.formula"
                            :options="widget.options"
                        ></external-widget-preview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
