<script setup>
import GroupableScores from "./_GroupableScores.vue";
import {computed} from "vue";

const props = defineProps({
    questions: {
        type: Array,
        required: true,
    },
    respondent: {
        type: Object,
        required: true,
    },
    respondentTotals: {
        type: Object,
        required: true,
    },
    groupScores: {
        type: Boolean,
        required: false,
        default: true,
    }
})

const respondentGrandTotals = computed(() => {
    let total = 0;
    for (const [questionUuid, questionTotals] of Object.entries(props.respondentTotals)) {
        total += questionTotals[props.respondent.respondent.id]?.score ?? 0;
    }
    return total;
})

const rowMaxWidth = computed(() => {
    return 160 * (Object.values(props.questions).length + 2) + 'px';
})

</script>

<template>
    <div class="divide-y border-b border-t bg-gray-50 first:border-t-0" :style="{maxWidth: rowMaxWidth}">
        <div class="flex divide-x">
            <div class="stat-cell font-bold !bg-gray-50">
                {{ respondent.respondent.name }}
            </div>
            <div
                class="stat-cell text-right !bg-gray-50 font-bold"
                v-for="(question, i) in questions"
                :key="i"
            >
                {{ respondentTotals[question.uuid][respondent.respondent.id]?.score }}
            </div>
            <div class="stat-cell !bg-gray-50 font-bold text-right">
                {{ respondentGrandTotals }}
            </div>
        </div>
        <div
            v-for="(week, w) in Object.values(respondent.weeks).reverse()"
            :key="w"
        >
            <div class="flex divide-x">
                <div class="stat-cell">
                    {{ _mft('calendar:weekShort', {count: 1}) }}.{{ week.weeknumber }}
                </div>
                <div
                    v-for="(question, q) in week.questions"
                    :key="q"
                    class="stat-cell"
                >
                    <GroupableScores
                        :scores="question"
                        :show-grouped="groupScores"
                    />
                </div>
                <div class="stat-cell"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.stat-cell {
    background: #fff;
    padding: 4px 8px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 160px;
}
</style>
