<script setup>
import {computed, onMounted, ref, watch} from 'vue';
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    const emit = defineEmits(['tagsSelected', 'close', 'doReloadLeads']);

    const props = defineProps({
        preSelectedTags: {
            type: Array,
            required: false,
            default: () => []
        },
        allowCreation: {
            type: Boolean,
            required: false,
            default: true
        },
        placeholder: {
            type: String,
            required: false,
            default: _mft('crm:tag.searchTags')
        },
        selectMultiple: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    const availableTags = ref([]);
    const search = ref('');
    const showAll = ref(false);
    const selectedTags = ref([]);
    const showSuggestions = ref(false);

    const loadTags = () => {
        axios.get(route('crm.leadtags.index')).then((response) => {
            availableTags.value = response.data.tags;
        }).then(() => {
            if (props.preSelectedTags.length > 0 && selectedTags.value.length === 0) {
                props.preSelectedTags.forEach((tagId) => {
                    const _tag = availableTags.value.find((t) => t.id === tagId);
                    if (_tag) {
                        selectTag(_tag);
                    }
                });
            }
        });


    }

    const createTag = () => {
        axios.post(route('crm.leadtags.store'), {
            name: search.value
        }).then((response) => {
            loadTags();
        });
    }

    const selectTag = (tagId) => {
        if (props.selectMultiple) {
            if (selectedTags.value.includes(tagId)) {
                selectedTags.value = selectedTags.value.filter((id) => id !== tagId);
            } else {
                selectedTags.value.push(tagId);
            }
        } else {
            selectedTags.value = [tagId];
            search.value = ''
            showSuggestions.value = false;
        }
    }

    const onSelectionDone = () => {
        showSuggestions.value = false
    }

    const filteredTags = computed(() => {
        return availableTags.value.filter((tag) => {
            return tag.name.toLowerCase().includes(search.value.toLowerCase());
        });
    });

    const limitedFilteredTags = computed(() => {
        if (showAll.value === true) {
            return filteredTags.value;
        } else {
            return filteredTags.value.slice(0, 5);
        }
    });

    const moreTagsAvailable = computed(() => {
        return filteredTags.value.length - 5;
    });

    watch(search, async (newVal, oldVal) => {
       if (newVal.length === 0) {
           // showSuggestions.value = false
       }
    });

    watch(selectedTags,  (newVal, oldVal) => {
        emit('tagsSelected', selectedTags.value);
    }, {deep: true});

    onMounted(() => {
        loadTags();
    });

</script>
<template>
    <div>
        <div class="relative">
            <div class="relative mb-2">
                <input-text
                    class="!mb-0"
                    v-model:content="search"
                    :maxlength="24"
                    :placeholder="placeholder"
                    @focus="showSuggestions = true"
                />
                <button
                    v-if="search && allowCreation"
                    class="absolute top-2 right-1 rounded-md bg-black px-2 py-1 text-white"
                    @click="createTag"
                >{{ _mft('crm:tag.createNew') }}</button>

                <button
                    v-if="search.length === 0 && showSuggestions"
                    class="absolute top-2 right-1 rounded-md bg-wlPrimary px-2 py-1 text-white"
                    @click="onSelectionDone"
                >{{ _mft('shared:done') }}</button>

                <button
                    v-if="search.length > 0"
                    class="absolute top-2 right-1 rounded-md bg-gray-400 px-2 py-1 text-white"
                    @click="search = ''"
                >{{ _mft('shared:action.clear') }}</button>

                <ul
                    v-if="showSuggestions || search.length > 0"
                    class="px-2 bg-white rounded-md shadow-2xl mb-4 flex flex-wrap "
                >
                    <li
                        v-for="tag in limitedFilteredTags"
                        :key="tag.id"
                        @click="selectTag(tag)"
                        class="cursor-pointer rounded-md text-black px-1.5 py-0.5 border-1 bg-gray-200 hover:bg-gray-300 "
                        :class="selectedTags.includes(tag) ? 'hidden' : 'my-1 mr-2'"
                    >
                        {{ tag.name }}
                    </li>
                    <li
                        v-if="limitedFilteredTags.length === 0"
                        class="mr-2 mt-0.5 text-gray-400"
                    >{{ _mft('crm:tag.noTagsFound', {count: moreTagsAvailable }) }}</li>
                    <li
                        v-if="moreTagsAvailable > 0 && !showAll"
                        class="mr-2 cursor-pointer mt-0.5 underline"
                        @click="showAll = true"
                    >{{ _mft('crm:tag.andAnotherNMore', {count: moreTagsAvailable }) }}</li>
                    <li
                        v-if="search.length > 0"
                        class="flex justify-end"
                    >
                        <button
                            v-if="search.length === 0 && showSuggestions"
                            class="rounded-md bg-wlPrimary px-2 py-1 text-white"
                            @click="showSuggestions = false"
                        >{{ _mft('shared:done') }}</button>
                    </li>
                </ul>
            </div>
            <ul class="mb-4 flex flex-wrap">
                <li
                    v-for="tag in selectedTags"
                    :key="tag.id"
                    @click="selectTag(tag)"
                    class="mr-2 mb-1.5 cursor-pointer rounded-md text-black px-1.5 py-0.5 border-1 bg-gray-300 ring-2 ring-gray-500"
                >
                    {{ tag.name }}
                </li>
            </ul>
        </div>
    </div>
</template>
