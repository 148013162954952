<script setup>
import GeneralModal from "../../../../modals/generalModal.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";

const emit = defineEmits(['close', 'submit'])

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    open: {
        type: Boolean,
        required: false,
        default: false
    },
    purchaseLabel: {
        type: String,
        required: false,
        default: _mft('shared:action.purchase')
    },
    performingPurchase: {
        type: Boolean,
        required: false,
        default: false,
    },
    createsAccount: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const emailIsValid = ref(false)
const userIsLoggedIn = ref(false);
const integrityPolicyAccepted = ref(false);
const termsOfServiceAccepted = ref(false);

const billingInfo = reactive({
    name: '',
    company: '',
    email: '',
    address: '',
    identityno: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    phone: '',
})

const submit = () => {
    emit('submit', billingInfo)
}

const billingInfoValidates = computed(() => {
    let valid = emailIsValid.value
        && billingInfo.name !== ''
        && billingInfo.email !== ''
        && billingInfo.address !== ''
        && billingInfo.zip !== ''
        && billingInfo.city !== ''
        && billingInfo.country !== ''

    if (props.createsAccount) {
        valid = valid && integrityPolicyAccepted.value && termsOfServiceAccepted.value
    }

    return valid
})

onMounted(() => {
    if (window.myflow.user && window.myflow.user.loggedIn) {
        billingInfo.name = window.myflow.user.name
        billingInfo.email = window.myflow.user.email

        integrityPolicyAccepted.value = true
        termsOfServiceAccepted.value = true
        emailIsValid.value = true

        userIsLoggedIn.value = true
    }
})

</script>

<template>
<div>
    <slideout-with-slots
        v-if="props.open"
        @close="emit('close')"
    >
        <template #body>
            <h1 class="mb-2 md:mb-4">{{ props.title }}</h1>

            <input-text
                v-model:content="billingInfo.name"
                :label="_mft('shared:name')"
                required
            />

            <input-email
                :label="_mft('shared:email')"
                required
                v-model:content="billingInfo.email"
                @isEmailValid="emailIsValid = $event"
            />

            <input-text
                v-model:content="billingInfo.company"
                :label="_mft('shared:company')"
            />

            <input-text
                v-model:content="billingInfo.identityno"
                :label="_mft('shared:identityno')"
            />

            <input-text
                v-model:content="billingInfo.address"
                :label="_mft('shared:address')"
                required
            />

            <div class="grid grid-cols-2 gap-4">
                <div>
                    <input-text
                        v-model:content="billingInfo.zip"
                        :label="_mft('shared:zip')"
                        required
                    />
                </div>
                <div>
                    <input-text
                        v-model:content="billingInfo.city"
                        :label="_mft('shared:city')"
                        required
                    />
                </div>
                <div>
                    <input-text
                        v-model:content="billingInfo.state"
                        :label="_mft('shared:state')"
                    />
                </div>
                <div>
                    <input-text
                        v-model:content="billingInfo.country"
                        :label="_mft('shared:country')"
                        required
                    />
                </div>
            </div>

            <input-text
                v-model:content="billingInfo.phone"
                :label="_mft('shared:phone')"
            />

            <div v-if="! userIsLoggedIn">
                <div class="flex items-center justify-start">
                    <input-checkbox
                        v-if="props.createsAccount"
                        v-model:content="integrityPolicyAccepted"
                        :label="_mft('auth:terms.accept')"
                        required
                        class="-mr-9"
                    />
                    <span class="mt-1">
                        <a :href="route('site-policy.show', 'integrity-policy')" target="_blank" >
                            {{ _mft('auth:terms.integrityPolicy') }}
                        </a>
                    </span>
                </div>

                <div class="flex items-center justify-start">
                    <input-checkbox
                        v-if="props.createsAccount"
                        v-model:content="termsOfServiceAccepted"
                        :label="_mft('auth:terms.accept')"
                        required
                        class="-mr-9"
                    />
                    <span class="mt-1">
                        <a :href="route('site-policy.show', 'integrity-policy')" target="_blank" >
                            {{ _mft('auth:terms.termsOfService') }}
                        </a>
                    </span>
                </div>
            </div>

            <div class="min-h-5">
                <p
                    v-if="! billingInfoValidates"
                    class="text-xs mt-4 pb-4 text-right text-gray-400"
                >
                    {{ _mft('pagebuilderBlock:invoiceLink.fillAllFieldsToPurchase') }}
                </p>
            </div>

            <div class="flex justify-end pb-24">
                <button-secondary
                    v-if="! billingInfoValidates"
                    disabled
                >
                    {{ props.purchaseLabel }}
                </button-secondary>
                <button-success
                    v-else
                    @click="submit"
                    :state="props.performingPurchase ? 'waiting' : ''"
                >
                    {{ props.purchaseLabel }}
                </button-success>
            </div>

        </template>
    </slideout-with-slots>
</div>
</template>

<style scoped>

</style>
