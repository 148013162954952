<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
            class="block w-full pr-10 mt-1 p-2 border rounded-md bg-white text-gray-900"
            :class="inputClass"
        >
            <label
                :class="['block', i > 0 ? 'mt-2' : '']"
                v-for="(option, i) in options"
                v-bind:key="i"
            >
                <input
                    class="mr-2"
                    type="checkbox"
                    :value="option.value"
                    :name="`${name}[]`"
                    :required="required && (!value || value.length === 0)"
                    v-model="value"
                    @change="emitValue"
                />
                {{option.label}}
            </label>

            <label
                v-if="allowCustomAnswer"
                class="flex items-center mt-2"
            >
                <input
                    class="mr-2"
                    type="checkbox"
                    :name="`${name}[]`"
                    :value="customAnswer"
                    :required="false"
                    v-model="enableCustomAnswer"
                    @change="emitValue"
                />
                <input-text
                    class="!mb-0"
                    :disabled="! enableCustomAnswer"
                    v-model:content="customAnswer"
                />
            </label>

        </div>
        <input-error v-if="error" :error="error" />
    </div>
</template>

<script>
export default {
    data: function(){
        return {
            inputClass: '',
            value: [],
            customAnswer: '',
            enableCustomAnswer: false,
        }
    },
    props: {
        content: Array,
        options: Array,
        error: String,
        label: String,
        required: Boolean,
        name: String,
        allowCustomAnswer: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        content: {
            handler() {
                if (JSON.stringify(this.content) === JSON.stringify(this.value)) { return; }

                this.value = this.content ? JSON.parse(JSON.stringify(this.content)) : [];
            },
            immediate: true
        },
        customAnswer: {
            handler() {
                this.emitValue()
            },
            immediate: true
        },
        error: function(error){
            let classes = [];
            if( error !== '' ){
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClass = classes.join(' ')
        }
    },
    methods: {
        emitValue() {
            let _value = [...this.value]
            if (this.enableCustomAnswer) {
                _value.push(this.customAnswer)
            }

            this.$emit('update:content', _value)
        }
    }
}
</script>
