<template>
    <div>
        <div>
            <label>
                Password Length:
            </label>
            <input-text
                type="number"
                v-model:content="passwordLength"
            />

            <label>
                Generated Password:
            </label>

            <input-text
                type="text"
                v-model:content="generatedPassword"
                readonly
                maxlength="255"
            />

            <div class="flex justify-end">
                <button-secondary
                    size="sm"
                    @click="generatePassword"
                >
                    Generate
                </button-secondary>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const generatedPassword = ref('')
const passwordLength = ref(16)

const generatePassword = () => {
    const length = passwordLength.value
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+'
    let password = ''
    for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    generatedPassword.value = password
}

onMounted(() => {
    generatePassword()
})

</script>
