<script setup>
import {computed, onMounted, ref, watch} from 'vue';

const props = defineProps({
    styles: {
        type: Object,
        required: false,
        default: {}
    },
    containerRef: {
        type: String,
        required: true
    },
    containerType: {
        type: String,
        required: true,
    },
    rowUuid: {
        type: String,
        required: true
    },
    persistentClass: {
        type: String,
        required: false,
        default: ''
    }
})

const backgroundImageStyle = ref({})
const forceRefresh = ref(false);
const transitionableRow = ref(null)
const transitionOnEntry = ref(false)
const transitioned = ref(false)

const appliedStyles = computed(() => {
    forceRefresh.value = !forceRefresh.value;
    let aStyles = {};

    if (props.containerType === 'app_page') {
        aStyles.desktopBackgroundColor = props.styles.app?.backgroundColor ? props.styles.app.backgroundColor : 'transparent';
        aStyles.mobileBackgroundColor = props.styles.app?.backgroundColor ? props.styles.app.backgroundColor : 'transparent';

        aStyles.desktopColor = props.styles.app?.color ? props.styles.app.color : '#212529'
        aStyles.mobileColor = props.styles.app?.color ? props.styles.app.color : '#212529'

        aStyles.mobileRowMinHeight = props.styles.app?.rowMinHeight ? props.styles.app.rowMinHeight : 'auto'
        aStyles.desktopRowMinHeight = props.styles.app?.rowMinHeight ? props.styles.app.rowMinHeight : 'auto'

        aStyles.verticalAlignment = '';
        aStyles.verticalAlignment = props.styles.app?.verticalAlignment ? props.styles.app.verticalAlignment : 'start';

        aStyles.verticalAlignmentInnerHeight = 'auto';
        aStyles.verticalAlignmentInnerHeight = props.styles.app?.verticalAlignment === 'stretch' ? '100%' : 'auto';

        return aStyles
    }

    aStyles.mobileMaxWidth = props.styles.mobile.maxWidth ? props.styles.mobile.maxWidth : '100%';
    aStyles.desktopMaxWidth = props.styles.desktop.maxWidth ? props.styles.desktop.maxWidth : props.styles.mobile.maxWidth ? props.styles.mobile.maxWidth : '100%';

    aStyles.mobileRowMinHeight = props.styles.mobile.rowMinHeight ? props.styles.mobile.rowMinHeight : 'auto';
    aStyles.desktopRowMinHeight = props.styles.desktop.rowMinHeight ? props.styles.desktop.rowMinHeight : props.styles.mobile.rowMinHeight ? props.styles.mobile.rowMinHeight : 'auto';

    aStyles.verticalAlignment = '';
    aStyles.verticalAlignment = props.styles.desktop.verticalAlignment ? props.styles.desktop.verticalAlignment : 'start';

    aStyles.verticalAlignmentInnerHeight = 'auto';
    aStyles.verticalAlignmentInnerHeight = props.styles.desktop.verticalAlignment === 'stretch' ? '100%' : 'auto';


    aStyles.desktopBackgroundColor = props.styles.desktop.backgroundUrl ? null : props.styles.desktop.backgroundColor ? props.styles.desktop.backgroundColor : aStyles.mobileBackgroundColor;
    aStyles.desktopPadding = props.styles.desktop.padding ? props.styles.desktop.padding : props.styles.mobile.padding;
    aStyles.desktopMargin = props.styles.desktop.margin ? props.styles.desktop.margin : props.styles.mobile.margin;
    aStyles.desktopColor = props.styles.desktop.color ? props.styles.desktop.color : null;

    aStyles.mobileBackgroundColor = props.styles.mobile.backgroundUrl ? null : props.styles.mobile.backgroundColor ? props.styles.mobile.backgroundColor : aStyles.desktopBackgroundColor;
    aStyles.mobileBackgroundUrl = props.styles.mobile.backgroundUrl ? 'url(' + props.styles.mobile.backgroundUrl + ')' : null;

    aStyles.desktopBackgroundUrl = props.styles.desktop.backgroundUrl ? 'url(' + props.styles.desktop.backgroundUrl + ')' : aStyles.mobileBackgroundUrl;

    aStyles.mobilePadding = props.styles.mobile.padding;
    aStyles.mobileMargin = props.styles.mobile.margin;
    aStyles.mobileColor = props.styles.mobile.color ? props.styles.mobile.color : props.styles.desktop.color ? props.styles.desktop.color : null;

    aStyles.overlay = props.styles.desktop.overlay;

    if (backgroundImageStyle.value) {
        aStyles.desktopBackgroundColor = null;
        aStyles.mobileBackgroundColor = null;
    }

    return aStyles;
})

const setBackgroundImageStyle = async () => {
    backgroundImageStyle.value = null;

    if (!props.styles.desktop.backgroundMediaBankEntryId) {
        if (!props.styles.mobile.backgroundMediaBankEntryId) {
            return null;
        }
    }
    let mediaBankEntryId;
    if (props.styles.desktop.backgroundMediaBankEntryId) {
        mediaBankEntryId = props.styles.desktop.backgroundMediaBankEntryId;
    } else {
        mediaBankEntryId = props.styles.mobile.backgroundMediaBankEntryId;
    }

    const imageRoute = route('block-editor-layout.row.media-bank-entry', {
        containerRef: props.containerRef,
        rowUuid: props.rowUuid,
        mediaBankEntryId: mediaBankEntryId,
    })

    try {
        const image = await axios.get(imageRoute)

        backgroundImageStyle.value = {
            backgroundImage: 'url(' + image.data.url + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        }
    } catch (error) {
        console.error(error)
    }
}

watch(() => props.styles.desktop.backgroundMediaBankEntryId, () => {
    setBackgroundImageStyle();
})

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            transitioned.value = true
        }
    });
});


onMounted(() => {
    setBackgroundImageStyle();
    forceRefresh.value = !forceRefresh.value;
    document.addEventListener('DOMContentLoaded', function () {
        setTimeout(function () {
            observer.observe(transitionableRow.value);
            transitionOnEntry.value = true;
        }, 500)
    })
})

</script>

<template>
    <div
        class="mfpb-row-outer mfpbr"
        :style="backgroundImageStyle"
        :class="['mfpbr-' + rowUuid, persistentClass]"
        ref="transitionableRow"
    >
        <div class="relative row-paddable">
            <div
                class="absolute inset-0 z-0 mfpb-row-overlay row-transition"
                :class="transitionOnEntry ? transitioned ? '' : 'row-is-not-transitioned' : ''"
            ></div>
            <div
                class="relative width-container z-10 row-transition"
                :class="transitionOnEntry ? transitioned ? '' : 'row-is-not-transitioned' : ''"
            >
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
    div.mfpb-row-outer > div {
        background-color: v-bind('appliedStyles.desktopBackgroundColor');
        background-image: v-bind('appliedStyles.desktopBackgroundUrl');
        padding: v-bind('appliedStyles.desktopPadding');
        margin: v-bind('appliedStyles.desktopMargin');
    }

    div.mfpb-row-outer > div :deep(.styleable-block) {
        color: v-bind('appliedStyles.desktopColor') !important;
    }

    div.mfpb-row-outer {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    div.mfpb-row-outer > div > div.width-container {
        max-width: v-bind('appliedStyles.desktopMaxWidth');
        min-height: v-bind('appliedStyles.desktopRowMinHeight');
        margin-left: auto;
        margin-right: auto;
    }

    div.mfpb-row-outer > div > div.width-container :deep(> div) {
        min-height: v-bind('appliedStyles.desktopRowMinHeight');
    }

    div.mfpb-row-outer > div > div.mfpb-row-overlay {
        background: v-bind('appliedStyles.overlay');
    }

    div.mfpb-row-outer > div :deep(.mfpb-col-container) {
        display: flex;
        flex-direction: column;
        justify-content: v-bind('appliedStyles.verticalAlignment');
        align-items: stretch;
        height: 100%;
    }

    div.mfpb-row-outer > div :deep(.mfpb-column-outer) {
        height: v-bind('appliedStyles.verticalAlignmentInnerHeight');
    }

    @media screen and (max-width: 959px) {
        div.mfpb-row-outer > div {
            background-color: v-bind('appliedStyles.mobileBackgroundColor');
            background-image: v-bind('appliedStyles.mobileBackgroundUrl');
            background-size: cover;
            background-position: center center;
            padding: v-bind('appliedStyles.mobilePadding');
            margin: v-bind('appliedStyles.mobileMargin');
        }

        div.mfpb-row-outer > div :deep(.styleable-block) {
            color: v-bind('appliedStyles.mobileColor') !important;
        }
    }
</style>
<style>
body:not(.layout-landingpages) div.mfpb-col-container:not(:has( .styleable-block)) {
    opacity: 95%;
}
</style>
