<script setup>

import {computed, onMounted, ref, watch} from "vue";
import { v4 as uuidv4 } from 'uuid';
import IconChevron from "../../icons/iconChevron.vue";
import ButtonBase from "../../buttons/buttonBase.vue";
import InputNumber from "../../forms/inputNumber.vue";
import QuestionTypeWidget from "./_questionTypeWidget.vue";

const emit = defineEmits(['save'])

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    },
    uiType: {
        type: String,
        default: 'ui',
        required: false,
    }
})

const channelMode = ref('default')
const previewingChannel = ref(false);
const importingQuestions = ref(false);
const importableQuestions = ref([]);
const expandedImportableChannels = ref([]);
const addingQuestion = ref(false);

const startAddingQuestion = () => {
    if (channelMode.value === 'trackAccountability') {
        addQuestion('completion')
        return
    }

    addingQuestion.value = true
}

const addQuestion = (type) => {

    let role = 'question'
    let metadata = {}

    if (type === 'heading') {
        role = 'layout'
    }

    if (type === 'widget') {
        metadata.widgetUuid = ''
    }

    props.channel.dailyCheckInQuestions.questions.push({
        index: props.channel.dailyCheckInQuestions.questions.length + 1,
        question: null,
        type: type,
        role: role,
        enabled: true,
        defaultAnswer: 10,
        uuid: uuidv4(),
        metadata: metadata
    });

    addingQuestion.value = false
}

const previewChannel = () => {
    previewingChannel.value = true;
}

const loadImportableQuestions = async () => {
    const res = await axios.get(route('community.admin.api.channelQuestions'))
    importableQuestions.value = res.data.channels
}

const importQuestion = (question) => {
    let _question = JSON.parse(JSON.stringify(question))
    _question.uuid = uuidv4()
    _question.index = props.channel.dailyCheckInQuestions.questions.length + 1

    props.channel.dailyCheckInQuestions.questions.push(_question)
}

const importAllQuestions = (questions) => {
    questions.forEach((question) => {
        importQuestion(question)
    })
}

const save = () => {
    if (! channelValidates.value) {
        return;
    }

    emit('save', props.channel)
}

const setPresetMode = () => {
    if (props.channel.trackAccountability) {
        channelMode.value = 'trackAccountability'
        return
    }
    if (props.channel.trackProgress) {
        channelMode.value = 'trackProgress'
        return
    }
    channelMode.value = 'default'
}

const moveQuestionUp = (questionIndex) => {
    if (questionIndex === 0) {
        return;
    }

    const question = props.channel.dailyCheckInQuestions.questions[questionIndex];
    props.channel.dailyCheckInQuestions.questions.splice(questionIndex, 1);
    props.channel.dailyCheckInQuestions.questions.splice(questionIndex - 1, 0, question);
}

const moveQuestionDown = (questionIndex) => {
    if (questionIndex === props.channel.dailyCheckInQuestions.questions.length - 1) {
        return;
    }

    const question = props.channel.dailyCheckInQuestions.questions[questionIndex];
    props.channel.dailyCheckInQuestions.questions.splice(questionIndex, 1);
    props.channel.dailyCheckInQuestions.questions.splice(questionIndex + 1, 0, question);
}

const toggleImportableQuestions = (channelId) => {
    if (expandedImportableChannels.value.includes(channelId)) {
        expandedImportableChannels.value = expandedImportableChannels.value.filter(id => id !== channelId)
    } else {
        expandedImportableChannels.value.push(channelId)
    }
}

const channelValidates = computed(() => {
    return props.channel.name?.length > 0
});

watch(channelMode, (newValue) => {
    if (newValue === 'default') {
        props.channel.trackAccountability = false
        props.channel.trackProgress = false
        props.channel.postSubtitle = ''
        props.channel.reminderDays = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        }
    }
    if (newValue === 'trackAccountability') {
        props.channel.trackAccountability = true
        props.channel.trackProgress = false
        props.channel.postSubtitle = ''
    }
    if (newValue === 'trackProgress') {
        props.channel.trackAccountability = false
        props.channel.trackProgress = true
    }
})

watch(importingQuestions, (newValue) => {
    if (newValue) {
        loadImportableQuestions()
    } else {
        importableQuestions.value = []
        expandedImportableChannels.value = []
    }
})

watch(() => props.channel, () => {
    setPresetMode()
}, {deep: true})

onMounted(() => {
    setPresetMode()
})

</script>
<template>
    <div>
        <div
            v-if="channel"
            class="@container"
        >
            <component :is="uiType === 'ui' ? 'ui-tabbed' : 'card-tabbed'">
                <component
                    :is="uiType === 'ui' ? 'ui-tabbed-tab' : 'card-tabbed-tab'"
                    :title="_mft('community:channel.tab.settings')"
                    value="settings"
                    :active="true"
                >
                    <div
                        v-if="! channel.id"
                    >
                        <input-label :label="_mft('community:newChannel.type.label')" class="mb-1" />
                        <button-bar
                            :options="[
                                { label: 'Normal channel', name: 'default' },
                                { label: _mft('community:newChannel.trackAccountability.label'), name: 'trackAccountability' },
                                { label: 'Track progress', name: 'trackProgress' },
                            ]"
                            :selectedOptionName="channelMode"
                            @select="channelMode = $event.name"
                            :class="channel.lockedForCommunityPosts ? 'opacity-50 pointer-events-none' : ''"
                        />
                    </div>

                    <input-text
                        :label="_mft('community:newChannel.name.label')"
                        :placeholder="_mft('community:newChannel.name.label')"
                        :autofocus="true"
                        v-model:content="channel.name"
                        class="mb-2"
                    />

                    <div
                        v-if="typeof(channel.metaText) !== 'string'"
                        class="flex justify-end mb-6"
                    >
                        <button-secondary
                            size="sm"
                            @click="channel.metaText = ''"
                        >
                            {{ _mft('community:newChannel.addDescription') }}
                        </button-secondary>
                    </div>

                    <div
                        v-else
                        class="relative"
                    >
                        <span class="absolute top-0 right-0">
                            <button-secondary
                                size="sm"
                                @click="delete(channel.metaText)"
                            >
                                {{ _mft('community:newChannel.removeDescription') }}
                            </button-secondary>
                        </span>
                        <input-editor
                            :label="_mft('community:newChannel.description.label')"
                            v-model:content="channel.metaText"
                            class="mb-6"
                        />
                    </div>

                    <div class="@lg:grid grid-cols-2 gap-4 mb-4">
                        <div class="flex items-center space-x-2">
                            <input-date
                                :label="_mft('community:newChannel.allowPostingFrom.label')"
                                :placeholder="_mft('community:newChannel.allowPostingFrom.label')"
                                v-model:content="channel.allowPostingFrom"
                                class="w-full"
                            />
                            <button-base
                                size="sm"
                                class="mt-2"
                                @click="channel.allowPostingFrom = null"
                            >
                                {{ _mft('shared:action.clear')}}
                            </button-base>
                        </div>

                        <div class="flex items-center space-x-2">
                            <input-date
                                :label="_mft('community:newChannel.allowPostingThrough.label')"
                                :placeholder="_mft('community:newChannel.allowPostingThrough.label')"
                                v-model:content="channel.allowPostingThrough"
                                class="w-full"
                            />
                            <button-base
                                size="sm"
                                class="mt-2"
                                @click="channel.allowPostingThrough = null"
                            >
                                {{ _mft('shared:action.clear')}}
                            </button-base>
                        </div>
                    </div>

                    <toggle-switch
                        :label="_mft('community:newChannel.lockedForCommunityPosts.label')"
                        v-model:content="channel.lockedForCommunityPosts"
                    />
                </component>
                <component
                    v-if="channel.trackAccountability || channel.trackProgress"
                    :is="uiType === 'ui' ? 'ui-tabbed-tab' : 'card-tabbed-tab'"
                    :title="_mft('community:channel.tab.questions')"
                    value="questions"
                >
                    <input-text
                        v-if="channelMode === 'trackProgress'"
                        :label="_mft('community:newChannel.postSubtitle')"
                        autofocus
                        :placeholder="_mft('community:newChannel.postSubtitle.placeholder')"
                        v-model:content="channel.postSubtitle"
                    />

                    <div
                        class="text-xs"
                        :class="uiType === 'ui' ? 'bg-gray-100 pl-4 pr-1 py-4 -mx-4 lg:py-6 lg:-mx-6 lg:pl-6' : '-mx-4'"
                    >
                        <p
                            v-if="channel.postCount > 0"
                            class="italic px-4 mt-4"
                        >
                            <i class="fas fa-exclamation-triangle text-myflowYellow mr-1"></i>
                            {{ _mft('community:channel.editingQuestionsWarning') }}
                        </p>
                        <div>
                            <div
                                v-for="(question, index) in channel.dailyCheckInQuestions.questions"
                                :class="channel.dailyCheckInQuestions.questions[index].enabled ? 'border-t mb-4' : ''"
                            >
                                <div
                                    v-if="channel.dailyCheckInQuestions.questions[index].enabled"
                                    class="mb-4 flex space-x-0.5 items-stretch"
                                >
                                    <div class="bg-white p-4 w-full">
                                        <div class="flex justify-between space-x-4">
                                            <span class="text-gray-400">{{ _mft('community:question.type.'+question.type) }}</span>
                                        </div>
                                        <input-text
                                            :placeholder="_mft('form:field.label')"
                                            v-model:content="channel.dailyCheckInQuestions.questions[index].question"
                                            class="!mb-1"
                                        />
                                        <div class="text-gray-400 flex justify-end">
                                            <div class="flex space-x-1">
                                                <button-with-confirmation
                                                    size="xs"
                                                    confirm-type="danger"
                                                    :confirm-text="_mft('community:channel.deletingQuestionsWarning')"
                                                    :confirm-button-label="_mft('shared:action.delete')"
                                                    :abort-button-label="_mft('shared:action.cancel')"
                                                    @confirmed="channel.dailyCheckInQuestions.questions[index].enabled = false"
                                                >
                                                    {{ _mft('shared:action.delete') }}
                                                </button-with-confirmation>
                                            </div>
                                        </div>
                                        <input-number
                                            v-if="question.type === 'todo'"
                                            class="w-48 -mt-2"
                                            :label="_mft('community:question.todo.score')"
                                            v-model:content="channel.dailyCheckInQuestions.questions[index].defaultAnswer"
                                        />

                                        <div
                                            v-if="question.type === 'widget'"
                                            class="rounded-md bg-gray-100 -mx-2 p-2 mt-2"
                                        >
                                            <QuestionTypeWidget
                                                :widget-uuid="channel.dailyCheckInQuestions.questions[index].metadata.widgetUuid"
                                                @update-widget-uuid="channel.dailyCheckInQuestions.questions[index].metadata.widgetUuid = $event"
                                            ></QuestionTypeWidget>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-between py-2">
                                        <button-transparent
                                            size="sm"
                                            @click="moveQuestionUp(index)"
                                        >
                                            <icon-chevron class="w-4 h-4 rotate-180"></icon-chevron>
                                        </button-transparent>
                                        <button-transparent
                                            size="sm"
                                            @click="moveQuestionDown(index)"
                                        >
                                            <icon-chevron class="w-4 h-4"></icon-chevron>
                                        </button-transparent>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="! addingQuestion"
                            class="flex space-x-2 justify-end"
                        >
                            <button-secondary
                                v-if="! importingQuestions"
                                size="sm"
                                @click="importingQuestions = true"
                            >
                                {{ _mft('community:channel.accountability.importQuestions') }}
                            </button-secondary>

                            <button-secondary
                                v-if="importingQuestions"
                                size="sm"
                                @click="importingQuestions = false"
                            >
                                {{ _mft('community:channel.accountability.importQuestionsDone') }}
                            </button-secondary>

                            <button-primary
                                v-if="! importingQuestions"
                                size="sm"
                                @click="startAddingQuestion"
                            >
                                {{ _mft('community:channel.accountability.addQuestion') }}
                            </button-primary>
                        </div>

                        <div
                            v-if="addingQuestion"
                        >
                            <div class="flex justify-end flex-wrap space-x-2 mb-2">
                                <button-primary
                                    size="sm"
                                    @click="addQuestion('heading')"
                                >
                                    {{ _mft('community:question.type.heading') }}
                                </button-primary>

                                <container-with-tooltip>
                                    <button-primary
                                        size="sm"
                                        @click="addQuestion('completion')"
                                    >
                                        {{ _mft('community:question.type.completion') }}
                                    </button-primary>
                                    <template #tooltip>
                                        <div class="bg-white w-32">
                                            {{ _mft('community:question.type.completion.tooltip') }}
                                        </div>
                                    </template>
                                </container-with-tooltip>

                                <button-primary
                                    size="sm"
                                    @click="addQuestion('text')"
                                >
                                    {{ _mft('community:question.type.text') }}
                                </button-primary>

                                <button-primary
                                    size="sm"
                                    @click="addQuestion('boolean')"
                                >
                                    {{ _mft('community:question.type.boolean') }}
                                </button-primary>

                                <button-primary
                                    size="sm"
                                    @click="addQuestion('todo')"
                                >
                                    {{ _mft('community:question.type.todo') }}
                                </button-primary>

                                <button-primary
                                    size="sm"
                                    @click="addQuestion('number')"
                                >
                                    {{ _mft('community:question.type.number') }}
                                </button-primary>

                                <button-primary
                                    size="sm"
                                    @click="addQuestion('widget')"
                                >
                                    {{ _mft('community:question.type.widget') }}
                                </button-primary>

                                <button-base
                                    size="sm"
                                    @click="addingQuestion = false"
                                >
                                    {{ _mft('shared:action.cancel') }}
                                </button-base>
                            </div>
                        </div>

                        <div
                            v-if="importingQuestions"
                            class="pr-2"
                        >
                            <ul class="space-y-3">
                                <li
                                    v-if="importableQuestions.length === 0"
                                    class="text-center text-gray-400"
                                >
                                    {{ _mft('community:channel.accountability.noChannelsToImportFrom') }}
                                </li>
                                <li
                                    v-for="(channel, index) in importableQuestions"
                                    :key="index"
                                >
                                    <div class="flex justify-between items-center space-x-2 group">
                                        <div
                                            class="w-full"
                                        >
                                            <div>{{ channel.channel_name }}</div>
                                            <div class="text-xs text-gray-400">
                                                {{ _mft('community:nQuestions', {count: channel.question_count}) }}
                                            </div>
                                        </div>
                                        <div class="flex space-x-1">
                                            <button-base
                                                size="xs"
                                                class="whitespace-nowrap"
                                                @click="importAllQuestions(channel.questions)"
                                            >
                                                {{ _mft('shared:action.useAll') }}
                                            </button-base>
                                            <button-base
                                                size="xs"
                                                style-type="transparent"
                                                @click="toggleImportableQuestions(channel.channel_id)"
                                            >
                                                {{ expandedImportableChannels.includes(channel.channel_id) ? _mft('shared:action.hide') : _mft('shared:action.show') }}
                                            </button-base>
                                        </div>
                                    </div>
                                    <ul
                                        v-if="expandedImportableChannels.includes(channel.channel_id)"
                                        class="mb-4 border-l ml-0.5"
                                    >
                                        <li
                                            v-for="(question, q) in channel.questions"
                                            :key="q"
                                            class="pl-1 py-1 hover:bg-gray-200 flex justify-between space-x-2"
                                        >
                                            <span>{{ question.question }}</span>
                                            <button-base
                                                size="xs"
                                                @click="importQuestion(question)"
                                            >{{ _mft('shared:action.use') }}</button-base>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </component>
                <component
                    v-if="channel.trackAccountability || channel.trackProgress"
                    :is="uiType === 'ui' ? 'ui-tabbed-tab' : 'card-tabbed-tab'"
                    :title="_mft('community:channel.tab.reminders')"
                    value="reminders"
                    :active="true"
                >
                    <div>
                        <div class="mt-6 mb-4">
                            <input-time
                                v-model:content="channel.reminderTime"
                                :label="_mft('community:newChannel.sendReminder.label')"
                                :required="required"
                            />
                        </div>
                        <div class="pt-3 pl-2 ml-2 -mt-4 border-l">
                            <toggle-switch
                                :label="_mft('calendar:weekday.monday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.monday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.tuesday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.tuesday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.wednesday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.wednesday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.thursday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.thursday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.friday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.friday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.saturday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.saturday"
                            />
                            <toggle-switch
                                :label="_mft('calendar:weekday.sunday.long')"
                                class="transform -translate-y-1"
                                v-model:content="channel.reminderDays.sunday"
                            />
                        </div>

                    </div>
                </component>
            </component>
            <div
                v-if="! channel.id"
                class="flex justify-end"
            >
                <button-base
                    :style-type="channelValidates ? 'success' : 'disabled'"
                    :disabled="! channelValidates"
                    class="mt-3 text-center"
                    v-on:click="save()"
                >
                    {{ _mft('shared:action.save') }}
                </button-base>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
