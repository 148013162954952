<script setup>

import {computed, reactive, ref} from "vue";
import PrioritySelector from "./prioritySelector.vue";

const emit = defineEmits('created');

const props = defineProps({
    taskList: {
        type: Object,
        required: true,
    }
})

const changingPriority = ref(false)

const task = reactive({
    label: '',
    priority: null,
    available_at: null,
    due_at: null,
})

const createTask = async () => {
    const res = await axios.post(route('api.task.store', props.taskList.id), {
        task: task
    })
    emit('created', res.data.task)
}

const setPriority = (priorityLevel) => {
    task.priority = priorityLevel
    changingPriority.value = false
}

const priorityIcon = computed(() => {
    const icons = {
        1: 'fa-solid fa-flag text-red-500',
        2: 'fa-solid fa-flag text-yellow-500',
        3: 'fa-solid fa-flag text-blue-500',
    }

    if (! task.priority) {
        return 'fa-regular fa-flag text-gray-500';
    }
    if (task.priority > 3) {
        return 'fa-solid fa-flag text-red-500';
    }

    return icons[task.priority];
})

</script>

<template>
<div>
    <div class="flex items-center space-x-4">
        <input-text
            v-model:content="task.label"
            placeholder="Task name"
            class="w-full"
            autofocus
            @keyup.enter="createTask"
        ></input-text>
        <div class="w-6 mb-2 relative">
            <button
                class="h-5 flex justify-center items-center transition-opacity"
                @click="changingPriority = !changingPriority"
            >
                <i
                    :class="priorityIcon"
                ></i>
            </button>

            <priority-selector
                v-if="changingPriority"
                class="absolute -bottom-0.5 -right-2"
                @priority-selected="setPriority"
                @cancel="changingPriority = false"
            ></priority-selector>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
        <div>
            <label
                class="text-xs"
            >
                Start date
            </label>
            <input-date
                :content="task.available_at"
                @update:content="task.available_at = $event"
            />
        </div>
        <div>
            <label
                class="text-xs"
            >
                Due date
            </label>
            <input-date
                :content="task.due_at"
                @update:content="task.due_at = $event"
            />
        </div>

    </div>

    <div class="flex justify-end">
        <button-success
            size="sm"
            @click="createTask"
        >Create</button-success>
    </div>
</div>
</template>
