<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    post: {
        type: Object,
        required: true
    }

})
const expanded = ref(false)
const zoomMedia = ref(false)

const expandable = computed(() => {
    return props.post.content.length > 200
})

const postContentClass = computed(() => {
    if (! expandable.value) {
        return ''
    }
    return expanded.value ? '' : 'h-48 lg:h-24 overflow-hidden'
})

</script>

<template>
    <div
        class="mb-2 md:grid md:grid-cols-3"
    >
        <div
            class="w-full relative whitespace-break-spaces md:pr-4"
            :class="[postContentClass, post.has_media ? 'col-span-2' : 'col-span-3', zoomMedia ? 'md:hidden' : 'block']"
        >
            {{ post.content }}
            <div
                v-if="! expanded && expandable"
                class="absolute bottom-0 w-full"
            >
                <div
                    class="h-6 bg-gradient-to-t from-white/90 via-white/50 to-transparent"
                ></div>
                <div
                    class="bg-white/90 pb-2 text-center text-xs text-black"
                >
                    <button-transparent
                        size="xs"
                        @click="expanded = true"
                    >
                        {{ _mft('shared:showMore') }}
                    </button-transparent>
                </div>
            </div>
        </div>
        <div
            v-if="post.has_media"
            class="mt-4 md:mt-0"
            :class="zoomMedia ? 'md:col-span-3' : 'aspect-video'"
        >
            <img
                :src="post.post_image"
                class="object-contain w-full h-full"
                @click="zoomMedia = !zoomMedia"
                alt=""
            />
        </div>
    </div>
</template>

<style scoped>

</style>
