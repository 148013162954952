<script setup>

import {onMounted, ref} from "vue";

const props = defineProps({
    type: String,
    draftAutomations: Object,
    completedAutomations: Object,
    activeAutomations: Object,
    smsSettingsAvailable: Boolean,
    emailSettingsAvailable: Boolean,
});

const draftAutomations = ref([])
const completedAutomations = ref([])
const activeAutomations = ref([])

const loadAutomations = async () => {
    const res = await axios.get(route('crm.communications.apiIndex', props.type))

    draftAutomations.value = res.data.draft_automations
    activeAutomations.value = res.data.active_automations
    completedAutomations.value = res.data.completed_automations
}

onMounted(() => {
    loadAutomations()
})

</script>
<template>
    <div class="mb-5">
        <card-tabbed
            v-if="type === 'newsletter'"
        >
            <card-tabbed-tab
                :title="_mft('communication:automation.scheduled') + ' ' + (activeAutomations.length ? '(' + activeAutomations.length + ')' : '')"
                value="scheduled"
                :active="false"
            >
                <list-basic
                    list-class="bg-transparent -mx-4 md:-mx--6 lg:-mx-8"
                >
                    <list-basic-item v-if="activeAutomations.length === 0">
                        <div class="text-gray-400 text-center">
                            {{ _mft('communication:noScheduledFound.newsletter') }}
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="automation in activeAutomations"
                        :key="automation.id"
                    >
                        <template #status>
                            <container-with-tooltip
                                alignment="center"
                                vertical-alignment="top"
                                class="w-4 h-4 mr-2 mt-1 flex justify-center items-center"
                            >
                                <span class="relative">
                                    <i class="fa-regular fa-clock text-gray-500 text-sm"></i>
                                </span>

                                <template #tooltip>
                                    <div class="text-xs bg-white rounded-md p-1">
                                        {{ _mft('communication:automation.scheduled') }}
                                    </div>
                                </template>
                            </container-with-tooltip>
                        </template>

                        <template #title>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                {{ automation.name }}
                            </a>
                        </template>

                        <div class="text-xs text-gray-400">
                            <span v-if="automation.send_rule === 'once_to_all'">{{ _mft('communication:sendRuleTypes.onceToAll') }}</span>
                            <span v-if="automation.send_rule === 'once_assigned_in_time_range'">{{ _mft('communication:sendRuleTypes.onceAssignedInTimeRange') }}</span>
                            <span class="ml-1 lowercase">{{ _mft('communication:automation.scheduledAt') }} {{ automation.activate_at_display }}</span>
                        </div>

                        <div
                            class="text-xs text-gray-400"
                        >
                            <ul class="flex flex-wrap mt-1">
                                <li
                                    v-for="(tag, i) in automation.included_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <pill
                                        size="xs"
                                        class="bg-gray-400 text-white cursor-default"
                                    >
                                    {{ tag.name }}
                                    </pill>
                                </li>

                                <li
                                    v-for="(tag, i) in automation.excluded_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <container-with-tooltip>
                                        <pill
                                            size="xs"
                                            class="bg-myflowRed text-white cursor-default"
                                        >
                                            {{ tag.name }}
                                        </pill>
                                        <template #tooltip>
                                            <div class="text-xxs bg-white rounded-md p-1 whitespace-nowrap text-black">
                                                {{ _mft('communication:tag.thisTagIsExcluded') }}
                                            </div>
                                        </template>
                                    </container-with-tooltip>
                                </li>
                            </ul>
                        </div>

                        <template #actions>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                <button-base size="sm">
                                    {{ _mft('communication:automation.manage') }}
                                </button-base>
                            </a>
                        </template>
                    </list-basic-item>
                </list-basic>
            </card-tabbed-tab>
            <card-tabbed-tab
                :title="_mft('communication:automation.drafts') + ' ' + (draftAutomations.length ? '(' + draftAutomations.length + ')' : '')"
                value="drafts"
                :active="false"
            >
                <list-basic
                    list-class="bg-transparent -mx-4 md:-mx--6 lg:-mx-8"
                >
                    <list-basic-item v-if="draftAutomations.length === 0">
                        <div class="text-gray-400 text-center">
                            {{ _mft('communication:noDraftsFound.newsletter') }}
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="automation in draftAutomations"
                        :key="automation.id"
                    >
                        <template #title>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                {{ automation.name }}
                            </a>
                        </template>

                        <div
                            class="text-xs text-gray-400"
                        >
                            <span v-if="automation.send_rule === 'once_to_all'">{{ _mft('communication:sendRuleTypes.onceToAll') }}</span>
                            <span v-if="automation.send_rule === 'once_assigned_in_time_range'">{{ _mft('communication:sendRuleTypes.onceAssignedInTimeRange') }}</span>
                            <ul class="flex flex-wrap mt-1">
                                <li
                                    v-for="(tag, i) in automation.included_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <pill
                                        size="xs"
                                        class="bg-gray-400 text-white cursor-default"
                                    >
                                    {{ tag.name }}
                                    </pill>
                                </li>

                                <li
                                    v-for="(tag, i) in automation.excluded_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <container-with-tooltip>
                                        <pill
                                            size="xs"
                                            class="bg-myflowRed text-white cursor-default"
                                        >
                                            {{ tag.name }}
                                        </pill>
                                        <template #tooltip>
                                            <div class="text-xxs bg-white rounded-md p-1 whitespace-nowrap text-black">
                                                {{ _mft('communication:tag.thisTagIsExcluded') }}
                                            </div>
                                        </template>
                                    </container-with-tooltip>
                                </li>
                            </ul>
                        </div>

                        <template #status>
                            <container-with-tooltip
                                alignment="center"
                                vertical-alignment="top"
                                class="w-4 h-4 mr-2 mt-1 flex justify-center items-center"
                            >
                                <span class="relative">
                                    <i class="fa-regular fa-pencil text-gray-400 text-sm"></i>
                                </span>

                                <template #tooltip>
                                    <div class="text-xs bg-white rounded-md p-1">
                                        {{ _mft('communication:automation.draft') }}
                                    </div>
                                </template>
                            </container-with-tooltip>
                        </template>

                        <template #actions>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                <button-base size="sm">
                                    {{ _mft('shared:edit') }}
                                </button-base>
                            </a>
                        </template>
                    </list-basic-item>
                </list-basic>
            </card-tabbed-tab>
            <card-tabbed-tab
                :title="_mft('communication:automation.sent') + ' ' + (completedAutomations.length ? '(' + completedAutomations.length + ')' : '')"
                value="history"
                :active="true"
            >
                <list-basic
                    list-class="bg-transparent -mx-4 md:-mx--6 lg:-mx-8"
                >
                    <list-basic-item v-if="completedAutomations.length === 0">
                        <div class="text-gray-400 text-center">
                            {{ _mft('communication:noCompletedFound.newsletter') }}
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="(automation, i) in completedAutomations"
                        :key="automation.id"
                    >
                        <template #status>
                            <container-with-tooltip
                                alignment="center"
                                vertical-alignment="top"
                                class="w-4 h-4 mr-2 mt-1 flex justify-center items-center"
                            >
                                <span class="relative">
                                    <i class="fa-regular fa-circle-check text-myflowGreen text-sm"></i>
                                    <i
                                        v-if="automation.stats.failed_deliveries > 0"
                                        class="fa-solid fa-triangle-exclamation text-xs absolute -top-1 -right-1"
                                        :class="automation.stats.labels.includes('high_failure_rate') ? 'text-myflowRed' : 'text-myflowYellow'"
                                    ></i>
                                </span>

                                <template #tooltip>
                                    <div class="text-xs bg-white rounded-md p-1">
                                        {{ automation.stats.failed_deliveries === 0 ? _mft('communication:automation.completed') : _mft('communication:automation.completedWithIssues') }}
                                    </div>
                                </template>
                            </container-with-tooltip>
                        </template>

                        <template #title>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                {{ automation.name }}
                            </a>
                        </template>

                        <div class="text-xs text-gray-400">
                            <span v-if="automation.send_rule === 'once_to_all'">{{ _mft('communication:sendRuleTypes.onceToAll') }}</span>
                            <span v-if="automation.send_rule === 'once_assigned_in_time_range'">{{ _mft('communication:sendRuleTypes.onceAssignedInTimeRange') }}</span>
                            <span class="ml-1 lowercase">{{ _mft('communication:automation.scheduledAt') }} {{ automation.activate_at_display }}</span>
                        </div>

                        <div
                            class="text-xs text-gray-400"
                        >
                            <ul class="flex flex-wrap mt-1">
                                <li
                                    v-for="(tag, i) in automation.included_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <pill
                                        size="xs"
                                        class="bg-gray-400 text-white cursor-default"
                                    >
                                    {{ tag.name }}
                                    </pill>
                                </li>

                                <li
                                    v-for="(tag, i) in automation.excluded_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <container-with-tooltip>
                                        <pill
                                            size="xs"
                                            class="bg-myflowRed text-white cursor-default"
                                        >
                                            {{ tag.name }}
                                        </pill>
                                        <template #tooltip>
                                            <div class="text-xxs bg-white rounded-md p-1 whitespace-nowrap text-black">
                                                {{ _mft('communication:tag.thisTagIsExcluded') }}
                                            </div>
                                        </template>
                                    </container-with-tooltip>
                                </li>
                            </ul>
                        </div>

                        <communications-stats
                            class="flex space-x-4"
                            :stats="automation.stats"
                        />

                        <template #actions>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                <button-base size="sm">
                                    {{ _mft('communication:automation.show') }}
                                </button-base>
                            </a>
                        </template>
                    </list-basic-item>
                </list-basic>
            </card-tabbed-tab>
        </card-tabbed>




        <card-tabbed
            v-if="type === 'automation'"
        >
            <card-tabbed-tab
                :title="_mft('communication:automation.active')"
                value="scheduled"
                :active="true"
            >
                <list-basic
                    list-class="bg-transparent -mx-4 md:-mx--6 lg:-mx-8"
                >
                    <list-basic-item v-if="activeAutomations.length === 0">
                        <div class="text-gray-400 text-center">
                            {{ _mft('communication:noActiveFound.automation') }}
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="automation in activeAutomations"
                        :key="automation.id"
                    >
                        <template #status>
                            <container-with-tooltip
                                alignment="center"
                                vertical-alignment="top"
                                class="w-4 h-4 mr-2 mt-1 flex justify-center items-center"
                            >
                                <span class="relative">
                                    <i class="fa-regular fa-play text-myflowGreen text-sm"></i>
                                </span>

                                <template #tooltip>
                                    <div class="text-xs bg-white rounded-md p-1">
                                        {{ _mft('communication:automation.active') }}
                                    </div>
                                </template>
                            </container-with-tooltip>
                        </template>

                        <template #title>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                {{ automation.name }}
                            </a>
                        </template>

                        <div class="text-xs text-gray-400">
                            {{ _mft('communication:composer.summary.automation.activeFrom') }} {{ automation.activate_at_display }}
                        </div>

                        <div
                            class="text-xs text-gray-400"
                        >
                            <ul class="flex flex-wrap mt-1">
                                <li
                                    v-for="(tag, i) in automation.included_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <pill
                                        size="xs"
                                        class="bg-gray-400 text-white cursor-default"
                                    >
                                        {{ tag.name }}
                                    </pill>
                                </li>

                                <li
                                    v-for="(tag, i) in automation.excluded_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <container-with-tooltip>
                                        <pill
                                            size="xs"
                                            class="bg-myflowRed text-white cursor-default"
                                        >
                                            {{ tag.name }}
                                        </pill>
                                        <template #tooltip>
                                            <div class="text-xxs bg-white rounded-md p-1 whitespace-nowrap text-black">
                                                {{ _mft('communication:tag.thisTagIsExcluded') }}
                                            </div>
                                        </template>
                                    </container-with-tooltip>
                                </li>
                            </ul>
                        </div>

                        <communications-stats
                            class="flex space-x-4"
                            :stats="automation.stats"
                        />

                        <template #actions>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                <button-base size="sm">
                                    {{ _mft('communication:automation.manage') }}
                                </button-base>
                            </a>
                        </template>
                    </list-basic-item>
                </list-basic>
            </card-tabbed-tab>
            <card-tabbed-tab
                :title="_mft('communication:automation.drafts')"
                value="drafts"
                :active="false"
            >
                <list-basic
                    list-class="bg-transparent -mx-4 md:-mx--6 lg:-mx-8"
                >
                    <list-basic-item v-if="draftAutomations.length === 0">
                        <div class="text-gray-400 text-center">
                            {{ _mft('communication:noDraftsFound.automation') }}
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="automation in draftAutomations"
                        :key="automation.id"
                    >
                        <template #title>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                {{ automation.name }}
                            </a>
                        </template>

                        <div
                            class="text-xs text-gray-400"
                        >
                            <ul class="flex flex-wrap mt-1">
                                <li
                                    v-for="(tag, i) in automation.included_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <pill
                                        size="xs"
                                        class="bg-gray-400 text-white cursor-default"
                                    >
                                        {{ tag.name }}
                                    </pill>
                                </li>

                                <li
                                    v-for="(tag, i) in automation.excluded_lead_tags"
                                    :key="i"
                                    class="pr-0.5 pb-0.5"
                                >
                                    <container-with-tooltip>
                                        <pill
                                            size="xs"
                                            class="bg-myflowRed text-white cursor-default"
                                        >
                                            {{ tag.name }}
                                        </pill>
                                        <template #tooltip>
                                            <div class="text-xxs bg-white rounded-md p-1 whitespace-nowrap text-black">
                                                {{ _mft('communication:tag.thisTagIsExcluded') }}
                                            </div>
                                        </template>
                                    </container-with-tooltip>
                                </li>
                            </ul>
                        </div>

                        <template #status>
                            <container-with-tooltip
                                alignment="center"
                                vertical-alignment="top"
                                class="w-4 h-4 mr-2 mt-1 flex justify-center items-center"
                            >
                                <span class="relative">
                                    <i class="fa-regular fa-pencil text-gray-400 text-sm"></i>
                                </span>

                                <template #tooltip>
                                    <div class="text-xs bg-white rounded-md p-1">
                                        {{ _mft('communication:automation.draft') }}
                                    </div>
                                </template>
                            </container-with-tooltip>
                        </template>

                        <communications-stats
                            class="flex space-x-4"
                            :stats="automation.stats"
                        />

                        <template #actions>
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="mf-link"
                            >
                                <button-base size="sm">
                                    {{ _mft('shared:edit') }}
                                </button-base>
                            </a>
                        </template>
                    </list-basic-item>
                </list-basic>
            </card-tabbed-tab>
        </card-tabbed>
    </div>
</template>

