<script setup>

import {onMounted, ref} from "vue";

const props = defineProps({
    courseId: {
        type: Number,
        required: true
    }
})

const course = ref(null)

const loadCourse = async () => {
    const res = await axios.get(route('api.course.customerarea.show', props.courseId))
    course.value = res.data.course
}

onMounted(() => {
    loadCourse()
})

</script>
<template>
    <div
        v-if="course"
        class="flex items-stretch min-h-screen"
        :class="'course-id-' + courseId"
    >
        <course-chapter-navigation-modal
            :course-id="courseId"
        ></course-chapter-navigation-modal>
        <div class="w-full max-w-5xl mx-auto mt-12 lg:mt-24">
            <h1>{{ course.title }}</h1>
            <div v-if="course.description"
                 v-html="course.description"
            ></div>
        </div>
    </div>
    <div v-else class="flex justify-center mt-24">
        <mf-spinner-medium class="w-24 h-12" />
    </div>
</template>
