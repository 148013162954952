<script setup>
import {computed} from "vue";

const props = defineProps({
    stat: {
        type: Object,
        required: true,
    },
    chartColor: {
        type: String,
        required: false,
        default: '#666666',
    }
})

const answersAsLabels = computed(() => {
    return Object.values(props.stat).map(answer => answer.value)
})

const counts = computed(() => {
    return Object.values(props.stat).map(answer => answer.count)
})

</script>

<template>
    <chart-bar
        :lazy-render="true"
        :show-legend="true"
        :fill-colors="[chartColor]"
        :show-data-labels="true"
        :show-tooltip="true"
        data-label-suffix=""
        :labels="answersAsLabels"
        :series="[{
            name: 'Responses',
            data: counts
        }]"
    ></chart-bar>
</template>

<style scoped>

</style>
