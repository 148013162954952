<script setup>
import {computed, ref} from "vue";

    const emit = defineEmits(['close', 'select-icon', 'select-weight']);

    const props = defineProps({
        open: {
            type: Boolean,
            default: false
        }
    })

    const icons = [
        { name: 'Check', class: 'fa-check', faName: 'check' },
        { name: 'Times', class: 'fa-times', faName: 'times' },
        { name: 'User', class: 'fa-user', faName: 'user' },
        { name: 'Home', class: 'fa-home', faName: 'home' },
        { name: 'Star', class: 'fa-star', faName: 'star' },
        { name: 'Heart', class: 'fa-heart', faName: 'heart' },
        { name: 'Thumbs Up', class: 'fa-thumbs-up', faName: 'thumbs-up' },
        { name: 'Thumbs Down', class: 'fa-thumbs-down', faName: 'thumbs-down' },
        { name: 'Calendar', class: 'fa-calendar', faName: 'calendar' },
        { name: 'Clock', class: 'fa-clock', faName: 'clock' },
        { name: 'Envelope', class: 'fa-envelope', faName: 'envelope' },
        { name: 'Phone', class: 'fa-phone', faName: 'phone' },
        { name: 'Map Marker', class: 'fa-map-marker-alt', faName: 'map-marker-alt' },
        { name: 'Paperclip', class: 'fa-paperclip', faName: 'paperclip' },
        { name: 'Image', class: 'fa-image', faName: 'image' },
        { name: 'Video', class: 'fa-video', faName: 'video' },
        { name: 'Music', class: 'fa-music', faName: 'music' },
        { name: 'Cog', class: 'fa-cog', faName: 'cog' },
        { name: 'Bell', class: 'fa-bell', faName: 'bell' },
        { name: 'Info', class: 'fa-info-circle', faName: 'info-circle' },
        { name: 'Question', class: 'fa-question-circle', faName: 'question-circle' },
        { name: 'Warning', class: 'fa-exclamation-triangle', faName: 'exclamation-triangle' },
        { name: 'Lock', class: 'fa-lock', faName: 'lock' },
        { name: 'Unlock', class: 'fa-unlock', faName: 'unlock' },
    ]

    const searchQuery = ref('')
    const selectedIcon = ref(null)

    const filteredIcons = computed(() => {
        if (!searchQuery.value) {
            return icons;
        }

        const query = searchQuery.value.toLowerCase();

        return icons.filter(icon =>
            icon.name.toLowerCase().includes(query) ||
            icon.class.toLowerCase().includes(query)
        );
    })


    const selectIcon = (icon) => {
        selectedIcon.value = icon;
        emit('select-icon', selectedIcon.value);
    }

    const selectWeight = (iconWeight) => {

        emit('select-weight', [iconWeight, selectedIcon.value.class].join(' '));
        emit('close');

        selectedIcon.value = null
    }

</script>
<template>
    <div>
      <div class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" @click="emit('close')">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-wl shadow-xl w-full">
          <div class="bg-white px-4">
            <div
                v-if="selectedIcon === null"
                class="sm:flex sm:items-start"
            >
              <div class="w-full text-center">
                <div class="mt-4">
                  <input-text
                    type="text"
                    autofocus
                    v-model:content="searchQuery"
                    :placeholder="_mft('shared:search')"
                  />

                  <div class="grid grid-cols-6 gap-4 overflow-y-auto max-h-64">
                    <button
                      v-for="icon in filteredIcons"
                      :key="icon.class"
                      @click="selectIcon(icon)"
                      class="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-100"
                    >
                      <i :class="icon.class" class="mb-1 text-xl fa-solid"></i>
                      <span class="w-full text-xs text-center truncate">{{ icon.name }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
                v-if="selectedIcon !== null"
            >
                <ul class="grid grid-cols-4 pt-6 pb-4">
                    <li class="text-center">
                        <button @click="selectWeight('fa-solid')">
                            <i class="text-xl fa-solid" :class="selectedIcon.class"></i>
                        </button>
                    </li>
                    <li class="text-center">
                        <button @click="selectWeight('fa-regular')">
                            <i class="text-xl fa-regular" :class="selectedIcon.class"></i>
                        </button>
                    </li>
                    <li class="text-center">
                        <button @click="selectWeight('fa-light')">
                            <i class="text-xl fa-light" :class="selectedIcon.class"></i>
                        </button>
                    </li>
                    <li class="text-center">
                        <button @click="selectWeight('fa-thin')">
                            <i class="text-xl fa-thin" :class="selectedIcon.class"></i>
                        </button>
                    </li>
                </ul>
            </div>
          </div>

          <div class="px-4 pb-3 bg-white flex justify-end">
            <button-base
              size="sm"
              @click="emit('close')"
            >
              {{ _mft('shared:action.cancel') }}
            </button-base>
          </div>
        </div>
      </div>
    </div>
  </template>
