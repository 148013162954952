<script setup>
import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import FieldStatistic from "./FieldStatistic.vue";
import FieldResponses from "./FieldResponses.vue";

const props = defineProps({
    formId: {
        type: Number,
        required: true,
    },
    chartColor: {
        type: String,
        required: false,
        default: '#666666',
    }
})

const statisticsLoaded = ref(false)
const form = ref({
    id: '',
    slug: '',
    title: '',
    response_count: '',
})
const statistics = ref([])
const presenterMode = ref(false)

const loadStatistics = async () => {
    const res = await axios.get(route('api.form.statistic.show', props.formId))
    statistics.value = res.data.statistics
    form.value.id = res.data.form.id
    form.value.slug = res.data.form.slug
    form.value.title = res.data.form.title
    form.value.response_count = res.data.form.response_count
    statisticsLoaded.value = true
}

onMounted(() => {
    loadStatistics()
})

</script>
<template>
    <div
        :class="presenterMode ? 'fixed inset-0 z-modal bg-white overflow-y-scroll px-4 md:px-6 lg:px-8' : ''"
    >
        <div
            v-if="! presenterMode"
            class="md:sticky top-2 z-topbar transition-transform"
        >
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        <a
                            :href="route('forms.edit', form.id)"
                            class="mf-link"
                        >
                            {{ form.title }}
                        </a>
                    </h1>
                    <div class="flex items-center justify-end space-x-2">
                        <a
                            v-if="form"
                            :href="route('form-submissions.create', form.slug)"
                            target="_blank"
                        >
                            <button-secondary>{{ _mft('shared:action.show') }}</button-secondary>
                        </a>
                        <button-secondary
                            @click="presenterMode = !presenterMode"
                        >Present</button-secondary>
                    </div>
                </div>
                <template #navigation>
                    <a
                        :href="route('forms.index')"
                        class="mf-link text-xs"
                    >
                        {{ _mft('form:form.domainNamePlural') }}
                    </a>
                </template>
            </frosted-bar>
        </div>

        <div
            :class="presenterMode ? '' : 'mt-12'"
        >
            <span
                v-if="presenterMode"
                class="fixed top-4 right-4"
            >
                <button-secondary
                    size="sm"
                    @click="presenterMode = !presenterMode"
                >
                    <i class="far fa-times"></i>
                </button-secondary>
            </span>

            <div
                v-if="! statisticsLoaded"
                class="flex justify-center"
            >
                <mf-spinner-medium class="w-24 h-12" />
            </div>

            <div
                v-else
                class="space-y-12"
            >
                <div
                    v-if="presenterMode"
                    class="min-h-screen flex items-center justify-center"
                >
                    <div>
                        <h1 class="text-3xl">{{ form.title}}</h1>
                        <p class="lowercase text-center">{{ form.response_count }} {{ _mft('form:statistics.responses') }}</p>
                    </div>
                </div>

                <p
                    v-if="form.response_count === 0"
                    class="text-center text-gray-500"
                >
                    {{ _mft('form:form.noResponses') }}
                </p>

                <div
                    v-for="(field, f) in statistics.fields"
                    class="mb-4"
                    :class="presenterMode ? 'min-h-screen' : ''"
                >
                    <h2>
                        {{ field.title}}
                    </h2>
                    <div class="text-xs text-gray-500 mb-2">
                        {{ _mft('blocks:'+field.type) }}
                    </div>
                    <div>
                        <div
                            v-for="(response, r) in field.responses"
                            class="hidden"
                        >
                            {{ response }}
                        </div>
                        <div class="grid grid-cols-6 gap-4">
                            <field-statistic
                                v-if="field.stats_available"
                                v-for="(stat, s) in field.stats"
                                :type="s"
                                :stat="stat"
                                :chart-color="chartColor"
                            />
                            <field-responses
                                v-else
                                :field="field"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
