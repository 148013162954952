<script setup>
import {ref, computed, reactive, onMounted, watch} from 'vue'
import TemplateOptions from "./Assistant/TemplateOptions.vue";
import AllDonePage from "./Assistant/AllDonePage.vue";
import Loadingscreen from "./Assistant/Loadingscreen.vue";

const props = defineProps({
    showIntro: {
        type: Boolean,
        default: true,
    },
    presetPrompts: {
        type: Object,
        required: false,
        default: null,
    },
    presetTemplateOptions: {
        type: Object,
        required: false,
        default: null,
    }
})

const timeSpentPerWeek = ref(60)
const dealSize = ref(1500)
const templateOptions = ref(null)

const data = ref(null)

const steps = ref({
    prerequisites: false,
    formStep: false,
    resultStep: null,
    leadHunter: false,
    template: false,
    prompt: false,
    summary: false,
})

const anchorlinks = {
    0: {
        id: 'prerequisites',
        label: 'Time and money',
    },
    1: {
        id: 'formStep',
        label: 'Possible results',
    },
    2: {
        id: 'howIsThisWorking',
        label: 'How it works',
    },
    3: {
        id: 'leadHunter',
        label: 'You audience',
    },
    4: {
        id: 'template',
        label: 'Your looks',
    },
    5: {
        id: 'prompt',
        label: 'Your story',
    },
    6: {
        id: 'summary',
        label: 'Summary'
    },
}

const loading = ref(true)
const working = ref(false)
const completionPoller = ref(null)
const allDone = ref(false)
const isLaunched = ref(false)
const links = ref(null)
const baseColor = ref('#ebebeb')
const expectedResults = ref(null)

const leadHunter = reactive({
    initialSearchPerformed: false,
    waiting: false,
    leadCount: null,
    leadCountDisplay: null,
    leadCountRangeDisplay: '',
    estimatedActualLeadCount: 0,
})

const details = reactive({
    timeSpentPerWeek: timeSpentPerWeek.value,
    dealSize: dealSize.value,
    resultStep: null,
    leadHunterParameters: null,
    templateId: null,
    templateOptions: props.presetTemplateOptions,
    prompt: {
        who: props.presetPrompts?.who || null,
        what: props.presetPrompts?.what || null,
        result: props.presetPrompts?.result || null,
    }
})

const goToStep = (step) => {
    steps.value[step] = true

    setTimeout(function() {
        const el = document.getElementById(step)
        if (!el) {
            return
        }
        window.scrollTo({
            top: el.offsetTop,
            behavior: 'smooth'
        })
    }, 10)
}

const setPreferredResult = (option) => {
    steps.value.resultStep = option.id
    details.resultStep = option.id
    expectedResults.value = option
    if (props.showIntro) {
        goToStep('howIsThisWorking')
    } else {
        goToStep('leadHunter')
    }
}

const completedSteps = computed(() => {
    const completed = []

    if (steps.value.formStep) {
        completed.push(1)
    }

    if (steps.value.resultStep !== null) {
        completed.push(2)
    }

    if (steps.value.leadHunter) {
        completed.push(3)
    }

    if (steps.value.prompt) {
        completed.push(4)
    }

    if (steps.value.template) {
        completed.push(5)
    }

    if (steps.value.summary) {
        completed.push(6)
    }

    return completed
})

const updateTemplateOptions = (options) => {
    details.templateOptions = options
    templateOptions.value = options
}

const updateLeadhunterParameters = (params) => {
    details.leadHunterParameters = params
}

const startPolling = () => {
    completionPoller.value = setInterval(async () => {
        await checkSetupCompletion()
    }, 10000)
}

const checkSetupCompletion = async () => {
    const res = await axios.get(route('assistant.pollSetupCompletion'))

    if (res.data.isLaunched === true) {
        isLaunched.value = true
    }

    if (res.data.setupInitiated === true) {
        working.value = true
        startPolling()
    }

    if (res.data.setupCompleted === true) {
        clearInterval(completionPoller.value)
        allDone.value = true
        working.value = false
        data.value = res.data
        setTimeout(function(){
            if (! props.showIntro) {
                window.location.href = route('lead-hunter.home')
                return
            }

            goToStep('allDone')
        }, 100)
    }
    loading.value = false
}

const submit = async () => {
    working.value = true
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
    startPolling()

    const res = await axios.post(route('assistant.initiateSetup'), {
            template_options: details.templateOptions,
            who_text: details.prompt.who,
            offer_text: details.prompt.what,
            result_text: details.prompt.result,
            lead_search: details.leadHunterParameters,
            time_per_week: details.timeSpentPerWeek,
            deal_size: details.dealSize,
            expected_results: expectedResults.value,
    })

    console.log(res)
}

const leadSufficiency = computed(() => {
    const required = (((timeSpentPerWeek.value  / 5) * 20) * 3) * 1.5

    return {
        isSufficient: leadHunter.leadCount >= required,
        approximateDeficit: required > leadHunter.leadCount ? 0 - (Math.floor((leadHunter.leadCount - required) / 10) * 10) : 0,
    }
})

const options = computed(() => {
    const baseConversations = (timeSpentPerWeek.value * 4) / 5

    return [
        {
            id: 1,
            title: 'Sceptical',
            description: 'Calculated with 5% progressing to a meeting, and about 15% of the meetings leading to a deal.',
            icon: 'fa-face-raised-eyebrow',
            conversations: baseConversations,
            meetings: baseConversations * 0.05,
            deals: baseConversations * 0.05 * 0.15,
            results90: baseConversations * 0.05 * 0.15 * dealSize.value * 3,
            results180: baseConversations * 0.05 * 0.15 * dealSize.value * 3 * 2,
            results365: baseConversations * 0.05 * 0.15 * dealSize.value * 3 * 4,
            cta: 'I\m feeling sceptical',
        },
        {
            id: 2,
            title: 'Optimistic',
            description: 'Calculated with 10% progressing to a meeting, and about 20% of the meetings leading to a deal.',
            icon: 'fa-face-smile',
            conversations: baseConversations,
            meetings: baseConversations * 0.1,
            deals: baseConversations * 0.1 * 0.20,
            results90: baseConversations * 0.1 * 0.20 * dealSize.value * 3,
            results180: baseConversations * 0.1 * 0.20 * dealSize.value * 3 * 2,
            results365: baseConversations * 0.1 * 0.20 * dealSize.value * 3 * 4,
            cta: 'I\m feeling optimistic',
        },
        {
            id: 3,
            title: 'Home Run',
            description: 'Calculated with 15% progressing to a meeting, and about 25% of the meetings leading to a deal.',
            icon: 'fa-rocket',
            conversations: baseConversations,
            meetings: baseConversations * 0.15,
            deals: baseConversations * 0.15 * 0.25,
            results90: baseConversations * 0.15 * 0.25 * dealSize.value * 3,
            results180: baseConversations * 0.15 * 0.25 * dealSize.value * 3 * 2,
            results365: baseConversations * 0.15 * 0.25 * dealSize.value * 3 * 4,
            cta: 'It\'s a home run',
        },
    ]
})

const hoursSpentPerWeek = computed(() => {
    return parseInt(timeSpentPerWeek.value / 60, 10)
})

const weeksKeptBusy = computed(() => {
    return Math.floor(leadHunter.leadCount / expectedResults.value.conversations)
})

watch(() => timeSpentPerWeek, () => {
    details.timeSpentPerWeek = timeSpentPerWeek.value
}, {deep: true})

watch(() => dealSize, () => {
    details.dealSize = dealSize.value
}, {deep: true})

onMounted(() => {
    if (! props.showIntro) {
        loading.value = false
        goToStep('prerequisites')
    } else {
        checkSetupCompletion()
    }
})

</script>

<template>
    <div v-if="! loading">
        <div v-if="! working && ! allDone">
            <div
                v-if="showIntro"
                class="flex flex-col items-center justify-center min-h-screen pt-16 pb-12"
            >
                <div class="w-full max-w-3xl">
                    <div class="flex items-start justify-center mb-8 -ml-8 space-x-4">
                        <div class="text-right">
                            <i class="w-16 h-16 text-5xl fa-light fa-hat-wizard text-myflowPurple" aria-hidden="true"></i>
                        </div>
                        <div>
                            <h1 class="flex items-center justify-center -mt-2 -mb-1 text-3xl">
                                Setup Assistant
                            </h1>
                            <div class="text-center">
                                <p class="mb-0 text-xl text-center">
                                    It cures procrastination*
                                </p>
                                <span class="text-gray-400">* Not clinically tested</span>
                            </div>
                        </div>
                    </div>

                    <iframe
                        class="w-full overflow-hidden rounded-lg md:hidden aspect-video"
                        src="https://www.loom.com/embed/ad7f788d348e4b6f8a4fdfc2d6cff969?sid=956f4c46-68d8-4945-bba8-14f8cef45d92&hide_owner=true
                            &hide_title=true
                            &hide_share=true
                            &hideEmbedTopBar=true
                            &hideTranscript=true"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                    ></iframe>

                    <iframe
                        class="hidden w-full overflow-hidden rounded-lg md:block aspect-video"
                        src="https://www.loom.com/embed/ad7f788d348e4b6f8a4fdfc2d6cff969?sid=956f4c46-68d8-4945-bba8-14f8cef45d92&hide_owner=true
                            &hide_title=true
                            &hide_share=true
                            &hideEmbedTopBar=true
                            &hideTranscript=true"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                    ></iframe>

                    <div class="flex justify-center mt-8">
                        <button-primary
                            size="lg"
                            @click="goToStep('prerequisites')"
                            :shimmer="true"
                        >
                            Let's get started
                        </button-primary>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-4 gap-8 mb-8">
                <div
                    v-if="steps.prerequisites"
                    id="prerequisites"
                    class="flex flex-col col-span-4 pt-16 pb-12"
                    :class="showIntro ? 'min-h-screen  justify-center' : ''"
                >
                    <div>
                        <div class="grid grid-cols-5 gap-8 mb-8 text-center">
                            <div class="col-span-1">
                                &nbsp;
                            </div>
                            <div class="col-span-5 md:col-span-3">
                                <h2 class="mb-4 text-2xl md:text-3xl">How much time do you want to invest in acquiring more customers?</h2>
                                <p class="mb-6 md:text-lg">Weekly time spent</p>

                                <button-bar
                                    size="lg"
                                    :options="[
                                        { label: '1 Hour', name: 60, value: 60 },
                                        { label: '2 Hours', name: 120, value: 120 },
                                        { label: '4 Hours', name: 240, value: 240 },
                                    ]"
                                    :selectedOptionName="timeSpentPerWeek"
                                    @select="timeSpentPerWeek = $event.name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-span-4">
                        <div class="grid grid-cols-5 gap-8 mb-8">
                            <div class="col-span-1">&nbsp;</div>

                            <div class="flex flex-col items-center col-span-3">
                                <h2 class="mb-4 text-2xl text-center md:text-3xl">
                                    What is your average deal value?
                                </h2>

                                <p class="mb-6 text-center md:text-lg">
                                    Answer in euro, and don't worry about it being exact, an estimation goes a long way here.
                                </p>

                                <div class="relative flex items-center justify-center w-full max-w-xs">
                                    <span
                                        class="text-3xl text-gray-500 transform pointer-events-none">
                                        €
                                    </span>
                                    <input
                                        type="number"
                                        class="w-full font-mono text-3xl text-center bg-transparent border-none appearance-none focus:outline-none min-w-24"
                                        placeholder="1500"
                                        :style="{width: `${2+dealSize.toString().length * 1.5}rem`}"
                                        autofocus
                                        v-model="dealSize"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-span-4 transition-opacity"
                        :class="steps.formStep === false ? '' : 'opacity-0 pointer-events-none'"
                    >
                        <div class="text-center ">
                            <button-base
                                :style-type="dealSize && dealSize > 0 ? 'primary' : 'disabled'"
                                size="lg"
                                @click="dealSize && dealSize > 0 ? goToStep('formStep') : null"
                            >
                                    Show me the results
                            </button-base>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div
                    v-if="steps.formStep"
                    class="flex flex-col justify-center col-span-4 pt-16 pb-12"
                    :class="showIntro ? 'min-h-screen' : ''"
                    id="formStep"
                >
                    <div>
                        <h2 class="mb-4 text-2xl text-center md:text-3xl">
                            Your possible results!
                        </h2>
                        <p class="mb-6 text-center md:text-lg">
                            Think of this as a small, medium and large scenario. You know your business best, so choose the one
                            that fits you the best.
                        </p>
                        <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                            <li class="overflow-hidden border border-gray-200 rounded-xl" v-for="option in options"
                                :key="option.title">
                                <div class="flex items-center p-6 border-b gap-x-4 border-gray-900/5 bg-gray-50">
                                    <i class="text-2xl fa-light text-myflowPurple-900" :class="option.icon"></i>
                                    <div>
                                        <h2 class="">
                                            {{ option.title }}
                                        </h2>
                                        <small>{{ option.description }}</small>
                                    </div>
                                </div>
                                <dl class="px-6 pt-6 pb-4 -my-3 divide-y divide-gray-100 text-sm/6">
                                    <div class="flex justify-between py-1 gap-x-4">
                                        <dt class="text-gray-500">New Emails / Month:</dt>
                                        <dd class="flex items-start gap-x-2">
                                            <div class="font-medium text-gray-900">
                                                {{ option.conversations.toLocaleString('sv', { maximumFractionDigits: 2 }) }}
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="flex justify-between py-1 gap-x-4">
                                        <dt class="text-gray-500">Meetings / Month:</dt>
                                        <dd class="flex items-start gap-x-2">
                                            <div class="font-medium text-gray-900">
                                                {{ option.meetings.toLocaleString('sv', { maximumFractionDigits: 2 }) }}
                                            </div>
                                        </dd>
                                    </div>
                                    <div class="flex justify-between py-1 gap-x-4">
                                        <dt class="text-gray-500">Deals / Month:</dt>
                                        <dd class="flex items-start gap-x-2">
                                            <div class="font-medium text-gray-900">
                                                {{ option.deals.toLocaleString('sv', { maximumFractionDigits: 1 }) }}
                                            </div>
                                        </dd>
                                    </div>

                                    <hr class="mt-3 mb-3" />

                                    <div class="flex justify-between py-1 gap-x-4">
                                        <dt class="text-gray-500">90 Day Results:</dt>
                                        <dd class="flex items-start gap-x-2">
                                            <div class="font-medium text-gray-900">
                                                €{{ option.results90.toLocaleString('sv', { maximumFractionDigits: 2 }) }}
                                            </div>
                                        </dd>
                                    </div>

                                    <div class="flex justify-between py-1 gap-x-4">
                                        <dt class="text-gray-500">365 Days Results:</dt>
                                        <dd class="flex items-start gap-x-2">
                                            <div class="font-medium text-gray-900">
                                                €{{ option.results365.toLocaleString('sv', { maximumFractionDigits: 2 }) }}
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                                <div class="p-2 text-center">
                                    <button-base
                                        :style-type="steps.resultStep === option.id ? 'primary' : 'secondary'"
                                        @click="setPreferredResult(option)"
                                    >
                                        <span class="p-2"
                                            :class="steps.resultStep === option.id ? '' : ''">
                                            {{ option.cta }}
                                        </span>
                                    </button-base>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>



                <div
                    v-if="steps.formStep && steps.resultStep !== null"
                    class="flex-col justify-center min-h-screen col-span-4 pt-16 pb-12"
                    :class="showIntro ? 'flex' : 'hidden'"
                    id="howIsThisWorking"
                >
                    <div class="mb-16">
                        <h2 class="mb-4 text-2xl text-center md:text-3xl">
                            How is this working?
                        </h2>
                        <p class="mb-6 text-center md:text-lg">
                            Our AI Wizard will help you with the following tools to get you started. Each step is designed to
                            help you get more customers, faster.
                        </p>
                        <div
                            class="overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                            <div
                                class="relative p-6 bg-white rounded-tl-lg rounded-tr-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:rounded-tr-none">
                                <div class="flex gap-8">
                                    <div>
                                        <span
                                            class="inline-flex items-center justify-center p-3 rounded-lg text-myflowPurple-700 bg-myflowPurple-100 ring-4 ring-white aspect-square w-14">
                                            <i class="text-2xl fa-light fa-users-viewfinder"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <h3 class="text-base font-semibold text-gray-900">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <span class="text-myflowPurple font-extrabold mr-0.5 inline-block transform">1</span> LeadHunter AI
                                        </h3>
                                        <p class="mt-2 text-sm text-gray-500">Use LeadHunter to get daily, segmented list into your
                                            contacts, ready to be contacted. We will provide your with a tailored email that you can
                                            send as is, or personalize.</p>
                                    </div>
                                </div>
                                <a href="https://myflow.io/lead-hunter/" target="_blank">
                                    <span class="absolute text-gray-300 right-6 top-6 group-hover:text-gray-400"
                                        aria-hidden="true">
                                        <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <div
                                class="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:rounded-tr-lg">
                                <div class="flex gap-8">
                                    <div>
                                        <span
                                            class="inline-flex items-center justify-center p-3 rounded-lg text-myflowPurple-700 bg-myflowPurple-100 ring-4 ring-white aspect-square w-14">
                                            <i class="text-2xl fa-light fa-bell"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <h3 class="text-base font-semibold text-gray-900">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <span class="text-myflowPurple font-extrabold mr-0.5 inline-block transform">2</span> LinkedIn Nudging
                                        </h3>
                                        <p class="mt-2 text-sm text-gray-500">A couple of days after sending the email, we help you
                                            with a daily list of whom you should give a nudge on LinkedIn to start the conversation.
                                            Don't worry, we would never risk you getting banned, so there is no shady automation
                                            going on.</p>
                                    </div>
                                </div>
                                <a href="https://myflow.io/lead-hunter/" target="_blank">
                                    <span class="absolute text-gray-300 right-6 top-6 group-hover:text-gray-400"
                                        aria-hidden="true">
                                        <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <div
                                class="relative p-6 bg-white group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:rounded-bl-lg">
                                <div class="flex gap-8">
                                    <div>
                                        <span
                                            class="inline-flex items-center justify-center p-3 rounded-lg text-myflowPurple-700 bg-myflowPurple-100 ring-4 ring-white aspect-square w-14">
                                            <i class="text-2xl fa-light fa-square-person-confined"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <h3 class="text-base font-semibold text-gray-900">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <span class="text-myflowPurple font-extrabold mr-0.5 inline-block transform">3</span> Landing Page
                                        </h3>
                                        <p class="mt-2 text-sm text-gray-500">Don't waste time sending more information in an email.
                                            Step into 2025 and send a jaw dropping landing page that displays just how great it is
                                            to work with you. </p>
                                    </div>
                                </div>
                                <a href="https://myflow.io/features/landing-pages/" target="_blank">
                                    <span class="absolute text-gray-300 right-6 top-6 group-hover:text-gray-400"
                                        aria-hidden="true">
                                        <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <div
                                class="relative p-6 bg-white rounded-bl-lg rounded-br-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:rounded-bl-none">
                                <div class="flex gap-8">
                                    <div>
                                        <span
                                            class="inline-flex items-center justify-center p-3 rounded-lg text-myflowPurple-700 bg-myflowPurple-100 ring-4 ring-white aspect-square w-14">
                                            <i class="text-2xl fa-light fa-file-signature"></i>
                                        </span>
                                    </div>
                                    <div>
                                        <h3 class="text-base font-semibold text-gray-900">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <span class="text-myflowPurple font-extrabold mr-0.5 inline-block transform">4</span> Shiny Quotes
                                        </h3>
                                        <p class="mt-2 text-sm text-gray-500">We generate a drafted quote template based on you and
                                            your business. You choose the design, and let our AI do the majority of the writing.
                                            Look professional and act swiftly to close the deal faster.</p>
                                    </div>
                                </div>
                                <a href="https://myflow.io/features/quotes/" target="_blank">
                                    <span class="absolute text-gray-300 right-6 top-6 group-hover:text-gray-400"
                                        aria-hidden="true">
                                        <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="max-w-2xl col-span-4 mx-auto" v-if="steps.formStep && steps.resultStep !== null">
                        <p class="mb-4 text-lg text-center">All you have to do is to go to the next step and tell us
                            a bit more about yourself and your business, and we will put the Wizard to work for you.</p>
                        <div class="text-center ">
                            <button-secondary
                                size="lg"
                                @click="goToStep('leadHunter')"
                            >
                                Start the <b>AI Assistant</b>
                            </button-secondary>
                        </div>
                    </div>
                </div>

                <div
                    v-if="steps.leadHunter"
                    class="flex flex-col justify-center w-full max-w-2xl min-h-screen col-span-4 pt-16 pb-12 mx-auto"
                    id="leadHunter"
                >
                    <h2 class="mb-4 text-2xl md:text-3xl">Your audience</h2>
                    <lead-hunter-query
                        @initial-search-performed="leadHunter.initialSearchPerformed = true"
                        @waiting="leadHunter.waiting = $event"
                        @lead-count="leadHunter.leadCount = $event"
                        @lead-count-display="leadHunter.leadCountDisplay = $event"
                        @lead-count-range-display="leadHunter.leadCountRangeDisplay = $event"
                        @estimated_actual_lead_count="leadHunter.estimatedActualLeadCount = $event"
                        @parameters-updated="updateLeadhunterParameters"
                    ></lead-hunter-query>

                    <div
                        class="flex justify-center h-48"
                    >
                        <mf-spinner-medium
                            v-if="leadHunter.waiting"
                            class="w-24 h-12"
                        />
                        <div
                            v-if="! leadHunter.waiting && leadHunter.initialSearchPerformed"
                        >
                            <p
                                v-if="! leadSufficiency.isSufficient"
                                class="text-center"
                            >
                                We found <strong>{{ leadHunter.leadCount > 0 ? leadHunter.leadCountRangeDisplay : 0 }} potential leads</strong>, which is about {{ leadSufficiency.approximateDeficit }} short of your goal.<br>
                                It should however keep you busy for {{ weeksKeptBusy }} weeks, so if that's enough for you -  <strong>go for it!</strong><br>
                                <em class="text-gray-500 block mt-2 text-center max-w-lg mx-auto text-xs">Some fields, such as number of employees are difficult for us to get an accurate number for, and may severely limit the number of results.</em>
                            </p>
                            <div
                                v-else
                                class="text-center"
                            >
                                <h3 class="md:text-2xl">Great!</h3>
                                <p class="md:text-lg">We found <strong>{{ leadHunter.leadCount > 0 ? leadHunter.leadCountRangeDisplay : 0 }} potential leads</strong> within your audience.</p>
                            </div>
                            <div v-if="leadHunter.leadCount > 0" class="text-center">
                                <button-base
                                    :style-type="leadSufficiency.isSufficient ? 'success' : 'secondary'"
                                    size="lg"
                                    @click="goToStep('prompt')"
                                >
                                    Continue
                                </button-base>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="steps.prompt"
                    id="prompt"
                    class="flex flex-col justify-center w-full max-w-2xl min-h-screen col-span-4 pt-16 pb-12 mx-auto"
                >
                    <div>
                        <assistant-prompt
                            :timeSpentPerWeek="timeSpentPerWeek"
                            :dealSize="dealSize"
                            :preset-prompts="props.presetPrompts"
                            @prompt-updated="details.prompt = $event"
                            @color-updated="baseColor = $event"
                        ></assistant-prompt>

                        <div class="text-center">
                            <button-base
                                size="lg"
                                :style-type="details.prompt.what && details.prompt.who && details.prompt.result ? 'secondary' : 'disabled'"
                                :disabled="!details.prompt.what || !details.prompt.who || !details.prompt.result"
                                @click="!details.prompt.what || !details.prompt.who || !details.prompt.result ? null : goToStep('template')"
                            >
                                Continue
                            </button-base>
                        </div>
                    </div>
                </div>


                <div
                    v-if="steps.template"
                    id="template"
                    class="flex flex-col justify-center w-full max-w-2xl min-h-screen col-span-4 pt-16 pb-12 mx-auto"
                >
                    <template-options
                        :preset-template-options="props.presetTemplateOptions"
                        @options-updated="updateTemplateOptions"
                        :base-color="baseColor"
                    ></template-options>

                    <div class="flex justify-center mt-4">
                        <button-secondary
                            @click="goToStep('summary')"
                        >Looks good!</button-secondary>
                    </div>
                </div>

                <div
                    class="sticky left-0 right-0 flex justify-center w-full px-4 bottom-14 md:bottom-12 md:px-6 lg:px-8 z-50"
                >
                    <div
                        class="flex items-center justify-center p-2 space-x-4 bg-white/50 backdrop-blur-lg rounded-wl"
                    >
                        <a
                            v-for="step in [1, 2, 3, 4, 5, 6]"
                            class="relative flex items-center justify-center w-6 h-6 p-1 text-xs bg-white border-2 rounded-full"
                            :class="completedSteps.includes(step) ? 'border-success' : 'border-gray-200'"
                            :href="'#'+anchorlinks[step-1].id"
                        >
                            {{ step }}
                            <span
                                class="absolute transition-all bg-white rounded-full -top-2 -right-2"
                                :class="completedSteps.includes(step) ? 'scale-100' : 'scale-0 opacity-0'"
                            ><i class="fa-solid fa-check-circle text-success-light"></i></span>
                        </a>

                        <a
                            v-if="completedSteps.includes(6)"
                            class="relative flex items-center justify-center h-6 p-1 text-xs text-sm text-white border-2 rounded-full bg-success border-success"
                            href="#summary"
                        >
                            Summary
                        </a>
                    </div>
                </div>
            </div>

            <div
                v-if="steps.summary"
                id="summary"
                class="flex flex-col justify-center w-full max-w-2xl min-h-screen col-span-4 pt-16 pb-12 mx-auto"
            >
                <card-basic>
                    <h1 class="mb-2 text-2xl md:text-3xl">Summary</h1>

                    <ul class="mb-8 divide-y">
                        <li
                            v-for="step in [1, 2, 4, 6]"
                            class="px-4 py-4 -mx-4 md:-mx-6 lg:-mx-8 md:px-6 lg:px-8"
                        >
                            <div>
                                <h3 class="flex justify-between space-x-2">
                                    <span>{{ anchorlinks[step-1].label }}</span>
                                    <span>
                                        <a
                                            :href="'#'+anchorlinks[step-1]?.id"
                                            class="inline-block font-normal text-gray-400 transform scale-75 mf-link"
                                        >
                                            Edit
                                        </a>
                                    </span>
                                </h3>
                                <div v-if="step === 1">
                                    <p class="md:text-base">You plan to spend {{ hoursSpentPerWeek }} {{ hoursSpentPerWeek > 1 ? 'hours' : 'hour' }} per week to get the results you want.</p>
                                    <p class="md:text-base">Your average deal is worth about €{{ Intl.NumberFormat('sv-SE').format(details.dealSize) }}</p>
                                </div>

                                <div
                                    v-if="step === 2"
                                >
                                    <p class="md:text-base">You're feeling {{ options.filter(option => option.id === details.resultStep)[0].title }} and will potentially make an additional €{{ Intl.NumberFormat('sv-SE').format(parseInt(options.filter(option => option.id === details.resultStep)[0][`results${details.resultStep === 1 ? 90 : details.resultStep === 2 ? 180 : 365}`])) }} in a year.
                                    </p>
                                </div>

                                <div
                                    v-if="step === 4"
                                >
                                    <p class="md:text-base">We will look for leads matching these preferences</p>
                                    <div class="space-y-3">
                                        <div
                                            v-if="details.leadHunterParameters?.job_title?.length > 0"
                                        >
                                            <div class="font-bold">Title</div>
                                            <div>{{ details.leadHunterParameters.job_title.map(job_title => job_title.label).join(', ') }}</div>
                                        </div>
                                        <div
                                            v-if="details.leadHunterParameters?.company_industry?.length > 0"
                                        >
                                            <div class="font-bold">Industry</div>
                                            <div>{{ details.leadHunterParameters.company_industry.map(industry => industry.label).join(', ') }}</div>
                                        </div>
                                        <div
                                            v-if="details.leadHunterParameters?.company_size?.length > 0"
                                        >
                                            <div class="font-bold">Company size</div>
                                            <div>{{ details.leadHunterParameters.company_size.map(size => size.label).join(', ') }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="step === 6"
                                    class="italic"
                                >
                                    <p class="md:text-sm md:pr-12">{{ details.prompt.who }}</p>
                                    <p class="md:text-sm md:pr-12">{{ details.prompt.what }}</p>
                                    <p class="md:text-sm md:pr-12">{{ details.prompt.result }}</p>
                                </div>

                            </div>
                        </li>
                    </ul>
                    <div class="flex flex-col items-center justify-center">
                        <p class="text-center md:text-lg">
                            <strong>That's it!</strong><br>
                            Just one really simple step left: Hit that button
                        </p>
                        <button-success
                            size="lg"
                            @click="submit"
                            :shimmer="true"
                        >
                            Let's go!
                        </button-success>
                    </div>
                </card-basic>
            </div>

            <div class="pb-[100vh]"></div>

        </div>

        <div
            v-if="working"
            class="flex items-center justify-center min-h-screen py-16"
        >
            <Loadingscreen />
        </div>

        <div
            v-if="allDone"
            class="flex items-center justify-center min-h-screen py-16"
        >
            <AllDonePage
                :data="data"
                :isLaunched="isLaunched"
            ></AllDonePage>
            <confetti-bomb />
        </div>
    </div>
</template>
