<script setup>
import {ref, onMounted, watch} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const helpers = [
    {
        sectionName: 'Helpers',
        components: [
            {
                name: 'URL/Link Shortener',
                description: 'Shorten URLs to make them easier to share.',
                icon: 'fa-regular fa-link',
                toolName: 'url-shortener',
                componentName: 'utility-url-shortener',
            },
            {
                name: 'Password Generator',
                description: 'Generate secure passwords.',
                icon: 'fa-regular fa-key',
                toolName: 'password-generator',
                componentName: 'utility-password-generator',
            },
            {
                name: 'Base64 Encoder/Decoder',
                description: 'Encode or decode text to/from Base64.',
                icon: 'fa-regular fa-code',
                toolName: 'base64-coder',
                componentName: 'utility-base64-coder',
            },
            {
                name: 'QR-Code Generator',
                description: 'Generate QR-Codes.',
                icon: 'fa-regular fa-qrcode',
                toolName: 'qr-generator',
                componentName: 'utility-qr-generator',
            },
        ]
    },
    {
        sectionName: 'Toolbar Favorites',
        components: [
            {
                name: 'Time Tracking',
                description: 'Quickly add time tracking entries.',
                icon: 'fa-regular fa-clock',
                toolName: 'time-tracking',
                componentName: 'time-tracking-form',
            },
            {
                name: 'Add Contact',
                description: 'Add contacts quickly on the go.',
                icon: 'fa-regular fa-user-plus',
                toolName: 'toolbar-add-lead',
                componentName: 'toolbar-add-lead',
            },
            {
                name: 'Media Upload',
                description: 'Upload to Media Library from anywhere.',
                icon: 'fa-regular fa-upload',
                toolName: 'media-upload',
                componentName: 'media-upload',
            },
            {
                name: 'Tasks',
                description: 'Manage tasks and todos on the fly',
                icon: 'fa-light fa-list-check',
                toolName: 'utility-manage-tasks',
                componentName: 'utility-manage-tasks',
            }
        ]
    },
]


const showingComponent = ref(null)
const pinnedHelpers = ref([])

onMounted(() => {
    _mfPrefGet('pinned-toolbar-items').then(data => {
        pinnedHelpers.value = data.split(',')
    })
})

const pinHelper = (helper) => {
    if (pinnedHelpers.value.includes(helper.toolName)) {
        pinnedHelpers.value = pinnedHelpers.value.filter(item => item !== helper.toolName)
    } else {
        pinnedHelpers.value.push(helper.toolName)
    }
    _mfPrefSet('pinned-toolbar-items', pinnedHelpers.value.join(','))
    setTimeout(function(){
        window.location.reload()
    }, 250)
}

</script>

<template>
    <div
        class="max-w-5xl grid-cols-2 gap-8 mx-auto space-y-6 md:grid md:space-y-0 md:mt-16"
    >
        <div
            v-for="helper in helpers" v-bind:key="helper.sectionName"
            class="space-y-5"
        >
            <h2 class="text-2xl font-semibold">{{ helper.sectionName }}</h2>

            <div
                v-for="helper in helper.components" v-bind:key="helper.name"
            >
                <div
                    class="items-center justify-between h-full p-4 space-y-4 bg-white shadow-xl lg:flex rounded-wl lg:space-y-0"
                >
                    <div class="flex items-center space-x-4">
                        <i
                            class="text-4xl text-wlPrimary"
                            v-bind:class="helper.icon"
                            @click="showingComponent = helper"
                        ></i>
                        <div>
                            <h3 class="text-lg font-semibold">{{ helper.name }}</h3>
                            <p class="mb-0 text-sm text-gray-500">{{ helper.description }}</p>
                        </div>
                    </div>
                    <div class="flex items-center justify-center space-x-4 md:space-x-0 md:space-y-2 md:flex-col">
                        <button-secondary
                            size="sm"
                            class="justify-center w-full max-w-48"
                            @click="showingComponent = helper"
                        >
                            Open
                        </button-secondary>
                        <button-secondary
                            size="sm"
                            class="justify-center w-full max-w-48"
                            @click="pinHelper(helper)"
                        >
                            {{ pinnedHelpers.includes(helper.toolName) ? 'Unpin' : 'Pin' }}
                        </button-secondary>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <slideout-with-slots
        v-if="showingComponent !== null"
        @close="showingComponent = null"
    >
        <template #title>
            <h1 class="text-xl font-semibold">{{ showingComponent.name }}</h1>
        </template>
        <template #body>
            <component
                v-bind:is="showingComponent.componentName"
            ></component>
        </template>

    </slideout-with-slots>

</template>
