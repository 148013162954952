<script setup>
import automationOptions from './_automationOptions.vue';
import emailContentEditor from './_emailContentEditor.vue';
import recipients from './_recipients.vue';
import communicationSummary from './_summary.vue';
import smsContentEditor from './_smsContentEditor.vue';

import { ref, onMounted, computed, reactive, watch } from 'vue';
import ButtonSecondary from "../../buttons/buttonSecondary.vue";

const state = reactive({
    currentStep: 'automationOptions',
    showQuickNavigation: false
})

const steps = [
    {
        name: 'automationOptions',
        title: _mft('communication:composer.steps.automationOptions'),
    },
    {
        name: 'emailContent',
        title: _mft('communication:composer.steps.emailContent'),
    },
    {
        name: 'smsContent',
        title: _mft('communication:composer.steps.smsContent'),
    },
    {
        name: 'summary',
        title: _mft('communication:composer.steps.summary'),
    }
]

const enabledSteps = computed(() => {
    return steps.filter(step => {
        if (step.name === 'emailContent') {
            return composition.automationOptions.selectedDeliveryTypes?.includes('email');
        }
        if (step.name === 'smsContent') {
            return composition.automationOptions.selectedDeliveryTypes?.includes('sms');
        }
        if (step.name === 'summary') {
            return automationOptionsValidate.value;
        }

        return true;
    })
})

const props = defineProps({
    communicationType: {
        type: String,
        required: true
    },
    automation: {
        type: Object,
        required: false
    },
    sms_enabled: {
        type: Boolean,
        required: false,
        default: false
    },
    email_enabled: {
        type: Boolean,
        required: false,
        default: false
    },
    current_user_contact: {
        type: Object,
        required: false,
        default: null
    },
    company_color_set: {
        type: Boolean,
        required: false,
        default: false
    }
})

const recipientCounts = reactive({
    total: 0,
    email: 0,
    phone: 0,
})

const composition = reactive({
    automationId: null,
    includedTags: [],
    excludedTags: [],
    emailContent: {
        subject: '',
        body: []
    },
    smsContent: '',
    automationOptions: {
        name: '',
        selectedDeliveryTypes: ['email', 'sms'],
        selectedSendRuleType: 'once_to_all',
        onceToAllDate: null,
        onceAssignedInTimeRangeStart: null,
        onceAssignedInTimeRangeEnd: null,
        recurringAfterTagAssignedDelayInHours: null,
        activateAt: null,
    },
})

const automations = ref(null);
const savingState = ref('')
const emailContentEditorRef = ref(null)

const loadAutomations = async () => {
    const response = await axios.get(route('crm.communications.automations.list'));
    return response.data.automations;
}

const storeOrUpdateAutomation = async (automationId, data) => {
    let response;
    savingState.value = 'working'
    if (automationId) {
        response = await axios.put(route('crm.communications.automations.update', automationId), data);
    } else {
        response = await axios.post(route('crm.communications.automations.store'), data);
    }
    automations.value = await loadAutomations();
    savingState.value = 'success'
    return response;
}

const submitAndPreviewAutomation = async () => {
    savingState.value = 'waiting'
    // TODO - this is a copy of submitAutomation, refactor to avoid duplication
    const automationData = compileAutomationData();

    const response = await storeOrUpdateAutomation(composition.automationId, automationData);
    composition.automationId = response.data.data.id;

    const previewResponse = await axios.post(route('crm.communications.automations.dispatchTestMessages', composition.automationId))

    if (emailContentEditorRef.value) {
        emailContentEditorRef.value.state.contentChanged = false
    }
    savingState.value = 'success'
    window.location.href = route('crm.communications.automations.show', response.data.data.id);
}

const submitAndPreviewAutomationToTestEmail = async () => {
    savingState.value = 'waiting'
    // TODO - this is a copy of submitAutomation, refactor to avoid duplication
    const automationData = compileAutomationData();

    const response = await storeOrUpdateAutomation(composition.automationId, automationData);
    composition.automationId = response.data.data.id;

    const previewResponse = await axios.post(route('crm.communications.automations.dispatchTestMessages', composition.automationId, {
        email: state.testEmail
    }))

    if (emailContentEditorRef.value) {
        emailContentEditorRef.value.state.contentChanged = false
    }
    savingState.value = 'success'
    window.location.href = route('crm.communications.automations.show', response.data.data.id);
}

const submitAutomation = async () => {
    savingState.value = 'waiting'
    // Changes here also need to be reflected in submitAndPreviewAutomation
    const automationData = compileAutomationData();

    const response = await storeOrUpdateAutomation(composition.automationId, automationData);
    composition.automationId = response.data.data.id;
    composition.automationOptions.activateAtDisplay = response.data.data.activate_at_display
    composition.automationOptions.activateAt = response.data.data.activate_at

    let url = route('crm.communications.automations.show', response.data.data.id) + '#' + state.currentStep;
    history.pushState({}, "", url);
    if (emailContentEditorRef.value) {
        emailContentEditorRef.value.state.contentChanged = false
    }
    savingState.value = 'success'
}

const setDateRange = (dates) => {
    composition.automationOptions.onceAssignedInTimeRangeStart = dates[0];
    composition.automationOptions.onceAssignedInTimeRangeEnd = dates[1]
}

const updateRecipientCount = (counts) => {
    recipientCounts.total = counts.leads_count;
    recipientCounts.email = counts.leads_with_email_count;
    recipientCounts.phone = counts.leads_with_phone_count;
}

const updateIncludedTags = (tagData) => {
    composition.includedTags = tagData
}
const updateExcludedTags = (tagData) => {
    composition.excludedTags = tagData
}

const compileAutomationData = () => {
    let automationData = {
        name: composition.automationOptions.name,
        send_rule: composition.automationOptions.selectedSendRuleType,
        included_lead_tag_ids: composition.includedTags.map(tag => tag.id),
        excluded_lead_tag_ids: composition.excludedTags.map(tag => tag.id),
    }

    if (composition.automationOptions.activateAt) {
        automationData.activate_at = composition.automationOptions.activateAt;
    }

    if (composition.automationOptions.selectedSendRuleType === 'once_assigned_in_time_range') {
        automationData.once_assigned_in_time_range_start = composition.automationOptions.onceAssignedInTimeRangeStart;
        automationData.once_assigned_in_time_range_end = composition.automationOptions.onceAssignedInTimeRangeEnd;
    }

    if (composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned') {
        automationData.recurring_after_tag_assigned_delay_in_hours = composition.automationOptions.recurringAfterTagAssignedDelayInHours;
    }

    if (composition.automationOptions.selectedDeliveryTypes.includes('email')) {
        automationData.email_template = {
            subject: composition.emailContent.subject,
            body: JSON.stringify(composition.emailContent.body)
        }
    }

    if (composition.automationOptions.selectedDeliveryTypes.includes('sms')) {
        automationData.sms_template = {
            message: composition.smsContent
        }
    }

    return automationData;
}

const prevStep = () => {
    const currentIndex = enabledSteps.value.findIndex(step => step.name === state.currentStep);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
        state.currentStep = enabledSteps.value[prevIndex].name;
    }
}

const nextStep = () => {
    const currentIndex = enabledSteps.value.findIndex(step => step.name === state.currentStep);
    const nextIndex = currentIndex + 1;
    if (nextIndex < enabledSteps.value.length) {
        state.currentStep = enabledSteps.value[nextIndex].name;
    }
}

const repopulateComposition = (automation) => {
    composition.automationId = automation.id;
    composition.automationOptions.name = automation.name;
    composition.automationOptions.activateAt = automation.activate_at;
    composition.automationOptions.activateAtDisplay = automation.activate_at_display;
    composition.automationOptions.selectedSendRuleType = automation.send_rule;

    composition.includedTags = automation.included_lead_tags;
    composition.excludedTags = automation.excluded_lead_tags;

    composition.automationOptions.onceAssignedInTimeRangeStart = automation.once_assigned_in_time_range_start;
    composition.automationOptions.onceAssignedInTimeRangeEnd = automation.once_assigned_in_time_range_end;
    composition.automationOptions.recurringAfterTagAssignedDelayInHours = automation.recurring_after_tag_assigned_delay_in_hours;
    composition.emailContent.subject = automation.email_template?.subject;
    composition.emailContent.body = automation.email_template?.block_editor_content.content.length > 0 ? automation.email_template.block_editor_content.content : [];
    composition.smsContent = automation.sms_template ? automation.sms_template.message : '';
    composition.automationOptions.selectedDeliveryTypes = [];
    if (automation.email_template) {
        composition.automationOptions.selectedDeliveryTypes.push('email');
    }
    if (automation.sms_template) {
        composition.automationOptions.selectedDeliveryTypes.push('sms');
    }
}

const onUpdateAutomationOptions = (automationOptions) => {
    composition.automationOptions = automationOptions
}

const automationOptionsValidate = computed(() => {
    let enabled = true
    if (composition.automationOptions.name?.length === 0) {
        enabled = false;
    }
    if (composition.automationOptions.selectedDeliveryTypes.length === 0) {
        enabled = false;
    }
    if (!composition.automationOptions.selectedSendRuleType) {
        enabled = false;
    }
    return enabled;
})

const validationErrors = computed(() => {
    let errors = [];
    if (!composition.automationOptions.name?.length > 0) {
        errors.push('Name is required');
    }
    if (composition.automationOptions.selectedDeliveryTypes.length === 0) {
        errors.push('At least one delivery method must be selected');
    }
    if (!composition.automationOptions.selectedSendRuleType) {
        errors.push('Please select a send rule type');
    }
    if (composition.automationOptions.selectedDeliveryTypes.includes('sms') && !composition.smsContent.length > 0) {
        errors.push('Please add SMS content');
    }
    return errors;
})

const compositionValidates = computed(() => {
        let validates = true;

        return validates;
    })

watch(() => state.currentStep, (newVal) => {
    window.location.hash = newVal;
})

onMounted(async () => {
    automations.value = await loadAutomations();

    if (props.automation) {
        repopulateComposition(props.automation);
    }

    if (window.location.hash) {
        state.currentStep = window.location.hash.substring(1);
    }
})

</script>
<template>
    <div>
        <div class="flex flex-row-reverse items-center justify-between">
            <div class="relative">
                <button
                    @click="state.showQuickNavigation = !state.showQuickNavigation"
                >
                    <i class="fa-solid fa-bars"></i>
                </button>
                <div
                    v-if="state.showQuickNavigation"
                    class="absolute right-0 z-20 w-48 overflow-x-hidden overflow-y-scroll bg-white divide-y shadow-lg top-full h-96 rounded-xl"
                >
                    <div v-for="automation in automations" :key="automation.id" class="flex gap-2 px-2 py-2">
                        <div class="flex flex-col items-start gap-2">
                            <a
                                :href="route('crm.communications.automations.show', automation.id)"
                                class="block w-full overflow-x-hidden text-xs text-ellipsis"
                            >{{ automation.name }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="composition.automationOptions.activateAtDisplay"
                class="px-3 py-1 mb-2 mr-4 text-center bg-white rounded-wl"
            >
                <p
                    class="mb-0 font-bold"
                >
                    <span
                        v-if="composition.automationOptions.selectedSendRuleType === 'recurring_after_tag_assigned'"
                    >
                        {{ _mft('communication:composer.summary.automation.activeFrom') }} {{ composition.automationOptions.activateAtDisplay }}
                    </span>
                    <span
                        v-else
                        class="flex items-center"
                    >
                        <i class="mr-1 text-xl text-gray-400 fa-regular fa-clock"></i> {{ _mft('communication:composer.summary.automation.scheduledFor') }} {{ composition.automationOptions.activateAtDisplay }}
                    </span>
                </p>
            </div>
        </div>

        <div class="relative flex justify-around mb-8">
            <button
                v-for="(step, index) in enabledSteps"
                :key="index"
                @click="state.currentStep = step.name"
                :class="state.currentStep === step.name ? 'bg-myflowPurple-900 text-white' : 'bg-myflowPurple-400 text-white'"
                class="relative z-10 flex items-center justify-center w-8 h-8 mt-6 text-xs border-4 rounded-full group border-gray-50"
            >
                {{ index + 1 }}
                <div
                    class="absolute z-10 hidden text-black transition-colors transform -translate-x-1/2 -translate-y-1 bottom-full left-1/2 whitespace-nowrap group-hover:text-myflowPurple-700 md:block"
                >{{ step.title }}</div>
            </button>
            <div class="absolute bg-myflowPurple-700 z-0 left-0 right-0 bottom-3.5 h-0.5"></div>
        </div>

        <card-basic>
            <template #body>
                <div class="flex items-center justify-end mb-2 space-x-2">
                    <span class="inline-block space-x-1 text-xs">
                        <pill
                            v-for="(error, i) in validationErrors"
                            :key="i"
                            size="xs"
                            class="text-white bg-myflowYellow"
                        >
                            {{ error }}
                        </pill>
                    </span>
                    <button-base
                        :style-type="automationOptionsValidate ? 'success' : 'disabled'"
                        @click="submitAutomation"
                        :disabled="! automationOptionsValidate"
                        :state="savingState"
                    >{{ _mft('shared:action.save') }}</button-base>
                </div>
                <div
                    :class="state.currentStep === 'automationOptions' ? 'block' : 'hidden'"
                >
                    <automationOptions
                        :communication-type="props.communicationType"
                        :presetOptions="composition.automationOptions"
                        :smsEnabled="props.sms_enabled"
                        :emailEnabled="props.email_enabled"
                        @update:automationOptions="onUpdateAutomationOptions"
                    />

                    <recipients
                        :timeRangeApplicable="composition.automationOptions.selectedSendRuleType === 'once_assigned_in_time_range'"
                        :dateRange="[
                            composition.automationOptions.onceAssignedInTimeRangeStart ? composition.automationOptions.onceAssignedInTimeRangeStart : new Date(),
                            composition.automationOptions.onceAssignedInTimeRangeEnd ? composition.automationOptions.onceAssignedInTimeRangeEnd : new Date()
                        ]"
                        :preincludedTags="composition.includedTags"
                        :preexcludedTags="composition.excludedTags"
                        :recipientCounts="recipientCounts"
                        @update:includedTags="updateIncludedTags"
                        @update:excludedTags="updateExcludedTags"
                        @update:setDateRange="setDateRange"
                        @update:recipientCount="updateRecipientCount"
                    />
                </div>

                <div
                    :class="state.currentStep === 'emailContent' ? 'block' : 'hidden'"
                >
                    <emailContentEditor
                        ref="emailContentEditorRef"
                        :presetEmailContent="composition.emailContent"
                        :automationId="composition.automationId"
                        @update:emailContent="composition.emailContent = $event"
                    />
                </div>

                <div
                    :class="state.currentStep === 'smsContent' ? 'block' : 'hidden'"
                >
                    <smsContentEditor
                        :presetSmsContent="composition.smsContent"
                        @update:smsContent="composition.smsContent = $event"
                    />
                </div>

                <div
                    :class="state.currentStep === 'summary' ? 'block' : 'hidden'"
                >
                    <communicationSummary
                        :composition="composition"
                        :compositionValidates="compositionValidates && automationOptionsValidate"
                        :recipientCounts="recipientCounts"
                        :currentUserContact="props.current_user_contact"
                        @update:activateAt="composition.automationOptions.activateAt = $event"
                        @submitAutomation="submitAutomation"
                        @submitAndPreviewAutomation="submitAndPreviewAutomation"
                        @submitAndPreviewAutomationToTestEmail="submitAndPreviewAutomationToTestEmail"
                    />
                </div>

                <div class="flex justify-between mt-6 space-x-2 md:mt-10 -mx-4 md:-mx-6 lg:-mx-8  -mb-4 md:-mb-6 lg:-mb-8 py-4 px-4 md:px-6 lg:px-8 bg-gray-100 shadow-inner">
                    <span>
                        <button-secondary
                            v-if="state.currentStep !== 'automationOptions'"
                            @click="prevStep"
                        >{{ _mft('shared:previous') }}</button-secondary>
                    </span>
                    <span>
                        <button-secondary
                            v-if="state.currentStep !== 'summary' && automationOptionsValidate"
                            @click="nextStep"
                        >{{ _mft('shared:next') }}</button-secondary>
                    </span>
                </div>
            </template>
        </card-basic>
    </div>
</template>
