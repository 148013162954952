<script setup>
    import {computed, defineAsyncComponent, ref} from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
    import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";

    const notificationStore = useNotificationStore()
    const emit = defineEmits(['done'])

    const blocks = {
        BlocksHeadline: defineAsyncComponent(() => import('./block-editor/blocks/headline.vue')),
        BlocksText: defineAsyncComponent(() => import('./block-editor/blocks/text.vue')),
        BlocksVideo: defineAsyncComponent(() => import('./block-editor/blocks/video.vue')),
        BlocksVideoWithNotes: defineAsyncComponent(() => import('./block-editor/blocks/videoWithNotes.vue')),
        BlocksImage: defineAsyncComponent(() => import('./block-editor/blocks/image.vue')),
        BlocksImageUnified: defineAsyncComponent(() => import('./block-editor/blocks/imageUnified.vue')),
        BlocksFile: defineAsyncComponent(() => import('./block-editor/blocks/file.vue')),
        BlocksCardText: defineAsyncComponent(() => import('./block-editor/blocks/cardText.vue')),
        BlocksCardTextList: defineAsyncComponent(() => import('./block-editor/blocks/cardTextList.vue')),
        BlocksHeader: defineAsyncComponent(() => import('./block-editor/blocks/header.vue')),
        BlocksImageDescription: defineAsyncComponent(() => import('./block-editor/blocks/imageDescription.vue')),
        BlocksImageText: defineAsyncComponent(() => import('./block-editor/blocks/imageText.vue')),
        BlocksFullWidthImage: defineAsyncComponent(() => import('./block-editor/blocks/fullWidthImage.vue')),
        BlocksPexels: defineAsyncComponent(() => import('./block-editor/blocks/pexels.vue')),
        BlocksSpacer: defineAsyncComponent(() => import('./block-editor/blocks/spacer.vue')),
        BlocksNavigationButtons: defineAsyncComponent(() => import('./block-editor/blocks/navigationButtons.vue')),
        BlocksQuote: defineAsyncComponent(() => import('./block-editor/blocks/quote.vue')),
        BlocksSlideshow: defineAsyncComponent(() => import('./block-editor/blocks/slideshow.vue')),
        BlocksSlimProduct: defineAsyncComponent(() => import('./block-editor/blocks/slimProduct.vue')),
        BlocksTextWithTitle: defineAsyncComponent(() => import('./block-editor/blocks/textWithTitle.vue')),
        BlocksLoomEmbed: defineAsyncComponent(() => import('./block-editor/blocks/loomEmbed.vue')),
        BlocksVideoRecorder: defineAsyncComponent(() => import('./block-editor/blocks/videoRecorder.vue')),
        BlocksLeadCapture: defineAsyncComponent(() => import('./block-editor/blocks/leadCapture.vue')),
        BlocksSocialMedia: defineAsyncComponent(() => import('./block-editor/blocks/socialMedia.vue')),
        BlocksVotingSuggestions: defineAsyncComponent(() => import('./block-editor/blocks/votingSuggestions.vue')),
        BlocksLivestream: defineAsyncComponent(() => import('./block-editor/blocks/livestream.vue')),
        BlocksToc: defineAsyncComponent(() => import('./block-editor/blocks/tableOfContents.vue')),
        BlocksCalendly: defineAsyncComponent(() => import('./block-editor/blocks/calendly.vue')),
        BlocksIframe: defineAsyncComponent(() => import('./block-editor/blocks/iframe.vue')),
        BlocksAiIntroduction: defineAsyncComponent(() => import('./block-editor/blocks/aiIntroduction.vue')),
        BlocksConfetti: defineAsyncComponent(() => import('./block-editor/blocks/confetti.vue')),
        BlocksPricingOffer: defineAsyncComponent(() => import('./block-editor/blocks/pricingOffer.vue')),
        BlocksInvoiceLink: defineAsyncComponent(() => import('./block-editor/blocks/invoiceLink.vue')),
        BlocksImageFromUrl: defineAsyncComponent(() => import('./block-editor/blocks/imageFromUrl.vue')),
        BlocksFaq: defineAsyncComponent(() => import('./block-editor/blocks/faq.vue')),
        BlocksAudio: defineAsyncComponent(() => import('./block-editor/blocks/audio.vue')),
        BlocksYoutube: defineAsyncComponent(() => import('./block-editor/blocks/youtube.vue')),
        BlocksVimeo: defineAsyncComponent(() => import('./block-editor/blocks/vimeo.vue')),
        BlocksVimeoLive: defineAsyncComponent(() => import('./block-editor/blocks/vimeo-live.vue')),
        BlocksForm: defineAsyncComponent(() => import('./block-editor/blocks/form.vue')),
        BlocksFormsInputText: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputNumber: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputEmail: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputPhone: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputDate: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputTime: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputDateTime: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputTextarea: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksFormsInputRadio: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_multi.vue')),
        BlocksFormsInputCheckbox: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_multi.vue')),
        BlocksFormsInputSelect: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_multi.vue')),
        BlocksFormsInputUpload: defineAsyncComponent(() => import('./block-editor/blocks/forms-input/_basic.vue')),
        BlocksLogo: defineAsyncComponent(() => import('./block-editor/blocks/logo.vue')),
        BlocksCronofy: defineAsyncComponent(() => import('./block-editor/blocks/cronofy.vue')),
        BlocksSingleButton: defineAsyncComponent(() => import('./block-editor/blocks/singleButton.vue')),
        BlocksAnchorButton: defineAsyncComponent(() => import('./block-editor/blocks/anchorButton.vue')),
        BlocksCoachingBookings: defineAsyncComponent(() => import('./block-editor/blocks/coachingBookings.vue')),
        BlocksLicensedCourses: defineAsyncComponent(() => import('./block-editor/blocks/licensedCourses.vue')),
        BlocksTaskLists: defineAsyncComponent(() => import('./block-editor/blocks/taskLists.vue')),
        BlocksLoginForm: defineAsyncComponent(() => import('./block-editor/blocks/loginForm.vue')),
        BlocksCommunity: defineAsyncComponent(() => import('./block-editor/blocks/community.vue')),
        BlocksExternalWidget: defineAsyncComponent(() => import('./block-editor/blocks/externalWidget.vue')),
        BlocksWlAppNavigationButton: defineAsyncComponent(() => import('./block-editor/blocks/wlAppNavigationButton.vue')),
        BlocksWlAppOutlinkButton: defineAsyncComponent(() => import('./block-editor/blocks/wlAppOutlinkButton.vue')),
        BlocksWlAppSpacer: defineAsyncComponent(() => import('./block-editor/blocks/wlAppSpacer.vue')),
    }
    const props = defineProps({
        block: {
            type: Object,
            required: true
        },
    })

    const closeButton = ref(null)
    const blockValidates = ref(true)
    const undoBlock = ref(JSON.parse(JSON.stringify(props.block)))

    const completeEditing = () => {
        if (blockValidates.value) {
            emit('done')
        }
    }

    const endEditing = () => {
        if (blockValidates.value) {
            emit('close')
        }
    }

    const undoEditing = () => {
        if (undoBlock.value.content) {
            props.block.content = JSON.parse(JSON.stringify(undoBlock.value.content))
        } else {
            props.block.content = null
        }
        emit('close')
    }

    const setIsHidden = (hidden) => {
        props.block.hidden = hidden
    }

    const blockComponent = computed(() => {
        const pascalName = `blocks-${props.block.type}`.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')

        if (! blocks[pascalName]) {
            notificationStore.addNotification({
                type: 'error',
                title: _mft('error:error.generalOccurred'),
                message: _mft('pagebuilder:block.error.notEditable'),
                dismissAfter: 5000,
            })
            return null
        } else {
            return blocks[pascalName]
        }
    })
</script>
<template>
    <div>
        <slideout-with-slots
            :title="_mft('pagebuilder:block.editBlock')"
            :allow-self-close="false"
            @self-close-prevented="closeButton.arm()"
            @close="undoEditing"
        >
            <template #actions>
                <div class="mb-2 flex justify-end space-x-2">
                    <button-with-confirmation
                        ref="closeButton"
                        @confirmed="undoEditing"
                        modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-10"
                        :buttonLabel="_mft('shared:cancel')"
                        buttonClass="text-wlPrimaryContrast"
                        :confirmButtonLabel="_mft('shared:confirm.continue')"
                        confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                        :confirm-text="_mft('pagebuilder:block.confirm.undoChanges')"
                    ></button-with-confirmation>

                    <button-success
                        :disabled="! blockValidates"
                        @click="completeEditing"
                    >{{ _mft('shared:action.save') }}</button-success>
                </div>
            </template>

            <template #body>
                <div>
                    <button-bar
                        size="sm"
                        :options="[
                            {name: 'visible', label: _mft('shared:show')},
                            {name: 'hidden', label: _mft('shared:hide')},
                        ]"
                        :selected-option-name="props.block.hidden ? 'hidden' : 'visible'"
                        @select="setIsHidden($event.name === 'hidden')"
                    />

                    <slot
                        name="sloteditortools"
                        v-bind:block="props.block"
                        v-bind:someblock="props.block"
                    ></slot>

                    <component
                        v-if="blockComponent"
                        class="transition-opacity duration-300 ease-in-out"
                        :key="props.blockIndex"
                        :is="blockComponent"
                        :layout="props.block.layout"
                        v-model="props.block.content"
                        @validationUpdate="blockValidates = $event"
                    ></component>
                    <p v-else>{{ _mft('pagebuilder:editor.loadingFailed') }}</p>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>
