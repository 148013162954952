<template>
  <div class="space-y-6">
    <!-- Header Stats -->
    <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
      <div class="p-6 overflow-hidden transition-transform duration-300 transform bg-white rounded-lg shadow-sm hover:scale-105">
        <h3 class="mb-4 text-lg font-semibold">Registration Sources ({{ lookback }})</h3>
        <div class="space-y-4">
          <div class="relative">
            <div class="text-sm text-gray-600">Affiliates</div>
            <div class="text-2xl font-bold">{{ registrationStats.affiliates }}</div>
            <div class="absolute top-0 right-0 text-indigo-500">
              <i class="fas fa-handshake"></i>
            </div>
          </div>
          <div class="relative">
            <div class="text-sm text-gray-600">Referrals</div>
            <div class="text-2xl font-bold">{{ registrationStats.referrals }}</div>
            <div class="absolute top-0 right-0 text-emerald-500">
              <i class="fas fa-users"></i>
            </div>
          </div>
          <div class="relative">
            <div class="text-sm text-gray-600">Organic</div>
            <div class="text-2xl font-bold">{{ registrationStats.organic }}</div>
            <div class="absolute top-0 right-0 text-amber-500">
              <i class="fas fa-seedling"></i>
            </div>
          </div>
          <div class="pt-4 mt-4 border-t">
            <div class="text-sm text-gray-600">Total Registrations</div>
            <div class="text-2xl font-bold">{{ registrationStats.total }}</div>
          </div>
        </div>
      </div>

      <div class="p-6 overflow-hidden transition-transform duration-300 transform bg-white rounded-lg shadow-sm hover:scale-105">
        <h3 class="mb-4 text-lg font-semibold">Sales Metrics</h3>
        <div class="space-y-6">
          <div class="relative">
            <div class="text-sm text-gray-600">Monthly Recurring Revenue</div>
            <div class="text-2xl font-bold">{{ salesStats.mrr }}</div>
            <div class="absolute top-0 right-0 text-emerald-500">
              <i class="fas fa-chart-line"></i>
            </div>
          </div>
          <div class="relative">
            <div class="text-sm text-gray-600">Annual Recurring Revenue</div>
            <div class="text-2xl font-bold">{{ salesStats.arr }}</div>
            <div class="absolute top-0 right-0 text-indigo-500">
              <i class="fas fa-calendar-check"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="p-6 overflow-hidden transition-transform duration-300 transform bg-white rounded-lg shadow-sm hover:scale-105">
        <h3 class="mb-4 text-lg font-semibold">Support Metrics</h3>
        <div class="space-y-6">
          <div class="relative">
            <div class="text-sm text-gray-600">Average Response Time</div>
            <div class="text-2xl font-bold">{{ supportStats.responseTime }}</div>
            <div class="absolute top-0 right-0 text-amber-500">
              <i class="fas fa-clock"></i>
            </div>
          </div>
          <div class="relative">
            <div class="text-sm text-gray-600">Average Resolution Time</div>
            <div class="text-2xl font-bold">{{ supportStats.resolutionTime }}</div>
            <div class="absolute top-0 right-0 text-emerald-500">
              <i class="fas fa-check-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Meetings Stats -->
    <div class="p-6 overflow-hidden bg-white rounded-lg shadow-sm">
      <h3 class="mb-6 text-lg font-semibold">Meeting Performance</h3>
      <div v-if="hasUsers" class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Name</th>
              <th class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Total Meetings</th>
              <th class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Unique Leads</th>
              <th class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">New Meetings %</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="user in users"
                :key="user.name"
                class="transition-colors duration-200 hover:bg-gray-50">
              <td class="px-6 py-4 whitespace-nowrap">{{ user.name }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ user.total_meetings }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ user.unique_leads }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span :class="getMeetingPercentageClass(user.new_meeting_percentage)">
                  {{ user.new_meeting_percentage }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="py-4 text-center text-gray-500">
        No meeting data available
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  computed: {
    lookback() {
      return this.data?.lookback || 'Loading...'
    },

    registrationStats() {
      const registrations = this.data?.registrations || {}
      return {
        affiliates: registrations.percentage_by_affiliates || 0,
        referrals: registrations.percentage_by_referrals || 0,
        organic: registrations.percentage_by_organic || 0,
        total: registrations.total_registrations || 0
      }
    },

    salesStats() {
      const sales = this.data?.sales || {}
      return {
        mrr: sales.mrr || '0',
        arr: sales.arr || '0'
      }
    },

    supportStats() {
      const support = this.data?.support || {}
      return {
        responseTime: support.avg_response_time || '0',
        resolutionTime: support.avg_resolution_time || '0'
      }
    },

    users() {
      return this.data?.meetings?.users || []
    },

    hasUsers() {
      return this.users.length > 0
    }
  },

  methods: {
    getMeetingPercentageClass(percentage) {
      const value = parseFloat(percentage || 0);
      return {
        'text-emerald-500 font-semibold': value >= 75,
        'text-amber-500 font-semibold': value >= 50 && value < 75,
        'text-gray-700': value < 50
      };
    }
  }
}
</script>