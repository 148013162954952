<script setup>
import { onMounted, ref, computed, provide } from 'vue';
import axios from 'axios';
import PostContent from "./_postContent.vue";
import ButtonMoreOptions from "../../buttons/buttonMoreOptions.vue";
import ProgressTrackingStatistics from "./_progressTrackingStatistics.vue";

const props = defineProps({
    channelId: {
        type: Number,
        required: true,
    },
    channel: {
        type: Object,
        required: true,
    }
})

const loadingChannel = ref(false);
const memberships = ref([]);
const posts = ref([]);
const postBeingEdited = ref(null);
const yesterdaysPost = ref(null);
const allowPosting = ref(null);
const trackRecord = ref(null)
const trackRecordDays = ref(null)
const channelMetaText = ref(null)

const showChannel = ref(false);
const activeChannel = ref(null);

const newPostContent = ref(null);
const newPostImage = ref(null);
const filepondUploader = ref(null)

const commentablePost = ref(null);

const postedToday = ref(false);

const currentUserCanPost = ref(false)

const accountability = ref({});

const showPostComposer = ref(true);
const newPostState = ref('')

provide('memberships', {memberships} );

provide('accountability-data', {accountability, yesterdaysPost, activeChannel} );

provide('commentablePost', commentablePost);
provide('activeChannel', activeChannel);

provide('getChannelData', getChannelData);

async function getChannelData(channelId, editingPostId = null) {
    loadingChannel.value = true;
    posts.value = []

    let params = {}

    if (editingPostId) {
        params = {
            editingPostId: editingPostId
        }
    }
    const response = await axios.get(route('community.member.getChannelData', channelId), {
        params: params
    });

    postBeingEdited.value = response.data.post_being_edited;

    resetAccountability()

    posts.value = response.data.posts;
    allowPosting.value = response.data.allow_posting;
    yesterdaysPost.value = response.data.yesterdays_post;
    postedToday.value = response.data.posted_today;
    currentUserCanPost.value = response.data.current_user_can_post;
    loadingChannel.value = false;
    channelMetaText.value = response.data.meta_text
    trackRecord.value = response.data.track_record
    trackRecordDays.value = response.data.track_record_days

    if (postBeingEdited.value) {
        newPostContent.value = postBeingEdited.value.content
    }
}

const editPost = (post) => {
    getChannelData(activeChannel.value.id, post.id);
}

const updateFiles = (files) => {
    newPostImage.value = files[0]
}

const removedFile = () => {
    newPostImage.value = null
}

const cancelEditing = () => {
    postBeingEdited.value = null;
    newPostContent.value = null;
    resetAccountability();
}

const deletePost = async (post) => {
    const res = await axios.delete(route('community.member.deletePost', post.id))
    await getChannelData(activeChannel.value.id);
}

async function submitPost(channelId) {
    if (newPostValidates.value === false) {
        return;
    }

    newPostState.value = 'waiting';

    let formData = new FormData();
    formData.append('content', newPostContent.value);
    formData.append('accountability', JSON.stringify(accountability.value));

    if (newPostImage.value) {
        formData.append('image', newPostImage.value.file);
    }

    if (postBeingEdited.value) {
        formData.append('editingPostId', postBeingEdited.value.id);
    }

    try {
        const response = await axios.post(route('community.member.addPost', channelId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data.success === false) {
            throw new Error('Post submission failed');
        }

        newPostState.value = 'success';

        if (postBeingEdited.value) {
            await getChannelData(channelId, postBeingEdited.value.id);
            if (! activeChannel.value.progress_tracking_enabled) {
                cancelEditing()
            }
        } else {
            await getChannelData(channelId);
            newPostContent.value = null;
        }

        resetAccountability();
    } catch (error) {
        newPostState.value = 'error';
        console.error(error);
    }

    filepondUploader.value.clearFiles()
}
async function highFivePost(postId) {
    const response = await axios.post(route('community.member.highFivePost', postId));

    await getChannelData(activeChannel.value.id);
}

async function flagPost(postId) {
    const response = await axios.post(route('community.member.flagPost', postId));

    await getChannelData(activeChannel.value.id);
}

const resetAccountability = () => {
    if (props.channel.accountability_version === 1) {
        accountability.value.question1 = null
        accountability.value.question2 = null
        accountability.value.question3 = null
        accountability.value.yesterdaysQuestion1 = null
        accountability.value.yesterdaysQuestion2 = null
        accountability.value.yesterdaysQuestion3 = null
    }

    if (props.channel.accountability_version === 2) {
        accountability.value.questions = []

        if (postBeingEdited.value && postBeingEdited.value.post_accountability_questions_actions) {
            postBeingEdited.value.post_accountability_questions_actions.questions.forEach((question, i) => {
                let data = {
                    uuid: question.uuid,
                    index: question.index,
                    question: question.question,
                    enabled: question.enabled,
                    type: question.type,
                    role: question.role ? question.role : null,
                    answer: question.answer,
                    defaultAnswer: question.defaultAnswer,
                    reflection: question.reflection,
                    metadata: question.metadata,
                }
                accountability.value.questions.push(data)
            })
        } else {
            if (props.channel.accountability_enabled || props.channel.progress_tracking_enabled) {
                props.channel.accountability_questions_actions?.questions.forEach((question, i) => {
                    let data = {
                        uuid: question.uuid,
                        index: question.index,
                        question: question.question,
                        enabled: question.enabled,
                        type: question.type,
                        role: question.role ? question.role : null,
                        defaultAnswer: question.defaultAnswer,
                        answer: null,
                        reflection: null,
                        metadata: question.metadata,
                    }
                    accountability.value.questions.push(data)
                })
            }
        }
    }
}

function prepareComment(post) {
    if (commentablePost.value === post.id) {
        commentablePost.value = null;
        return;
    }
    commentablePost.value = post.id;
}

const canPost = computed(() => {
    if (activeChannel.value.accountability_enabled) {
        return !postedToday.value;
    }

    if (activeChannel.value.archived === true) {
        return false;
    }

    return currentUserCanPost.value;
})

const newPostValidates = computed(() => {
    if (! activeChannel.value.accountability_enabled &&
        ! activeChannel.value.progress_tracking_enabled &&
        (newPostContent.value === null || newPostContent.value.length === 0)
    ) {
        return false
    }

    if (! activeChannel.value.accountability_enabled && ! activeChannel.value.progress_tracking_enabled) {
        return true
    }

    if (props.channel.accountability_version === 1) {
        return accountabilityV1Validates.value;
    }

    if (props.channel.accountability_version === 2) {
        return accountabilityV2Validates.value
    }

    return true
});

const accountabilityV1Validates = computed(() => {
    if (
        activeChannel.value.accountability_questions_actions.question1 !== null
        && (! accountability.value.question1 || accountability.value.question1.length === 0)
    ) {
        return false
    }

    if (
        activeChannel.value.accountability_questions_actions.question2 !== null
        && (! accountability.value.question2 || accountability.value.question2.length === 0)
    ) {
        return false
    }

    if (
        activeChannel.value.accountability_questions_actions.question3 !== null
        && (! accountability.value.question3 || accountability.value.question3.length === 0)
    ) {
        return false
    }

    return true
})

const accountabilityV2Validates = computed(() => {
    let validates = true
    // Validation is a mess I will take care of later.
    // Or maybe I will just leave it as it is, since post editing is now a thing.
    return validates
})

const postComposerVisible = computed(() => {
    return showPostComposer.value || (postBeingEdited.value && newPostContent.value?.length > 0)
})

onMounted(() => {
    getChannelData(props.channelId);
    activeChannel.value = props.channel;
    showChannel.value = true;
    if (activeChannel.value.progress_tracking_enabled && newPostContent) {
        showPostComposer.value = false;
    }
});

</script>

<template>
    <div
        v-if="showChannel"
        class="relative"
    >
        <div
            v-if="loadingChannel"
            class="absolute inset-0 flex items-center justify-center h-screen bg-white/90"
        >
            <mf-spinner-medium class="w-24 h-12" />
        </div>
        <div class="-mt-4 lg:-mt-6">
            <component
                :is="channelMetaText ? 'ui-tabbed' : 'div'"
                :track-state="false"
            >
                <component
                    :is="channelMetaText ? 'ui-tabbed-tab' : 'div'"
                    title="Post"
                    value="posts"
                    :active="true"
                >
                    <div
                        v-if="canPost && allowPosting"
                        class="px-4 pt-4 -mx-4 -mt-4 bg-gray-100 border-b lg:-mx-6 lg:px-6 lg:-mt-6 lg:pt-6"
                    >
                        <div
                            class="flex flex-col"
                            :class="activeChannel.progress_tracking_enabled ? 'flex-col-reverse' : ''"
                        >
                            <div
                                v-if="postComposerVisible"
                                class="mb-2 md:mb-0 md:grid md:grid-cols-4 md:gap-4"
                            >
                                <div class="md:col-span-3">
                                    <input-textarea
                                        :placeholder="_mft('community:channel.newPost.placeholder')"
                                        v-model:content="newPostContent"
                                        class="!mb-2"
                                        input-height-class="h-16"
                                    ></input-textarea>
                                </div>

                                <div
                                    class="md:col-span-1 flex items-center justify-stretch md:-mt-2 md:h-full"
                                    :class="newPostImage ? 'mb-2' : ''"
                                >
                                    <div class="w-full border-gray-300 rounded-wl border-2 border-dashed">
                                        <filepond-upload
                                            class="rounded-wl overflow-hidden"
                                            :class="newPostImage ? '' : 'h-12'"
                                            ref="filepondUploader"
                                            :showPreview="true"
                                            accepted-file-types="image/png,image/jpeg,image/jpg,image/gif"
                                            :required="false"
                                            max-file-size="8MB"
                                            :label-idle="_mft('community:channel.newPost.addImage')"
                                            @updatefiles="updateFiles($event)"
                                            @removedfile="removedFile()"
                                        ></filepond-upload>
                                    </div>
                                </div>

                            </div>

                            <div v-if="activeChannel.accountability_enabled || activeChannel.progress_tracking_enabled">
                                <accountability-post-data
                                    v-if="activeChannel.accountability_version === 1"
                                ></accountability-post-data>

                                <accountability-v2-post-data
                                    v-if="activeChannel.accountability_version === 2"
                                    :track-record="trackRecord"
                                    :track-record-days="trackRecordDays"
                                ></accountability-v2-post-data>
                            </div>

                        </div>

                        <div class="flex justify-end -mt-1 space-x-2">
                            <button-base
                                size="sm"
                                v-if="activeChannel.progress_tracking_enabled && ! newPostContent && ! showPostComposer"
                                class="mb-3 text-center"
                                v-on:click="showPostComposer = true"
                            >
                                {{ _mft('community:channel.addPostText') }}
                            </button-base>
                            <button-base
                                size="sm"
                                v-if="postBeingEdited && ! activeChannel.progress_tracking_enabled"
                                class="mb-3 text-center"
                                v-on:click="cancelEditing"
                            >
                                {{ _mft('shared:cancel') }}
                            </button-base>
                            <button-base
                                size="sm"
                                :style-type="newPostValidates ? 'primary' : 'disabled'"
                                :state="newPostState"
                                class="mb-3 text-center"
                                :disabled="! newPostValidates"
                                v-on:click="submitPost(activeChannel.id)"
                            >
                                {{ activeChannel.progress_tracking_enabled && postBeingEdited ? _mft('community:channel.newPost.update') : _mft('community:channel.newPost.addPost') }}
                            </button-base>
                        </div>
                    </div>
                </component>

                <ui-tabbed-tab
                    v-if="channelMetaText"
                    title="Information"
                    value="information"
                >
                    <div
                        v-if="channelMetaText"
                        v-html="channelMetaText"
                        class="-mt-4 lg:-mt-6 pt-4 lg:pt-6 border-b pb-8 mb-8 -mx-4 lg:-mx-6 px-4 lg:px-6 bg-gray-100"
                    ></div>
                </ui-tabbed-tab>
            </component>


            <p
                v-if="posts.length === 0 && ! loadingChannel"
                class="py-8 text-center text-gray-400"
            >
                {{ _mft('community:channel.noPosts') }}
            </p>

            <div
                v-if="activeChannel.progress_tracking_enabled"
                class="py-4"
            >
                <h2 class="text-center mb-2">Scoreboard</h2>
                <progress-tracking-statistics
                    :channel-id="activeChannel.id"
                ></progress-tracking-statistics>
            </div>

            <div v-else>
                <div
                    v-for="post in posts"
                    :key="post.id"
                >
                    <div class="flex py-4 space-x-2">
                        <div class="w-6 shrink-0 md:w-10">
                            <img class="object-cover object-center w-6 h-6 rounded-full md:h-10 md:w-10" :src="post.user.profile_img" alt="avatar">
                        </div>
                        <div class="w-full">
                            <div class="md:flex items-center justify-between h-12 -translate-y-px md:h-10 relative z-40">
                                <div>
                                    <span class="font-semibold">{{ post.user.name }}</span>
                                </div>
                                <div class="flex items-center flex-row-reverse justify-end space-x-0 md:flex-row">
                                    <span class="mx-2">
                                        <button-more-options
                                            v-if="post.owned_by_current_user && ! channel.accountability_enabled && postBeingEdited?.id !== post.id"
                                            size="sm"
                                            alignment="center"
                                            :cancel-on-outside-click="false"
                                        >
                                            <button-more-options-option
                                                @click="editPost(post)"
                                            >
                                                {{ _mft('shared:edit') }}
                                            </button-more-options-option>
                                            <button-more-options-option-with-confirmation
                                                :confirm-button-label="_mft('shared:action.delete')"
                                                @confirmed="deletePost(post)"
                                            >
                                                {{ _mft('shared:action.delete') }}
                                            </button-more-options-option-with-confirmation>
                                        </button-more-options>
                                    </span>
                                    <span class="text-xs text-gray-400">{{ post.created_at }}</span>
                                </div>
                            </div>
                            <PostContent
                                :post="post"
                            />

                            <div
                                v-if="channel.accountability_enabled || channel.progress_tracking_enabled"
                                class="grid gap-4 p-4 mb-4 -ml-4 border rounded-wl md:grid-cols-2 md:gap-8"
                            >
                                <div v-if="channel.accountability_enabled">
                                    <h5 class="text-sm mb-1">
                                        {{ _mft('community:channel.yesterdaysReflection.title') }}
                                    </h5>

                                    <div v-if="activeChannel.accountability_version === 2">
                                        <div
                                            v-for="(question, i) in post.reflection_display?.questions"
                                            :key="i"
                                        >
                                            <community-yesterday-reflection
                                                :active-channel="activeChannel"
                                                :enabled="true"

                                                :question="question.question"
                                                :type="question.type"
                                                :answer="question.answer"

                                                :previous-question="question.reflection"
                                            ></community-yesterday-reflection>
                                        </div>
                                    </div>

                                    <div v-if="activeChannel.accountability_version === 1">
                                        <community-yesterday-reflection
                                            :active-channel="activeChannel"
                                            :enabled="activeChannel.accountability_questions_actions.question1Enabled"

                                            :question="activeChannel.accountability_questions_actions.question1"
                                            :answer="post.previous_post_accountability_questions_actions?.question1"

                                            :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion1"
                                        ></community-yesterday-reflection>

                                        <community-yesterday-reflection
                                            :active-channel="activeChannel"
                                            :enabled="activeChannel.accountability_questions_actions.question2Enabled"

                                            :question="activeChannel.accountability_questions_actions.question2"
                                            :answer="post.previous_post_accountability_questions_actions?.question2"

                                            :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion2"
                                        ></community-yesterday-reflection>

                                        <community-yesterday-reflection
                                            :active-channel="activeChannel"
                                            :enabled="activeChannel.accountability_questions_actions.question3Enabled"

                                            :question="activeChannel.accountability_questions_actions.question3"
                                            :answer="post.previous_post_accountability_questions_actions?.question3"

                                            :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion3"
                                        ></community-yesterday-reflection>
                                    </div>

                                </div>

                                <div>
                                    <h5 class="text-sm mb-1">
                                        {{ _mft('community:channel.dailyActions.title') }}
                                    </h5>

                                    <div v-if="activeChannel.accountability_version === 2">
                                        <div
                                            v-for="(question, i) in post.post_accountability_questions_actions?.questions"
                                            :key="i"
                                        >
                                            <community-todays-post-action
                                                :enabled="post.post_accountability_questions_actions.questions[i].enabled"
                                                :question="post.post_accountability_questions_actions.questions[i].question"
                                                :answer="post.post_accountability_questions_actions.questions[i].answer"
                                                :type="post.post_accountability_questions_actions.questions[i].type"
                                                :role="post.post_accountability_questions_actions.questions[i].role"
                                            ></community-todays-post-action>
                                        </div>
                                    </div>

                                    <div v-if="activeChannel.accountability_version === 1">
                                        <community-todays-post-action
                                            :enabled="activeChannel.accountability_questions_actions.question1Enabled"
                                            :question="activeChannel.accountability_questions_actions.question1"
                                            :answer="post.post_accountability_questions_actions.question1"
                                        ></community-todays-post-action>

                                        <community-todays-post-action
                                            :enabled="activeChannel.accountability_questions_actions.question2Enabled"
                                            :question="activeChannel.accountability_questions_actions.question2"
                                            :answer="post.post_accountability_questions_actions.question2"
                                        ></community-todays-post-action>

                                        <community-todays-post-action
                                            :enabled="activeChannel.accountability_questions_actions.question3Enabled"
                                            :question="activeChannel.accountability_questions_actions.question3"
                                            :answer="post.post_accountability_questions_actions.question3"
                                        ></community-todays-post-action>
                                    </div>
                                </div>
                            </div>

                            <div class="flex mt-4 space-x-1 md:space-x-2">
                                <button-base
                                    :style-type="commentablePost === post.id ? 'secondary' : 'base'"
                                    size="sm"
                                    v-on:click="prepareComment(post)"
                                >
                                    <i class="mr-1 fa-regular fa-comment"></i>
                                    {{ _mft('community:post.comment') }}
                                </button-base>

                                <button-base
                                    v-if="! post.is_high_fived"
                                    v-on:click="highFivePost(post.id)"
                                    size="sm"
                                >
                                    <i class="mr-1 fa-regular fa-hand-wave"></i>
                                    {{ _mft('community:post.highFive') }}
                                </button-base>
                                <button-base
                                    v-else
                                    style-type="transparent"
                                    size="sm"
                                    class="text-myflowGreen"
                                    v-on:click="highFivePost(post.id)"
                                >
                                    <i class="mr-1 fa-regular fa-hand-wave"></i>
                                    {{ _mft('community:post.highFived') }}
                                </button-base>

                                <button-base
                                    v-if="! post.is_flagged"
                                    v-on:click="flagPost(post.id)"
                                    size="sm"
                                    class="text-myflowRed-600"
                                    classes="!border-myflowRed-600"
                                >
                                    <i class="mr-1 fa-regular fa-flag"></i>
                                    {{ _mft('community:post.flag') }}
                                </button-base>
                                <button-base
                                    v-else
                                    v-on:click="flagPost(post.id)"
                                    size="sm"
                                    style-type="transparent"
                                    class="text-myflowRed-600"
                                    classes="!border-myflowRed-600"
                                >
                                    <i class="mr-1 fa-solid fa-flag"></i>
                                    {{ _mft('community:post.flagged') }}
                                </button-base>
                            </div>
                        </div>

                    </div>

                    <community-channel-comments-list
                        :post-data="post"
                    ></community-channel-comments-list>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
/*
:deep(.filepond--root) {
    margin-bottom: 0;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
}
:deep(.filepond--root .filepond--drop-label) {
    min-height: 30px !important;
}
/
 */
</style>
