<template>
    <div v-if="value">
        <input-text
            :label="_mft('form:field.title.label')"
            :required="true"
            v-model:content="value.title"
        />

        <div class="mt-12 mb-4">
            <div class="flex justify-between border-t-2 mx-4">
                <div class="h-4 border-r border-2 -translate-y-2 -mt-px relative">
                    <span
                        class="absolute left-0 bottom-full transform -translate-x-1/2 mb-1.5 text-xs text-gray-400"
                    >1</span>
                </div>
                <div class="h-4 border-r border-2 -translate-y-2 -mt-px relative">
                    <span
                        class="absolute left-0 bottom-full transform -translate-x-1/2 mb-1.5 text-xs text-gray-400"
                    >3</span>
                </div>
                <div class="h-4 border-r border-2 -translate-y-2 -mt-px relative">
                    <span
                        class="absolute left-0 bottom-full transform -translate-x-1/2 mb-1.5 text-xs text-gray-400"
                    >5</span>
                </div>
            </div>
            <div class="flex justify-between">
                <div class="w-24">
                    <input-text
                        :label="_mft('form:field.label')"
                        :required="false"
                        v-model:content="value.lowSymbol"
                    />
                </div>

                <div class="w-24">
                    <input-text
                        :label="_mft('form:field.label')"
                        :required="false"
                        v-model:content="value.midSymbol"
                    />
                </div>

                <div class="w-24">
                    <input-text
                        :label="_mft('form:field.label')"
                        :required="false"
                        v-model:content="value.highSymbol"
                    />
                </div>
            </div>
        </div>

        <toggle-switch
            :label="_mft('form:required')"
            v-model:content="value.required"
        ></toggle-switch>

        <toggle-switch
            :label="_mft('form:field.showMoreInfo')"
            v-model:content="value.showInstructions"
        ></toggle-switch>

        <Instructions
            :model-value="value"
        />
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import Instructions from './_instructions.vue'

export default {
    components: {Instructions},
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null,
            showInstructions: false,
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) {
                return;
            }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        },
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.uuid) {
                value.uuid = uuidv4();
            }
            if (!value.title) {
                value.title = null;
            }
            if (!value.showInstructions) {
                value.showInstructions = false;
            }
            if (!value.metaText) {
                value.metaText = null;
            }
            if (!value.metaImage) {
                value.metaImage = null;
            }
            if (!value.metaVideo) {
                value.metaVideo = null;
            }
            if (!value.required) {
                value.required = false;
            }
            if (!value.lowSymbol) {
                value.lowSymbol = '🔴'
            }
            if (!value.midSymbol) {
                value.midSymbol = '🟡'
            }
            if (!value.highSymbol) {
                value.highSymbol = '🟢'
            }
            this.value = value;
        }
    },
}
</script>
