<script setup>
import {computed, ref} from "vue";
import PrioritySelector from "./prioritySelector.vue";
import DateModal from "./dateModal.vue";

const emit = defineEmits(['completed', 'deleted']);

const props = defineProps({
    task: {
        type: Object,
        required: true,
    }
})

const changingPriority = ref(false)
const changingDates = ref(false)
const editingLabel = ref(false)

const toggleCompleted = async () => {
    props.task.is_completed = !props.task.is_completed
    await axios.put(route('api.task.completed', props.task.id), {
        completed: props.task.is_completed
    })
}

const saveLabel = async () => {
    await axios.put(route('api.task.label', props.task.id), {
        label: props.task.label
    })
    editingLabel.value = false
}

const setPriority = async (priorityLevel) => {
    props.task.priority = priorityLevel
    await axios.put(route('api.task.priority', props.task.id), {
        priority: props.task.priority
    })
    changingPriority.value = false
}

const setStartDate = async (dateTime) => {
    const res = await axios.put(route('api.task.available-at', props.task.id), {
        available_at: dateTime
    })
    props.task.is_available = res.data.task.is_available
    props.task.available_at = res.data.task.available_at
}

const setDueDate = async (dateTime) => {
    const res = await axios.put(route('api.task.due-at', props.task.id), {
        due_at: dateTime
    })
    props.task.is_due = res.data.task.is_due
    props.task.due_at = res.data.task.due_at
}

const deleteTask = async () => {
    await axios.delete(route('api.task.delete', props.task.id))
    emit('deleted')
}

const priorityIcon = computed(() => {
    const icons = {
        1: 'fa-solid fa-flag text-red-500',
        2: 'fa-solid fa-flag text-yellow-500',
        3: 'fa-solid fa-flag text-blue-500',
    }

    if (! props.task.priority) {
        return 'fa-regular fa-flag text-gray-500';
    }
    if (props.task.priority > 3) {
        return 'fa-solid fa-flag text-red-500';
    }

    return icons[props.task.priority];
})

</script>

<template>
    <div>
        <div class="flex space-x-3 mb-px">
            <div>
                <button
                    class="w-5 h-5 rounded-sm border-2 flex items-center justify-center transition-all"
                    :class="task.is_completed ? 'bg-green-600 border-green-800' : 'bg-white border-gray-400 '"
                    @click="toggleCompleted"
                >
                    <i
                        class="fa-solid fa-check text-xs transition-opacity"
                        :class="task.is_completed ? 'opacity-100 text-white' : 'opacity-0 text-black'"
                    ></i>
                </button>
            </div>
            <div
                class="w-full transition-opacity group"
                :class="task.is_completed && ! editingLabel ? 'opacity-50' : ''"
            >
                <div class="mb-1 relative">
                    <div
                        v-if="editingLabel"
                        class="flex space-x-2 items-center -mt-3 z-10 absolute top-0 left-0 right-0 bg-white w-full"
                    >
                        <input-text
                            v-model:content="task.label"
                            class="w-full !mb-0"
                            @keyup.enter="saveLabel"
                            autofocus
                        ></input-text>
                        <button-secondary
                            size="sm"
                            @click="saveLabel"
                        >Save</button-secondary>
                    </div>
                    <div
                        class="relative"
                    >
                        <strong
                            @click="editingLabel = true"
                            class="block w-full min-h-4 cursor-text"
                        >{{ task.label }}</strong>
                        <span
                            v-if="! editingLabel"
                            class="hidden group-hover:inline-block absolute -top-2 -right-2"
                        >
                            <button-with-confirmation
                                size="xs"
                                style-type="transparent"
                                confirm-type="danger"
                                confirm-button-label="Delete"
                                :abort-button-label="_mft('shared:action.cancel')"
                                @confirmed="deleteTask"
                            >
                                <i class="fa-regular fa-trash text-myflowRed"></i>
                            </button-with-confirmation>
                        </span>
                    </div>
                </div>
                <div class="flex justify-end items-center space-x-2 relative">
                    <button
                        @click="changingDates = !changingDates"
                        class="flex justify-end items-center space-x-2"
                    >
                        <span
                            v-if="! task.is_available && task.available_at"
                            class="text-xxs px-1"
                            :class="task.is_available ? 'text-gray-500' : 'text-gray-400'"
                        >
                            Start {{ task.available_at.date}}
                        </span>
                        <span
                            v-if="task.is_available && task.due_at"
                            class="text-xxs px-1"
                            :class="task.is_due && !task.is_completed ? 'bg-red-500 text-white rounded-md' : 'text-gray-500'"
                        >
                                Due {{ task.due_at.date}}
                        </span>

                        <span
                            class="h-5 flex justify-center items-center transition-opacity"
                        >
                            <i
                                class="fa-regular transition-transform"
                                :class="changingDates ? 'fa-calendar-minus text-black scale-125' : 'fa-calendar text-gray-500 scale-100'"
                            ></i>
                        </span>
                    </button>

                    <button
                        class="h-5 flex justify-center items-center transition-opacity"
                        :class="task.is_completed && ! editingLabel ? 'opacity-50' : ''"
                        @click="changingPriority = !changingPriority"
                    >
                        <i
                            :class="priorityIcon"
                        ></i>
                    </button>

                    <priority-selector
                        v-if="changingPriority"
                        class="absolute -bottom-0.5 -right-2"
                        @priority-selected="setPriority"
                        @cancel="changingPriority = false"
                    ></priority-selector>
                </div>
            </div>
        </div>
        <div class="bg-white z-10 relative transition-all transform origin-top" :class="changingDates ? 'opacity-100 scale-y-100' : 'opacity-0 scale-y-0'">
            <date-modal
                v-if="changingDates"
                class=""
                :start-date="task.available_at ? task.available_at.date : null"
                :due-date="task.due_at ? task.due_at.date : null"
                @update:start-date="setStartDate"
                @update:due-date="setDueDate"
            ></date-modal>
        </div>
    </div>
</template>
