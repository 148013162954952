<script setup>

import {onMounted, reactive, ref} from "vue";

const statistics = reactive({
    communities: [],
    comments: []
});

const loadStatistics = async () => {
    const res = await axios.get(route('community.admin.api.statistics'));

    statistics.communities = res.data.communities;
}

onMounted(() => {
    loadStatistics();
});

</script>

<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('community:statistics.title') }}</h1>
            </div>
        </frosted-bar>

        <div class="mt-8 lg:mt-12">
            <list-basic>
                <list-basic-item
                    v-for="(community, ci) in statistics.communities"
                >
                    <template #title>
                        <h4>{{ community.name }}</h4>
                    </template>
                    <ul class="mt-1">
                        <li
                            v-for="(channel, i) in community.channels"
                        >
                            <div
                                v-if="! channel.progress_tracking_enabled"
                                class="text-gray-400"
                            >
                                <container-with-tooltip>
                                    <span>{{ channel.name}}</span>
                                    <template #tooltip>
                                        <p class="w-48">{{ _mft('community:community.statistics.notAvailableForChannelType') }}</p>
                                    </template>
                                </container-with-tooltip>
                            </div>
                            <a
                                v-else
                                :href="route('user.community.channel.statistics', [community.id, channel.id])"
                                class="mf-link"
                                target="_blank"
                            >
                                {{ channel.name}}
                            </a>
                        </li>
                    </ul>
                </list-basic-item>
            </list-basic>
        </div>

    </div>
</template>
