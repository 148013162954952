<script setup>
import { onMounted, ref, computed, inject, watch } from 'vue';
import axios from 'axios';

const props = defineProps({
    answer: String,
    question: String,
    enabled: Boolean,
    type: {
        type: String,
        default: 'completion',
        required: false,
    },
    role: {
        type: String,
        required: false,
        default: 'question',
    },
});

const yesterdaysQuestion = ref(props.yesterdayQuestion);
const answer = ref(props.answer);
const question = ref(props.question);
const enabled = ref(props.enabled);

</script>

<template>
    <div>
        <div
            v-if="enabled"
            :class="role === 'layout' ? '' : 'mb-2'"
        >
            <div v-if="role === 'layout'" class="font-bold">{{ question }}</div>
            <div v-else class="text-xs text-gray-400 leading-2">{{ question }}</div>
            <div
                v-if="type === 'boolean'"
            >
                {{ answer ? _mft('shared:confirm.positive') : _mft('shared:confirm.negative') }}
            </div>
            <div v-else>
                {{ answer }}
            </div>
        </div>
    </div>
</template>
