<script setup>
import { reactive, computed, watch, onMounted } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const emit = defineEmits(['update:date', 'dateSelected']);

const props = defineProps({
    date: {
        type: [Date, Array],
        default: () => new Date()
    },
    showTime: {
        type: Boolean,
        required: false,
        default: false
    },
    rangeMode: {
        type: Boolean,
        required: false,
        default: false
    },
    actionRow: {
        type: Object,
        required: false,
        default: {
            showNow: false,
            showPreview: false,
            showSelect: true,
            showCancel: false,
        }
    },
    minDate: {
        type: Date,
        required: false,
        default: () => new Date()
    },
    minTime: {
        type: Date,
        required: false,
        default: () => new Date()
    },
    showInline: {
        type: Boolean,
        required: false,
        default: true
    }
})

const state = reactive({
    date: null,
    actionRow: {
        showNow: props.actionRow.showNow,
        showPreview: props.actionRow.showPreview,
        showSelect: props.actionRow.showSelect,
        showCancel: props.actionRow.showCancel,
    }
})

const format = computed(() => {
    if (props.showTime) {
        return 'yyyy-MM-dd HH:mm';
    }

    return 'yyyy-MM-dd';
})

const handleDate = (newValue) => {
    emit('update:date', newValue);
    emit('dateSelected');
    setTimeout(function(){
        state.actionRow.showSelect = false;
        state.actionRow.showCancel = false;
    }, 100)
}

const handleInternalChange = (newValue) => {
    if (props.rangeMode) {
        state.actionRow.showSelect = true;
        state.actionRow.showCancel = true;
    }
}

watch(state, (newValue) => {
    emit('update:date', newValue.date);
})

watch(props, (newValue) => {
    state.date = newValue.date
}, {deep: true})


onMounted(async () => {
    if (props.rangeMode && !Array.isArray(props.date)) {
        state.date = [props.date, props.date];
        return;
    }

    state.date = props.date;
})

</script>
<template>
    <VueDatePicker
        v-model="state.date"
        :inline="props.showInline"
        :range="props.rangeMode"
        :auto-apply="true"
        :enable-time-picker="props.showTime"
        :time-picker-inline="props.showTime"
        :month-change-on-scroll="false"
        :minutes-increment="1"
        :no-hours-overlay="true"
        :no-minutes-overlay="true"
        :min-date="props.minDate"
        :format="format"
        locale="sv-SE"
        :select-text="_mft('shared:select')"
        :action-row="state.actionRow"
        @update:model-value="handleDate"
        @internal-model-change="handleInternalChange"
        :no-today="true"
        class="font-body"
    ></VueDatePicker>
</template>
<style>
.dp__cell_inner {
    margin-left: auto;
    margin-right: auto;
}

.dp__tp_inline_btn_top .dp__tp_btn_in_l {
    transform: rotate(-12deg) scale(1.15) translateY(-2px);
}
.dp__tp_inline_btn_top .dp__tp_btn_in_r {
    transform: rotate(12deg) scale(1.15) translateY(-2px);
}
.dp__tp_inline_btn_bottom .dp__tp_btn_in_r {
    transform: rotate(-12deg) scale(1.15) translateY(-2px) !important;
}
.dp__tp_inline_btn_bottom .dp__tp_btn_in_l {
    transform: rotate(12deg) scale(1.15) translateY(-2px) !important;
}

.dp__theme_light {
   --dp-font-family: 'Plus Jakarta Sans', 'Open Sans', sans-serif;
   --dp-font-size: 14px;
   --dp-background-color: #ffffff;
   --dp-text-color: #212121;
   --dp-hover-color: #f3f3f3;
   --dp-hover-text-color: #212121;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: var(--whitelabel-primary);
   --dp-primary-text-color: #f8f5f5;
   --dp-secondary-color: #c0c4cc;
   --dp-border-color: #ddd;
   --dp-menu-border-color: #ddd;
   --dp-border-color-hover: #aaaeb7;
   --dp-disabled-color: #f6f6f6;
   --dp-scroll-bar-background: #f3f3f3;
   --dp-scroll-bar-color: #959595;
   --dp-success-color: #76d275;
   --dp-success-color-disabled: #a3d9b1;
   --dp-icon-color: #959595;
   --dp-danger-color: #ff6f60;
   --dp-highlight-color: rgba(25, 118, 210, 0.1);
}

</style>
