<script setup>
import MediaBankEntry from './util/media-bank-entry.vue';
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
})

const hasLabel = computed(() => {
    return props.content.label && props.content.label.length > 0;
})

</script>
<template>
    <div
        class="max-w-4xl mx-auto my-6 overflow-hidden rounded-wl shadow-custom"
        :class="hasLabel ? 'px-1 py-1' : ''"
    >
        <div v-if="hasLabel" class="text-center pt-1 pb-2">
            {{ content.label }}
        </div>
        <MediaBankEntry
            v-if="content"
            class="w-full"
            :container-ref="containerRef"
            :block-uuid="uuid"
            :block-content="content"
            :media-bank-entry-id="content.media_bank_entry_id"
            property-name="media_bank_entry_id"
        ></MediaBankEntry>
    </div>
</template>
