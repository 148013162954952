<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue';

const props = defineProps({
    containerType: {
        type: String,
        required: true,
    },
    styles: {
        type: Object,
        required: false,
        default: {}
    },
})

const forceRefresh = ref(false);

const appliedStyles = computed(() => {
    forceRefresh.value = !forceRefresh.value;
    let aStyles = {};

    if (props.containerType === 'app_page') {
        aStyles.desktopBackgroundColor = props.styles.app?.backgroundColor ? props.styles.app.backgroundColor : 'transparent';
        aStyles.mobileBackgroundColor = props.styles.app?.backgroundColor ? props.styles.app.backgroundColor : 'transparent';

        aStyles.desktopColor = props.styles.app?.color ? props.styles.app.color : '#212529'
        aStyles.mobileColor = props.styles.app?.color ? props.styles.app.color : '#212529'

        return aStyles
    }

    aStyles.desktopBackgroundColor = props.styles.desktop.backgroundUrl ? null : props.styles.desktop.backgroundColor ? props.styles.desktop.backgroundColor : aStyles.mobileBackgroundColor;
    aStyles.desktopPadding = props.styles.desktop.padding ? props.styles.desktop.padding : props.styles.mobile.padding;
    aStyles.desktopMargin = props.styles.desktop.margin ? props.styles.desktop.margin : props.styles.mobile.margin;
    aStyles.desktopColor = props.styles.desktop.color ? props.styles.desktop.color : null;

    aStyles.mobileBackgroundColor = props.styles.mobile.backgroundUrl ? null : props.styles.mobile.backgroundColor ? props.styles.mobile.backgroundColor : aStyles.desktopBackgroundColor;
    aStyles.mobileBackgroundUrl = props.styles.mobile.backgroundUrl ? 'url(' + props.styles.mobile.backgroundUrl + ')' : null;

    aStyles.desktopBackgroundUrl = props.styles.desktop.backgroundUrl ? 'url(' + props.styles.desktop.backgroundUrl + ')' : aStyles.mobileBackgroundUrl;

    aStyles.mobilePadding = props.styles.mobile.padding;
    aStyles.mobileMargin = props.styles.mobile.margin;
    aStyles.mobileColor = props.styles.mobile.color ? props.styles.mobile.color : props.styles.desktop.color ? props.styles.desktop.color : null;

    return aStyles;
})

onMounted(() => {
    forceRefresh.value = !forceRefresh.value;
})

</script>

<template>
    <div
        class="mfpb-column-outer"
    >
        <div :class="[appliedStyles.desktopColor ? 'custom-desktop-color' : '', appliedStyles.mobileColor ? 'custom-mobile-color' : '']">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped>
    .mfpb-column-outer {

    }

    div > div {
        background-color: v-bind('appliedStyles.desktopBackgroundColor');
        background-image: v-bind('appliedStyles.desktopBackgroundUrl');
        background-size: cover;
        background-position: center center;
        padding: v-bind('appliedStyles.desktopPadding');
        margin: v-bind('appliedStyles.desktopMargin');
    }

    div.mfpb-column-outer > div.custom-desktop-color :deep(.styleable-block) {
        color: v-bind('appliedStyles.desktopColor') !important;
    }

    @media screen and (max-width: 959px) {
        div > div {
            background-color: v-bind('appliedStyles.mobileBackgroundColor');
            background-image: v-bind('appliedStyles.mobileBackgroundUrl');
            background-size: cover;
            background-position: center center;
            padding: v-bind('appliedStyles.mobilePadding');
            margin: v-bind('appliedStyles.mobileMargin');
        }

        div.mfpb-column-outer > div.custom-mobile-color :deep(.styleable-block) {
            color: v-bind('appliedStyles.mobileColor') !important;
        }
    }
</style>
