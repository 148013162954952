<script setup>

import {computed, onMounted, ref} from "vue";
import { PencilIcon } from "@heroicons/vue/outline"
import { PlayIcon, PauseIcon, CheckCircleIcon } from "@heroicons/vue/solid"

const campaigns = ref([])
const loaded = ref(false)
const inspectedCampaignId = ref(null)
const editingCampaign = ref(null)

const loadCampaigns = async () => {
    const res = await axios.get(route('api.lead-hunter.campaign.index'))
    campaigns.value = res.data.campaigns
    loaded.value = true
}

const setCampaignActive = async (campaign) => {
    const res = await axios.post(route('api.lead-hunter-campaign.set-active', {id: campaign.id}))
    window.location.reload()
}

const setCampaignPaused = async (campaign) => {
    const res = await axios.post(route('api.lead-hunter-campaign.set-paused', {id: campaign.id}))
    window.location.reload()
}

const expandCampaign = (campaign) => {
    if (inspectedCampaignId.value === campaign.id) {
        inspectedCampaignId.value = null
        return
    }
    inspectedCampaignId.value = campaign.id
}

const updateEditingCampaign = async () => {
    const res = await axios.post(route('api.lead-hunter.campaign.rename', {id: editingCampaign.value.id}), {
        name: editingCampaign.value.name
    })
    editingCampaign.value = null
    await loadCampaigns()
}

const runningCampaigns = computed(() => {
    return campaigns.value.filter(campaign => campaign.status === 'running')
})

const notRunningCampaigns = computed(() => {
    return campaigns.value.filter(campaign => campaign.status !== 'running')
})

const allCampaignsRunningFirst = computed(() => {
    return [...runningCampaigns.value, ...notRunningCampaigns.value]
})

onMounted(() => {
    loadCampaigns()
})

</script>

<template>
    <div>
        <div class="container w-full mx-auto top-2 z-topbar md:sticky">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        Lead Hunter Campaigns
                    </h1>
                    <div class="flex items-center space-x-4">
                        <story-lane-modal :module="'leadhunter-v2'"></story-lane-modal>

                        <a :href="route('lead-hunter.campaign.create')">
                            <button-primary
                            >
                                New campaign
                            </button-primary>
                        </a>
                    </div>
                </div>
            </frosted-bar>
        </div>

        <list-basic class="mt-8">
            <list-basic-item
                v-for="campaign in allCampaignsRunningFirst"
                :key="campaign.id"
            >
                <template #status>
                    <div
                        v-if="campaign.status === 'running'"
                        class="relative"
                    >
                        <PlayIcon
                            class="absolute w-4 h-4 text-green-400 right-0.5 top-1"
                        ></PlayIcon>
                    </div>
                </template>

                <template #title>
                    <div class="mb-0.5 flex items-center space-x-2">
                        <span>
                            {{ campaign.name }}
                        </span>
                        <button-transparent
                            size="line"
                            @click="editingCampaign = campaign"
                            class="transform -translate-y-0.5 text-gray-400"
                        >
                            <i class="fa-regular fa-pencil text-xs"></i>
                        </button-transparent>
                    </div>
                    <div class="text-xs mb-0.5 text-gray-400">
                        <span v-if="campaign.status === 'running'">
                            Running
                            <span class="pl-0.5"> since {{ campaign.updated_at_display }}</span>
                        </span>
                        <span v-if="campaign.status === 'draft'" class="italic">Draft</span>
                        <span v-if="campaign.status === 'paused'">
                            Paused
                            <span class="pl-0.5"> since {{ campaign.updated_at_display }}</span>
                        </span>
                        <span v-if="campaign.status === 'finished'">Finished</span>
                    </div>

                    <div class="text-xs text-gray-400">
                        <div class="flex space-x-4">
                            <span>{{ campaign.sent_emails }} sent</span>
                            <span>{{ campaign.skipped_leads }} skipped</span>
                        </div>
                    </div>

                    <div v-if="inspectedCampaignId === campaign.id">
                        <div class="pt-4">
                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.jobTitles.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="job_title in campaign.lead_search.job_title"
                                    :key="job_title"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ job_title.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.job_title.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companyIndustry.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="company_industry in campaign.lead_search.company_industry"
                                    :key="company_industry"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ company_industry.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_industry.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companySize.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="company_size in campaign.lead_search.company_size"
                                    :key="company_size"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ company_size.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_size.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.locations.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="location in campaign.lead_search.location"
                                    :key="location"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ location.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.location.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>
                        </div>
                        <div class="text-gray-400 text-xs">
                            Created {{ campaign.created_at_display }}
                        </div>
                    </div>
                </template>
                <template #actions>
                    <div class="flex items-center">
                        <button-base
                            size="sm"
                            @click="expandCampaign(campaign)"
                        >
                            <template #icon>
                                <i
                                    class="fa-regular"
                                    :class="campaign.id === inspectedCampaignId ? 'fa-minus' : 'fa-plus'"
                                ></i>
                            </template>
                            <span class="pl-1">{{ _mft('shared:action.show') }}</span>
                        </button-base>
                        <button-transparent
                            v-if="campaign.status === 'running'"
                            size="sm"
                            @click="setCampaignPaused(campaign)"
                        >
                            <PauseIcon
                                class="w-7 h-7 text-gray-400"
                            ></PauseIcon>
                        </button-transparent>
                        <button-transparent
                            v-if="campaign.status !== 'running' && campaign.manual_mode === 1"
                            size="sm"
                            @click="setCampaignActive(campaign)"
                        >
                            <PlayIcon
                                class="w-7 h-7 text-wlPrimary"
                            ></PlayIcon>
                        </button-transparent>

                        <button-transparent
                            v-if="campaign.status !== 'running' && campaign.manual_mode === 0"
                            size="sm"
                            title="This campaign cannot be started"
                        >
                            <PlayIcon
                                class="w-7 h-7 text-gray-200"
                            ></PlayIcon>
                        </button-transparent>
                    </div>
                </template>
            </list-basic-item>
        </list-basic>

        <slideout-with-slots
            v-if="editingCampaign"
            @close="editingCampaign = null"
            title="Rename campaign"
        >
            <template #body>
                <div>
                    <input-text
                        v-model:content="editingCampaign.name"
                        label="Campaign name"
                        maxlength="64"
                    ></input-text>
                    <div class="flex justify-end">
                        <button-success
                            @click="updateEditingCampaign"
                        >
                            {{ _mft('shared:action.update') }}
                        </button-success>
                    </div>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
