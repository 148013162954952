<script setup>
import {computed, ref, onMounted, watch} from 'vue'
import IconTrashcan from "../icons/iconTrashcan.vue";
import IconSettings from "../icons/iconSettings.vue";
import IconPen from "../icons/iconPen.vue";
import ButtonMoreOptions from "../buttons/buttonMoreOptions.vue";
import ButtonMoreOptionsOption from "../buttons/buttonMoreOptionsOption.vue";

const emit = defineEmits(['pickedUp', 'chosenForEditing', 'deleted', 'clone', 'save'])

const blockComponents = import.meta.glob('../page-builder-view/block-editor/blocks/*.vue', { eager: true, import: 'default' });
const blocks = Object.entries(blockComponents).reduce((ack, [path, component]) => {
    const name = path
        .replace(/^\.\.\/page-builder-view\/block-editor\/blocks\//, '')
        .replace(/\.vue$/, '');

    ack[name] = component;
    return ack;
}, {});

const props = defineProps({
    block: {
        type: Object,
        required: true
    },
    containerRef: {
        type: String,
        required: true
    },
    rowIndex: {
        type: Number,
        required: true
    },
    colIndex: {
        type: Number,
        required: true
    },
    blockIndex: {
        type: Number,
        required: true
    },
    isPickedUp: {
        type: Boolean,
        required: false,
        default: false
    }
})

const appearanceClass = ref('scale-90 opacity-0')

const pickUpBlock = () => {
    emit('pickedUp', {blockUuid: props.block.uuid, rowIndex: props.rowIndex, colIndex: props.colIndex})
}

const cloneBlock = () => {
    emit('clone', {block: props.block, rowIndex: props.rowIndex, colIndex: props.colIndex})
}

const saveBlock = () => {
    emit('save', props.block)
}

const editBlock = () => {
    if (props.isPickedUp) return;
    emit('chosenForEditing', props.block.uuid)
}

const deleteBlock = () => {
    if (props.isPickedUp) return;
    emit('deleted', props.block.uuid)

}

const _block = computed(() => {
    const block = {...props.block}

    delete block.hidden

    return block
})

const anchorableId = computed(() => {
    return props.block?.uuid?.substring(props.block?.uuid?.length - 12)
})


watch(() => props.isPickedUp, (newValue) => {
    if (newValue) {
        appearanceClass.value = 'scale-90 opacity-50 transition-all'
        setTimeout(function() {
            appearanceClass.value = appearanceClass.value + ' overflow-hidden'
        }, 150);
    } else {
        appearanceClass.value = 'transition-none'
    }
})

onMounted(() => {
    setTimeout(function(){
        appearanceClass.value = ''
    }, 150)
})

</script>
<template>
    <div
        class="bg-transparent rounded-md hover:bg-white/60"
        :class="appearanceClass"
    >

        <div
            class="p-1 mfpbb"
            :class="'mfpbb-' + props.block.uuid"
        >
            <div class="flex items-center justify-between">
                <span class="overflow-hidden text-xs text-gray-400 cursor-default hover:text-gray-800 whitespace-nowrap overflow-ellipsis">
                    {{ props.block.name }}
                </span>
                <div class="text-xs pb-0.5 flex justify-end space-x-1 items-center flex-wrap">
                    <button-more-options
                        size="sm"
                        alignment="right"
                        class="mb-0.5"
                        :cancel-on-outside-click="false"
                    >
<!--                        <button-more-options-option-->
<!--                            class="text-xs whitespace-nowrap"-->
<!--                            @click="saveBlock"-->
<!--                        >-->
<!--                            Save block-->
<!--                        </button-more-options-option>-->

                        <button-more-options-option
                            size="sm"
                            @click="cloneBlock"
                        >
                            {{ _mft('pagebuilder:block.clone') }}
                        </button-more-options-option>
                    </button-more-options>
                    <button-base
                        class="mb-0.5"
                        size="sm"
                        :disabled="props.isPickedUp"
                        @click="pickUpBlock"
                    >{{ _mft('pagebuilder:block.move') }}
                    </button-base>

                    <button-base
                        class="mb-0.5"
                        size="sm"
                        :disabled="props.isPickedUp"
                        @click="editBlock"
                    >
                        <icon-pen class="w-4 h-4" />
                    </button-base>

                    <button-with-confirmation
                        class="mb-0.5"
                        :disabled="props.isPickedUp"
                        size="sm"
                        @confirmed="deleteBlock"
                        modalClass="absolute -top-2 -bottom-2 -right-2 p-2 bg-white rounded shadow-md z-20"
                        :confirmButtonLabel="_mft('shared:action.delete')"
                        confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                    >
                        <icon-trashcan class="w-4 h-4 text-danger" />
                    </button-with-confirmation>
                </div>
            </div>
            <div
                v-if="props.block.content"
                class="styleable-block anchorable"
                :id="anchorableId"
                :data-blocktypename="props.block.name"
                :data-block="'page-builder-view-block-editor-blocks-' + props.block.type"
                :class="block.hidden ? 'opacity-25 hover:opacity-100' : ''"
            >
                <component
                    v-if="blocks[props.block.type]"
                    :is="blocks[props.block.type]"
                    :container-ref="props.containerRef"
                    v-bind="_block"
                ></component>
            </div>
        </div>
    </div>
</template>
