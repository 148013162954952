<script setup>
import { ref } from 'vue'
import axios from 'axios'

const name = ref(null)
const email = ref(null)
const phone = ref(null)
const company = ref(null)
const title = ref(null)

const contactId = ref(null)

async function addContact() {
    try {
        const { data } = await axios.post(route('utility.lead.store'), {
            name: name.value,
            email: email.value,
            phone: phone.value,
            company: company.value,
            title: title.value,
        })

        contactId.value = data

        name.value = null
        email.value = null
        phone.value = null
        company.value = null
        title.value = null
    } catch (error) {
    }
}
</script>

<template>
    <div>
        <div v-if="contactId !== null">
            <h5>Contact Added!</h5>
            <a :href="route('crm.leads.view', contactId)" class="mf-link">
                Open Contact
            </a>
            <hr class="mt-5 mb-5">
        </div>

        <div>
            <input-text
                v-model:content="name"
                placeholder="Name"
                maxlength="255"
                :required="true"
            />
            <input-text
                v-model:content="email"
                placeholder="Email"
                maxlength="255"
                inputtype="email"
            />
            <input-text
                v-model:content="phone"
                placeholder="Phone"
                maxlength="255"
            />
            <input-text
                v-model:content="company"
                placeholder="Company"
                maxlength="255"
            />
            <input-text
                v-model:content="title"
                placeholder="Title"
                maxlength="255"
            />
            <div class="flex justify-end mb-2 -mt-2">
                    <button-secondary
                        size="sm"
                        @click="addContact"
                    >
                    Add contact
                </button-secondary>
            </div>
        </div>
    </div>
</template>
