<script setup>
defineProps({
    name: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    isPro: {
        type: Boolean,
        default: false
    },
    showPreview: {
        type: Boolean,
        default: true
    },
    links: {
        type: Object,
        required: true,
        // expects: { show?: string, edit: string }
    },
    isReviewed: {
        type: Boolean,
        required: true
    },
    reviewField: {
        type: String,
        required: true
    },
    imageUrl: {
        type: String,
        required: false,
        default: null
    }
})

const emit = defineEmits(['review'])
</script>

<template>
    <card-basic
        :padless-image="true"
    >
        <template
            v-if="imageUrl || name === 'campaign'"
            #image
        >
            <img
                v-if="imageUrl"
                :src="imageUrl"
                class="w-full aspect-square mb-4"
            />
            <img
                v-else-if="name === 'campaign'"
                src="https://myflow.fra1.digitaloceanspaces.com/leadhunter-illustration-lg.webp"
                class="w-full aspect-square mb-4"
            />
            <div
                v-else
                class="w-full aspect-square mb-4 bg-gray-100 flex justify-center items-center"
            >
            </div>
        </template>
        <div class="flex flex-col items-between justify-between">
            <div>
                <h3 class="text-xl font-semibold">{{ title }}</h3>
                <span
                    class="inline-flex items-center px-2 py-1 mt-3 text-xs font-medium rounded-full"
                    :class="isPro ? 'text-myflowPurple-700 bg-myflowPurple-100' : 'text-myflowGreen-700 bg-myflowGreen-100'"
                >
                    {{ isPro ? 'Pro Feature' : 'Basic Feature' }}
                </span>

                <p class="my-4 text-sm text-gray-600">{{ description }}</p>
            </div>

            <div class="flex flex-col space-y-4 h-full justify-end min-h-36">
                <div class="grid grid-cols-1 gap-4">
                    <template v-if="showPreview">
                        <a :href="links.show" target="_blank">
                            <button-base class="flex items-center justify-center w-full">
                                <i class="w-4 h-4 mr-1 text-gray-500 fa-regular fa-eye"></i>
                                <span>Preview</span>
                            </button-base>
                        </a>
                    </template>
                    <a :href="links.edit" target="_blank">
                        <button-base class="flex items-center justify-center w-full">
                            <i class="w-4 h-4 mr-1 text-gray-500 fa-regular fa-pen-to-square"></i>
                            <span>Edit</span>
                        </button-base>
                    </a>
                </div>
                <button-success
                    v-if="!isReviewed"
                    @click="emit('review', reviewField)"
                    class="flex justify-center w-full"
                >
                    <i class="w-4 h-4 mr-1 text-gray-300 fa-regular fa-circle-check"></i>
                    <span>Mark as reviewed</span>
                </button-success>
                <button-success
                    v-else
                    disabled
                    class="flex justify-center w-full"
                >
                    <i class="w-4 h-4 mr-1 text-myflowGreen-300 fa-regular fa-circle-check"></i>
                    <span class="text-myflowGreen">Reviewed</span>
                </button-success>
            </div>
        </div>
    </card-basic>
</template>
