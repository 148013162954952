<script setup>
import {computed, ref} from 'vue'
import FeatureCard from './FeatureCard.vue'
import NotPro from "./NotPro.vue";

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    isLaunched: {
        type: Boolean,
        required: false,
        default: false
    }
})

const isLaunching = ref(false)
const input = ref(props.data)

const features = computed(() => {
    return [
        {
            name: 'campaign',
            title: 'LeadHunter Campaign',
            description: 'Automatically find your ideal customers. Available with myflow Pro.',
            isPro: true,
            showPreview: false,
            links: props.data.links.campaign,
            isReviewed: input.value.setup.campaign_reviewed,
            reviewField: 'campaign',
            imageUrl: input.value.links.campaign.image
        },
        {
            name: 'landingPage',
            title: 'Landing page',
            description: 'A professional landing page that showcases your expertise and converts visitors into leads.',
            isPro: false,
            links: props.data.links.landingPage,
            isReviewed: input.value.setup.landing_page_reviewed,
            reviewField: 'landing_page',
            imageUrl: input.value.links.landingPage?.image
        },
        {
            name: 'expertContract',
            title: 'Quote',
            description: 'A professional quote template that helps you close deals faster and more efficiently.',
            isPro: false,
            links: props.data.links.expertContract,
            isReviewed: input.value.setup.expert_contract_reviewed,
            reviewField: 'expert_contract',
            imageUrl: input.value.links.expertContract?.image
        }
    ]
})

const launchEverything = () => {
    if (! input.value.hasPro ) {
        console.error('You need to have Pro to launch everything');
        console.log(input.value);
        return;
    }

    isLaunching.value = true
    axios.post(route('assistant.launchSetup'))
        .then(() => {
            window.location.reload()
        })
        .catch(() => {
            isLaunching.value = false
        })
}

const markAsReviewed = (field) => {
    axios.post(route('assistant.markAsReviewed'), {
        field: field
    }).then(() => {
        input.value.setup[`${field}_reviewed`] = true
        features.value = features.value.map(feature => {
            if (feature.reviewField === field) {
                feature.isReviewed = true
            }
            return feature
        })
    })
}
</script>

<template>
    <div class="max-w-4xl mx-auto text-center">
        <h1 class="mb-4 text-5xl">You rule!</h1>
        <p class="max-w-sm mx-auto mb-8 text-lg leading-tight">At least over the small kingdom we've created for you, have a look:</p>

        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <feature-card
                v-for="feature in features"
                :name="feature.name"
                :key="feature.title"
                v-bind="feature"
                @review="markAsReviewed"
            />
        </div>

        <div
            v-if="isLaunched"
            class="text-center"
        >
            <p class="mb-6 text-lg font-medium text-gray-600 max-w-sm mx-auto">
                Visit the Weekly Assistant where you can find your new leads and check your progress
            </p>
            <a :href="route('assistant.daily')">
                <button-primary
                    size="lg"
                >
                    Weekly Assistant
                </button-primary>
            </a>
        </div>

        <div
            v-if="! isLaunched"
            class="mt-12 text-center"
        >
            <p
                v-if="input.hasPro"
                class="mb-6 text-lg font-medium text-gray-600"
            >Ready to unleash this masterpiece upon the world?</p>

            <div v-if="!input.hasPro" class="mb-6">
                <NotPro />
            </div>

            <div v-if="input.hasPro">
                <button-success
                    class="flex items-center justify-center px-8 py-4 mx-auto text-lg font-medium"
                    @click="launchEverything"
                    :disabled="isLaunching || !input.hasPro"
                >
                    <div class="flex items-center" :class="{'opacity-50': !input.hasPro}">
                        <span class="mr-2 text-2xl">🚀</span>
                        <span>{{ isLaunching ? 'Launching...' : 'Launch Everything' }}</span>
                    </div>
                </button-success>
                <p class="mt-6 text-sm italic text-gray-500">
                    {{ input.hasPro
                        ? "Don't worry, we've triple-checked the self-destruct sequence"
                        : 'Your landing page and quote will work without Pro - but the lead generation requires an upgrade!' }}
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
