<script setup>

import {useSlots} from "vue";

const slots = useSlots();
</script>

<template>
    <div class="relative">
        <div class="backdrop-blur-lg p-2 bg-white/75 rounded-wl mt-2 z-20 pl-4 lg:pl-6 shadow-xl">
            <slot></slot>
            <span
                v-if="slots.navigation"
                class="bg-white absolute bottom-full left-4 lg:left-6 rounded-wl -mb-2.5 py-0.5 px-2 text-xs -ml-2"
            >
                <slot name="navigation"></slot>
            </span>
        </div>
    </div>
</template>

<style scoped>

</style>
