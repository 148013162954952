<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    pageType: {
        type: String,
        required: true
    },
    folderId: {
        type: String,
        required: false,
        default: null
    }
})

const pagesLoaded = ref(false)
const pages = ref([])
const folders = ref([])
const sortOrder = ref('desc')
const sortColumn = ref('updated_at')
const filterTerm = ref('')
const frontPageId = ref(null)
const customerAreaFrontPageId = ref(null)
const pageMovingToFolder = ref(null)
const pageForDuplication = ref(null)
const duplicationOptions = reactive({
    pageType: props.pageType,
    title: null
})
const sortOptions = [
    { label: _mft('shared:recentlyUpdatedFirst.short'), name: 'updated_at:desc' },
    { label: _mft('shared:newestFirst.short'), name: 'created_at:desc' },
    { label: _mft('shared:oldestFirst.short'), name: 'created_at:asc' },
]
const currentPage = ref(1)
const pageSize = 15

const loadPages = async () => {
    pagesLoaded.value = false
    const response = await axios.get(route('api.pages.index', props.pageType), {
        params: {
            sortColumn: sortColumn.value,
            sortOrder: sortOrder.value,
            folderId: props.folderId,
        }
    })
    pages.value = response.data.pages
    frontPageId.value = response.data.front_page_id
    customerAreaFrontPageId.value = response.data.customer_area_front_page_id
    pagesLoaded.value = true
}

const loadFolders = async () => {
    const response = await axios.get(route('api.pages.folders.index', props.pageType))
    folders.value = response.data.folders
}

const setSortOrder = (order) => {
    sortColumn.value = order.name.split(':')[0]
    sortOrder.value = order.name.split(':')[1]
    loadPages()
    _mfPrefSet('pages-sort-order', order.name)
}

const deletePage = async (page) => {
    await axios.delete(route('api.pages.delete', [props.pageType, page.id]))

    notificationStore.addNotification({
        type: 'success',
        title: _mft('page:deleted'),
        dismissAfter: 5000,
    })
    await loadPages()
}
const setPageForDuplication = (page) => {
    pageForDuplication.value = page
}

const duplicatePage = async () => {
    if (!duplicationOptions.pageType || !duplicationOptions.title) {
        return
    }

    const res = await axios.post(route('api.pages.duplicate', [props.pageType, pageForDuplication.value.id]), duplicationOptions)

    if (res.data.success) {
        window.location.href = route('pages.edit', [props.pageType, res.data.page_id])
    }
    await loadPages()
    pageForDuplication.value = null
}

const movePageToFolder = async (folder) => {
    const res = await axios.post(route('api.pages.move', [props.pageType, pageMovingToFolder.value.id]), {
        folder_id: folder.id
    })

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('page:folder.move.confirmation'),
            message: pageMovingToFolder.value.title + ' ' + _mft('page:folder.move.confirmation.pageMovedToFolder') + ' ' + folder.name,
            dismissAfter: 5000,
        })
    }
    await loadPages()
    pageMovingToFolder.value = null
}

const toggleFavorite = async (page) => {
    page.favorited = ! page.favorited
    const res = await axios.post(route('api.pages.toggle-favorite', [props.pageType, page.id]))
    page.favorited = res.data.favorited
    await loadPages()
}

const searchedPages = computed(() => {
    if (filterTerm.value === '') {
        return pages.value
    }
    return pages.value.filter(page => {
        return page.title.toLowerCase().includes(filterTerm.value.toLowerCase())
    })
})

const paginatedPages = computed(() => {
    const start = (currentPage.value - 1) * pageSize
    const end = start + pageSize
    return searchedPages.value.slice(start, end)
})

const nextPageAvailable = computed(() => {
    return pages.value.length > currentPage.value * pageSize
})

const showFolders = computed(() => {
    return props.pageType !== 'app_page'
})

const showLinks = computed(() => {
    return props.pageType !== 'app_page'
})

const showEdit = computed(() => {
    return props.pageType !== 'app_page'
})

onMounted(() => {
    loadPages()
    loadFolders()
    _mfPrefGet('pages-sort-order').then(data => {
        sortColumn.value = data.split(':')[0]
        sortOrder.value = data.split(':')[1]
    })
})

</script>

<template>
    <div class="w-full pb-24">
        <div class="container w-full mx-auto md:sticky top-2 z-topbar mb-8">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        {{ _mft('page:' + pageType + '.domainNamePlural') }}
                    </h1>

                    <div class="flex items-center space-x-4">
                        <story-lane-modal v-if="pageType === 'landingpage'" :module="'landing-pages-v2'"></story-lane-modal>
                        <story-lane-modal v-if="pageType === 'webpage'" :module="'web-pages-v1'"></story-lane-modal>

                        <a :href="route('pages.create', pageType)">
                            <button-primary>{{ _mft('page:newPage') }}</button-primary>
                        </a>
                    </div>
                </div>
            </frosted-bar>
        </div>
        <div class="mb-2 flex flex-col md:flex-row items-center md:items-center justify-between space-y-2 md:space-y-0 md:space-x-2 @container relative z-20">
            <folders-widget
                v-if="showFolders"
                class="w-56 bg-white rounded-wl"
                :current-folder-id="folderId"
                add-route-name="pages.addFolder"
                index-route-name="api.pages.folders.index"
                show-route-name="pages.showFolder"
                :route-parameters="{pageType: pageType}"
                @folder-added="loadFolders"
            ></folders-widget>
            <div
                v-else
                class="w-56"
            ></div>

            <div class="flex flex-col md:flex-row items-center md:items-center justify-between space-y-2 md:space-y-0 md:space-x-2">
                <div class="w-64 !-mb-5">
                    <button-bar
                        size="xs"
                        :options="sortOptions"
                        :selected-option-name="sortColumn+':'+sortOrder"
                        @select="setSortOrder"
                        :responsive="false"
                    />

                </div>
                <input-text
                    v-model:content="filterTerm"
                    :placeholder="_mft('shared:search')"
                    :show-clear="true"
                    class="w-36 !mb-0 -mt-1"
                    input-class="text-xs"
                />
            </div>
        </div>
        <list-basic
            v-if="pagesLoaded"
        >
            <list-basic-item
                v-for="page in paginatedPages"
                :key="page.id"
            >
                <template #status>
                    <div class="flex flex-col justify-start space-y-0 text-base pr-4">
                        <container-with-tooltip
                            v-if="page.public"
                        >
                            <i class="text-myflowGreen far fa-unlock"></i>
                            <template #tooltip>
                                <div>
                                    {{ _mft('page:status.public') }}
                                </div>
                            </template>
                        </container-with-tooltip>

                        <container-with-tooltip
                            v-else
                        >
                            <i class="text-myflowRed fa-solid fa-lock"></i>
                            <template #tooltip>
                                <div>
                                    {{ _mft('page:status.notPublic') }}
                                </div>
                            </template>
                        </container-with-tooltip>

                        <i
                            class="fa-star text-sm transform -translate-x-px"
                            :class="page.favorited ? 'fa-solid text-yellow-300' : 'fa-regular text-gray-300 hover:text-yellow-300'"
                            @click="toggleFavorite(page)"
                        ></i>

                        <container-with-tooltip
                            v-if="page.id === frontPageId"
                        >
                            <i class="text-gray-400 fa-solid fa-store text-sm transform -translate-x-px"></i>
                            <template #tooltip>
                                <div class="whitespace-nowrap">{{ _mft('page:selectedAsPublicFrontPage') }}</div>
                            </template>
                        </container-with-tooltip>

                        <container-with-tooltip
                            v-if="page.id === customerAreaFrontPageId"
                        >
                            <i class="text-gray-400 fa-solid fa-user text-sm transform -translate-x-px"></i>
                            <template #tooltip>
                                <div class="whitespace-nowrap">{{ _mft('page:selectedAsLoggedInFrontPage') }}</div>
                            </template>
                        </container-with-tooltip>
                    </div>
                </template>

                <template #title>
                    <span class="font-medium text-gray-900 truncate">
                        {{ page.title }}
                    </span>
                </template>


                <div>
                    <div class="flex flex-col md:flex-row md:justify-between md:items-center">
                        <div
                            v-if="showLinks"
                            class="w-full truncate"
                        >
                            <div class="flex flex-col w-full text-sm mb-4 md:mb-0">
                                <div
                                    v-if="page.id === frontPageId"
                                >
                                    <input
                                        class="w-full max-w-full text-xs truncate bg-transparent focus:outline-none"
                                        type="text"
                                        :value="siteRoute('home')"
                                        onfocus="event.target.select()"
                                    >
                                    <link-field
                                        class="flex mt-2 ml-1 space-x-4 md:ml-0 md:space-x-4 text-xs"
                                        :url="siteRoute('home')"
                                    ></link-field>
                                </div>
                                <div v-else>
                                    <input
                                        class="w-full max-w-full text-xs truncate bg-transparent focus:outline-none"
                                        type="text"
                                        :value="siteRoute('landing-pages.show', page.slug)"
                                        onfocus="event.target.select()"
                                    >
                                    <link-field
                                        class="flex mt-2 ml-1 space-x-4 md:ml-0 md:space-x-4 text-xs"
                                        :url="siteRoute('landing-pages.show', page.slug)"
                                    ></link-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template #actions>
                    <div class="flex space-x-2">
                        <button-more-options
                            alignment="right"
                            size="sm"
                            options-class="min-w-36"
                        >
                            <button-more-options-option-with-confirmation
                                v-if="page.id !== frontPageId && page.id !== customerAreaFrontPageId"
                                style-type="danger"
                                :confirm-text="_mft('shared:confirm.areYouSure')"
                                :confirm-button-label="_mft('shared:action.delete')"
                                :abort-button-label="_mft('shared:action.cancel')"
                                @confirmed="deletePage(page)"
                            >
                                {{ _mft('shared:action.delete') }}
                            </button-more-options-option-with-confirmation>
                            <button-more-options-option
                                class="whitespace-nowrap"
                                @click="setPageForDuplication(page)"
                            >
                                {{ _mft('shared:action.duplicate') }}
                            </button-more-options-option>
                            <button-more-options-option
                                v-if="showFolders"
                                class="whitespace-nowrap"
                                @click="pageMovingToFolder = page"
                            >
                                {{ _mft('page:folder.moveToFolder') }}
                            </button-more-options-option>
                        </button-more-options>


                        <a
                            v-if="showEdit"
                            :href="route('pages.edit', [pageType, page.id])"
                        >
                            <button-secondary size="sm">
                                {{ _mft('page:settings') }}
                            </button-secondary>
                        </a>

                        <a :href="route('pages.pagebuilder', [pageType, page.id])">
                            <button-primary size="sm">
                                {{ _mft('shared:action.edit') }}
                            </button-primary>
                        </a>

                    </div>
                </template>
            </list-basic-item>
            <list-basic-item
                v-if="pages.length === 0"
                class="text-center"
            >
                <p class="mb-2 text-gray-400">{{ _mft('page:noPagesToShowHere') }}</p>
            </list-basic-item>
        </list-basic>

        <div class="flex items-center justify-center mt-4">
            <div class="w-8">
                <button-base
                    size="sm"
                    style-type="transparent"
                    v-if="currentPage > 1"
                    @click="currentPage--"
                >
                    <icon-chevron class="w-3 h-3 rotate-90" />
                </button-base>
            </div>
            <ul
                v-if="searchedPages.length / pageSize < 10"
            >
                <li
                    v-for="i in Math.ceil(searchedPages.length / pageSize)"
                    :key="i"
                    class="inline-block mx-1"
                    @click="currentPage = i"
                >
                    <span
                        class="cursor-pointer"
                        :class="currentPage === i ? 'text-black font-bold' : 'text-gray-500'"
                        @click="currentPage = i"
                    >
                        {{ i }}
                    </span>
                </li>
            </ul>
            <div
                v-else
                class="mx-1"
            >
                {{ currentPage }} <span class="text-gray-500">{{ _mft('shared:of') }} {{ Math.ceil(searchedPages.length / pageSize) }}</span>
            </div>
            <div class="w-8">
                <button-base
                    size="sm"
                    style-type="transparent"
                    v-if="nextPageAvailable"
                    @click="currentPage++"
                >
                    <icon-chevron class="w-3 h-3 -rotate-90" />
                </button-base>
            </div>
        </div>

        <slideout-with-slots
            v-if="pageForDuplication !== null"
            @close="pageForDuplication = null"
        >
            <template #title>
                <h2>{{ _mft('shared:action.duplicate') }}</h2>
            </template>
            <template #body>
                <input-text
                    :label="_mft('page:pageTitle')"
                    maxlength="255"
                    name="title"
                    placeholder=""
                    required
                    v-model:content="duplicationOptions.title"
                    autofocus
                />

                <div>
                    <input-label :label="_mft('page:duplicateAsType')"></input-label>
                    <div class="flex items-center space-x-2 mb-4 mt-1">
                        <button-base
                            size="sm"
                            :style-type="duplicationOptions.pageType === 'landingpage' ? 'primary' : 'base'"
                            @click="duplicationOptions.pageType = 'landingpage'"
                        >
                            {{ _mft('page:landingpage.domainNameSingular') }}
                        </button-base>
                        <button-base
                            size="sm"
                            :style-type="duplicationOptions.pageType === 'webpage' ? 'primary' : 'base'"
                            @click="duplicationOptions.pageType = 'webpage'"
                        >
                            {{ _mft('page:webpage.domainNameSingular') }}
                        </button-base>
                        <button-base
                            size="sm"
                            :style-type="duplicationOptions.pageType === 'customerarea' ? 'primary' : 'base'"
                            @click="duplicationOptions.pageType = 'customerarea'"
                        >
                            {{ _mft('page:customerarea.domainNameSingular') }}
                        </button-base>
                    </div>
                </div>
                <div class="flex justify-end">
                    <button-success
                        :disabled="!duplicationOptions.pageType || !duplicationOptions.title"
                        @click="duplicatePage"
                    >
                        {{ _mft('shared:action.duplicate') }}
                    </button-success>
                </div>
            </template>
        </slideout-with-slots>

        <slideout-with-slots
            v-if="pageMovingToFolder !== null"
            @close="pageMovingToFolder = null"
        >
            <template #title>
                <h2>{{ _mft('page:folder.moveToFolder') }}</h2>
            </template>
            <template #body>
                <ul class="divide-y -mx-4 lg:-mx-6">
                    <li
                        v-for="folder in folders"
                        :key="folder.id"
                        class="flex justify-between space-x-2 py-2 hover:bg-gray-50 px-4 lg:px-6"
                    >
                        <span>
                            {{ folder.name }}
                        </span>
                        <span>
                            <button-secondary
                                size="sm"
                                @click="movePageToFolder(folder)"
                            >
                                {{ _mft('page:folder.move') }}
                            </button-secondary>
                        </span>
                    </li>
                </ul>
            </template>
        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
