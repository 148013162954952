<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import OptionsField from "./optionsField.vue";
import SearchField from "./searchField.vue";
import {debounce} from "lodash";
import MultiTextField from "./multiTextField.vue";
import TitleCompletion from "./_titleCompletion.vue";

const emit = defineEmits(['nextStage', 'leadCount', 'leadCountDisplay', 'leadCountRangeDisplay', 'waiting', 'initialSearchPerformed'])

const searchFields = ref([
    {
        label: _mft('leadHunterCampaign:audience.fields.jobTitle.label'),
        field: 'job_title',
        type: 'multiText',
        completionType: 'titleSuggestions',
        inputPlaceholder: 'CEO, HR, Marketing etc.'
    },
    {
        label: _mft('leadHunterCampaign:audience.fields.companyIndustry.label'),
        field: 'company_industry',
        type: 'options',
        options: [
            {
                label: 'Accounting',
                value: 'accounting',
            },
            {
                label: 'Airlines/aviation',
                value: 'airlines/aviation',
            },
            {
                label: 'Alternative dispute resolution',
                value: 'alternative dispute resolution',
            },
            {
                label: 'Alternative medicine',
                value: 'alternative medicine',
            },
            {
                label: 'Animation',
                value: 'animation',
            },
            {
                label: 'Apparel & fashion',
                value: 'apparel & fashion',
            },
            {
                label: 'Architecture & planning',
                value: 'architecture & planning',
            },
            {
                label: 'Arts and crafts',
                value: 'arts and crafts',
            },
            {
                label: 'Automotive',
                value: 'automotive',
            },
            {
                label: 'Aviation & aerospace',
                value: 'aviation & aerospace',
            },
            {
                label: 'Banking',
                value: 'banking',
            },
            {
                label: 'Biotechnology',
                value: 'biotechnology',
            },
            {
                label: 'Broadcast media',
                value: 'broadcast media',
            },
            {
                label: 'Building materials',
                value: 'building materials',
            },
            {
                label: 'Business supplies and equipment',
                value: 'business supplies and equipment',
            },
            {
                label: 'Capital markets',
                value: 'capital markets',
            },
            {
                label: 'Chemicals',
                value: 'chemicals',
            },
            {
                label: 'Civic & social organization',
                value: 'civic & social organization',
            },
            {
                label: 'Civil engineering',
                value: 'civil engineering',
            },
            {
                label: 'Commercial real estate',
                value: 'commercial real estate',
            },
            {
                label: 'Computer & network security',
                value: 'computer & network security',
            },
            {
                label: 'Computer games',
                value: 'computer games',
            },
            {
                label: 'Computer hardware',
                value: 'computer hardware',
            },
            {
                label: 'Computer networking',
                value: 'computer networking',
            },
            {
                label: 'Computer software',
                value: 'computer software',
            },
            {
                label: 'Construction',
                value: 'construction',
            },
            {
                label: 'Consumer electronics',
                value: 'consumer electronics',
            },
            {
                label: 'Consumer goods',
                value: 'consumer goods',
            },
            {
                label: 'Consumer services',
                value: 'consumer services',
            },
            {
                label: 'Cosmetics',
                value: 'cosmetics',
            },
            {
                label: 'Dairy',
                value: 'dairy',
            },
            {
                label: 'Defense & space',
                value: 'defense & space',
            },
            {
                label: 'Design',
                value: 'design',
            },
            {
                label: 'E-learning',
                value: 'e-learning',
            },
            {
                label: 'Education management',
                value: 'education management',
            },
            {
                label: 'Electrical/electronic manufacturing',
                value: 'electrical/electronic manufacturing',
            },
            {
                label: 'Entertainment',
                value: 'entertainment',
            },
            {
                label: 'Environmental services',
                value: 'environmental services',
            },
            {
                label: 'Events services',
                value: 'events services',
            },
            {
                label: 'Executive office',
                value: 'executive office',
            },
            {
                label: 'Facilities services',
                value: 'facilities services',
            },
            {
                label: 'Farming',
                value: 'farming',
            },
            {
                label: 'Financial services',
                value: 'financial services',
            },
            {
                label: 'Fine art',
                value: 'fine art',
            },
            {
                label: 'Fishery',
                value: 'fishery',
            },
            {
                label: 'Food & beverages',
                value: 'food & beverages',
            },
            {
                label: 'Food production',
                value: 'food production',
            },
            {
                label: 'Fund-raising',
                value: 'fund-raising',
            },
            {
                label: 'Furniture',
                value: 'furniture',
            },
            {
                label: 'Gambling & casinos',
                value: 'gambling & casinos',
            },
            {
                label: 'Government administration',
                value: 'government administration',
            },
            {
                label: 'Government relations',
                value: 'government relations',
            },
            {
                label: 'Graphic design',
                value: 'graphic design',
            },
            {
                label: 'Health, wellness and fitness',
                value: 'health, wellness and fitness',
            },
            {
                label: 'Higher education',
                value: 'higher education',
            },
            {
                label: 'Hospital & health care',
                value: 'hospital & health care',
            },
            {
                label: 'Hospitality',
                value: 'hospitality',
            },
            {
                label: 'Human resources',
                value: 'human resources',
            },
            {
                label: 'Import and export',
                value: 'import and export',
            },
            {
                label: 'Individual & family services',
                value: 'individual & family services',
            },
            {
                label: 'Industrial automation',
                value: 'industrial automation',
            },
            {
                label: 'Information services',
                value: 'information services',
            },
            {
                label: 'Information technology and services',
                value: 'information technology and services',
            },
            {
                label: 'Insurance',
                value: 'insurance',
            },
            {
                label: 'International affairs',
                value: 'international affairs',
            },
            {
                label: 'International trade and development',
                value: 'international trade and development',
            },
            {
                label: 'Internet',
                value: 'internet',
            },
            {
                label: 'Investment banking',
                value: 'investment banking',
            },
            {
                label: 'Investment management',
                value: 'investment management',
            },
            {
                label: 'Judiciary',
                value: 'judiciary',
            },
            {
                label: 'Law enforcement',
                value: 'law enforcement',
            },
            {
                label: 'Law practice',
                value: 'law practice',
            },
            {
                label: 'Legal services',
                value: 'legal services',
            },
            {
                label: 'Legislative office',
                value: 'legislative office',
            },
            {
                label: 'Libraries',
                value: 'libraries',
            },
            {
                label: 'Logistics and supply chain',
                value: 'logistics and supply chain',
            },
            {
                label: 'Luxury goods & jewelry',
                value: 'luxury goods & jewelry',
            },
            {
                label: 'Machinery',
                value: 'machinery',
            },
            {
                label: 'Management consulting',
                value: 'management consulting',
            },
            {
                label: 'Maritime',
                value: 'maritime',
            },
            {
                label: 'Market research',
                value: 'market research',
            },
            {
                label: 'Marketing and advertising',
                value: 'marketing and advertising',
            },
            {
                label: 'Mechanical or industrial engineering',
                value: 'mechanical or industrial engineering',
            },
            {
                label: 'Media production',
                value: 'media production',
            },
            {
                label: 'Medical devices',
                value: 'medical devices',
            },
            {
                label: 'Medical practice',
                value: 'medical practice',
            },
            {
                label: 'Mental health care',
                value: 'mental health care',
            },
            {
                label: 'Military',
                value: 'military',
            },
            {
                label: 'Mining & metals',
                value: 'mining & metals',
            },
            {
                label: 'Motion pictures and film',
                value: 'motion pictures and film',
            },
            {
                label: 'Museums and institutions',
                value: 'museums and institutions',
            },
            {
                label: 'Music',
                value: 'music',
            },
            {
                label: 'Nanotechnology',
                value: 'nanotechnology',
            },
            {
                label: 'Newspapers',
                value: 'newspapers',
            },
            {
                label: 'Non-profit organization management',
                value: 'non-profit organization management',
            },
            {
                label: 'Oil & energy',
                value: 'oil & energy',
            },
            {
                label: 'Online media',
                value: 'online media',
            },
            {
                label: 'Outsourcing/offshoring',
                value: 'outsourcing/offshoring',
            },
            {
                label: 'Package/freight delivery',
                value: 'package/freight delivery',
            },
            {
                label: 'Packaging and containers',
                value: 'packaging and containers',
            },
            {
                label: 'Paper & forest products',
                value: 'paper & forest products',
            },
            {
                label: 'Performing arts',
                value: 'performing arts',
            },
            {
                label: 'Pharmaceuticals',
                value: 'pharmaceuticals',
            },
            {
                label: 'Philanthropy',
                value: 'philanthropy',
            },
            {
                label: 'Photography',
                value: 'photography',
            },
            {
                label: 'Plastics',
                value: 'plastics',
            },
            {
                label: 'Political organization',
                value: 'political organization',
            },
            {
                label: 'Primary/secondary education',
                value: 'primary/secondary education',
            },
            {
                label: 'Printing',
                value: 'printing',
            },
            {
                label: 'Professional training & coaching',
                value: 'professional training & coaching',
            },
            {
                label: 'Program development',
                value: 'program development',
            },
            {
                label: 'Public policy',
                value: 'public policy',
            },
            {
                label: 'Public relations and communications',
                value: 'public relations and communications',
            },
            {
                label: 'Public safety',
                value: 'public safety',
            },
            {
                label: 'Publishing',
                value: 'publishing',
            },
            {
                label: 'Railroad manufacture',
                value: 'railroad manufacture',
            },
            {
                label: 'Ranching',
                value: 'ranching',
            },
            {
                label: 'Real estate',
                value: 'real estate',
            },
            {
                label: 'Recreational facilities and services',
                value: 'recreational facilities and services',
            },
            {
                label: 'Religious institutions',
                value: 'religious institutions',
            },
            {
                label: 'Renewables & environment',
                value: 'renewables & environment',
            },
            {
                label: 'Research',
                value: 'research',
            },
            {
                label: 'Restaurants',
                value: 'restaurants',
            },
            {
                label: 'Retail',
                value: 'retail',
            },
            {
                label: 'Security and investigations',
                value: 'security and investigations',
            },
            {
                label: 'Semiconductors',
                value: 'semiconductors',
            },
            {
                label: 'Shipbuilding',
                value: 'shipbuilding',
            },
            {
                label: 'Sporting goods',
                value: 'sporting goods',
            },
            {
                label: 'Sports',
                value: 'sports',
            },
            {
                label: 'Staffing and recruiting',
                value: 'staffing and recruiting',
            },
            {
                label: 'Supermarkets',
                value: 'supermarkets',
            },
            {
                label: 'Telecommunications',
                value: 'telecommunications',
            },
            {
                label: 'Textiles',
                value: 'textiles',
            },
            {
                label: 'Think tanks',
                value: 'think tanks',
            },
            {
                label: 'Tobacco',
                value: 'tobacco',
            },
            {
                label: 'Translation and localization',
                value: 'translation and localization',
            },
            {
                label: 'Transportation/trucking/railroad',
                value: 'transportation/trucking/railroad',
            },
            {
                label: 'Utilities',
                value: 'utilities',
            },
            {
                label: 'Venture capital & private equity',
                value: 'venture capital & private equity',
            },
            {
                label: 'Veterinary',
                value: 'veterinary',
            },
            {
                label: 'Warehousing',
                value: 'warehousing',
            },
            {
                label: 'Wholesale',
                value: 'wholesale',
            },
            {
                label: 'Wine and spirits',
                value: 'wine and spirits',
            },
            {
                label: 'Wireless',
                value: 'wireless',
            },
            {
                label: 'Writing and editing',
                value: 'writing and editing',
            },
        ],
        inputPlaceholder: 'Accounting, Government, Management etc.',
    },
    {
        label: _mft('leadHunterCampaign:audience.fields.companySize.label'),
        field: 'company_size',
        type: 'options',
        options: [
            {
                label: '1-10',
                value: '1-10'
            },
            {
                label: '11-50',
                value: '11-50'
            },
            {
                label: '51-200',
                value: '51-200'
            },
            {
                label: '201-500',
                value: '201-500'
            },
            {
                label: '501-1000',
                value: '501-1000'
            },
            {
                label: '1001-5000',
                value: '1001-5000'
            },
            {
                label: '5001-10000',
                value: '5001-10000'
            },
            {
                label: '10001+',
                value: '10001+'
            },
        ],
        inputPlaceholder: '',
    },
    {
        label: _mft('leadHunterCampaign:audience.fields.location.label'),
        field: 'location',
        type: 'search',
        searchUrl: route('api.lead-hunter.locations'),
        inputPlaceholder: 'Texas, United States'
    }
])

const parameters = reactive({})
const initialSearchPerformed = ref(false)
const waiting = ref(false)
const leadCount = ref(0)
const leadCountDisplay = ref(0)
const leadCountRangeDisplay = ref('')
const estimatedActualLeadCount = ref(0)

const addFieldOption = (field, option) => {
    parameters[field.field].push(option)
}

const removeFieldOption = (field, option) => {
    parameters[field.field] = parameters[field.field].filter((value) => value.value !== option.value)
}

const performQueryWithDebounce = debounce(() => {
    performQuery()
}, 1200)

const performQuery = async () => {
    let mergedSearch = Object.values(parameters).join('')
    if(mergedSearch === '' && mergedSearch.length < 2) {
        return;
    }

    waiting.value = true
    emit('waiting', waiting.value)
    initialSearchPerformed.value = true
    emit('initialSearchPerformed', true)
    const res = await axios.post(route('api.lead-hunter.search'), parameters)
    if (res.data.success) {
        leadCount.value = res.data.lead_count
        leadCountDisplay.value = res.data.lead_count_display
        leadCountRangeDisplay.value = res.data.lead_count_range_display
        estimatedActualLeadCount.value = res.data.estimated_actual_lead_count
    }
    waiting.value = false
    emit('parameters-updated', parameters)
    emit('waiting', waiting.value)
    emit('leadCount', leadCount.value)
    emit('leadCountDisplay', leadCountDisplay.value)
    emit('leadCountRangeDisplay', leadCountRangeDisplay.value)
    emit('estimatedActualLeadCount', estimatedActualLeadCount.value)
}

const addSuggestion = (parameter, suggestion) => {
    parameter.push({label: suggestion, value: suggestion})
}

watch(() => parameters, (newVal) => {
    performQueryWithDebounce()
}, {deep: true})

onMounted(() => {
    searchFields.value.forEach((field) => {
        if(field.type === 'text') {
            parameters[field.field] = ''
        }

        if(field.type === 'multiText') {
            parameters[field.field] = []
        }

        if(field.type === 'options') {
            parameters[field.field] = []
        }

        if(field.type === 'search') {
            parameters[field.field] = []
        }
    })
})

</script>

<template>
    <div class="grid gap-8 pb-4 lg:grid-cols-1">
        <div
            v-if="Object.keys(parameters).length === searchFields.length"
            v-for="(field, i) in searchFields"
            :key="i"
        >
            <input-label :label="field.label"></input-label>

            <input-text
                v-if="field.type === 'text'"
                :placeholder="field.inputPlaceholder"
                v-model:content="parameters[field.field]"
            ></input-text>

            <multi-text-field
                v-if="field.type === 'multiText'"
                :field="field"
                :parameters="parameters"
                :placeholder="field.inputPlaceholder"
                @add-field-option="addFieldOption"
                @remove-field-option="removeFieldOption"
            ></multi-text-field>

            <options-field
                v-if="field.type === 'options'"
                :field="field"
                :parameters="parameters"
                :placeholder="field.inputPlaceholder"
                @add-field-option="addFieldOption"
                @remove-field-option="removeFieldOption"
            ></options-field>

            <search-field
                v-if="field.type === 'search'"
                :field="field"
                :search-url="field.searchUrl"
                :parameters="parameters"
                :placeholder="field.inputPlaceholder"
                @add-field-option="addFieldOption"
                @remove-field-option="removeFieldOption"
            ></search-field>

            <TitleCompletion
                v-if="field.type === 'multiText'"
                :input="parameters[field.field]"
                @suggestion-selected="addSuggestion(parameters[field.field], $event)"
            ></TitleCompletion>

        </div>

    </div>
</template>

<style scoped>

</style>
