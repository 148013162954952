<script setup>
import {onMounted, ref, computed, watch} from 'vue';
import axios from 'axios';
import ChannelForm from "./channelForm.vue";
import ChannelPreview from "./channelPreview.vue";


const props = defineProps({
    communityId: Number,
    channelId: Number,
});

const channel = ref({});
const community = ref({
    id: props.communityId,
    name: '',
});
const previewing = ref(false);
const savingState = ref('');

const updateChannel = async () => {
    savingState.value = 'waiting';
    await axios.put(route('community.admin.api.updateChannel', {community_id: props.communityId, channel_id: props.channelId}), channel.value);

    await getChannel();
    savingState.value = 'success';
}

async function getChannel() {
    const response = await axios.get(route('community.admin.api.channel.show', props.channelId));

    channel.value = {
        id: response.data.channel.id,
        name: response.data.channel.name,
        metaText: response.data.channel.meta_text,
        postCount: response.data.channel.posts_count,
        allowPostingFrom: response.data.channel.allow_posting_from ? response.data.channel.allow_posting_from.split(' ')[0] : null,
        allowPostingThrough: response.data.channel.allow_posting_through ? response.data.channel.allow_posting_through.split(' ')[0] : null,
        lockedForCommunityPosts: response.data.channel.locked_for_community_posts,
        trackAccountability: response.data.channel.accountability_enabled,
        trackProgress: response.data.channel.progress_tracking_enabled,
        postSubtitle: response.data.channel.post_subtitle,
        dailyCheckInQuestions: {
            questions: response.data.channel.accountability_questions_actions ? response.data.channel.accountability_questions_actions.questions : [],
        },
        reminderTime: response.data.channel.daily_check_in_reminder_time.split(":").slice(0, 2).join(":"),
        reminderDays: {
            monday: response.data.channel.daily_check_in_reminder_days.monday,
            tuesday: response.data.channel.daily_check_in_reminder_days.tuesday,
            wednesday: response.data.channel.daily_check_in_reminder_days.wednesday,
            thursday: response.data.channel.daily_check_in_reminder_days.thursday,
            friday: response.data.channel.daily_check_in_reminder_days.friday,
            saturday: response.data.channel.daily_check_in_reminder_days.saturday,
            sunday: response.data.channel.daily_check_in_reminder_days.sunday,
        }
    }

    community.value.name = response.data.community.name

}

onMounted(() => {
    getChannel();
});

</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar mb-8">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>{{ channel ? channel.name : '' }}</h1>

                    <div class="flex justify-end items-center space-x-4 mt-1 lg:mg-0">
                        <button-secondary
                            v-on:click="previewing = !previewing"
                        >
                            {{ previewing ? _mft('shared:action.edit') : _mft('shared:preview') }}
                        </button-secondary>

                        <button-success
                            :state="savingState"
                            v-on:click="updateChannel"
                        >
                            {{ _mft('shared:action.save') }}
                        </button-success>
                    </div>
                </div>

                <template #navigation>
                    <a
                        :href="route('community.admin.show', {id: community.id})+'#channels'"
                        class="mf-link text-xs"
                    >
                        &laquo; {{ community.name }}
                    </a>
                </template>

            </frosted-bar>
        </div>

        <div class="max-w-5xl mx-auto">
            <channel-preview
                v-if="previewing"
                :channel="channel"
            ></channel-preview>

            <channel-form
                v-else
                :channel="channel"
                @save="updateChannel"
                ui-type="card"
            ></channel-form>
        </div>
    </div>
</template>
