<script setup>

import {computed, nextTick, onMounted, ref, watch} from "vue";
import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";
import ButtonMoreOptionsOption from "../buttons/buttonMoreOptionsOption.vue";
import ButtonMoreOptionsOptionWithConfirmation from "../buttons/buttonMoreOptionsOptionWithConfirmation.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'

const notificationStore = useNotificationStore()

const emit = defineEmits(['update:email', 'remove', 'emailGenerated'])
const props = defineProps({
    campaignId: {
        type: Number,
        required: true,
    },
    email: {
        type: Object,
        required: true,
    },
    sequenceNumber: {
        type: Number,
        required: true,
    },
    previousSubject: {
        type: String,
        required: false,
        default: null
    },
    previousContent: {
        type: String,
        required: false,
        default: null
    }
})

const mailContentEl = ref(null)

const _email = ref(props.email)
const editing = ref(false)
const editingPage = ref(false)

const addingEmail = ref(false)
const addingPage = ref(false)
const translating = ref(false)

const updateEmail = () => {
    editing.value = false
    emit('update:email', _email.value)
}

const removeEmail = () => {
    editing.value = false
    emit('remove', _email.value)
}

const addPage = async () => {
    addingPage.value = true
    let res

    try {
        res = await axios.post(route('api.lead-hunter.campaign.generate-page', props.campaignId), {
            sequence_number: props.sequenceNumber,
            previous_subject: props.previousSubject,
            previous_body: props.previousContent,
        })

        _email.value.pageContent = res.data.content
        _email.value.pageEnabled = true
    } catch (e) {
        console.error('Failed to fetch page content')
        _email.value.pageContent = ''
        _email.value.pageEnabled = true
    }
    addingPage.value = false
}

const removePage = () => {
    editingPage.value = false
    _email.value.pageEnabled = false
    _email.value.pageContent = ''
}

const generateEmail = async () => {
    addingEmail.value = true
    let res

    try {
        res = await axios.post(route('api.lead-hunter.campaign.generate-email', props.campaignId), {
            sequence_number: props.sequenceNumber,
            previous_subject: props.previousSubject,
            previous_body: props.previousContent,
        })
    } catch (e) {
        console.error('Failed to fetch email content')
        addingEmail.value = false
        emit('emailGenerated')
        return
    }

    _email.value.subject = res.data.subject
    _email.value.content = res.data.body
    addingEmail.value = false
    emit('emailGenerated')
    scrollToElement()
}

const pendingTranslation = ref(false)

const translateEmail = async (language) => {
    let res

    try {
        pendingTranslation.value = true
        res = await axios.post(route('api.lead-hunter.campaign.translate-email'), {
            language: language,
            previous_subject: props.previousSubject,
            previous_body: props.previousContent,
        })
    } catch (e) {
        console.error('Failed to translate email content')
        addingEmail.value = false
        emit('emailGenerated')
        return
    }

    _email.value.subject = res.data.subject
    _email.value.content = res.data.body
    emit('emailGenerated')
    scrollToElement()
    pendingTranslation.value = false
    translating.value = false
}

const scrollToElement = () => {
    nextTick(() => {
        mailContentEl.value.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    })
}

const previewPage = () => {
    if (editingPage.value) { return; }
    window.open(route('lead-hunter.campaign.preview-page', [props.campaignId, props.sequenceNumber]), '_blank')
}

const sendPreview = async () => {
    const res = await axios.get(route('lead-hunter.campaign.send-preview-email', [props.campaignId, props.sequenceNumber]));

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('leadHunterCampaign:editEmail.sendPreview.success'),
            dismissAfter: 5000,
        })
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('leadHunterCampaign:editEmail.sendPreview.failed'),
            dismissAfter: 5000,
        })
    }

}

const emailContentPreview = computed(() => {
    return _email.value.content.replace(/\[page-link\]/g, '<span style="text-decoration: underline">https://your-campaign-link.example.com</span>')
})

watch(() => editingPage.value, (newVal) => {
    emit('update:email', _email.value)
})

onMounted(() => {
    if (_email.value.subject === '' && _email.value.content === '') {
        generateEmail()
    }
})

const userHasAddedLink = computed(() => {
    return _email.value.content?.includes('[page-link]') || false;
});

</script>

<template>
    <div
        v-if="addingEmail"
        class="pt-6 pb-12"
    >
        <mf-spinner-medium class="w-24 h-12" />
    </div>
    <div v-else>
        <div
            v-if="_email.delayDays > 0"
            class="flex justify-center"
        >
            <div class="h-16 mb-4 -mt-4 border-l-8 border-gray-200 lg:h-12"></div>
        </div>
        <p
            v-if="sequenceNumber > 0"
            class="flex items-center justify-center mb-0 text-lg text-center text-gray-500"
        >
            <span>{{ _mft('leadHunterCampaign:emailDelay.sentDaysAfterPreviousPrefix') }}</span>
            <span class="mx-0.5">
                <input type="number" class="text-lg text-center text-black bg-transparent border border-b border-gray-800 rounded-md outline-none" min="1" max="14" v-model="_email.delayDays" />
            </span>
            <span>{{ _mft('leadHunterCampaign:emailDelay.sentDaysAfterPreviousSuffix', {days: _email.delayDays}) }}</span>
        </p>
        <div
            v-if="sequenceNumber"
            class="flex justify-center"
        >
            <div class="h-16 mt-4 mb-4 border-l-8 border-gray-200 lg:h-12"></div>
        </div>

        <div class="md:grid md:grid-cols-2 md:gap-4" ref="mailContentEl">
            <card-basic class="overflow-visible">
                <div v-if="addingEmail">
                    <p class="text-center">
                        {{ _mft('leadHunterCampaign:email.generating') }}
                    </p>
                    <mf-spinner-medium class="w-12 h-6" />
                </div>
                <div v-else>
                    <h3 class="pb-2 mb-2 border-b">{{ _mft('leadHunterCampaign:email.title') }}</h3>
                    <div v-if="! editing">
                        <p class="mb-2 font-bold">
                            {{ _mft('leadHunterCampaign:emailSubject.title') }} {{ _email.subject }}
                        </p>
                        <div v-html="emailContentPreview"></div>
                    </div>
                    <div v-if="editing">
                        <input-text
                            :label="_mft('leadHunterCampaign:emailSubject.label')"
                            v-model:content="_email.subject"
                        ></input-text>

                        <input-editor
                            :content="_email.content"
                            @update:content="_email.content = $event"
                        ></input-editor>
                    </div>

                    <div class="flex items-center justify-end mt-2 space-x-4">
                        <button-success
                            v-if="editing"
                            @click="updateEmail"
                        >
                            {{ _mft('leadHunterCampaign:editEmail.saveChanges') }}
                        </button-success>
                        <button-primary
                            v-else
                            @click="editing = true"
                        >{{ _mft('leadHunterCampaign:editEmail.label') }}</button-primary>

                        <button-more-options
                            v-if="! editing"
                            alignment="right"
                            size="md"
                            options-class="min-w-48"
                        >
                            <button-more-options-option-with-confirmation
                                v-if="sequenceNumber > 0"
                                style-type="danger"
                                :confirm-text="_mft('leadHunterCampaign:email.delete.confirmText')"
                                :confirm-button-label="_mft('shared:action.delete')"
                                :abort-button-label="_mft('shared:action.cancel')"
                                @confirmed="removeEmail"
                            >
                                {{ _mft('shared:action.delete') }}
                            </button-more-options-option-with-confirmation>
                            <button-more-options-option
                                class="whitespace-nowrap"
                                @click="sendPreview"
                            >
                                {{ _mft('leadHunterCampaign:editEmail.sendPreview') }}
                            </button-more-options-option>
                            <button-more-options-option
                                class="whitespace-nowrap"
                                @click="translating = true"
                            >
                                Translate
                            </button-more-options-option>

                        </button-more-options>
                    </div>

                    <card-dashed
                        v-if="_email.pageEnabled === true"
                        class="mt-4 text-center"
                    >
                        <div>
                            <h6 v-if="userHasAddedLink" class="mb-0 font-semibold text-myflowGreen-700">
                                Great, campaign page link is included in the email
                            </h6>

                            <h6 v-if="!userHasAddedLink" class="mb-4 font-semibold text-myflowRed-700">
                                Oh no, you forgot to add the link to the campaign page!
                            </h6>
                        </div>

                        <p v-if="!userHasAddedLink" class="mt-4 text-gray-400">Add <code class="select-all">[page-link]</code> where you want the link to your Campaign Page to appear, and we will auto-magically update it with the correct link.</p>
                    </card-dashed>


                </div>
            </card-basic>

            <card-basic class="overflow-visible"
                v-if="_email.pageEnabled === true || addingPage"
                :class="addingPage ? 'flex items-center justify-center' : ''"
            >
                <div
                    v-if="addingPage"
                >
                    <p class="text-center">
                        {{ _mft('leadHunterCampaign:page.generating') }}
                    </p>
                    <mf-spinner-medium class="w-12 h-6" />
                </div>
                <div v-else>
                    <h3 class="pb-2 mb-2 border-b">{{ _mft('leadHunterCampaign:page.title') }}</h3>
                    <input-editor
                        v-if="editingPage"
                        :content="_email.pageContent"
                        @update:content="_email.pageContent = $event"
                    ></input-editor>
                    <div
                        v-else
                        v-html="_email.pageContent"
                    ></div>

                    <div class="flex items-center justify-end mt-2 space-x-4">
                        <button-success
                            v-if="editingPage"
                            @click="editingPage = false"
                        >
                            {{ _mft('leadHunterCampaign:editPage.saveChanges') }}
                        </button-success>
                        <button-primary
                            v-else
                            @click="editingPage = true"
                        >{{ _mft('leadHunterCampaign:editPage.label') }}</button-primary>

                        <button-more-options
                            alignment="right"
                            size="md"
                            options-class="min-w-48"
                        >
                            <button-more-options-option-with-confirmation
                                style-type="danger"
                                :confirm-text="_mft('shared:confirm.areYouSure')"
                                :confirm-button-label="_mft('shared:confirm.positive')"
                                :abort-button-label="_mft('shared:action.cancel')"
                                @confirmed="removePage"
                            >
                                {{ _mft('shared:action.delete') }}
                            </button-more-options-option-with-confirmation>
                            <button-more-options-option
                                class="whitespace-nowrap"
                                :class="editingPage ? 'cursor-not-allowed opacity-50' : ''"
                                @click="previewPage"
                            >
                                {{ _mft('shared:previewInNewTab') }}
                            </button-more-options-option>
                        </button-more-options>
                    </div>
                </div>
            </card-basic>

        </div>
    </div>

    <slideout-with-slots
        v-if="translating"
        @close="translating = false"
    >
        <template #body>
            <div v-if="!pendingTranslation">
                <h5 class="mb-3">Translate to:</h5>

                <button-base class="mb-3" v-on:click="translateEmail('Swedish')">
                    Swedish
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('English')">
                    English
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('French')">
                    French
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('German')">
                    German
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('Spanish')">
                    Spanish
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('Italian')">
                    Italian
                </button-base>
                <br>
                <button-base class="mb-3" v-on:click="translateEmail('Dutch')">
                    Dutch
                </button-base>
            </div>
            <div v-else>
                <mf-spinner-medium class="w-24 h-12" />
            </div>
        </template>

    </slideout-with-slots>
</template>

<style scoped>

</style>
