<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    currentPageId: {
        type: Number,
        required: false,
        default: null
    },
    route: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: _mft('shared:search')
    }
})

const emit = defineEmits(['page-selected'])

const candidatePages = ref([])
const pageId = ref(null)

const loadPageCandidates = async () => {
    const res = await axios.get(props.route)
    candidatePages.value = res.data.pages
}

const handleSelect = (selected) => {
    emit('page-selected', selected)
}

const currentPageObject = computed(() => {
    return candidatePages.value.find(page => page.id === pageId.value)
})

const placeholder = computed( () => {
    return currentPageObject.value ? currentPageObject.value.title : props.placeholder
})

watch(props, () => {
    pageId.value = props.currentPageId
})

onMounted(() => {
    loadPageCandidates()
    pageId.value = props.currentPageId
})

</script>

<template>
    <div>
        <input-searchable
            :searchableItems="candidatePages"
            searchableField="title"
            labelField="title"
            :placeholder="placeholder"
            v-model:content="currentPageObject"
            @selected="handleSelect"
        ></input-searchable>

    </div>
</template>

<style scoped>

</style>
