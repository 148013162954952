<script setup>
import MediaBankEntry from './util/media-bank-entry.vue';
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
})

const alignmentClass = computed(() => {
    if (props.content?.alignment === 'left') {
        return 'justify-start'
    }
    if (props.content?.alignment === 'center') {
        return 'justify-center'
    }
    if (props.content?.alignment === 'right') {
        return 'justify-end'
    }
    if (props.content?.alignment === 'fullwidth') {
        return 'justify-stretch'
    }

    return 'justify-center'
})

const roundingClass = computed(() => {
    if (props.content?.rounding === 'siteAppearance') {
        return 'rounded-wl'
    }
    if (props.content?.rounding === 'none') {
        return 'rounded-none'
    }
    if (props.content?.rounding === 'slight') {
        return 'rounded-lg'
    }
    if (props.content?.rounding === 'medium') {
        return 'rounded-3xl'
    }
    if (props.content?.rounding === 'very') {
        return 'rounded-5xl'
    }

    return 'rounded-wl'
})

const maxDimensionsStyle = computed(() => {
    const style = {}
    if (props.content.max_width && props.content.alignment !== 'fullwidth') {
        style.maxWidth = props.content.max_width + props.content.max_width_unit
    }
    return style
})

</script>
<template>
    <div>
        <div
            class="flex mx-auto mb-6 overflow-hidden rounded-custom shadow-custom"
            :class="alignmentClass"
        >
            <component
                :is="content.link_url? 'a' : 'div'"
                :href="content.link_url"
                target="_blank"
                class="text-inherit"
                :class="props.content?.alignment === 'fullwidth' ? 'w-full' : ''"
                :style="maxDimensionsStyle"

            >
                <div
                    class="overflow-hidden"
                    :class="roundingClass"
                >
                    <MediaBankEntry
                        v-if="content.source_type === 'mediaLibrary'"
                        :container-ref="containerRef"
                        :block-uuid="uuid"
                        :block-content="content"
                        :media-bank-entry-id="content.media_bank_entry_id"
                        :alt-text="content.alt_text"
                        property-name="media_bank_entry_id"
                        :class="props.content?.alignment === 'fullwidth' ? 'w-full' : ''"
                        :style="{aspectRatio: 1 / content.media_height_aspect}"
                    ></MediaBankEntry>
                    <img
                        v-if="content.source_type === 'pexels' || content.source_type === 'url'"
                        :alt="content.alt_text"
                        :src="content.media_url"
                        :style="{aspectRatio: 1 / content.media_height_aspect}"
                    />
                </div>
                <p
                    v-if="content.caption"
                    class="mt-1 text-xs text-center"
                >
                    {{ content.caption}}
                </p>
            </component>
        </div>
    </div>
</template>
