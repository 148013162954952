<script setup>
import {computed, ref, watchEffect} from 'vue';
import axios from 'axios';

const props = defineProps({
    slug: {
        type: String,
        required: true
    }
});

const isLoaded = ref(false);
const formData = ref(null);
const currentPage = ref(0);
const pageTransitioning = ref(false)
const pageDirection = ref('forward')
const transitionedOut = ref(false)

const nextPage = () => {
    if (currentPage.value === pagedForm.value.length - 1) {return}
    pageDirection.value = 'forward'
    pageTransitioning.value = true

    const formEl = document.getElementById('form-container');
    const offsetTop = formEl ? formEl.getBoundingClientRect().top + window.scrollY: 0;

    setTimeout(function(){
        currentPage.value++;
        transitionedOut.value = true

        window.scrollTo(0, offsetTop, {behavior: 'auto'})

        setTimeout(function(){
            transitionedOut.value = false
            pageTransitioning.value = false
        }, 300)
    }, 300)
}

const prevPage = () => {
    if (currentPage.value === 0) {return}
    pageDirection.value = 'backward'
    pageTransitioning.value = true

    const formEl = document.getElementById('form-container');
    const offsetTop = formEl ? formEl.getBoundingClientRect().top + window.scrollY: 0;

    setTimeout(function(){
        currentPage.value--;
        transitionedOut.value = true

        window.scrollTo(0, offsetTop, {behavior: 'auto'})

        setTimeout(function(){
            transitionedOut.value = false
            pageTransitioning.value = false
        }, 300)
    }, 300)
}

const maybeRedirect = (response) => {
    if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url
    }
}

watchEffect(async () => {
    if (!props.slug) {
        isLoaded.value = false;
        formData.value = null;
    }

    isLoaded.value = false;
    const response = await axios.get(route('form-submissions.create', props.slug));
    formData.value = response.data;
    isLoaded.value = true;
});

const pagedForm = computed(() => {
    if (!formData.value) {
        return null;
    }

    let pages = [];
    let page = [];

    formData.value.content.forEach((field, f) => {
        if (field.type === 'forms-page-break') {
            pages.push(page);
            page = [];
            return;
        }

        page.push(field);

        if (f === formData.value.content.length - 1) {
            pages.push(page);
        }
    });

    return pages
});

const formHasPageBreaks = computed(() => {
    return pagedForm.value.length > 1;
})

const transitionClass = computed(() => {
    let classes = ['opacity-0']

    if (pageDirection.value === 'forward') {
        if (transitionedOut.value) {
            classes.push('scale-95')
        } else {
            classes.push('scale-105')
        }
    } else {
        if (transitionedOut.value) {
            classes.push('scale-105')
        } else {
            classes.push('scale-95')
        }
    }

    return classes.join(' ');
})

</script>
<template>
    <div id="form-container">
        <axios-form
            v-if="isLoaded && formData"
            method="post"
            :action="route('form-submissions.store', formData.slug)"
            enctype="multipart/form-data"
            @success="maybeRedirect"
        >
            <div
                v-if="formHasPageBreaks"
            >
                <div
                    v-for="(page, p) in pagedForm"
                    class="transition-all duration-300"
                    :class="pageTransitioning ? transitionClass : ''"
                >
                    <div
                        :class="currentPage === p ? 'block' : 'hidden'"
                    >
                        <page-builder-view
                            :container-ref="formData.container_ref"
                            :content="page"
                            :design="formData.design"
                        ></page-builder-view>

                        <div class="flex space-x-2 justify-center">
                            <button-base
                                :style-type="currentPage === 0 ? 'disabled' : 'secondary'"
                                @click="prevPage"
                            >
                                {{ _mft('shared:previous') }}
                            </button-base>
                            <button-secondary
                                v-if="currentPage !== pagedForm.length - 1"
                                @click="nextPage"
                            >
                                {{ _mft('shared:next') }}
                            </button-secondary>
                            <button-primary
                                v-if="currentPage === pagedForm.length - 1"
                                type="submit"
                                class="inline-block"
                            >
                                {{ _mft('form:submit') }}
                            </button-primary>
                        </div>
                    </div>
                </div>
            </div>

            <page-builder-view
                v-else
                :container-ref="formData.container_ref"
                :content="formData.content"
                :design="formData.design"
            ></page-builder-view>

            <div
                v-if="! formHasPageBreaks"
                class="text-center mb-3"
            >
                <button-primary
                    type="submit"
                    class="inline-block"
                >
                    {{ _mft('form:submit') }}
                </button-primary>
            </div>
            <template #success-content>
                <div class="w-full flex justify-center items-center mt-4 md:mt-6 lg:mt-12">
                    <div
                        class="p-5"
                        v-if="formData.submit_complete_message"
                        v-html="formData.submit_complete_message"
                    >
                    </div>
                    <p
                        v-else
                        class="flex justify-center items-center p-5 shadow-custom rounded-custom tint-background"
                    >
                        {{ _mft('form:submitConfirmation') }}
                    </p>
                </div>
            </template>
        </axios-form>
        <div v-else-if="isLoaded" class="w-full flex justify-center items-center mt-4 md:mt-6 lg:mt-12">
            <p class="flex justify-center items-center p-5 shadow-custom rounded-custom tint-background">
                {{ _mft('form:notFound') }}
            </p>
        </div>
        <mf-spinner-medium v-else class="w-12 h-6"></mf-spinner-medium>
    </div>
</template>
