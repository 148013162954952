<script setup>
import { ref } from 'vue'

const linkFieldQr = ref(null)
const baseLink = ref('')
const downloadUrl = ref('')

const compileDownloadUrl = () => {
    downloadUrl.value = linkFieldQr.value.imgUrl;
}

</script>

<template>
    <div>
        <div>
            <label>
                Original URL:
            </label>
            <input-text
                v-model:content="baseLink"
                maxlength="1200"
            />

            <div
                class="transition-all duration-500"
                :class="baseLink ? '' : 'opacity-0'"
            >
                <div class="flex justify-center">
                    <vue-qr
                        id="link-field-qr"
                        ref="linkFieldQr"
                        :text="baseLink"
                        :size="380"
                        :correctLevel="3"
                        :callback="compileDownloadUrl"
                    ></vue-qr>
                </div>
                <div class="flex justify-center">
                    <a
                        download="myflow-qr.png"
                        :href="downloadUrl"
                    >
                        <button-base size="sm">
                            {{ _mft('linkField:qrCode.download') }}
                        </button-base>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
