<script setup>
import {reactive, ref, onMounted, computed, watch} from 'vue'

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
});

const meetingMinutes = computed(() => props.content.meetingLength);
const bookableUserUuid = computed(() => props.content.bookableUserUuid);
const bookingEventTitle = computed(() => props.content.bookingEventTitle);
const blockLocation = computed(() => props.containerRef);
const blockKey = computed(() => props.uuid);

const cronofyAccountId = ref(null)
const cronofyEmbedToken = ref(null)
const cronofyEnabled = ref(false)
const bufferMinutesAfter = ref(null)
const startDateTime = ref(null)
const endDateTime = ref(null)
const timezone = ref(null)
const cronofyError = ref(null)
const dateTimeSlotSelected = ref(false)
const displayBookingCalendar = ref(false)
const bookingCompleted = ref(false)
const completion = ref(null)
const emit = defineEmits(['bookingCompleted', 'bookingFailed'])

const selectedSlot = reactive({
    start: null,
    end: null,
    timezone: null,
    coach_cronofy_sub: null,
})

const client = reactive({
    name: null,
    email: null,
})

const loadBookingPrerequisites = async () => {
    const response = await axios.post(route('block-editor-block.booking.getMeetingBooking'), {
        block_location: blockLocation.value,
        block_key: blockKey.value,
        user_uuid: bookableUserUuid.value
    })
    if (!response.data.cronofyEnabled) {
        cronofyError.value = _mft('pagebuilderView:cronofy.error.cronofyNotEnabled')
        return;
    }

    bufferMinutesAfter.value = response.data.bufferMinutesAfter;
    startDateTime.value = response.data.startDate;
    endDateTime.value = response.data.endDate;
    cronofyAccountId.value = response.data.cronofyAccountId;
    cronofyEmbedToken.value = response.data.cronofyEmbedToken;
    timezone.value = response.data.timezone;

    cronofyEnabled.value = true
}

const onDateTimeSlotSelected = (dateTimeSlot) => {
    dateTimeSlotSelected.value = true;

    selectedSlot.start = dateTimeSlot.slot.start;
    selectedSlot.end = dateTimeSlot.slot.end;
    selectedSlot.timezone = dateTimeSlot.tzid;
    selectedSlot.coach_cronofy_sub = dateTimeSlot.slot.participants[0].sub;

    submitBooking();
}

const clearSelectedTimeSlot = () => {
    dateTimeSlotSelected.value = false;
    selectedSlot.start = null;
    selectedSlot.end = null;
    selectedSlot.timezone = null;
    selectedSlot.coach_cronofy_sub = null;
}

const submitBooking = async () => {
    const response = await axios.post(route('block-editor-block.booking.collectMeetingBooking'), {
        block_location: blockLocation.value,
        block_key: blockKey.value,
        user_uuid: bookableUserUuid.value,
        client_name: client.name,
        client_email: client.email,
        event_title: bookingEventTitle.value,
        calendarProvider: 'cronofy',
        start: selectedSlot.start,
        end: selectedSlot.end,
        timezone: selectedSlot.timezone,
        coach_cronofy_sub: selectedSlot.coach_cronofy_sub,
    })

    if (response.data.success) {
        completion.value = response.data;
        bookingCompleted.value = true
        emit('bookingCompleted', response.data);
    } else {
        emit('bookingFailed', response.data);
        alert('Booking failed, please try again later');
    }
}

const continueToBookingAvailable = computed(() => {
    return client.name && client.email && client.email.includes('@') && client.email.includes('.')
})

const blockConfigured = computed(() => {
    return bookableUserUuid.value && props.content.bookingEventTitle && props.content.meetingLength
})

const cancelBooking = () => {
    clearSelectedTimeSlot();
    displayBookingCalendar.value = false;
}

const continueToBooking = () => {
    if (!client.name || !client.email) {
        cronofyError.value = _mft('pagebuilderView:cronofy.error.enterNameAndEmailToContinue')
        return;
    }

    displayBookingCalendar.value = true;
}

const onCronofyError = (error) => {
    cronofyError.value = error;
}

watch(blockConfigured, (value) => {
    if (value) {
        loadBookingPrerequisites();
    }
})

onMounted(() => {
    if (blockConfigured.value) {
        loadBookingPrerequisites();
    }
})

</script>
<template>
    <div>
        <div v-if="blockConfigured">
            <mf-spinner-medium
                v-if="!cronofyEnabled && !cronofyError"
                class="w-24 h-12"
            />
            <div v-if="bookingCompleted">
                <card-basic
                    v-if="completion.success"
                    class="!text-black"
                >
                    <h3>{{ _mft('pagebuilderBlock:meetingBooking.confirmation.title') }}</h3>
                    <p>{{ _mft('pagebuilderBlock:meetingBooking.confirmation.message', {email: completion.confirmation.clientEmail, date: completion.confirmation.startDate }) }}</p>

                    <div class="border-t pt-2">
                        <div class="text-md font-bold">{{ completion.confirmation.eventTitle }}</div>
                        <div class="flex items-center my-1">
                            <i class="fa-regular fa-user text-lg text-gray-400 mr-2"></i> {{ completion.booking.providerName }}
                        </div>
                        <div class="flex items-center">
                            <i class="fa-regular fa-calendar text-lg text-gray-400 mr-2"></i> {{ completion.confirmation.startDate }} {{ completion.confirmation.startTime }} - {{ completion.confirmation.endTime }}
                        </div>
                    </div>
                    <!-- !! Also, add event details box here !! -->
                </card-basic>
                <p class="font-bold" v-else>{{ _mft('pagebuilderBlock:meetingBooking.error.message') }}</p>
            </div>
            <div v-if="!bookingCompleted && cronofyEnabled" id="block-cronofy-date-time-picker">
                <div
                    v-if="!displayBookingCalendar"
                    class="grid-cols-2 gap-4 md:grid"
                >
                    <div class="col-span-1">
                        <input-text
                            :label="_mft('pagebuilderView:cronofy.input.name')"
                            v-model:content="client.name"
                        />
                        <input-text
                            :label="_mft('pagebuilderView:cronofy.input.email')"
                            v-model:content="client.email"
                        />
                        <div
                            v-if="continueToBookingAvailable"
                            class="flex justify-center"
                        >
                            <button-primary
                                @click="continueToBooking"
                                class="inline-block w-full px-4 py-2 mt-2 overflow-hidden rounded-custom shadow-custom justify-center"
                            >
                                {{ _mft('pagebuilderView:cronofy.continueToBooking') }}
                            </button-primary>
                        </div>
                        <div
                            v-else
                        >
                            <div>
                                <button-base disabled>
                                    {{ _mft('pagebuilderView:cronofy.continueToBooking') }}
                                </button-base>
                                <p class="mt-3 italic">
                                    {{ _mft('pagebuilderView:cronofy.info.fillNameAndEmailToContinue') }}
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="col-span-1">
                        <dummy-blurred-calendar></dummy-blurred-calendar>
                    </div>
                </div>

                <div
                    v-if="displayBookingCalendar"
                >
                    <button-secondary
                        @click="cancelBooking"
                        v-if="displayBookingCalendar"
                    >
                        {{ _mft('pagebuilderView:cronofy.back') }}
                    </button-secondary>
                    <div class="bg-white text-gray-900 rounded-wl mt-4 pb-4">
                        <cronofy-date-time-picker
                            :class="dateTimeSlotSelected ? 'hidden' : ''"
                            :cronofy-account-id="cronofyAccountId"
                            :embed-token="cronofyEmbedToken"
                            :buffer-minutes-after="bufferMinutesAfter"
                            :start-datetime="startDateTime"
                            :end-datetime="endDateTime"
                            :timezone="timezone.value"
                            :requiredDuration="meetingMinutes"
                            @dateTimeSlotSelected="onDateTimeSlotSelected"
                            @cronofyError="onCronofyError"
                        ></cronofy-date-time-picker>
                    </div>
                </div>
            </div>
            <div v-if="cronofyError" class="text-center">
                {{ _mft('pagebuilderView:cronofy.anErrorOccurred') }}: {{ cronofyError }}
            </div>
        </div>
    </div>
</template>
<style>
</style>
