<script setup>

import {computed, onMounted, ref, watch} from "vue";

const emit = defineEmits(['update:value', 'update:result', 'update:resultString'])

const props = defineProps({
    size: {
        type: String,
        required: false,
        default: null,
        options: ['sm', 'md',]
    },
    textAlignment: {
        type: String,
        required: false,
        default: 'center',
    },
    widgetUuid: {
        type: String,
        required: true,
    }
})

const widget = ref(null)
const calculating = ref(false)
const showResult = ref(false)
const result = ref(null)
const inputValues = ref({})

const loadWidget = async (widgetUuid) => {
    const res = await axios.get(route('api.widget.show', widgetUuid))
    widget.value = res.data.widget

    Object.values(res.data.widget.inputs).forEach((field) => {
        inputValues.value[field.name] = {name: field.name, value: field.defaultValue}
    })
}

const calculateResult = async () => {
    calculating.value = true
    const res = await axios.post(route('api.widget.calculate', props.widgetUuid), {
        inputValues: inputValues.value
    })
    result.value = res.data
    emit('update:value', res.data.result)
    emit('update:result', result.value)

    const resultString = `${result.value.title} ${result.value.prefix} ${result.value.result} ${result.value.suffix} ${result.value.byline}`
    emit('update:resultString', resultString)

    showResult.value = true
    calculating.value = false
}

const clear = () => {
    showResult.value = false
    result.value = null
}

const cardSizeClass = computed(() => {
    const sizes = {
        sm: 'p-4 ',
        md: 'p-8',
    }
    return sizes[props.size] ?? null
})

const buttonSizeClass = computed(() => {
    const sizes = {
        sm: 'sm',
        md: 'md',
    }
    return sizes[props.size]
})

watch(() => props.widgetUuid, () => {
    loadWidget(props.widgetUuid)
})

onMounted(() => {
    loadWidget(props.widgetUuid)
})

</script>

<template>
    <div
        v-if="widget"
        class="rounded-wl"
        :class="cardSizeClass"
    >
        <div>
            <div v-if="! calculating && ! showResult">
                <div>
                    <div
                        v-for="(input, i) in widget.inputs"
                        :key="i"
                    >
                        <input-label
                            :label="input.label"
                            align="center"
                        ></input-label>
                        <input-number
                            input-class="text-center text-black"
                            v-model:content="inputValues[input.name].value"
                        ></input-number>
                    </div>
                </div>
                <div class="flex justify-center">
                    <button-primary
                        :size="buttonSizeClass"
                        :disabled="!Object.keys(widget.inputs).length"
                        @click="calculateResult"
                        class="w-full justify-center max-w-24"
                    >
                        {{ widget.buttonLabels.calculate }}
                    </button-primary>
                </div>
            </div>
            <div v-if="showResult">
                <div
                    class="text-center"
                >
                    <p
                        v-if="result.title"
                        class="text-lg mb-0"
                    >
                        {{ result.title }}
                    </p>
                    <div class="text-xl font-bold">
                        {{ result.prefix}}
                        {{ result.result }}
                        {{ result.suffix}}
                    </div>
                    <p
                        v-if="result.byline"
                    >
                        {{ result.byline }}
                    </p>
                </div>
                <div class="flex justify-center">
                    <button-base
                        :size="buttonSizeClass"
                        @click="clear"
                        class="text-black"
                    >
                        {{ widget.buttonLabels.back }}
                    </button-base>
                </div>
            </div>
        </div>
    </div>
    <div v-else><mf-spinner-medium class="w-12 h-6"></mf-spinner-medium></div>
</template>

<style scoped>

</style>
