<script setup>
import MediaBankEntry from './util/media-bank-entry.vue';
import SlideoutWithSlots from "../../../elements/slideoutWithSlots.vue";
import {computed, ref, watch} from "vue";

const emit = defineEmits(['close'])

const props = defineProps({
    open: {
        type: Boolean,
        required: false,
        default: false,
    },
    containerRef: {
        type: String,
        required: true,
    },
    uuid: {
        type: String,
        required: true,
    },
    content: {
        type: Object,
        required: true,
    },
})

const _open = ref(props.open)

const close = () => {
    _open.value = false
    emit('close')
}

const sidebarHasContent = computed(() => {
    return props.content.metaText || props.content.metaImage || props.content.metaVideo
})

watch(() => props.open, (value) => {
    _open.value = value
})

</script>

<template>
    <div v-if="sidebarHasContent">
        <div class="absolute top-6 -right-1 transform translate-y-0.5">
            <button-base
                @click="_open = true"
                style-type="transparent"
            >
                <i class="fa-solid fa-circle-info text-gray-700"></i>
            </button-base>
        </div>
        <slideout-with-slots
            v-if="_open"
            @close="close"
        >
            <template #body>
                <div class="space-y-4">
                    <div
                        v-if="content.metaText"
                        v-html="content.metaText"
                    ></div>

                    <MediaBankEntry
                        v-if="content.metaImage"
                        :container-ref="containerRef"
                        :block-uuid="uuid"
                        :block-content="content"
                        :media-bank-entry-id="content.metaImage"
                        property-name="metaImage"
                    ></MediaBankEntry>

                    <MediaBankEntry
                        v-if="content.metaVideo"
                        :container-ref="containerRef"
                        :block-uuid="uuid"
                        :block-content="content"
                        :media-bank-entry-id="content.metaVideo"
                        property-name="metaVideo"
                    ></MediaBankEntry>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>
