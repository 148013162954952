<script setup>

const emit = defineEmits(['markTaskSkipped', 'markTaskSent', 'openEmail'])

const props = defineProps({
    task: {
        type: Object,
        required: true
    }
})



</script>

<template>
    <div
        class="flex justify-between py-3"
    >
        <div
            class="grid w-full grid-cols-5 gap-4"
            :class="task.done ? 'text-gray-400' : ''"
        >
            <div class="flex col-span-3">
                <div class="w-full">
                    <div :class="{'line-through': task.done}">{{ task.name }}</div>
                    <div class="flex mt-1 space-x-2">
                        <a
                            :href="task.linkedin_url"
                            target="_blank"
                            class="text-lg leading-none hover:text-gray-500"
                        >
                            <i class="fa-brands fa-linkedin"></i>
                        </a>
                        <span
                            class="text-lg leading-none cursor-pointer hover:text-gray-500"
                            @click="emit('openEmail', task)"
                        >
                            <i class="fa-regular fa-envelope"></i>
                        </span>
                        <span
                            class="text-xs select-all truncate"
                        >{{ task.email }}</span>
                    </div>
                </div>
            </div>

            <div class="flex col-span-2">
                <div class="flex flex-col w-full">
                    <span>{{ task.job_title }}</span>
                    <div class="mt-1 text-xs">{{ task.company }}</div>
                    <small
                        class="text-myflowGreen-900"
                        v-if="task.leadExists"
                    >Already in your contacts</small>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-2 text-center">
            <button-base
                v-if="!task.done"
                @click="emit('markTaskSent',task)"
                size="sm"
                class="justify-center whitespace-nowrap"
            >
                Mark as sent
            </button-base>
            <button-danger
                v-if="!task.done"
                @click="emit('markTaskSkipped', task)"
                size="sm"
                class="justify-center whitespace-nowrap"
            >
                Skip
            </button-danger>
            <button-base
                v-if="task.done"
                size="sm"
                disabled
                class="justify-center whitespace-nowrap"
            >
                Mark as sent
            </button-base>
            <button-danger
                v-if="task.done"
                size="sm"
                disabled
                class="justify-center whitespace-nowrap"
            >
                Skip
            </button-danger>
        </div>
    </div>
</template>

<style scoped>

</style>
