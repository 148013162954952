<script setup>
import {ref, computed, watch} from 'vue'
import InputText from "../forms/inputText.vue";

const emit = defineEmits(['prompt-updated', 'color-updated'])

const props = defineProps({
    timeSpentPerDay: {
        type: Number,
    },
    dealSize: {
        type: Number,
    },
    option: {
        type: Object,
        required: false,
        default: null,
    },
    parameters: {
        type: Object,
        required: false,
        default: null,
    },
    presetPrompts: {
        type: Object,
        required: false,
        default: null,
    }
})

const prompts = ref({
    who: null,
    what: null,
    result: null,
})

const baseColor = ref('#ebebeb');
const websiteUrl = ref('')
const editingWebsiteUrl = ref(false)
const selectedPromptingOption = ref('neither')
const awaitingCurrentWebPrompt = ref(false)

const promptingOptions = [
    {
        label: 'Take my info from my website',
        name: 'website',
    },
    {
        label: 'I\'ll write my info myself',
        name: 'prompt',
    },
]

const setSelectedPromptingOption = (option) => {
    if (option.name === 'prompt') {
        if (props.presetPrompts) {
            prompts.value.who = props.presetPrompts.who
            prompts.value.what = props.presetPrompts.what
            prompts.value.result = props.presetPrompts.result
        }
    }

    selectedPromptingOption.value = option.name
}

const loadWebsiteData = () => {
    loadPromptFromWebsite()
    loadColorFromWebsite()
}

const loadPromptFromWebsite = async () => {
    awaitingCurrentWebPrompt.value = true
    const res = await axios.get(route('assistant.currentWebPrompt', {url: parsedWebsiteUrl.value.url}))
    prompts.value.who = res.data[0] ? res.data[0].description_of_author : ' '
    prompts.value.what = res.data[0] ? res.data[0].offer : ' '
    prompts.value.result = res.data[0] ? res.data[0].result : ' '

    awaitingCurrentWebPrompt.value = false
    selectedPromptingOption.value = 'prompt'
}

const loadColorFromWebsite = async () => {
    const res = await axios.post(route('assistant.getBrandColor'), {
        url: parsedWebsiteUrl.value.url
    })
    baseColor.value = res.data.color
    emit('color-updated', baseColor.value)
}

const parsedWebsiteUrl = computed(() => {
    let parsedUrl = {
        url: '',
        valid: false,
        message: '',
    }

    parsedUrl.url = websiteUrl.value.trim();
    if (parsedUrl.url && !/^https?:\/\//i.test(parsedUrl.url)) {
        parsedUrl.url = "https://" + parsedUrl.url;
    }

    try {
        new URL(parsedUrl.url);
        if (parsedUrl.url.includes('.')) {
            parsedUrl.valid = true
            parsedUrl.message = ''
        } else {
            parsedUrl.valid = false
        }
    } catch (e) {
        parsedUrl.valid = false
    }

    if (! parsedUrl.valid) {
        parsedUrl.message = 'That URL doesn\'t look right. Please check it and try again.'
    }

    return parsedUrl
})

const promptsEmpty = computed(() => {
    return ! prompts.value.who && ! prompts.value.what && ! prompts.value.result
})

watch(() => prompts.value, () => {
    emit('prompt-updated', prompts.value)
}, {deep: true})

</script>

<template>
    <div>
        <div class="mb-8">
            <div>
                <h2 class="mb-4 text-center md:text-3xl">
                    Tell us about yourself
                </h2>

                <div class="flex justify-center mb-0">
                    <button-bar
                        size="lg"
                        :options="promptingOptions"
                        :selectedOptionName="selectedPromptingOption"
                        @select="setSelectedPromptingOption"
                        class="!mb-2"
                    />
                </div>

                <div
                    v-if="selectedPromptingOption === 'website' && promptsEmpty"
                    class="pb-8"
                >
                    <div v-if="! awaitingCurrentWebPrompt">
                        <p class="text-lg mb-0">
                            Where can we find your website?
                        </p>
                        <div class="relative">
                            <div class="flex items-start justify-between space-x-4">
                                <input-text
                                    v-model:content="websiteUrl"
                                    placeholder="https://yourawesomesite.com"
                                    @focus="editingWebsiteUrl = true"
                                    @blur="editingWebsiteUrl = false"
                                    class="w-full !mb-0"
                                    autofocus
                                />
                                <button-primary
                                    size="md"
                                    class="whitespace-nowrap mt-1"
                                    :disabled="! parsedWebsiteUrl.valid"
                                    @click="loadWebsiteData"
                                >Gather info</button-primary>
                            </div>
                            <p
                                v-if="! editingWebsiteUrl && parsedWebsiteUrl.url.length > 0 && ! parsedWebsiteUrl.valid"
                                class="absolute top-full left-2.5 right-0 text-xs text-myflowRed"
                            >
                                {{ parsedWebsiteUrl.message}}
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="awaitingCurrentWebPrompt"
                        class="flex flex-col items-center justify-center py-4"
                    >
                        <p class="text-center">Please wait while we have a look at your website, this should only take a few moments.</p>
                        <mf-spinner-medium
                            class="w-24 h-12"
                        />
                    </div>
                </div>

                <div
                    v-if="selectedPromptingOption === 'prompt'"
                >
                    <p class="text-xs text-center">Don't worry, you can keep it fairly short, about 20 words is enough</p>
                </div>
            </div>


            <div
                v-if="selectedPromptingOption === 'prompt' || ! promptsEmpty"
                class="mb-10 md:mb-12 mt-8"
            >
                <h3 class="mb-1 text-center md:text-2xl">
                    Who are you?
                </h3>
                <p class="mb-2 text-center md:text-lg">
                    As much as we would love to know about your Star Wars collection, we are more interested in your professional background.
                </p>
                <input-textarea
                    v-model:content="prompts.who"
                    autofocus
                    rows="4"
                ></input-textarea>
            </div>

            <div
                v-if="selectedPromptingOption === 'prompt' || ! promptsEmpty"
                class="mb-10 md:mb-12"
            >
                <h3 class="mb-1 text-center md:text-2xl">
                    What do you offer?
                </h3>

                <p class="mb-2 text-center md:text-lg">
                    Tell us about the services you are offering.
                </p>
                <input-textarea
                    v-model:content="prompts.what"
                    rows="4"
                ></input-textarea>
            </div>

            <div
                v-if="selectedPromptingOption === 'prompt' || ! promptsEmpty"
                class="mb-10 md:mb-12"
            >
                <h3 class="mb-1 text-center md:text-2xl">
                    What results can your customers expect?
                </h3>

                <p class="mb-2 text-center md:text-lg">
                    After working with you, what can your customers expect?
                </p>
                <input-textarea
                    v-model:content="prompts.result"
                    rows="4"
                ></input-textarea>
            </div>

        </div>

    </div>
</template>

<style scoped>

@media screen and (min-width: 768px) {
    :deep(label) {
        font-size: 1.125rem !important;
    }
}

</style>
