<template>
    <div>
        <div>

            <label>
                Text to encode/decode:
            </label>
            <textarea
                class="myflow-basic-input"
                v-model="input"
                rows="5"
            ></textarea>

            <div class="flex space-x-4 space x-4 items center">
                <button-secondary
                    size="sm"
                    class="justify-center w-full"
                    @click="generate('Decode')"

                >
                    Decode (Read)
                </button-secondary>
                <button-secondary
                    size="sm"
                    class="justify-center w-full"
                    @click="generate('Encode')"

                >
                    Encode (Create)
                </button-secondary>
            </div>

            <div
                class="overflow-hidden transition-all duration-500"
                :class="output ? 'max-h-screen opacity-100 mt-8' : 'max-h-0 opacity-0 mt-4'"
            >
                <label>
                    Result <span v-if="mode !== null">({{ mode }}d):</span>
                </label>
                <textarea
                    class="myflow-basic-input"
                    readonly
                    v-model="output"
                    rows="5"
                ></textarea>
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const input = ref('')

const mode = ref(null)

const output = ref('')

const generate = (mode) => {
    if (mode === 'Encode') {
        output.value = encode()
        mode.value = 'Encode'
    } else {
        output.value = decode()
        mode.value = 'Decode'
    }
}

const encode = () => {
    return btoa(input.value)
}

const decode = () => {
    return atob(input.value)
}

</script>
