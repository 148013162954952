<script setup>

import {onMounted, ref} from "vue";

const emit = defineEmits(['update-widget-uuid'])

const props = defineProps({
    widgetUuid: {
        type: String,
        required: false,
        default: null,
    }
})

const _widgetUuid = ref(props.widgetUuid)
const externalWidgets = ref([]);

const loadExternalWidgets = async () => {
    const res = await axios.get(route('api.external-widgets.index'))
    externalWidgets.value = res.data.widgets
}

const selectWidget = (widgetUuid) => {
    _widgetUuid.value = widgetUuid
    emit('update-widget-uuid', widgetUuid)
}

onMounted(() => {
    loadExternalWidgets();
})

</script>

<template>
<div>
    <ul class="space-y-2">
        <li
            v-if="externalWidgets.length === 0"
        >
            You have no widgets to use here
        </li>
        <li
            v-for="(widget, w) in externalWidgets"
            :key="w"
            class="flex justify-between items-center space-x-4"
            :class="widget.uuid === widgetUuid ? 'font-bold' : ''"
        >
            <div>
                {{ widget.name }}
            </div>
            <div>
                <button-secondary
                    size="sm"
                    @click="selectWidget(widget.uuid)"
                >
                    {{ _mft('shared:action.use') }}
                </button-secondary>
            </div>
        </li>
    </ul>
</div>
</template>

<style scoped>

</style>
