<script setup>

import {ref, onMounted} from "vue";
import axios from "axios";

const loading = ref(true);

const modules = ref([
    {
        name: 'Web Pages',
        category: 'Website',
        enabled: false
    },
    {
        name: 'User Area Pages',
        category: 'Website',
        enabled: false
    },
    {
        name: 'Landing Pages',
        category: 'Website',
        enabled: false
    },
    {
        name: 'Coaching',
        category: 'Product',
        enabled: false
    },
    {
        name: 'Courses',
        category: 'Product',
        enabled: false
    },
    {
        name: 'Community',
        category: 'Product',
        enabled: false
    },
    {
        name: 'Newsletters',
        category: 'Email',
        enabled: false
    },
    {
        name: 'Automations',
        category: 'Email',
        enabled: false
    },
    {
        name: 'LeadHunter',
        category: 'Email',
        enabled: false
    },
    {
        name: 'Contacts',
        category: 'Toolbox',
        enabled: false
    },
    {
        name: 'Quotes & Agreements',
        category: 'Toolbox',
        enabled: false
    },
    {
        name: 'Time Tracking',
        category: 'Toolbox',
        enabled: false
    },
    {
        name: 'Payments',
        category: 'Toolbox',
        enabled: false
    },
    {
        name: 'Forms',
        category: 'Toolbox',
        enabled: false
    },
]);

const currentModules = ref([]);


onMounted(() => {
    fetchCurrentModules();
});

const fetchCurrentModules = async () => {
    const response = await fetch(route('modules.current'));
    const data = await response.json();
    currentModules.value = data;

    mapModules();

    loading.value = false;
};

const storeOrUpdate = async () => {
    try {
        const enabledModuleNames = modules.value
            .filter(module => module.enabled)
            .map(module => module.name);

        const response = await axios.post(route('modules.updateCurrentModules'), {
            modules: enabledModuleNames
        });

        window.location.reload();
    } catch (error) {
        toast.error('Failed to update modules');
    }
};

const mapModules = () => {
    modules.value.forEach(module => {
        if (Array.isArray(currentModules.value)) {
            module.enabled = currentModules.value.includes(module.name);
        } else if (typeof currentModules.value === 'object') {
            const moduleNames = Object.values(currentModules.value);
            module.enabled = moduleNames.includes(module.name);
        } else {
            module.enabled = false;
        }
    });
};

</script>

<template>
    <div class="w-full">

        <frosted-bar>
            <div class="flex items-center justify-between space-x-4 f">
                <h1>Modules</h1>

                <div class="flex items-center space-x-4">
                    <button-success
                        @click="storeOrUpdate()"
                    >
                        Save
                    </button-success>
                </div>
            </div>
        </frosted-bar>


        <div
            class="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-3"
            v-if="!loading"
        >
            <card-basic
                v-for="module in modules"
                :key="module.name"
            >
                <div class="flex items-center justify-between">
                    <div>
                        <h2>{{ module.name }}</h2>
                        <p class="text-sm italic text-gray-500">{{ module.category }}</p>
                    </div>
                    <div>
                        <toggle-switch
                            label=""
                            v-model:content="module.enabled"
                        />
                    </div>
                </div>
            </card-basic>
        </div>

        <div v-else>
            <div class="flex items-center justify-center h-full">
                <mf-spinner-medium
                    class="w-24 h-12"
                />
            </div>
        </div>

    </div>
</template>

<style scoped>

</style>
