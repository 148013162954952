<script setup>

import {computed} from "vue";
import Score from "./Score.vue";

const props = defineProps({
    scores: {
        type: Object,
        required: true,
    },
    showGrouped: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const groupedScores = computed(() => {
    let _scores = Object.values(props.scores).reverse()
    if (!props.showGrouped) {
        return _scores;
    }
    if (_scores.length === 0)  {
        return _scores;
    }

    let groupedScore = {
        answer: 0,
        type: 'number',
    };
    let textAnswers = []

    _scores.forEach((score, a) => {
        if (score.type === 'text') {
            textAnswers.push(score.answer)
            groupedScore.type = 'text'
        } else {
            groupedScore.answer += score.answer
        }

        groupedScore.type = score.type
    })

    if (groupedScore.type === 'text') {
        groupedScore.answer = textAnswers.join("\r\n")
    }

    return [groupedScore];
})

</script>

<template>
    <ul class="">
        <li
            v-for="(score, a) in groupedScores"
            :key="a"
        >
            <Score
                :score="score"
            />
        </li>
    </ul>
</template>

<style scoped>

</style>
