<script setup>

import {computed, onMounted, reactive, ref} from "vue";
import SendSettings from './_sendSettings.vue'
import SendSchedule from './_sendSchedule.vue'
import EmailEditor from './_emailEditor.vue'
import FrostedBar from "../../components/layout/pageHeadings/frostedBar.vue";
import { DocumentIcon, PlayIcon, PauseIcon, CheckCircleIcon } from "@heroicons/vue/outline"
import { PlayIcon as SolidPlayIcon } from "@heroicons/vue/solid"
import { useNotificationStore } from '@/stores/NotificationStore.js'

const notificationStore = useNotificationStore()
const emit = defineEmits(['update:campaign'])

const props = defineProps({
    campaign: {
        type: Object,
        required: true,
    },
    workingState: {
        type: String,
        required: false,
        default: ''
    }
})

const _campaign = ref(props.campaign)
const campaignStatusWorkingState = ref('')
const addingEmail = ref(false)
const authWindowOpened = ref(false)
const emailRecentlyConnected = ref(false)
const poller = ref(null)
const collectedLeads = ref({})

const addEmail = () => {
    if (_campaign.value.emails.length >= 5 || addingEmail.value === true) {
        return
    }
    addingEmail.value = true

    _campaign.value.emails.push({
        id: _campaign.value.emails.length + 1,
        subject: '',
        content: '',
        delayDays: 3,
        pageEnabled: false,
        pageContent: '',
    })
}

const removeEmail = (i) => {
    if (i === 0) {return;}
    _campaign.value.emails.splice(i, 1)
}

const updateCampaign = () => {
    emit('update:campaign', _campaign.value)
}

const runCampaign = () => {
    setCampaignStatus('running')

    notificationStore.addNotification({
        type: 'success',
        title: _mft('leadHunterCampaign:notification.launched.title'),
        message: _mft('leadHunterCampaign:notification.launched.message'),
        dismissAfter: 5000,
    })
}

const setCampaignStatus = (status) => {
    campaignStatusWorkingState.value = 'waiting'
    axios.put(route('api.lead-hunter.campaign.update-status', [_campaign.value.id, status]))
        .then(res => {
            if (res.data.success) {
                _campaign.value.status = status
                window.scrollTo(0, 0, {behavior: 'smooth'})
            }
        })
    campaignStatusWorkingState.value = 'success'
}

const updateEmail = (e, i) => {
    _campaign.value.emails[i] = e
    updateCampaign()
}

const openAuthWindow = (provider) => {
    let authorizationUrl = '';

    switch (provider) {
        case 'google':
            authorizationUrl = route('google.auth.redirect')
            break

        case 'outlook':
            return;
    }

    authWindowOpened.value = true
    const authWindow = window.open(authorizationUrl, "", `toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=500,height=800,top=0`);

    poller.value = setInterval(function() {
        if(authWindow.closed && authWindowOpened.value) {
            console.log('Successfully detected window closed')
        } else {
            console.log('oAuth window is still open (or blocked by browser)')
        }
        pollForConnectedEmail()
    }, 2000)
}


const pollForConnectedEmail = async () => {
    const res = await axios.get(route('api.lead-hunter.campaign.show', _campaign.value.id))

    if (res.data.campaign.has_connected_email === true) {
        clearInterval(poller.value)
        emailRecentlyConnected.value = true
        authWindowOpened.value = false

        notificationStore.addNotification({
            type: 'success',
            title: _mft('leadHunterCampaign:notification.emailConnected.title'),
            dismissAfter: 5000,
        })
    }
}

const contentValidates = computed(() => {
    let validates = true

    _campaign.value.emails.forEach(email => {
        if (!email.subject || !email.content) {
            validates = false
        }
        if (email.pageEnabled && email.pageContent === '') {
            validates = false
        }
    })

    return validates
})

</script>

<template>
    <div>
        <div
            v-if="! campaign.loaded"
            class="flex flex-col items-center justify-center h-screen"
        >
            <div class="text-center">
                <mf-spinner-medium class="w-12 h-6 mb-8"></mf-spinner-medium>
                {{ _mft('leadHunterCampaign:summary.loading') }}
            </div>
        </div>
        <div v-else>
            <div class="container w-full mx-auto md:sticky top-2 z-topbar">
                <frosted-bar>
                    <div class="items-center justify-between space-x-4 md:flex">
                        <div class="flex items-center space-x-2">
                            <div class="w-12">
                                <DocumentIcon
                                    v-if="campaign.status === 'draft'"
                                    class="w-8 h-8 text-gray-400"
                                />
                                <PlayIcon
                                    v-if="campaign.status === 'running'"
                                    class="w-12 h-12 text-myflowGreen-500"
                                />
                                <PauseIcon
                                    v-if="campaign.status === 'paused'"
                                    class="w-12 h-12 text-gray-500"
                                />
                                <CheckCircleIcon
                                    v-if="campaign.status === 'finished'"
                                    class="w-12 h-12 text-myflowGreen-500"
                                />
                            </div>
                            <h1 class="overflow-ellipsis line-clamp-1">{{ campaign.name }}</h1>
                        </div>
                        <div class="flex items-center justify-end space-x-2">
                            <slot name="toolbar">
                                <button-secondary
                                    v-if="campaign.status === 'running'"
                                    @click="setCampaignStatus('paused')"
                                    :state="campaignStatusWorkingState"
                                >
                                    <span class="whitespace-nowrap">{{ _mft('leadHunterCampaign:summary.running.pause') }}</span>
                                </button-secondary>
                                <button-secondary
                                    v-if="campaign.status !== 'running'"
                                    @click="setCampaignStatus('running')"
                                    :state="campaignStatusWorkingState"
                                >
                                    <span class="whitespace-nowrap">Start</span>
                                </button-secondary>
                            </slot>
                            <button-success
                                :disabled="! contentValidates"
                                @click="updateCampaign"
                                :state="workingState"
                            >{{ _mft('shared:action.save') }}</button-success>
                        </div>
                    </div>
                </frosted-bar>
            </div>

            <card-basic
                v-if="campaign.status !== 'finished'"
                class="mt-8 lg:mt-12"
            >
                <div class="mx-auto mb-4 md:grid md:grid-cols-2 md:gap-8">
                    <div>
                        <div v-if="campaign.status !== 'finished'">
                            <h1>Campaign Overview</h1>
                        </div>

                        <div v-if="campaign.status === 'finished'">
                            <h1>{{ _mft('leadHunterCampaign:summary.finished.title') }}</h1>
                        </div>

                        <div class="pt-2 mt-4 border-t">
                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.locations.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="location in campaign.lead_search.location"
                                    :key="location"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ location.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.location.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.jobTitles.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="job_title in campaign.lead_search.job_title"
                                    :key="job_title"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ job_title.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.job_title.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companySize.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="company_size in campaign.lead_search.company_size"
                                    :key="company_size"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ company_size.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_size.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companyIndustry.title') }}</h5>
                            <ul class="flex flex-wrap mb-2">
                                <li
                                    v-for="company_industry in campaign.lead_search.company_industry"
                                    :key="company_industry"
                                >
                                    <span class="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded-md">{{ company_industry.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_industry.length === 0"
                                    class="text-xs text-gray-400"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </card-basic>

        </div>

    </div>
</template>

<style scoped>

</style>
