<script setup>
import {computed} from "vue";

const props = defineProps({
    score: {
        type: Object,
        required: true,
    }
})

const _score = computed(() => {
    if (props.score.type) {
        if (props.score.type === 'boolean') {
            if (props.score.answer === false || props.score.answer === 0) {
                return 0
            }
            return props.score.answer === true ? 1 : props.score.answer
        }
    }

    return props.score.answer
})

</script>

<template>
    <div
        class="whitespace-pre"
        :class="props.score.type === 'text' ? '' : 'text-right'"
        :title="score?.post?.date"
    >
        <div class="flex justify-between">
            <span class="text-gray-400">
                {{ score.post ? _mft('calendar:weekday.'+score.post.weekday.toLowerCase()+'.short') : '' }}</span>
            <span>{{ _score ? _score : '-' }}</span>
        </div>
    </div>
</template>

<style scoped>

</style>
