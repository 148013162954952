<script setup>
import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
})

const formsLoaded = ref(false)
const forms = ref([])
const duplicatingForm = ref({
    id: '',
    title: '',
    published: true,
})

const loadForms = async () => {
    const res = await axios.get(route('api.form.index'))
    forms.value = res.data.forms
    formsLoaded.value = true
}

const selectForDuplication = (form) => {
    duplicatingForm.value.id = form.id
    duplicatingForm.value.title = form.title + ' (copy)'
    duplicatingForm.value.published = form.published
}

const duplicateForm = async () => {
    const res = await axios.post(route('api.form.duplicate', duplicatingForm.value.id), {
        title: duplicatingForm.value.title,
        published: duplicatingForm.value.published,
    })

    if (res.data.success) {
        window.location.href = route('forms.edit', res.data.form.id)
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:domainNameSingular'),
            message: _mft('error:error.generalTryAgain'),
            dismissAfter: 5000,
        })
    }
}

const deleteForm = async (form) => {
    const res = await axios.delete(route('api.form.destroy', form.id))
    if (res.data.success) {
        await loadForms()
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:domainNameSingular'),
            message: _mft('form:form.delete.error'),
            dismissAfter: 5000,
        })
    }
}
onMounted(() => {
    loadForms()
})

</script>
<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>{{ _mft('form:form.domainNamePlural') }}</h1>

                    <div>
                        <div class="flex items-center justify-end space-x-2">
                            <a
                                :href="route('forms.create')"
                            >
                                <button-primary>{{ _mft('form:createForm') }}</button-primary>
                            </a>
                        </div>
                    </div>
                </div>
            </frosted-bar>
        </div>

        <div class="mt-12">
            <div
                v-if="! formsLoaded"
                class="flex justify-center"
            >
                <mf-spinner-medium class="w-24 h-12" />
            </div>

            <list-basic
                v-else
            >
                <list-basic-item
                    v-if="forms.length === 0"
                >
                    <div class="text-center  text-gray-400"><mf-translate domainkey="form:form.noForms"></mf-translate></div>
                </list-basic-item>

                <list-basic-item
                    v-for="(form, f) in forms"
                    :key="f"
                >
                    <template #status>
                        <i
                            v-if="form.published"
                            class="fa-solid fa-circle text-myflowGreen mr-4 mt-1.5"
                        ></i>
                        <i
                            v-else
                            class="fa-solid fa-circle text-myflowRed mr-4 mt-1.5"
                        ></i>
                    </template>
                    <template #title>
                        <a :href="route('forms.edit', form.id)">
                            {{ form.title }}
                        </a>
                    </template>
                    <template #default>
                        <div class="text-xs">
                            <div class="flex space-x-4">
                                <a
                                    :href="route('forms.show', form.id)"
                                    class="mf-link lowercase"
                                >
                                    {{ _mft('form:form.submissions', {count: form.submission_count}) }}
                                </a>
                                <a
                                    :href="route('forms.statistics', form.id)"
                                    class="mf-link"
                                >
                                    {{ _mft('form:statistics.title') }}
                                </a>
                            </div>
                            <div class="text-gray-500 select-all">
                                {{ form.published ? siteRoute('form-submissions.create', form.slug) : _mft('form:form.link.notPublic') }}
                            </div>
                        </div>
                    </template>
                    <template #actions>
                        <div>
                            <a
                                v-if="form.published"
                                :href="siteRoute('form-submissions.create', form.slug)"
                                target="_blank"
                            >
                                <button-secondary
                                    size="sm"
                                >
                                    {{ _mft('shared:action.show') }}
                                    <i class="fa-regular fa-arrow-up-right-from-square text-xxs ml-0.5 text-gray-400"></i>
                                </button-secondary>
                            </a>
                        </div>
                        <div class="flex space-x-1 mb-1">
                            <button-more-options
                                size="sm"
                                alignment="right"
                            >
                                <button-more-options-option
                                    @click="selectForDuplication(form)"
                                    size="sm"
                                >
                                    {{ _mft('shared:action.duplicate') }}
                                </button-more-options-option>
                                <button-more-options-option-with-confirmation
                                    size="sm"
                                    style-type="danger"
                                    class="min-w-48"
                                    :confirm-text="_mft('form:form.delete.confirm.collectedData')"
                                    :confirm-button-label="_mft('form:form.delete.label')"
                                    @confirmed="deleteForm(form)"
                                >
                                    {{ _mft('shared:action.delete') }}
                                </button-more-options-option-with-confirmation>
                            </button-more-options>
                            <a :href="route('forms.edit', form.id)">
                                <button-primary
                                    size="sm"
                                >
                                    {{ _mft('shared:action.edit') }}
                                </button-primary>
                            </a>
                        </div>

                    </template>
                </list-basic-item>
            </list-basic>
        </div>
        <slideout-with-slots
            v-if="duplicatingForm.id"
            @close="resetDuplication"
        >
            <template #title>
                <h2>{{ _mft('form:duplicateForm') }}</h2>
            </template>
            <template #body>
                <div>
                    <input-text
                        :label="_mft('form:form.title')"
                        v-model:content="duplicatingForm.title"
                        autofocus
                    />
                    <div class="flex justify-end">
                        <button-success
                            @click="duplicateForm"
                        >
                            {{ _mft('form:duplicateForm') }}
                        </button-success>
                    </div>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>
