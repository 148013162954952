<script setup>
import {ref, onMounted, computed} from 'vue'
    import axios from 'axios';

    const props = defineProps({
        options: {
            type: Object,
            required: false,
            default: () => {},
        }
    })

    const pageSize = 2;
    const urls = ref(null)
    const stagedForDeletion = ref(null)
    const newUrl = ref(null)
    const page = ref(0)

    const stageForDeletion = (id) => {
        stagedForDeletion.value = id
    }

    async function getUrls() {
        const response = await axios.get(route('utility.short-links.get'));
        urls.value = response.data.data;
    }

    async function deleteUrl(id) {
        await axios.post(route('utility.short-links.destroy', id));
        await getUrls();
    }

    async function addUrl() {
        await axios.post(route('utility.short-links.store'), {
            url: newUrl.value,
        });
        newUrl.value = '';
        await getUrls();
    }

    const paginatedUrls = computed(() => {
        if (!urls.value) {
            return [];
        }

        if (props.options?.paginate !== true) {
            console.log('Not paginating');
            return urls.value;
        }

        return urls.value.slice(page.value * pageSize, (page.value * pageSize) + pageSize);
    })

    onMounted(() => {
        getUrls();
    })

</script>
<template>
    <div>
        <div>
            <label>
                New short URL
            </label>
            <input-text
                v-model:content="newUrl"
                placeholder="URL to shorten"
                maxlength="255"
            />
            <div class="flex justify-end mb-2 -mt-2">
                <button-secondary
                    size="sm"
                    @click="addUrl"
                >
                    Create Short URL
                </button-secondary>
            </div>

            <div>
                <h5>Shortened URLs</h5>
                <ul class="divide-y">
                    <li
                        v-for="url in paginatedUrls"
                        :key="url.id"
                        class="pt-1 mt-2 mb-2 text-xs"
                    >
                        <div class="grid grid-cols-5 mb-2" style="overflow-wrap: break-word;">
                            <div class="inline-block pr-2 text-right text-gray-500 shrink-0 whitespace-nowrap">Short:</div>
                            <div class="col-span-3 select-all" style="overflow-wrap: break-word;">{{ url.short_url }}</div>
                        </div>

                        <div class="grid grid-cols-5 mb-2" style="overflow-wrap: break-word;">
                            <div class="inline-block pr-2 text-right text-gray-500 shrink-0 whitespace-nowrap">Custom:</div>
                            <div class="col-span-3 select-all" style="overflow-wrap: break-word;">{{ url.custom_url }}</div>
                        </div>

                        <div class="grid grid-cols-5" style="overflow-wrap: break-word;">
                            <div class="inline-block pr-2 text-right text-gray-500 whitespace-nowrap">Original:</div>
                            <div class="col-span-3 select-all">{{ url.original_url }}</div>
                        </div>
                        <div class="text-center">
                            <small>
                                Created {{ url.created_at }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                <span
                                    class="underline cursor-pointer text-myflowRed-900"
                                    @click="stageForDeletion(url.id)"
                                >
                                    Delete
                                </span>
                            </small>
                        </div>
                        <div
                            v-if="url.id === stagedForDeletion"
                            class="mt-2 mb-2 text-center"
                        >
                            <p class="mb-3 text-myflowRed-900">When deleted, this URL will no longer be accessible.</p>
                            <button-danger
                                @click="deleteUrl(url.id)"
                            >
                                Delete
                            </button-danger><br>
                        </div>
                    </li>
                </ul>
                <div
                    v-if="urls && props.options?.paginate === true"
                    class="grid grid-cols-3 gap-4justify-center mt-4 w-48 mx-auto"
                >
                    <div class="flex justify-end">
                        <button-secondary
                            v-if="page > 0"
                            size="sm"
                            @click="page--"
                        >
                            <i class="fa-light fa-chevron-left"></i>
                        </button-secondary>
                    </div>
                    <div class="flex items-center justify-center">
                        <p class="mb-0 text-center text-xs text-gray-400">{{ page + 1 }} / {{ Math.ceil(urls.length / 3) }}</p>
                    </div>
                    <div class="flex justify-start">
                        <button-secondary
                            v-if="urls.length > ((page + 1) * 3)"
                            size="sm"
                            @click="page++"
                        >
                            <i class="fa-light fa-chevron-right"></i>
                        </button-secondary>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
