<script setup>

import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    logoUrl: {
        type: String,
        required: false,
        default: null
    }
})

const savingState = ref('')
const navigatables = ref(null)
const navigationItems = ref([])
const creatingNavigationItem = ref(false)
const editingNavigationItem = ref(null)
const movingNavigationItem = ref(null)
const iconSelectorOpen = ref(false)
const defaultFrontPageId = ref(null)

const targetLabels = {
    app_page: 'Page',
}

const colors = ref({
    primaryBackgroundColor: '#fefefe',
    primaryForegroundColor: '#171717',
    secondaryBackgroundColor: '#fafafa',
    secondaryForegroundColor: '#050505',
    pinstripeColor: '#d1d1d1',
})

const loadSettings = async () => {
    try {
        const res = await axios.get(route('api.manage-whitelabel-app.settings'));
        navigationItems.value = res.data.navigation;
        if (res.data.colors.primaryForegroundColor) {
            colors.value.primaryForegroundColor = res.data.colors.primaryForegroundColor;
        }
        if (res.data.colors.primaryBackgroundColor) {
            colors.value.primaryBackgroundColor = res.data.colors.primaryBackgroundColor;
        }
        if (res.data.colors.secondaryForegroundColor) {
            colors.value.secondaryForegroundColor = res.data.colors.secondaryForegroundColor;
        }
        if (res.data.colors.secondaryBackgroundColor) {
            colors.value.secondaryBackgroundColor = res.data.colors.secondaryBackgroundColor;
        }
        if (res.data.colors.pinstripeColor) {
            colors.value.pinstripeColor = res.data.colors.pinstripeColor;
        }
        if (res.data.default_front_page_id) {
            defaultFrontPageId.value = res.data.default_front_page_id;
        }
    } catch (error) {
        console.error(error);
    }
}

const loadNavigatables = async () => {
    try {
        const res = await axios.get(route('api.manage-whitelabel-app.navigation-targets'));
        navigatables.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

const updateNavigation = async () => {
    savingState.value = 'waiting'
    try {
        const res = await axios.post(route('api.manage-whitelabel-app.settings.store'), {
            navigation: navigationItems.value,
            colors: colors.value,
            default_front_page_id: defaultFrontPageId.value,
        });

        if (res.data.success) {
            savingState.value = 'success'
        }
    } catch (error) {
        console.error(error);
        savingState.value = 'error'
    }
}

const editNavigationItem = (item) => {
    loadNavigatables();
    editingNavigationItem.value = item
}

const addNavigationItem = () => {
    creatingNavigationItem.value = true

    let newNavigationitem = {
        label: '',
        targetType: null,
        targetId: null,
        icon: 'fa-light fa-font-awesome',
    }

    editNavigationItem(newNavigationitem)
}

const startMovingNavigationItem = (item) => {
    movingNavigationItem.value = item
}

const moveNavigationItem = (item) => {
    if (movingNavigationItem.value === null) {
        return
    }

    const movingIndex = navigationItems.value.indexOf(movingNavigationItem.value)
    const targetIndex = navigationItems.value.indexOf(item)

    const movingItem = JSON.parse(JSON.stringify(movingNavigationItem.value))
    const targetItem = JSON.parse(JSON.stringify(item))

    navigationItems.value[movingIndex] = targetItem
    navigationItems.value[targetIndex] = movingItem

    movingNavigationItem.value = null
}

const deleteNavigationItem = (item) => {
    const index = navigationItems.value.indexOf(item)
    navigationItems.value.splice(index, 1)
    editingNavigationItem.value = null
}

const maybeFinishEditingNavigationItem = () => {
    if (navigationItemValidates.value) {
        if (creatingNavigationItem.value) {
            navigationItems.value.push(editingNavigationItem.value)
            creatingNavigationItem.value = false
        }

        editingNavigationItem.value = null
    }
}

const cancelEditingNavigationItem = () => {
    creatingNavigationItem.value = null
    editingNavigationItem.value = null
}

const setItemTarget = (item, target) => {
    item.targetType = target.type
    item.targetId = target.id
    if (item.label === '') {
        item.label = target.title
    }
}

const chromeStyle = computed(() => {
    return {
        backgroundColor: colors.value.secondaryBackgroundColor,
        color: colors.value.secondaryForegroundColor,
        borderColor: colors.value.pinstripeColor
    }
})

const backdropStyle = computed(() => {
    return {
        backgroundColor: colors.value.primaryBackgroundColor,
        color: colors.value.primaryForegroundColor
    }
})

const primaryNavigationItems = computed(() => {
    return navigationItems.value?.slice(0,primaryNavigationSlotCountAvailable.value) || []
})

const secondaryNavigationItems = computed(() => {
    return navigationItems.value?.slice(primaryNavigationSlotCountAvailable.value) || []
})

const navigationItemValidates = computed(() => {
    return editingNavigationItem.value.label.length > 0 && editingNavigationItem.value.targetId !== null
})

const showMoreButton = computed(() => {
    if (! navigationItems.value) {
        return false
    }
    return navigationItems.value.length > 4
})

const primaryNavigationSlotCountAvailable = computed(() => {
    return showMoreButton.value ? 3 : 4
})

watch(editingNavigationItem, () => {
    if (editingNavigationItem.value) {
        iconSelectorOpen.value = false
    }
}, {deep: true})

onMounted(() => {
    loadSettings();
    loadNavigatables();
});

</script>

<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>App design</h1>
                <button-success
                    :state="savingState"
                    @click="updateNavigation"
                >Save and publish</button-success>
            </div>
        </frosted-bar>
        <div class="pt-16 grid lg:grid-cols-2 gap-4">
            <div>
                <div class="flex flex-col items-center justify-center">
                    <div
                        class="w-full max-w-xs aspect-portraitVideo relative rounded-2xl shadow-2xl"
                        :style="backdropStyle"
                    >
                        <div
                            class="absolute top-0 left-0 right-0 h-14 border-b rounded-t-2xl transform -translate-y-px flex items-center justify-center font-bold tracking-wide"
                            :style="chromeStyle"
                        >Title</div>

                        <div class="absolute top-14 bottom-16 left-0 right-0 p-3 text-xs">
                            <img
                                v-if="logoUrl"
                                :src="logoUrl"
                                class="w-2/3 mx-auto mb-4"
                                alt="Logo"
                            />
                            <p>
                                Aliqua sint irure sunt ipsum. Irure sunt ipsum irure. Ipsum irure, pariatur id non enim sed. Id non enim sed officia qui dolor enim. Enim sed officia qui dolor enim, dolore. Qui dolor enim dolore ad amet id laboris. Enim dolore ad amet.
                            </p>
                            <h2>Et aliqua</h2>
                            <p>
                                Adipiscing esse magna eu amet nisi fugiat. Magna eu, amet nisi. Nisi fugiat voluptate sit. Voluptate sit mollit proident. Mollit, proident mollit voluptate exercitation culpa.
                            </p>
                        </div>

                        <div class="absolute bottom-0 left-0 right-0">
                            <div
                                class="relative border-t flex justify-around items-stretch h-16 rounded-b-2xl tranform translate-y-px"
                                :style="chromeStyle"
                            >
                                <div
                                    v-for="(item, i) in primaryNavigationItems"
                                    :key="i"
                                    class="w-1/5 flex flex-col justify-center items-center cursor-pointer relative group first:text-wlPrimary"
                                >
                                    <i
                                        :class="[movingNavigationItem ? 'opacity-20' : '', 'fa-'+item.icon]"
                                        class="fa-light text-lg"
                                    ></i>
                                    <div
                                        :class="movingNavigationItem ? 'opacity-20' : ''"
                                        class="w-full text-center text-xxs truncate"
                                    >{{ item.label }}</div>

                                    <div
                                        v-if="movingNavigationItem"
                                        class="absolute inset-0 flex justify-center items-center hover:bg-gray-100/50"
                                        @click="moveNavigationItem(item)"
                                    >
                                        <i class="fa-solid fa-arrow-down-to-dotted-line text-xl transform -translate-y-1"></i>
                                    </div>

                                    <div
                                        v-if="! movingNavigationItem"
                                        class="hidden group-hover:flex absolute -top-2 right-0 left-0 items-center space-x-1 z-10"
                                    >
                                        <button
                                            class="bg-gray-100 text-xs rounded-md px-1 py-0.5 space-x-2 text-gray-500"
                                            @click="startMovingNavigationItem(item)"
                                        >Move</button>
                                        <button
                                            class="bg-gray-100 text-xs rounded-md px-1 py-0.5 space-x-2 text-gray-500"
                                            @click="editNavigationItem(item)"
                                        >Edit</button>
                                    </div>
                                </div>

                                <div
                                    v-if="primaryNavigationSlotCountAvailable === 3"
                                    class="w-1/5 flex flex-col justify-center items-center cursor-pointer relative group"
                                >
                                    <i
                                        class="fa-light fa-ellipsis text-lg opacity-50"
                                    ></i>
                                    <div
                                        class="w-full text-center text-xxs truncate opacity-50"
                                    >More</div>
                                </div>

                                <div
                                    v-if="primaryNavigationSlotCountAvailable === 3"
                                    class="absolute right-2 bottom-full mb-2 rounded-lg border"
                                    :style="chromeStyle"
                                >
                                    <ul class="py-1">
                                        <li
                                            v-for="(item, i) in secondaryNavigationItems"
                                            :key="i"
                                            class="flex items-center space-x-2 px-4 py-1 max-w-64 relative group cursor-pointer"
                                        >
                                            <i
                                                :class="[movingNavigationItem ? 'opacity-20' : '', 'fa-'+item.icon]"
                                                class="fa-light text-lg"
                                            ></i>
                                            <div
                                                class="truncate text-xs"
                                                :class="movingNavigationItem ? 'opacity-20' : ''"
                                            >
                                                {{ item.label }}
                                            </div>

                                            <div
                                                v-if="movingNavigationItem"
                                                class="absolute inset-0 flex justify-center items-center hover:bg-gray-100/50"
                                                @click="moveNavigationItem(item)"
                                            >
                                                <i class="fa-solid fa-arrow-down-to-dotted-line text-xl transform -translate-y-1"></i>
                                            </div>

                                            <div
                                                v-if="! movingNavigationItem"
                                                class="hidden group-hover:flex absolute left-0 right-0 -top-1 w-full items-end space-x-4"
                                            >
                                                <button
                                                    class="bg-gray-100 text-xs rounded-md px-1 py-0.5 space-x-2 text-gray-500"
                                                    @click="startMovingNavigationItem(item)"
                                                >Move</button>
                                                <button
                                                    class="bg-gray-100 text-xs rounded-md px-1 py-0.5 space-x-2 text-gray-500"
                                                    @click="editNavigationItem(item)"
                                                >Edit</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-4">
                    <button-secondary
                        v-if="navigationItems?.length < 12"
                        size="sm"
                        @click="addNavigationItem()"
                    >
                        Add navigation item
                    </button-secondary>
                </div>
            </div>

            <div class="my-8 z-10 relative">
                <div class="mb-12">
                    <h2>Colors</h2>
                    <p class="text-gray-500 italic text-xs">
                        These colors should not be changed frequently, since they require updating the screenshots in App Store and Play Store.
                    </p>
                    <whitelabel-app-design-editor
                        :primary-background-color="colors.primaryBackgroundColor"
                        :primary-foreground-color="colors.primaryForegroundColor"
                        :secondary-background-color="colors.secondaryBackgroundColor"
                        :secondary-foreground-color="colors.secondaryForegroundColor"
                        :pinstripe-color="colors.pinstripeColor"
                        @update:colors="colors = $event"
                    ></whitelabel-app-design-editor>
                </div>

                <div class="mb-12">
                    <h2>Default front page</h2>
                    <p class="text-xs text-gray-500">This page will be shown when the users starts the app for the first time</p>
                    <input-select
                        v-model:content="defaultFrontPageId"
                        :options="navigatables?.pages.map(page => ({label: page.title, value: page.id}))"
                    ></input-select>
                </div>

            </div>

            <slideout-with-slots
                v-if="editingNavigationItem !== null"
                @close="editingNavigationItem = null"
                :allow-self-close="false"
                @self-close-prevented="maybeFinishEditingNavigationItem"
            >
                <template #body>
                    <input-label label="Select a target" />
                    <ul class="mb-4 bg-gray-100 -mx-4 md:-mx-6 px-4 md:px-6 border-y py-2 mt-1">
                        <li
                            v-for="(target, i) in navigatables?.pages"
                            @click="setItemTarget(editingNavigationItem, target)"
                            class="py-0.5 cursor-pointer -mx-4 md:-mx-6 px-4 md:px-6"
                            :class="{'bg-gray-300': editingNavigationItem.targetId === target.id}"
                        >
                            {{ target.title }}
                            <pill class="bg-gray-200" size="xs">
                                {{ targetLabels[target.type] }}
                            </pill>
                        </li>
                    </ul>

                    <input-label label="Icon and label" />
                    <div class="relative flex space-x-2 items-center mb-8">
                        <button @click="iconSelectorOpen = true" class="flex items-center mb-3 px-2">
                            <i
                                v-if="editingNavigationItem?.icon"
                               :class="'fa-'+editingNavigationItem?.icon"
                                class="fa-light text-lg"
                            ></i>
                            <i v-else class="fa-light fa-question text-lg text-gray-400"></i>
                        </button>
                        <input-text
                            class="w-full"
                            v-model:content="editingNavigationItem.label"
                        ></input-text>

                        <icon-selector-modal
                            v-if="iconSelectorOpen"
                            class="absolute inset-0 z-20"
                            @close="iconSelectorOpen = false"
                            @select-icon="editingNavigationItem.icon = $event.faName"
                        ></icon-selector-modal>
                    </div>

                    <div class="flex justify-between space-x-4">
                        <button-with-confirmation
                            @confirmed="deleteNavigationItem(editingNavigationItem)"
                            confirmButtonLabel="Delete"
                            confirm-text="Are you sure you want to delete this item?"
                            modalClass="absolute -top-2 -left-2 p-2 bg-white text-black rounded shadow-md z-20"

                        >
                            Delete
                        </button-with-confirmation>
                        <div class="flex justify-end space-x-4">
                            <button-base
                                @click="cancelEditingNavigationItem"
                            >
                                Cancel
                            </button-base>
                            <button-base
                                :style-type="navigationItemValidates ? 'success' : 'disabled'"
                                :disabled="! navigationItemValidates"
                                @click="maybeFinishEditingNavigationItem"
                            >
                                {{ creatingNavigationItem ? 'Add' : 'Save' }}
                            </button-base>
                        </div>
                    </div>
                </template>
            </slideout-with-slots>
        </div>
    </div>
</template>

<style scoped>

</style>
