<script setup>
import {computed} from "vue";

defineEmits(['update:content'])
const props = defineProps({
    content: {
        type: Number,
        required: false,
        default: 0
    },
    showSelectedValue: {
        type: Boolean,
        required: false,
        default: false
    },
    min: {
        type: Number,
        required: false,
        default: 0
    },
    max: {
        type: Number,
        required: false,
        default: 100
    },
})

const valueDisplayPosition = computed(() => {
    return `${(props.content - props.min) / (props.max - props.min) * 100}%`
})

const valueDisplayOffset = computed(() => {
    return `translateX(-${(props.content - props.min) / (props.max - props.min) * 100}%)`
})

</script>

<template>
    <div>
        <div
            v-if="showSelectedValue"
            class="relative mt-8 px-2"
        >
            <span
                class="absolute bottom-0 w-6 text-center text-gray-500 mb-0.5"
                :style="{left: valueDisplayPosition, transform: valueDisplayOffset}"
            >
                {{ props.content }}
            </span>
        </div>
        <input
            type="range"
            :min="props.min"
            :max="props.max"
            :value="props.content"
            @input="$emit('update:content', $event.target.value)"
            class="w-full h-3 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-red-800 dark:bg-gray-700"
        />
    </div>
</template>

<style scoped>
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

/* Remove default focus */
input[type="range"]:focus {
    outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    border-radius: 0.5rem;
    height: 0.5rem;
    @apply bg-gray-200;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    /*custom styles*/
    @apply h-6 w-6 rounded-full bg-wlPrimary transform -translate-y-2
}

input[type="range"]:focus::-webkit-slider-thumb {
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    @apply bg-gray-300
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
    @apply bg-gray-200;
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none;
    border-radius: 0;

    /*custom styles*/
    @apply h-6 w-6 rounded-full bg-wlPrimary transform -translate-y-2
}

input[type="range"]:focus::-moz-range-thumb {
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    @apply bg-gray-300
}

</style>
