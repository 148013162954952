<script setup>
import {computed, onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    formId: {
        type: Number,
        required: true,
    }
})

const submissionsLoaded = ref(false)
const form = ref({
    id: '',
    title: '',
})
const submissions = ref([])

const loadSubmissions = async () => {
    const res = await axios.get(route('api.form.submission.index', props.formId))
    submissions.value = res.data.submissions
    form.value.id = res.data.form.id
    form.value.title = res.data.form.title
    submissionsLoaded.value = true
}

const selectAll = () => {
    submissions.value.forEach(submission => submission.selected = true)
}

const deselectAll = () => {
    selectedSubmissions.value.forEach(submission => submission.selected = false)
}

const deleteSelectedSubmissions = async () => {
    const selectedSubmissionIds = selectedSubmissions.value.map(submission => submission.id)
    const res = await axios.post(route('api.form.submission.delete', props.formId), {
        submissionIds: selectedSubmissionIds
    })

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('form:form.submissions.deleted', {count: selectedSubmissionIds.length}),
            dismissAfter: 2500,
        })
    } else {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('error:error.generalTryAgain'),
            dismissAfter: 2500,
        })
    }

    await loadSubmissions();
}

const selectedSubmissions = computed(() => {
    return submissions.value.filter(submission => submission.selected)
})

onMounted(() => {
    loadSubmissions()
})

</script>
<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        <a
                            :href="route('forms.edit', form.id)"
                            class="mf-link"
                        >
                            {{ form.title }}
                        </a>
                    </h1>
                    <div class="flex space-x-2">
                        <div class="text-xs flex text-center space-x-2 items-center"
                            v-if="selectedSubmissions.length > 0"
                        >
                            <button-with-confirmation
                                @confirmed="deleteSelectedSubmissions"
                                :confirm-text="_mft('shared:confirm.cannotBeUndone')"
                                confirm-type="danger"
                                :confirm-button-label="_mft('shared:action.delete')"
                                :abort-button-label="_mft('shared:modal.defaultCancel')"
                            >
                                {{ _mft('shared:action.delete') }} {{ _mft('form:form.submissions', {count: selectedSubmissions.length}) }}
                            </button-with-confirmation>
                        </div>
                        <a
                            v-else
                            :href="route('forms.export', form.id)"
                        >
                            <button-secondary>
                                <mf-translate domainkey="shared:export"></mf-translate>
                            </button-secondary>
                        </a>
                    </div>
                </div>
            </frosted-bar>
        </div>

        <div class="mt-12">
            <div
                v-if="! submissionsLoaded"
                class="flex justify-center"
            >
                <mf-spinner-medium class="w-24 h-12" />
            </div>

            <div
                v-else
            >
                <div class="px-4 md:px-6 text-gray-500 flex justify-between items-center space-x-2">
                    <div class="w-24 -ml-2">
                        <button-secondary
                            v-if="selectedSubmissions.length === 0"
                            size="sm"
                            @click="selectAll"
                        >
                            {{ _mft('form:form.submissions.selectAll') }}
                        </button-secondary>
                        <button-secondary
                            v-if="selectedSubmissions.length > 0"
                            size="sm"
                            @click="deselectAll"
                        >
                            {{ _mft('form:form.submissions.deselectAll') }}
                        </button-secondary>
                    </div>
                    <span>
                        {{ _mft('form:form.submissions', {count: submissions.length}) }}
                    </span>
                    <div class="w-24 -mr-2"></div>
                </div>

                <list-basic
                >
                    <list-basic-item
                        v-if="submissions.length === 0"
                    >
                        <div class="text-center  text-gray-400">
                            <mf-translate domainkey="form:form.noResponses"></mf-translate>
                        </div>
                    </list-basic-item>

                    <list-basic-item
                        v-for="(submission, f) in submissions"
                        :key="f"
                    >
                        <template #title>
                            <div class="flex justify-between items-end mb-1 relative">
                                <span
                                    class="font-bold flex items-center space-x-1"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="submission.selected"
                                        class="w-4 h-4"
                                    />
                                    <span>{{ submission.created_at.user_tz }}</span>
                                </span>
                                <span
                                    v-if="submission.user"
                                    class="text-gray-500 text-xxs md:text-sm"
                                >
                                    <i class="fa-regular fa-user mr-1 text-gray-400"></i>{{ submission.user.name}}
                                </span>
                            </div>
                        </template>
                        <template #default>
                            <ul>
                                <li
                                    v-for="(entry, a) in submission.entries"
                                    :key="a"
                                    class="grid grid-cols-3 gap-4 lg:gap-8 py-0.5 odd:bg-gray-100 px-1 -mx-1 text-xs lg:text-sm"
                                >
                                    <div>
                                        {{ entry.question }}
                                    </div>
                                    <div class="col-span-2">
                                        {{ entry.answer }}
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template #actions>

                        </template>
                    </list-basic-item>
                </list-basic>
            </div>
        </div>
    </div>
</template>
