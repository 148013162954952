<script setup>

import {onMounted, ref, watch} from "vue";

const emit = defineEmits(['answerUpdated'])

const props = defineProps({
    question: {
        type: Object,
        required: true,
    }
})

const checked = ref(false)
const _question = ref(props.question)

const updateAnswer = () => {
    if (checked.value) {
        _question.value.answer = props.question.defaultAnswer
    } else {
        _question.value.answer = 0
    }

    emit('answerUpdated', _question.value.answer)
}

watch(() => props.question, () => {
    checked.value = !(props.question.answer === null || props.question.answer === 0);
    updateAnswer()
})

onMounted(() => {
    checked.value = !(props.question.answer === null || props.question.answer === 0);
    updateAnswer()
})

</script>

<template>
    <input
        type="checkbox"
        v-model="checked"
        @change="updateAnswer"
        class="w-5 h-5"
    />
</template>
