<script setup>
import {computed, ref} from 'vue'

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
});

const fieldUuid = computed(() => props.content.uuid);
const title = computed(() => props.content.title);
const required = computed(() => props.content.required);
const selectedValue = ref(3);


</script>

<template>
    <div class="relative mt-12">
        <input
            type="hidden"
            :name="`data[${uuid}][block_uuid]`"
            :value="fieldUuid"
        />

        <input
            type="hidden"
            :name="`data[${uuid}][value]`"
            :required="required"
            :value="selectedValue"
        />

        <input-label
            :label="title"
            :required="required"
        />
        <div class="px-4">
            <input-range
                v-model:content="selectedValue"
                :label="title"
                :required="required"
                :min="1"
                :max="5"
                :step="1"
                :show-selected-value="true"
            />
            <div class="flex justify-between mb-4 text-3xl">
                <span class="transform -translate-x-1">{{ content.lowSymbol }}</span>
                <span>{{ content.midSymbol }}</span>
                <span class="transform translate-x-1">{{ content.highSymbol }}</span>
            </div>
        </div>

        <page-builder-block-view-forms-sidebar
            v-if="content.showInstructions"
            :container-ref="containerRef"
            :uuid="uuid"
            :content="content"
        />
    </div>
</template>
