<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    subscriptionType: String,
})

const cancelInitiated = ref(null);
const readMore = ref('');

const includedInPlans = ref([
    {
        category: 'Pages',
        items: [
            { title: 'Landing Pages', basic: '2', pro: 'Unlimited', readMore: 'Landing Pages let you create custom pages that convert visitors into clients, boosting your business with ease.' },
            { title: 'Web Pages', basic: '2', pro: 'Unlimited', readMore: 'Web Pages help you showcase your brand online, attracting clients and building credibility effortlessly.' },
            { title: 'User Area Pages', basic: '2', pro: 'Unlimited', readMore: 'User Area Pages is where you can create exclusive content that only logged in clients can access. This is also where your clients manage their coaching sessions and keep track of course completion.' },
            { title: 'Remove "Powered by myflow"', basic: 'No', pro: 'Yes', readMore: 'Want your pages to be fully branded as your own? With Pro, you can remove the myflow logo from the footer.' },
            { title: 'SEO Tools', basic: 'No', pro: 'Yes', readMore: 'SEO Tools to boost your visibility online, driving more traffic and attracting new clients.' },
            { title: 'Visitor Statistics', basic: 'No', pro: 'Yes', readMore: 'Visitor Statistics give you insights into your audience, helping you make smarter business decisions.' },
        ]
    },
    {
        category: 'Coaching',
        items: [
            { title: 'Number of Clients', basic: 'Unlimited', pro: 'Unlimited', readMore: 'Gather all your coaching clients and manage them in one place.' },
            { title: 'Performed Sessions', basic: 'Unlimited', pro: 'Unlimited', readMore: 'See past and future sessions, in total or per client, including meeting notes.' },
            { title: 'Export Session Log', basic: 'No', pro: 'Yes', readMore: 'Export all sessions including status and notes.' },
            { title: 'Clients can book directly', basic: 'No', pro: 'Yes', readMore: 'Let your clients book, cancel and reschedule sessions by themselves.' },
        ]
    },
    {
        category: 'Courses',
        items: [
            { title: 'Number of courses', basic: 'Unlimited', pro: 'Unlimited', readMore: 'Share your knowledge, expand your reach, and earn passive income with online courses.' },
            { title: 'Student completion statistics', basic: 'No', pro: 'Yes', readMore: 'Track your students progress and nurture them along the road. Stats will help you know when to follow up and convert sales.' },
        ]
    },
    {
        category: 'Email',
        items: [
            { title: 'Newsletters', basic: 'Max 50 recipients', pro: 'Unlimited', readMore: 'Newsletters let you stay top-of-mind with clients, keeping them engaged and informed.' },
            { title: 'Automations', basic: '1 active', pro: 'Unlimited', readMore: 'Create email-automations and let the system deliver them based on client actions, regardless of whether you are by your computer, drinking coffee or out for a swim.' },
            { title: 'Remove "Powered by myflow"', basic: 'No', pro: 'Yes', readMore: 'Want your newsletters to be fully branded as your own? With Pro, the Powered by myflow footer is gone.' },
            { title: 'LeadHunter', basic: 'No', pro: 'Yes', readMore: 'Lead Hunter finds tons of leads in your target audience, and provides AI-driven tools for seamless outreach and follow-ups. It’s like having a sales assistant work for you.' },
            { title: 'myflow mail server', basic: 'No', pro: '1 000 emails / month *', readMore: 'Use your own domain as the sender while leveraging the power of myflow\'s mail server.' },
            { title: 'Add your own mailserver', basic: 'Yes', pro: 'Yes', readMore: 'Send email from myflow through your own mail server by connecting it to myflow' },
        ]
    },
    {
        category: 'CRM / Contacts',
        items: [
            { title: 'Tags', basic: '2', pro: 'Unlimited', readMore: 'Tags bring order to any amount of contacts, and can be used for numerous features including automations and newsletters.' },
            { title: 'Custom Fields', basic: '1', pro: 'Unlimited', readMore: 'Custom fields let you add extra fields to contacts, letting you add own touch to your CRM' },
            { title: 'Contacts', basic: 'Unlimited', pro: 'Unlimited', readMore: 'Store any number of contacts, easily searchable and filterable - and available everywhere' },
            { title: 'GDPR-tools', basic: 'No', pro: 'Yes', readMore: 'Get reminded when you need to update your contacts in order to be GDPR-compliant.' },
            { title: 'Deal Tracking from Quotes', basic: 'No', pro: 'Yes', readMore: 'Follow your deals in real time, and track which deals are closed and which deals are open.' },
        ]
    },
    {
        category: 'Quotes & Agreements',
        items: [
            { title: 'Remove "Powered by myflow"', basic: 'No', pro: 'Yes', readMore: 'Want your quotes and agreements to be fully branded as your own? With Pro, you can remove the myflow logo.' },
            { title: 'Sign on Screen', basic: 'Yes', pro: 'Yes', readMore: 'Let your customers sign your agreements on screen and close the deal in seconds.' },
            { title: 'Advanced Signing Methods', basic: 'No', pro: 'Yes', readMore: 'Use digital signing in your current region, for example Bank-ID.' },
            { title: 'Automatic Workflows', basic: 'No', pro: 'Yes', readMore: 'Send follow ups and reminders on quotes and agreements, even when you\'re sleeping or doing yoga.' },
        ]
    },
    {
        category: 'Domains',
        items: [
            { title: 'Custom Domain', basic: 'Yes', pro: 'Yes', readMore: 'Use your own domain for myflow, and serve your pages and newsletters on your own domain name.' },
        ]
    },
    {
        category: 'Payments',
        items: [
            { title: 'Instalments', basic: 'No', pro: 'Yes', readMore: 'Let your customers choose how to pay - all at once or split the payment.' },
            { title: 'Custom Products', basic: 'No', pro: 'Yes', readMore: 'Want to sell merch, home roasted coffee or your latest book? Sell whatever you want with Custom Products!' },
            { title: 'Embed in Page Builder', basic: 'Yes', pro: 'Yes', readMore: 'Package your offer beautifully with a purchase button on a landing page, website page or in your user area - or even as part of a course.' },
            { title: 'Direkt Links', basic: 'No', pro: 'Yes', readMore: 'Create a direct purchase link that you can share via email, newsletters, social media or on of your slides — anywhere you want!' },
            { title: 'Invoice Copy to email', basic: 'No', pro: 'Yes', readMore: 'When your customers receive their receipt a copy of the invoice is sent to your invoice email for easy bookkeeping.' },
            { title: 'Product Bundles', basic: 'No', pro: 'Yes', readMore: 'Offering both a course and coaching? A book and lectures? A presentation with access to a community? Bundle and package your products - and give your clients a no brainer deal.' },
        ]
    },
    {
        category: 'Community',
        items: [
            { title: 'Communities', basic: '1', pro: 'Unlimited', readMore: 'Communities let you build a loyal audience, connect with clients, and foster engagement. Invite members and create channels to communicate topics and areas you chose.' },
            { title: 'Channels', basic: '1', pro: 'Unlimited', readMore: 'Want to split topics into separate channels? No problem. Want a separate channel where only admins can post? You got it.' },
            { title: 'Members', basic: 'Max 20', pro: 'Unlimited', readMore: 'Invite members to your community and interact with each other - or sell memberships to a community using Payments.' },
            { title: 'Enable Accountability', basic: 'No', pro: 'Yes', readMore: 'Create an accountability channel and invite members to gather a "powerteam" and boost productivity in your group.' },
        ]
    },
    {
        category: 'Forms',
        items: [
            { title: 'Create forms', basic: '2', pro: 'Unlimited', readMore: 'Forms help you collect leads, feedback, and insights effortlessly - all in one place and of course available in the Page Builder.' },
            { title: 'Responses', basic: '50', pro: 'Unlimited', readMore: 'Gather responses in one place, and even create leads from submitted answers.' },
        ]
    },
    {
        category: 'Media Library',
        items: [
            { title: 'Video Storage', basic: '2 hours', pro: '20 hours **', readMore: 'Upload your videos to the media library and use them anywhere on myflow, optimized and just a few clicks away' },
            { title: 'Files & Images', basic: '2 GB', pro: '20 GB ***', readMore: 'Create content that boosts your business. Upload images, documents or even audio files and use them effortlessly across all myflow features' },
        ]
    },
    {
        category: 'Users',
        items: [
            { title: 'Customer Accounts', basic: '100', pro: 'Unlimited', readMore: 'Invite existing and potential customers let them access your content, engage in communities, book sessions, enrol in courses, download documents or give feedback - all while being logged in to your own user area.' },
            { title: 'Administrators', basic: '1', pro: '10 ****', readMore: 'Running a company with others? Need unloading? Collaborate with others and share the workload (and all the fun stuff).' },
        ]
    },
]);

</script>

<template>
    <div class="p-0 md:p-2 lg:p-4 mx-auto max-w-7xl text-xs md:text-base">
        <div class="grid grid-cols-3 gap-4 font-bold sticky bg-gray-100 top-16 md:top-0 items-end">
            <div></div>
            <div class="text-center">Basic</div>
            <div class="text-center">Pro</div>
        </div>

        <div v-for="(plan, index) in includedInPlans" :key="index" class="mt-6 mb-6">
            <div class="grid grid-cols-3 sticky top-16 md:top-0">
                <div class="md:mb-2 text-sm md:text-lg font-semibold bg-gray-100">{{ plan.category }}</div>
                <div></div>
                <div></div>
            </div>

            <div class="divide-y">
                <div
                    v-for="(item, idx) in plan.items"
                    :key="idx"
                    class="py-2"
                >
                    <div
                        class="grid grid-cols-3 gap-4"
                    >
                        <div>
                            {{ item.title }}<br>
                            <small
                                @click="readMore = readMore == index+'.'+idx ? null : index+'.'+idx"
                                class="text-gray-500 cursor-pointer hover:text-gray-800 flex space-x-1 items-center"
                            >
                                <span>{{ readMore === index+'.'+idx ? 'Read less' : 'Read more' }}</span>
                                <span class="flex w-3 h-3 rounded-full border border-gray-300 text-gray-400 items-center justify-center" style="font-size: 8px;">
                                    <i
                                        v-if="readMore !== index+'.'+idx"
                                        class="fa-solid fa-plus"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa-solid fa-minus"
                                    ></i>
                                </span>
                            </small>
                        </div>

                        <div class="text-center">{{ item.basic }}</div>

                        <div class="text-center">{{ item.pro }}</div>
                    </div>
                    <small
                        v-if="cancelInitiated"
                        class="col-span-3 text-myflowRed-900"
                    >
                        WARNING DANGER, YOU WILL LOSE ALL YOUR DATA
                    </small>

                    <div
                        class="col-span-3 transition-all overflow-hidden text-xs px-2 md:px-4 -mx-2 md:-mx-4"
                        :class="readMore === index+'.'+idx ? 'max-h-screen mt-1 mb-4 text-gray-500 rounded-wl bg-white py-2 md:py-4' : 'max-h-0 my-0 text-transparent'"
                    >
                        {{ item.readMore }}
                    </div>
                </div>
            </div>
        </div>

        <small>* Additional emails are billed at €0.80 per 1 000 emails</small><br>
        <small>** Additional hours are billed at €0.50 per hour / month</small><br>
        <small>*** Additional storage are billed at €0.20 per GB / month</small><br>
        <small>**** Additional Administrators are billed at €10 / month</small><br>
    </div>
</template>
