<template>
  <div
      class="flex items-end"
      :class="{
          'justify-between': align === 'left',
          'justify-center': align === 'center',
          'justify-end': align === 'right'
      }"
  >
    <label
      class="block text-sm font-medium"
    >{{ translatedLabel }}</label>
    <span
      class="inline-block text-xs opacity-80"
      v-if="required"
    >{{ _mft('form:required') }}</span>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    align: {
        type: String,
        required: false,
        default: 'left'
    }
  },
    computed: {
      translatedLabel() {
        if (this.label?.match(/^[^:]+:[^:]+$/)) {
          return _mft(this.label)
        } else {
          return this.label
        }
      }
    }
}
</script>
