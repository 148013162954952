<template>
  <div>
    <form @submit.prevent="submit">
        <div class="mb-10">
            <input-text
              v-model:content="form.name"
              :error="errors.name"
              :label="_mft('user:form.name.label')"
              :placeholder="_mft('user:form.name.placeholder')"
              required="required"
              name="name"
            />

            <input-text
              v-model:content="form.title"
              :label="_mft('user:form.title.label')"
              :placeholder="_mft('user:form.title.placeholder')"
              name="title"
            />

            <input-text
              type="email"
              v-model:content="form.email"
              :label="_mft('user:form.email.label')"
              :placeholder="_mft('user:form.email.placeholder')"
              disabled="disabled"
              required="required"
              name="email"
            />

            <input-text
              type="phone"
              v-model:content="form.phone"
              :label="_mft('user:form.phone.label')"
              :placeholder="_mft('user:form.phone.placeholder')"
              name="phone"
            />

            <input-timezone
                v-model:content="form.timezone"
                :label="_mft('user:form.timezone.label')"
                :placeholder="_mft('user:form.timezone.placeholder')"
                name="time_zone"
            />

            <input-text
              inputtype="password"
              type="password"
              v-model:content="form.password"
              :label="_mft('user:form.password.label')"
              :placeholder="_mft('user:form.password.placeholder')"
              name="password"
            />

            <div class="mb-4">
                <div class="flex items-center justify-center">
                    <div
                        v-if="user.profile_img_url && !changingProfilePicture"
                        :style="{backgroundImage: 'url('+ profileImage +')'}"
                        class="relative mb-4 w-36 h-36 lg:w-48 lg:h-48 myflow-profile-image"
                    >
                        <button
                            v-if="!changingProfilePicture"
                            v-on:click.prevent="changingProfilePicture = !changingProfilePicture"
                            class="absolute text-xs transform -translate-x-1/2 myflow-basic-button left-1/2 bottom-1"
                        >
                            {{ profileImageButtonLabel }}
                        </button>
                    </div>
                </div>

                <file-upload-with-cropping
                    v-if="changingProfilePicture"
                    :required="true"
                    :currentImage="profileImage"
                    previewClass="w-36 h-36 lg:w-48 lg:h-48 rounded-xl bg-center bg-cover mb-4"
                    @removedfile="changingProfilePicture = false"
                    @fileUpdated="fileUpdated($event)"
                    @croppingUpdated="croppingUpdated($event)"
                    @croppingFinished="croppingFinished($event)"
                    @croppingAborted="croppingAborted()"
                ></file-upload-with-cropping>
            </div>

            <toggle-switch
                name="impersonatable"
                v-model:content="form.impersonatable"
                :label="_mft('user:profile.impersonatable')"
            />
            <toggle-switch
                name="email_notifications"
                v-model:content="form.email_notifications"
                :label="_mft('user:profile.notifications.regular')"
            />
            <toggle-switch
                name="time_sensitive_notifications"
                v-model:content="form.time_sensitive_notifications"
                :label="_mft('user:profile.notifications.critical')"
            />
        </div>

        {{ errors.profile_img }}

        <input-button
            :label="submitLabel"
            :busy="isSubmitting"
            type="submit"
            class="float-right"
        />
    </form>
  </div>
</template>
<script>

export default {
    props: {
        user: {
            type: Object,
            default: {
                editing: false
            }
        },
    },
    data() {
        return {
            changingProfilePicture: false,
            editing: false,
            croppingActive: false,
            croppableImageBase64String: '',
            previewImage: '',
            previewImageSrc: '',
            form: {
                id: '',
                name: '',
                title: '',
                email: '',
                phone: '',
                timezone: '',
                impersonatable: '',
                email_notifications: '',
                time_sensitive_notifications: '',
                cropX: '',
                cropY: '',
                cropWidth: '',
                cropHeight: '',
                password: '',
            },
            errors: {
                name: '',
                title: '',
                email: '',
                phone: '',
                profile_img: ''
            },
            isSubmitting: false,
            submitLabel: _mft('shared:action.save'),
        };
    },
    methods: {
        fileUpdated(file) {
            this.form.profile_img = file;
        },
        croppingUpdated(croppingoptions){
            this.form.cropX = croppingoptions.x
            this.form.cropY = croppingoptions.y
            this.form.cropWidth = croppingoptions.width
            this.form.cropHeight = croppingoptions.height
        },
        croppingFinished(base64Image){
            this.changingProfilePicture = false
            this.previewImageSrc = base64Image
        },
        croppingAborted(){
            this.changingProfilePicture = false
            delete this.form.profile_img
        },
        submit() {
            let self = this;

            if( this.changingProfilePicture ) {
                this.croppingAborted()
            }

            if(this.form.profile_img === '' && this.previewImageSrc === ''){
                this.errors.profile_img = _mft('user:profileImage.required')
                console.log('error with image')
                return
            }

            this.isSubmitting = true;

            let previousLabel = this.submitLabel;
            this.submitLabel = _mft('shared:save.saving');

            var formData = new FormData();
            for ( const key in this.form ) {
                formData.append(key, this.form[key]);
            }

            axios.post(route('user.update', this.user.id), formData).then(function (response) {
                if( response.status === 200 ){
                    if( response.data.redirect ){
                        window.location = response.data.redirect;
                    } else {
                        self.isSubmitting = false;
                        self.submitLabel = previousLabel;
                    }
                }
            })

            this.isSubmitting = false;
            this.submitLabel = previousLabel;
        },
    },
    computed: {
        profileImage() {
            if( this.previewImageSrc ){
                return this.previewImageSrc
            } else {
                return this.user.profile_img_url
            }
        },
        profileImageButtonLabel() {
            if( this.user.profile_img === '' && this.previewImageSrc === '' ){
                return _mft('user:profileImage.set')
            } else {
                return _mft('user:profileImage.replace')
            }
        }
    },
    mounted: function(){
        if( this.user.id !== null ){
            this.submitLabel = _mft('user:action.updateProfile')
            this.editing = true
            this.form.name = this.user.name
            this.form.title = this.user.title
            this.form.email = this.user.email
            this.form.phone = this.user.phone
            this.form.timezone = this.user.time_zone
            this.form.impersonatable = this.user.impersonatable
            this.form.email_notifications = this.user.email_notifications
            this.form.time_sensitive_notifications = this.user.time_sensitive_notifications
        }
    }
};
</script>
<style scoped>
  :deep(.cropped-image-preview){
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
</style>
