<script setup>
import { watch, reactive } from 'vue';

const emit = defineEmits(['update:recurringAfterTagAssignedDelayInHours']);

const props = defineProps({
    recurringAfterTagAssignedDelayInHours: {
        type: Number,
        required: false,
        default: 24
    }
})

const state = reactive({
    recurringAfterTagAssignedDelayInHours: props.recurringAfterTagAssignedDelayInHours,
})

watch(state, (newValue) => {
    emit('update:recurringAfterTagAssignedDelayInHours', newValue);
})

</script>
<template>
    <div>
        <p class="px-2 text-gray-500 text-xs">
            <div class="mb-2" v-html="_mft('communication:sendRuleTypes.recurringAfterTagAssigned.description')"></div>
            <div v-html="_mft('communication:sendRuleTypes.recurringAfterTagAssigned.timeSettingDescription')"></div>
        </p>

        <input-number
            class="mb-1"
            :content="state.recurringAfterTagAssignedDelayInHours"
            @update:content="state.recurringAfterTagAssignedDelayInHours = $event"
            :label="_mft('communication:recurringAfterTagAssignedDelayInHours')"
        />
        <div class="flex flex-wrap -mt-2">
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 2 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 2"
            >
                {{ _mft('communication:delay.twoHours') }}
            </button-base>
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 24 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 24"
            >
                {{ _mft('communication:delay.oneDay') }}
            </button-base>
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 48 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 48"
            >
                {{ _mft('communication:delay.twoDays') }}
            </button-base>
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 168 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 168"
            >
                {{ _mft('communication:delay.oneWeek') }}
            </button-base>
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 336 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 336"
            >
                {{ _mft('communication:delay.twoWeeks') }}
            </button-base>
            <button-base
                size="sm"
                :style-type="state.recurringAfterTagAssignedDelayInHours === 720 ? 'secondary' : 'base'"
                class="mr-1 mb-1"
                @click="state.recurringAfterTagAssignedDelayInHours = 720"
            >
                {{ _mft('communication:delay.thirtyDays') }}
            </button-base>
        </div>
    </div>
</template>
