<script setup>
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import { ref, onMounted, watch } from 'vue'

    const notificationStore = useNotificationStore()
    const emit = defineEmits(['defaultCalendarSelected'])

    const calendarId = ref(null)
    const calendars = ref([])
    const currentDefaultCalendarId = ref(null)
    const calendarsHasMultipleProfiles = ref(false)

    const loadCalendars = async () => {
        const res = await axios.get(route('integrations.cronofy.index'))
        calendars.value = res.data.calendars
        currentDefaultCalendarId.value = res.data.currentDefaultCalendarId

        if (res.data.currentDefaultCalendarId) {
            emit('defaultCalendarSelected', res.data.currentDefaultCalendarId)
        }
    }

    const saveCalendar = async () => {
        const res = await axios.post(route('integrations.cronofy.store_calendar'), {
            calendar_id: calendarId.value,
        })

        if (res.data.success) {
            currentDefaultCalendarId.value = calendarId.value
            emit('defaultCalendarSelected', calendarId.value)
            notificationStore.addNotification({
                type: 'success',
                title: _mft('cronofy:calendarSavedNotification.title') + '!',
                message: _mft('cronofy:calendarSavedNotification.message'),
                dismissAfter: 2500,
            })
        }
    }

    const disableCalendarSync = async () => {
        const res = await axios.delete(route('integrations.cronofy.delete'));
        window.location.reload();
    }

    watch(calendarId, () => {
        saveCalendar()
    })

    onMounted(() => {
        loadCalendars()
    })

</script>
<template>
    <div>
        <h3>
            {{ _mft('cronofy:defaultCalendarForMeetingBookings') }}
        </h3>
        <div
            v-if="calendars.length === 0"
            class="py-2"
        >
            Something went wrong. Delete the calendar integration and start over.
            <div class="flex justify-center mt-2">
                <button-danger
                    @click="disableCalendarSync"
                >Delete calendar integration</button-danger>
            </div>
        </div>
        <ul class="divide-y">
            <li
                v-for="(calendar, i) in calendars"
                :key="i"
                class="my-1 py-1 flex justify-between space-x-4 items-center"
            >
                <span :class="currentDefaultCalendarId === calendar.calendar_id ? 'font-bold' : ''">
                    {{ calendar.calendar_name }}
                </span>
                <button
                    :class="currentDefaultCalendarId !== calendar.calendar_id ? '' : 'invisible'"
                    class="myflow-basic-button"
                    @click="calendarId = calendar.calendar_id"
                >{{ _mft('cronofy:defaultCalendarSelect') }}</button>
            </li>
        </ul>
    </div>
</template>
