<template>
  <div
    class="shadow-xl bg-white mb-10 rounded-wl"
    :class="[alignmentClass, highlightClass, paddingClass, overflowClass]"
  >
    <div
      v-if="hasHeaderSlot"
    >
      <span class="font-body mb-4 inline-block">
        <slot name="header"></slot>
      </span>
    </div>
    <div
      class="bg-white"
      :class="padlessImage ? '-mx-4 md:-mx-6 lg:-mx-8 -mt-3 md:-mt-5 lg:-mt-6' : ''"
      v-if="hasImageSlot"
    >
      <slot name="image"></slot>
    </div>

    <div :class="innerContainerClass">
      <slot name="body"></slot>
      <slot name="default"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    alignment: String,
    compact: {
      type: Boolean,
      default: false
    },
    highlight: {
        type: Boolean,
        default: false
    },
    overflow: {
        type: String,
        default: 'hidden'
    },
    padlessImage: {
        type: Boolean,
        default: false
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    highlightClass () {
        return this.highlight ? 'card-highlight' : ''
    },
    paddingClass ()  {
        return this.compact ? 'p-4 pt-3' : 'p-4 md:p-6 lg:p-8 pt-3 md:pt-5 lg:pt-6'
    },
    overflowClass () {
        return 'overflow-' + this.overflow
    }
  },
  setup() {

  },
}
</script>
<style scoped>
    .card-highlight {
        border: 1px solid var(--whitelabel-primary);
        box-shadow: var(--whitelabel-primary) 0px 0px 7px;
    }
</style>
