<script setup>
import {onMounted, ref, computed, watch} from 'vue';
import axios from 'axios';
import ChannelForm from "./channelForm.vue";
import BadgesForm from "./badgesForm.vue";

const community = ref([]);
const invoiceLinks = ref([]);
const channels = ref([]);
const memberCount = ref([]);
const members = ref([]);
const creatingChannel = ref(false);
const creatingInvoiceLink = ref(false);
const editingChannel = ref(null)

const props = defineProps({
    communityId: Number,
    canCreateChannel: {
        type: Boolean,
        required: true,
    }
});

const createChannelData = ref({
    name: '',
    postCount: 0,
    allowPostingFrom: null,
    allowPostingThrough: null,
    lockedForCommunityPosts: false,
    trackAccountability: false,
    trackProgress: false,
    postSubtitle: '',
    dailyCheckInQuestions: {
        questions: [],
    },
    reminderTime: '06:00',
    reminderDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
    }
});

async function createChannel() {
    const response = await axios.post(route('community.admin.api.storeChannel', props.communityId), createChannelData.value);

    await getCommunity();

    creatingChannel.value = false;

    createChannelData.value.name = ''
    createChannelData.value.metaText = null
    createChannelData.value.allowPostingFrom = null
    createChannelData.value.allowPostingThrough = null
    createChannelData.value.lockedForCommunityPosts = false
    createChannelData.value.trackAccountability = false
    createChannelData.value.trackProgress = false
    createChannelData.value.postSubtitle = ''
    createChannelData.value.dailyCheckInQuestions = {
        questions: []
    }
    createChannelData.value.reminderTime = '06:00'
    createChannelData.value.reminderDays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    }
}

const updateChannel = async (channel) => {
    const response = await axios.put(route('community.admin.api.updateChannel', {community_id: props.communityId, channel_id: channel.id}), channel);

    await getCommunity();

    editingChannel.value = null;
}

async function getCommunity() {
    const response = await axios.get(route('community.admin.api.show', props.communityId));
    community.value = response.data.community;
    invoiceLinks.value = response.data.invoice_links;
    channels.value = response.data.channels;
    memberCount.value = response.data.member_count;
    members.value = response.data.members;
}

async function revokeMembership(memberId) {
    const response = await axios.delete(route('community.admin.api.revokeMembership', memberId));
    if (response.data.message === 'Membership revoked') {
        getCommunity();
    }
}

async function archiveChannel(channelId) {
    const response = await axios.post(route('community.admin.api.archiveChannel', channelId));
    if (response.data.message === 'Channel archived') {
        getCommunity();
    }
}

const paymentLinkCreated = () => {
    creatingInvoiceLink.value = false
    getCommunity()
}

const activeChannels = computed(() => {
    return channels.value.filter(channel => !channel.archived)
})

const paymentLinkProducts = computed(() => {
    return [{
        title: community.value.name,
        price: 0,
        quantity: 1,
        description: '',
        linkableId: community.value.id,
        linkableType: 'community',
        quantity_is_fixed: false,
    }]
})

onMounted(() => {
    getCommunity();
});

</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar mb-8">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>{{ community.name }}</h1>

                    <div class="flex justify-end items-center space-x-4 mt-1 lg:mg-0">
                        <story-lane-modal :module="'communities'"></story-lane-modal>

                        <button-secondary
                            @click="creatingInvoiceLink = true"
                        >
                            {{ _mft('community:createInvoiceLink') }}
                        </button-secondary>
                    </div>
                </div>

                <template #navigation>
                    <a
                        :href="route('community.admin.index')+'#companyOwned'"
                        class="mf-link text-xs"
                    >
                        {{ _mft('community:allCommunities') }}
                    </a>
                </template>

            </frosted-bar>
        </div>

        <card-tabbed>
            <card-tabbed-tab
                :title="_mft('community:channels.title')"
                value="channels"
                :active="true"
            >
                <ul
                    v-if="activeChannels.length > 0"
                    class="mb-4 divide-y"
                >
                    <li
                        v-for="channel in activeChannels"
                        :key="channel.id"
                        class="pb-2 min-h-9"
                    >
                        <div class="flex items-center justify-between mt-2">
                            <div :class="channel.archived ? 'opacity-50' : ''">
                                <div>
                                    {{ channel.name }}
                                </div>
                                <small
                                    v-if="channel.locked_for_community_posts"
                                    class="block text-gray-400 -mt-0.5"
                                >
                                    {{ _mft('community:channel.type.lockedForCommunityPosts') }}
                                </small>
                                <small
                                    v-if="channel.accountability_enabled"
                                    class="block text-gray-400 -mt-0.5"
                                >
                                    {{ _mft('community:channel.type.trackAccountability') }}
                                </small>
                                <small
                                    v-if="channel.progress_tracking_enabled"
                                    class="block text-gray-400 -mt-0.5"
                                >
                                    {{ _mft('community:channel.type.trackProgress') }}
                                </small>
                            </div>
                            <div class="flex space-x-2">
                                <button-more-options
                                    size="sm"
                                    alignment="right"
                                >
                                    <button-more-options-option-with-confirmation
                                        v-if="! channel.archived && canCreateChannel"
                                        confirm-type="danger"
                                        :confirm-text="_mft('shared:confirm.areYouSure')"
                                        :confirm-button-label="_mft('community:archiveChannel')"
                                        :abort-button-label="_mft('shared:action.cancel')"
                                        @confirmed="archiveChannel(channel.id)"
                                        class="whitespace-nowrap"
                                    >
                                        {{ _mft('community:archiveChannel') }}
                                    </button-more-options-option-with-confirmation>
                                </button-more-options>

                                <a :href="route('community.admin.channel.show', {community_id: communityId, channel_id: channel.id})">
                                    <button-secondary
                                        size="sm"
                                    >
                                        {{ _mft('shared:action.edit') }}
                                    </button-secondary>
                                </a>
                            </div>

                        </div>
                    </li>
                </ul>
                <p
                    v-else
                    class="italic text-gray-400"
                >
                    {{ _mft('community:noChannelsYet') }}
                </p>

                <div class="flex justify-end">
                    <button-primary
                        v-if="canCreateChannel"
                        v-on:click="creatingChannel = true"
                        size="sm"
                    >
                        {{ _mft('community:addChannel') }}
                    </button-primary>
                    <p
                        v-else
                        class="text-right"
                    >
                        {{ _mft('myflowPro:limit.community.channel.title') }}<br>
                        <a href="https://myflow.io/pricing/" class="mf-link pt-1 inline-block">
                            <button-secondary size="sm">
                                {{ _mft('myflowPro:upgradeNow')}}
                            </button-secondary>
                        </a>
                    </p>
                </div>

                <slideout-with-slots
                    v-if="creatingChannel"
                    @close="creatingChannel = false"
                >
                    <template #title>
                        <h2>
                            {{ _mft('community:newChannel.title') }}
                        </h2>
                    </template>
                    <template #body>
                        <channel-form
                            :channel="createChannelData"
                            @save="createChannel"
                        ></channel-form>
                    </template>
                </slideout-with-slots>

                <slideout-with-slots
                    v-if="editingChannel !== null"
                    @close="editingChannel = null"
                >
                    <template #title>
                        <h2>
                            {{ editingChannel.name }}
                        </h2>
                    </template>
                    <template #body>
                        <channel-form
                            :channel="editingChannel"
                            @save="updateChannel"
                        ></channel-form>
                    </template>
                </slideout-with-slots>
            </card-tabbed-tab>

            <card-tabbed-tab
                :title="_mft('community:members.title')"
                value="members"
            >
                <ul
                    v-if="memberCount > 0"
                    class="divide-y"
                >
                    <li
                        v-for="member in members"
                        :key="member.id"
                        class="pb-2"
                    >
                        <div class="flex justify-between mt-2">
                            <div>
                                <div>
                                    {{ member.name }}
                                </div>
                                <small class="block text-gray-400 -mt-0.5">
                                    {{ _mft('community:memberSince') }} {{ member.created_at }}
                                </small>
                                <div>
                                    <badges-form
                                        :community="community"
                                        :member="member"
                                    />
                                </div>
                            </div>
                            <div class="flex space-x-1 items-center">
                                <button-with-confirmation
                                    size="sm"
                                    confirm-type="danger"
                                    :confirm-text="_mft('shared:confirm.areYouSure')"
                                    :confirm-button-label="_mft('community:member.revokeMembership')"
                                    :abort-button-label="_mft('shared:action.cancel')"
                                    @confirmed="revokeMembership(member.id)"
                                >
                                    {{ _mft('community:member.revokeMembership') }}
                                </button-with-confirmation>
                            </div>
                        </div>
                    </li>
                </ul>
                <p v-else>
                    {{ _mft('community:noMembersYet') }}
                </p>
            </card-tabbed-tab>

            <card-tabbed-tab
                v-if="invoiceLinks.length > 0"
                value="paymentLinks"
                :title="_mft('community:tab.paymentMethods.title')"
            >
                <ul class="divide-y">
                    <li
                        v-for="link in invoiceLinks"
                        :key="link.id"
                        class="flex justify-between py-1"
                    >
                            <span>
                                {{ link.title }}
                            </span>
                        <span>
                                <a
                                    :href="route('invoice-links.edit', link.uuid)"
                                    class="mf-link"
                                    target="_blank"
                                >
                                    {{ _mft('shared:action.edit') }}
                                </a>
                            </span>
                    </li>
                </ul>
            </card-tabbed-tab>
        </card-tabbed>

        <slideout-with-slots
            v-if="creatingInvoiceLink"
            @close="creatingInvoiceLink = false"
            max-width-class="max-w-xl"
        >
            <template #title>
                <h2>{{ _mft('course:createInvoiceLink') }}</h2>
            </template>
            <template #body>
                <invoice-link-form
                    :preset-products="paymentLinkProducts"
                    :preset-title="community.name"
                    :redirect="false"
                    form-style="ui"
                    :show-title="false"
                    :hidden-features="['backlink', 'navigation']"
                    preselected-tab="settings"
                    class="container"
                    @payment-link-created="paymentLinkCreated"
                ></invoice-link-form>
            </template>
        </slideout-with-slots>
    </div>
</template>
