<script setup>

const props = defineProps({
    channel: Object,
})

</script>

<template>
    <div>
        <card-basic>
            <h2>{{ _mft('community:channel.tab.settings') }}</h2>
            <div class="flex space-x-1 mb-2">
                <input-label :label="_mft('community:newChannel.name.label')" />:
                <span>{{ props.channel.name }}</span>
            </div>

            <div
                v-if="props.channel.trackAccountability || props.channel.trackProgress"
                class="flex space-x-1 mb-2"
            >
                <input-label :label="_mft('community:newChannel.type.label')" />:
                <span v-if="props.channel.trackAccountability">
                    {{ _mft('community:channel.type.trackAccountability') }}
                </span>

                <span v-if="props.channel.trackProgress">
                    {{ _mft('community:channel.type.trackProgress') }}
                </span>
            </div>

            <div
                v-if="props.channel.lockedForCommunityPosts"
                class="flex space-x-1 mb-2"
            >
                <input-label :label="_mft('community:newChannel.whoCanPost.label')" />:
                <span>
                    {{ _mft('community:newChannel.lockedForCommunityPosts.label') }}
                </span>
            </div>

            <div
                v-if="props.channel.allowPostingFrom"
                class="flex space-x-1 mb-2"
            >
                <input-label :label="_mft('community:newChannel.allowPostingFrom.label')" />:
                <span>
                    {{ props.channel.allowPostingFrom }}
                </span>
            </div>

            <div
                v-if="props.channel.allowPostingThrough"
                class="flex space-x-1 mb-2"
            >
                <input-label :label="_mft('community:newChannel.allowPostingThrough.label')" />:
                <span>
                    {{ props.channel.allowPostingThrough }}
                </span>
            </div>

            <input-label
                v-if="props.channel.lockedForCommunityPosts"
                :label="_mft('community:newChannel.lockedForCommunityPosts.label')"
                class="mb-8"
            />
        </card-basic>
        <card-basic
            v-if="props.channel.trackAccountability || props.channel.trackProgress"
        >
            <h2>{{ _mft('community:channel.tab.questions') }}</h2>
            <div
                v-for="(question, index) in props.channel.dailyCheckInQuestions.questions"
                class="my-2"
            >
                <input-label
                    :label="_mft('community:channel.accountability.question') + ' ' + question.index"
                />
                <div class="border-l py-1 pl-2 ml-1 min-h-6">
                    {{ question.question }}
                    <div class="text-gray-400 text-xs">{{ _mft('community:question.type.'+question.type) }}</div>
                </div>
            </div>
        </card-basic>
        <card-basic
            v-if="props.channel.trackAccountability || props.channel.trackProgress"
        >
            <h2>{{ _mft('community:channel.tab.reminders')}}</h2>
            <div
                v-if="Object.values(props.channel.reminderDays).filter(day => day).join(', ').length > 0"
            >
                <input-label
                    :label="_mft('community:newChannel.preview.sendReminder.label', {time: props.channel.reminderTime})"
                >
                </input-label>
                <ul class="border-l py-1 pl-2 ml-0.5 space-y-1">
                    <li v-if="props.channel.reminderDays.monday === true">{{ _mft('calendar:weekday.monday.long') }}</li>
                    <li v-if="props.channel.reminderDays.tuesday === true">{{ _mft('calendar:weekday.tuesday.long') }}</li>
                    <li v-if="props.channel.reminderDays.wednesday === true">{{ _mft('calendar:weekday.wednesday.long') }}</li>
                    <li v-if="props.channel.reminderDays.thursday === true">{{ _mft('calendar:weekday.thursday.long') }}</li>
                    <li v-if="props.channel.reminderDays.friday === true">{{ _mft('calendar:weekday.friday.long') }}</li>
                    <li v-if="props.channel.reminderDays.saturday === true">{{ _mft('calendar:weekday.saturday.long') }}</li>
                    <li v-if="props.channel.reminderDays.sunday === true">{{ _mft('calendar:weekday.sunday.long') }}</li>
                </ul>
            </div>
        </card-basic>
    </div>

</template>

<style scoped>

</style>
