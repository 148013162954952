<script setup>

const emit = defineEmits(['prioritySelected', 'cancel'])

const priorities = [
    {
        level: 1,
        iconClass: 'fa-solid fa-flag text-red-500',
    },
    {
        level: 2,
        iconClass: 'fa-solid fa-flag text-yellow-500',
    },
    {
        level: 3,
        iconClass: 'fa-solid fa-flag text-blue-500',
    },
]

const setPriority = (level) => {
    emit('prioritySelected', level)
}

</script>

<template>
    <div class="flex flex-row-reverse bg-white py-1 px-2 rounded-md shadow-md border border-gray-100">
        <button
            @click="emit('cancel')"
            class="w-4 flex justify-center items-center"
        >
            <i
                class="fa-solid fa-xmark"
            ></i>
        </button>
        <button
            v-for="(priority, p) in priorities"
            :key="p"
            @click="setPriority(priority.level)"
            class="w-4 flex justify-center items-center mr-2"
        >
            <i :class="priority.iconClass"></i>
        </button>
    </div>
</template>
