<script setup>
    import InputCssMeasures from "../forms/inputCssMeasures.vue";
    import {computed, ref, watch} from "vue";
    import IconChevron from "../icons/iconChevron.vue";

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        styletype: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        stylevalue: {
            type: [String, Number],
            required: false,
        },
        presets: {
            type: Array,
            required: false,
            default: () => []
        },
        colCount: {
            type: Number,
            required: false,
            default: 1
        }
    })

    const multiMeasureStyles = ['padding', 'margin'];
    const singleMeasureStyles = ['maxWidth', 'rowMinHeight'];
    const percentageStyles = [];
    const colorStyles = ['backgroundColor', 'color', 'overlay'];
    const mediaBankEntryStyles = ['backgroundMediaBankEntryId'];
    const verticalAlignmentStyles = ['verticalAlignment'];
    const columnLayoutStyles = ['layout'];

    const expanded = ref(false)
    const _stylevalue = ref(props.stylevalue)

    const getInputType = (attribute) => {
        if (singleMeasureStyles.includes(attribute)) {
            return 'input-css-measure';
        }
        if (multiMeasureStyles.includes(attribute)) {
            return 'input-css-measures';
        }
        if (colorStyles.includes(attribute)) {
            return 'color-picker';
        }
        if (percentageStyles.includes(attribute)) {
            return 'input-number';
        }
        if (mediaBankEntryStyles.includes(attribute)) {
            return 'input-media-bank-gallery';
        }
        if (verticalAlignmentStyles.includes(attribute)) {
            return 'select-vertical-alignment';
        }
        if (columnLayoutStyles.includes(attribute)) {
            return 'input-column-layout';
        }
        return 'input-text';
    }

    const inputType = computed(() => getInputType(props.styletype));

    watch(() => _stylevalue.value, (newValue) => {
        emit('update:modelValue', newValue);
    })


</script>

<template>
    <div>
        <div
            class="flex items-center justify-between space-x-2 group"
        >
            <h5
                @click="expanded = !expanded"
                class="w-full cursor-pointer"
            >
                {{ title }}
            </h5>
            <button-transparent
                @click="expanded = !expanded"
            >
                <icon-chevron
                    class="h-4 w-4 transition-all group-hover:text-wlPrimary"
                    :class="expanded ? 'rotate-180' : ''"
                />
            </button-transparent>
        </div>
        <div v-if="expanded" class="mt-2 mb-6">
            <div
                v-if="inputType === 'input-css-measure'"
            >
                <input-css-measure
                    v-model:content="_stylevalue"
                    :presets="presets"
                ></input-css-measure>
            </div>
            <div
                v-if="inputType === 'input-css-measures'"
            >
                <input-css-measures
                    v-model:content="_stylevalue"
                    :presets="presets"
                ></input-css-measures>
            </div>
            <div
                v-if="inputType === 'input-column-layout'"
            >
                <input-column-layout
                    v-model:content="_stylevalue"
                    :col-count="colCount"
                ></input-column-layout>
            </div>
            <div v-if="inputType === 'color-picker'">
                <color-picker
                    :color="_stylevalue"
                    :debounce="100"
                    :disable-alpha="false"
                    :show-palette="true"
                    format="prgb"
                    @colorChanged="_stylevalue = $event"
                    @clear-color="_stylevalue = null"
                ></color-picker>
            </div>
            <div v-if="inputType === 'input-text'">
                <input-text
                    type="text"
                    v-model:content="_stylevalue"
                ></input-text>
            </div>
            <div v-if="inputType === 'input-number'">
                <input-number
                    v-model:content="_stylevalue"
                ></input-number>
            </div>
            <div v-if="inputType === 'input-media-bank-gallery'">
                <input-media-bank-gallery
                    type="image"
                    :label="_mft('mediaBank:selectAnImage')"
                    v-model:content="_stylevalue"
                />
                <div
                    v-if="_stylevalue !== null"
                    class="flex justify-end mt-2"
                >
                    <button-danger
                        size="sm"
                        @click="_stylevalue = null"
                    >
                        {{ _mft('pagebuilder:styleEditor.setting.backgroundImage.remove') }}
                    </button-danger>
                </div>
            </div>
            <div v-if="inputType === 'select-vertical-alignment'">
                <input-select
                    v-model:content="_stylevalue"
                    :options="[
                        { value: 'start', label: _mft('pagebuilder:styleEditor.setting.verticalAlignment.start') },
                        { value: 'center', label: _mft('pagebuilder:styleEditor.setting.verticalAlignment.center') },
                        { value: 'end', label: _mft('pagebuilder:styleEditor.setting.verticalAlignment.end') },
                        { value: 'stretch', label: _mft('pagebuilder:styleEditor.setting.verticalAlignment.stretch') },
                    ]"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
