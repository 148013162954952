<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <textarea
            tabindex="-1"
            :name="name"
            class="ProseMirror-shadow-textarea"
            :required="required"
            v-model="shadowContent"
        ></textarea>
        <div
            class="relative mt-1 rounded-md shadow"
        >
            <div v-if="editor" class="p-1 mt-4 bg-gray-100 rounded-t-md">
                <div>
                    <div class="mb-2 space-x-1">
                        <button tabindex="-1" type="button" class="px-2 rounded-md" @click="editor.commands.toggleHeading({ level: 1 })" :class="{ 'bg-gray-400 text-white': editor.isActive('heading', {level: 1}) }">
                            <span class="text-xs">{{ _mft('form:inputEditor.headingSize.large') }}</span>
                        </button>
                        <button tabindex="-1" type="button" class="px-2 rounded-md" @click="editor.commands.toggleHeading({ level: 2 })" :class="{ 'bg-gray-400 text-white': editor.isActive('heading', {level: 2}) }">
                            <span class="text-xs">{{ _mft('form:inputEditor.headingSize.medium') }}</span>
                        </button>
                        <button tabindex="-1" type="button" class="px-2 rounded-md" @click="editor.commands.toggleHeading({ level: 3 })" :class="{ 'bg-gray-400 text-white': editor.isActive('heading', {level: 3}) }">
                            <span class="text-xs">{{ _mft('form:inputEditor.headingSize.small') }}</span>
                        </button>
                    </div>
                </div>

                <div class="flex items-center">
                    <div>
                        <button tabindex="-1" type="button" class="px-4 rounded-md" @click="editor.chain().focus().toggleBold().run()" :class="{ 'bg-gray-400 text-white': editor.isActive('bold') }">
                            <strong>B</strong>
                        </button>
                        <button tabindex="-1" type="button" class="px-4 rounded-md" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'bg-gray-400 text-white': editor.isActive('italic') }">
                            <em>i</em>
                        </button>
                        <button tabindex="-1" type="button" class="px-4 rounded-md" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'bg-gray-400 text-white': editor.isActive('underline') }">
                            <u>u</u>
                        </button>
                    </div>

                    <div class="w-px h-4 border-l border-gray-300"></div>

                    <div>
                        <button tabindex="-1" type="button" class="px-4 rounded-md" @click="setLink" :class="{ 'bg-gray-400 text-white': editor.isActive('link') }">
                            <em>url</em>
                        </button>
                    </div>

                    <div class="w-px h-4 border-l border-gray-300"></div>

                    <div>
                        <button tabindex="-1" type="button" class="px-4 rounded-md"  @click="setHorizontalRule">
                            <i class="fa-regular fa-horizontal-rule"></i>
                        </button>
                    </div>

                    <div class="w-px h-4 border-l border-gray-300"></div>

                    <div>
                        <button tabindex="-1" type="button" class="px-4 transition-opacity rounded-md" :class="editor.state.selection.empty ? 'opacity-30' : ''"  @click="clearNodes">
                            <i class="fa-regular fa-broom"></i>
                        </button>
                    </div>

                    <div class="w-px h-4 border-l border-gray-300"></div>

<!--                    <div>-->
<!--                        <button tabindex="-1" type="button" class="px-4 rounded-md"  @click="openIconSelector">-->
<!--                            <i class="fa-regular fa-icons"></i>-->
<!--                        </button>-->

<!--                        <div-->
<!--                            v-if="iconSelectorOpen"-->
<!--                            class="absolute top-0 z-50 right-0 w-full"-->
<!--                        >-->
<!--                            <icon-selector-modal-->
<!--                                :open="iconSelectorOpen"-->
<!--                                @close="iconSelectorOpen = false"-->
<!--                                @select-weight="insertIcon"-->
<!--                            ></icon-selector-modal>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="relative">
                        <button tabindex="-1" type="button" class="px-4 rounded-md"  @click="openColorPicker">
                            <i class="fa-regular fa-palette"></i>
                        </button>

                        <div
                            v-if="selectingTextColor"
                            class="absolute top-full right-0 z-50 overflow-y-auto w-64 bg-white rounded-wl shadow-lg p-2"
                        >
                            <color-picker
                                :color="textColor"
                                :debounce="100"
                                :show-palette="true"
                                format="hex"
                                @colorChanged="textColor = $event"
                            ></color-picker>

                            <div class="flex justify-end">
                                <button-primary
                                    size="sm"
                                    @click="applyTextColor"
                                >{{ _mft('shared:action.use') }}</button-primary>
                            </div>
                        </div>
                    </div>

                    <div class="w-px h-4 border-l border-gray-300" v-if="aiComponent"></div>
                    <div v-if="aiComponent">
                        <button tabindex="-1" type="button" class="px-4 rounded-md text-myflowGreen-900"  @click="openModal">
                            <i class="fa-regular fa-sparkles"></i> AI
                        </button>
                    </div>

                    <ai-modal
                        v-if="aiComponent"
                        :ai-component="aiComponent"
                        :open="modalOpen"
                        @close="modalOpen = false"
                        @export="appendExportedData"
                    ></ai-modal>
                </div>
            </div>
            <editor-content
                class="p-2 bg-white rounded-b-md prosemirror-container"
                :editor="editor"
                :required="required"
            />
            <span
                class="absolute text-xs opacity-50 pointer-events-none bottom-1 right-2"
                v-html="_mft('shared:tip.newLine')"
            ></span>
        </div>
        <input-error v-if="error" :error="error" />
    </div>
</template>
<script>
import { Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Underline from '@tiptap/extension-underline'
import { Node } from '@tiptap/core'
import aiModal from './ai/aiModal.vue'
import IconSelectorModal from './iconSelector/iconSelectorModal.vue'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

const FontAwesomeIcon = Node.create({
  name: 'fontAwesomeIcon',
  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      class: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[class*="fa-"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes]
  },
})

export default {
  components: {
    EditorContent,
    aiModal,
    TextStyle,
    Color,
    IconSelectorModal,
  },
  data: function(){
    return {
      shadowContent: null,
      inputClass: '',
      editor: null,
      aiComponent: this.aiFlow ?? null,
      modalOpen: false,
      iconSelectorOpen: false,
      textColor: null,
      selectingTextColor: false,
    }
  },
  props: {
    content: String,
    error: String,
    label: String,
    placeholder: String,
    required: Boolean,
    name: String,
    aiFlow: String,
    mirrorInputId: String
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    },
    content: function() {
      this.captureShadowContent(this.content)

      if (this.editor.getHTML() === this.content) { return; }
      this.editor.commands.setContent(this.content, false);
    }
  },
  methods: {
    appendExportedData(data) {
        this.editor.commands.selectTextblockEnd();
        this.editor.commands.insertContent('<p class="text-myflowGreen-700"><i><b>'+ _mft('form:inputEditor.createdWithMyflowAi') +'</b></i><br>'+data+'</p>');
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    setHorizontalRule() {
      this.editor
        .chain()
        .focus()
        .setHorizontalRule()
        .run()
    },
    toggleUnderline() {
      this.editor
        .chain()
        .focus()
        .toggleUnderline()
        .run()
    },
    clearNodes() {
      this.editor
        .chain()
        .focus()
        .clearNodes()
        .unsetAllMarks()
        .run()
    },
    openModal() {
      this.modalOpen = true;
    },
    openIconSelector() {
      this.iconSelectorOpen = true;
    },
    openColorPicker() {
      this.selectingTextColor = true;
    },
    applyTextColor() {
        this.editor.chain().focus().setColor(this.textColor).run();
        this.textColor = null
        this.selectingTextColor = false
    },
    insertIcon(icon) {
      this.editor
        .chain()
        .focus()
        .insertContent({
          type: 'fontAwesomeIcon',
          attrs: {
            class: icon
          }
        })
        .run();
    },
    resetEditor(){
      this.editor.destroy()
      this.editor = new Editor({
        content: '',
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
          }),
        ],
        onUpdate: () => {
          const html = this.editor.getHTML();
          this.$emit('update:content', html);
          this.captureShadowContent(html);

        },
      })
    },
    captureShadowContent(content) {
      this.shadowContent = content;
    }
  },
  mounted() {
    this.captureShadowContent(this.content);
    this.editor = new Editor({
      content: this.content,
      required: true,
      extensions: [
        StarterKit,
        HorizontalRule,
        Underline,
        TextStyle,
        Color,
        Link.configure({
          openOnClick: false,
        }),
        FontAwesomeIcon,
      ],
      onUpdate: () => {
        const html = this.editor.getHTML();
        this.$emit('update:content', html);
        this.captureShadowContent(html);
        if(this.mirrorInputId){
          document.getElementById(this.mirrorInputId).value = html;
        }
      },
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style>
  .prosemirror-container {
    overflow: scroll;
  }

  .ProseMirror {
    min-height: 4em;
    margin-bottom: 1em;
    transition: all 0.25s;
  }

  .ProseMirror {
    min-height: 14em;
  }

  .ProseMirror p {
    margin-bottom: 0.95em;
  }

  .ProseMirror a {
    text-decoration: underline;
  }

  .ProseMirror ul {
    list-style-type: disc;
    padding-left: 1em;
  }

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror-shadow-textarea {
    padding: 0;
    border: 0;
    outline: 0;
    opacity: 0;
    position: absolute;
  }
</style>
