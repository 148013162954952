<script setup>

import InputCheckboxes from "../forms/inputCheckboxes.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    blockEditorContentId: {
        type: Number,
        required: true
    },
    defaultRowStyles: {
        type: Object,
        required: true
    },
    defaultColStyles: {
        type: Object,
        required: true
    },
    loadContentUrl: {
        type: String,
        required: true
    },
    updateContentUrl: {
        type: String,
        required: true
    },
    previewUrl: {
        type: String,
        required: true
    },
    editUrl: {
        type: String,
        required: true
    }
})

</script>

<template>
    <page-builder-edit
        :container-ref="props.containerRef"
        :default-row-styles="props.defaultRowStyles"
        :default-col-styles="props.defaultColStyles"
        :load-content-url="props.loadContentUrl"
        :update-content-url="props.updateContentUrl"
        :inline-preview-url="props.previewUrl"
        :block-types="[
                    'headline',
                    'header',
                    'text-with-title',
                    'text',
                    'video',
                    'image',
                    'image-from-url',
                    'full-width-image',
                    'logo',
                    'audio',
                    'file',
                    'youtube',
                    'vimeo',
                    'spacer',
                    'card-text',
                    'card-text-list',
                    'image-description',
                    'image-text',
                    'navigation-buttons',
                    'single-button',
                    'quote',
                    'faq',
                    'slideshow',
                    'slim-product',
                    'pricing-offer',
                    'loom-embed',
                    'video-recorder',
                    'social-media',
                    'ai-introduction',
                    'confetti',
                ]"
    >
        <template #toolbar>
            <div class="mr-2 flex flex-col">
                <div class="relative">
                    <page-builder-template-create-template-modal
                        :content-id="props.blockEditorContentId"
                        category="quote"
                    />
                </div>
            </div>
            <a :href="props.editUrl">
                <button-secondary>
                    <mf-translate domainkey="page:pagebuilder.exit"></mf-translate>
                </button-secondary>
            </a>
        </template>
        <template #sloteditor="{ block }">
            <div
                v-if="block.hidden !== true"
                class="flex justify-between mb-4 text-xs items-center space-x-2"
            >
                <label class="text-sm font-medium" v-if="!block.excludedFromAgreement" v-html="_mft('expertContract:setting.willShowInContract')"></label>
                <button-secondary
                    v-if="!block.excludedFromAgreement"
                    @click="block.excludedFromAgreement = true"
                    size="sm"
                >
                    {{ _mft('expertContract:setting.hideInContract') }}
                </button-secondary>
                <label class="text-sm font-medium" v-if="block.excludedFromAgreement === true" v-html="_mft('expertContract:setting.willNotShowInContract')"></label>
                <button-primary
                    v-if="block.excludedFromAgreement === true"
                    @click="block.excludedFromAgreement = false"
                    size="sm"
                >
                    {{ _mft('expertContract:setting.showInContract') }}
                </button-primary>
            </div>
        </template>
    </page-builder-edit>
</template>

<style scoped>

</style>
