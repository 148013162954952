<script setup>

import {computed, onMounted, ref} from "vue";
import BarChart from "./_BarChart.vue"
import Respondent from './Table/Respondent.vue'

const props = defineProps({
    channelId: {
        type: Number,
        required: true
    }
})

const availableWidth = ref('1920px');
const shownDates = ref([])
const stats = ref(null)
const weeklyScores = ref(null)
const respondentScores = ref(null)
const respondentTotals = ref(null)
const questions = ref(null)
const channel = ref(null)
const groupScores = ref(true)
const sortByBadge = ref(false)

const loadStatistics = async () => {
    const res = await axios.get(route('community.admin.api.channel.statistics', props.channelId));

    stats.value = res.data.statistics;
    weeklyScores.value = res.data.weeklyScores;
    respondentScores.value = res.data.respondentScores;
    respondentTotals.value = res.data.respondentTotals;
    questions.value = res.data.questions;
    channel.value = res.data.channel;
}

const badgesInUse = computed(() => {
    if (sortByBadge.value === false) {
        return [{id: 0,name: ''}];
    }


    let uniqueBadges = []
    Object.values(respondentScores.value).flatMap(score => score.respondent.badges.map(badge => badge)).forEach(badge => {
       if (uniqueBadges.find(b => b.id === badge.id) === undefined) {
           uniqueBadges.push(badge);
       }
    });

    if (uniqueBadges.length === 0) {
        return [];
    }


    return uniqueBadges.sort((a, b) => a.name.localeCompare(b.name));
})

const grandTotals = computed(() => {
    let total = 0;
    for (const [questionUuid, questionTotals] of Object.entries(respondentTotals.value)) {
        for (const [a, answer] of Object.entries(questionTotals)) {
            total += answer.score ?? 0;
        }
    }
    return total;
})

const perBadgeTotals = computed(() => {
    const totals = {};
    badgesInUse.value.forEach(badge => {
        let totalsPerQuestion = {};
        let total = 0;

        for (const [questionUuid, questionTotals] of Object.entries(respondentTotals.value)) {
            if (! totalsPerQuestion[questionUuid]) {
                totalsPerQuestion[questionUuid] = 0;
            }
            for (const [a, answer] of Object.entries(questionTotals)) {
                Object.entries(answer.respondent.badges).forEach(([key, value]) => {
                    if (value.id === badge.id) {
                        totalsPerQuestion[questionUuid] += answer.score ?? 0;
                        total += answer.score ?? 0;
                    }
                })
            }
        }
        totals[badge.id] = {
            badge: badge,
            questions: totalsPerQuestion,
            totalScore: total,
        };
    })
    return totals
})

onMounted(() => {
    loadStatistics();
});

</script>

<template>
    <div class="">
        <div v-if="channel" class="mx-auto">
            <h1>{{ channel.name }}</h1>

            <div
                v-if="stats"
                class="mt-4 lg:mt-8"
            >
                <div class="w-64">
                    <toggle-switch
                        class="flex-row-reverse"
                        :label="'Group scores'"
                        v-model:content="groupScores"
                    />

                    <toggle-switch
                        class="flex-row-reverse"
                        :label="'Sort by badge'"
                        v-model:content="sortByBadge"
                    />
                </div>

                <p
                    v-if="stats.length === 0"
                    class="my-12 text-gray-400 italic"
                >
                    {{ _mft('community:statistics.noStatsToShow') }}
                </p>

                <div
                    class="relative overflow-auto table-outer-container"
                >
                    <div
                        v-for="(badge, b) in badgesInUse"
                        :key="b"
                        class="mb-12"
                    >
                        <h3 v-if="sortByBadge">{{ badge.name }}</h3>
                        <div class="text-xxs table-container">
                            <div class="table-content">
                                <div class="flex">
                                    <div class="stat-cell-header bg-gray-200 flex items-end">
                                        {{ _mft('community:statistics.columnHeader.member') }}
                                    </div>
                                    <div
                                        v-for="(question, i) in questions"
                                        :key="i"
                                        class="stat-cell-header bg-gray-200 flex items-end"
                                    >
                                        {{ question.question }}
                                    </div>
                                    <div class="stat-cell-header bg-gray-200 flex justify-end items-end">
                                        {{ _mft('community:statistics.columnHeader.total') }}
                                    </div>
                                </div>
                                <div
                                    v-for="(respondent, i) in respondentScores"
                                    :key="i"
                                >
                                    <div
                                        v-if="sortByBadge === false || respondent.respondent.badges.find(b => b.id === badge.id)"
                                        class="divide-y border-b bg-gray-50 last:border-b-0"
                                    >
                                        <Respondent
                                            :respondent="respondent"
                                            :questions="questions"
                                            :respondent-totals="respondentTotals"
                                            :group-scores="groupScores"
                                        />
                                    </div>
                                </div>

                                <div class="flex">
                                    <div class="stat-cell !bg-gray-50">
                                    </div>
                                    <div
                                        v-for="(question, i) in questions"
                                        :key="i"
                                        class="stat-cell !bg-gray-50 font-bold text-right"
                                    >
                                        {{ perBadgeTotals[badge.id].questions[question.uuid] }}
                                    </div>
                                    <div class="stat-cell !bg-gray-50 font-bold text-right">
                                        {{ sortByBadge === false ? grandTotals : perBadgeTotals[badge.id].totalScore }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <list-basic
                    v-if="false"
                    v-for="(stat, date) in stats"
                >
                    <list-basic-item>
                        <template #title>
                            <div
                                class="flex justify-between space-x-4 items-center"
                            >
                                <div
                                    class="cursor-pointer w-full"
                                    @click="shownDates.includes(date) ? shownDates = shownDates.filter(d => d !== date) : shownDates.push(date)"
                                >
                                    {{ date }}<br>
                                    <div class="text-xs text-gray-400 lowercase">{{ stat.postCount }} {{ _mft('community:trackProgress.respondents') }}</div>
                                </div>

                                <div>
                                    <button-base
                                        v-if="!shownDates.includes(date)"
                                        size="sm"
                                        :style-type="stat.statistics.length === 0 ? 'disabled' : 'base'"
                                        @click="stat.statistics.length === 0 ? null : shownDates.push(date)"
                                    >
                                        {{ _mft('shared:action.show') }}
                                    </button-base>
                                    <button-secondary
                                        v-else
                                        size="sm"
                                        @click="shownDates = shownDates.filter(d => d !== date)"
                                    >
                                        {{ _mft('shared:action.hide') }}
                                    </button-secondary>
                                </div>
                            </div>
                        </template>
                        <ul :class="shownDates.includes(date) ? 'mt-4' : ''">
                            <li
                                v-for="(statPoint, i) in stat.statistics"
                                class="py-8 odd:bg-gray-50 -mx-4 md:-mx-6 px-4 md:px-6 odd:border-y border-100"
                                :class="shownDates.includes(date) ? 'py-4' : 'hidden'"
                            >
                                <div class="space-y-8 xl:grid xl:grid-cols-3 xl:space-y-0 gap-8">
                                    <div :class="statPoint.type === 'text' ? 'col-span-3' : ''">
                                        <p class="text-base mb-1">{{ statPoint.question }}</p>
                                        <ul class="divide-y divide-gray-200">
                                            <li
                                                class="flex justify-between space-x-2 py-0.5 font-bold"
                                            >
                                                <span>{{ _mft('community:trackProgress.response') }}</span>
                                                <span>{{ _mft('community:trackProgress.count') }}</span>
                                            </li>
                                            <li
                                                v-for="(answer, i) in statPoint.answers"
                                                class="flex justify-between space-x-2 py-0.5 text-xs hover:bg-gray-50"
                                            >
                                                <span v-if="statPoint.type === 'boolean'">
                                                    {{ answer.answer ? _mft('shared:confirm.positive') : _mft('shared:confirm.negative') }}
                                                </span>
                                                <span v-else>
                                                    {{ answer.answer }}
                                                </span>
                                                <span class="text-gray-500">{{ answer.count }}</span>
                                            </li>
                                        </ul>

                                    </div>
                                    <div
                                        v-if="statPoint.type !== 'text'"
                                        class="col-span-2"
                                    >
                                        <BarChart
                                            v-if="statPoint.type !== 'text'"
                                            class="w-full"
                                            :stat="statPoint"
                                        ></BarChart>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </list-basic-item>
                </list-basic>

            </div>
        </div>
        <div
            v-else
            class="h-screen flex items-center justify-center"
        >
            <mf-spinner-medium class="w-24 h-12"></mf-spinner-medium>
        </div>
    </div>
</template>
<style scoped>
.table-outer-container {
    /*width: v-bind(liveAvailableWidth);*/
    width: 100%;
}

.stat-table-cell {
    flex-shrink: 0;
    flex-grow: 0;
    width: 160px;
}

.table-container {
    width: 100%;
}

.stat-cell-header {
    padding: 4px 8px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 160px;
}

.stat-cell {
    background: #fff;
    padding: 4px 8px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 160px;
}
</style>
