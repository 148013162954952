<script setup>
import {computed, inject} from 'vue';
import AccountabilityFormQuestionTypeTodo from './accountability-form/questionTypeTodo.vue'

const {accountability, yesterdaysPost, activeChannel} = inject('accountability-data');

const props = defineProps({
    trackRecord: {
        type: Object,
        required: false,
        default: {}
    },
    trackRecordDays: {
        type: Object,
        required: false,
        default: []
    }
})

const progressOptions = [
    {
        label: '0%',
        value: 0,
        classes: 'border-myflowRed',
    },
    {
        label: '25%',
        value: 25,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '50%',
        value: 50,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '75%',
        value: 75,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '100%',
        value: 100,
        classes: 'border-myflowGreen-800',
    },
];

const postScore = computed(() => {
    let score = 0;
    const scorableQuestionTypes = ['number', 'boolean', 'completion', 'todo'];

    accountability.value.questions.forEach((question, i) => {
        if (scorableQuestionTypes.includes(question.type) && ! isNaN(question.answer)) {
            score += question.answer ?? 0;
        }
    })

    return score
});

</script>

<template>
    <div>
        <div class="mt-3 gap-8 md:grid md:grid-cols-2">
            <div
                v-if="activeChannel.accountability_enabled"
                class="col-span-1"
            >
                <h5 class="mb-3">{{ _mft('community:channel.yesterdaysReflection.title') }}</h5>
                <div
                    v-if="yesterdaysPost !== null"
                    class="space-y-6"
                >
                    <div
                        v-for="(question, i) in activeChannel.accountability_questions_actions.questions"
                        :key="i"
                    >
                        <div
                            v-if="yesterdaysPost.post_accountability_questions_actions.questions[i]"
                        >
                            <label>
                                {{ question.question }}
                            </label>
                            <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                                {{ yesterdaysPost.post_accountability_questions_actions.questions[i].answer }}
                            </p>

                            <input-number
                                v-if="question.type === 'number'"
                                v-model:content="accountability.questions[i].reflection"
                            />

                            <input-text
                                v-else-if="question.type === 'text'"
                                v-model:content="accountability.questions[i].reflection"
                            />

                            <div
                                v-else-if="question.type === 'boolean'"
                                class="flex space-x-2"
                            >
                                <button-base
                                    :style-type="accountability.questions[i].reflection === true ? 'primary' : 'secondary'"
                                    size="sm"
                                    @click="accountability.questions[i].reflection = true"
                                >
                                    {{ _mft('shared:confirm.positive') }}
                                </button-base>
                                <button-base
                                    :style-type="accountability.questions[i].reflection === false ? 'primary' : 'secondary'"
                                    size="sm"
                                    @click="accountability.questions[i].reflection = false"
                                >
                                    {{ _mft('shared:confirm.negative') }}
                                </button-base>
                            </div>

                            <div
                                v-else
                                class="flex items-center space-x-2"
                            >
                                <label class="block">
                                    {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                                </label>

                                <progress-steps
                                    class="w-full"
                                    :progress="accountability.questions[i].reflection"
                                    :steps="progressOptions"
                                    @update:progress="accountability.questions[i].reflection = $event.value"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="activeChannel.accountability_questions_actions.question1Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question1 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question1 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion1"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion1 = $event.value"
                            />
                        </div>
                    </div>
                    <div v-if="activeChannel.accountability_questions_actions.question2Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question2 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question2 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion2"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion2 = $event.value"
                            />
                        </div>
                    </div>
                    <div v-if="activeChannel.accountability_questions_actions.question3Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question3 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question3 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion3"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion3 = $event.value"
                            />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="text-xs italic text-gray-500">
                        {{ _mft('community:channel.yesterdaysReflection.noQuestionsAnsweredYet') }}
                    </p>
                </div>
            </div>
            <div
                :class="activeChannel.progress_tracking_enabled ? 'col-span-2' : 'col-span-1'"
            >
                <h5
                    class="mb-1 flex space-x-1"
                >
                    <div v-if="activeChannel.progress_tracking_enabled && trackRecord" class="w-32 md:w-36 mr-3 md:mr-2">Track Record</div>
                    <div>{{ activeChannel.post_subtitle ? activeChannel.post_subtitle : _mft('community:channel.todaysQuestions') }}</div>
                </h5>

                <div
                    v-if="activeChannel.progress_tracking_enabled && trackRecord"
                    class="flex divide-x "
                >
                    <div
                        v-for="(day, d) in trackRecordDays"
                        :key="d"
                        class="flex justify-center w-5 text-xxs"
                    >
                        {{ day.weekday[0]+day.weekday[1] }}
                    </div>
                </div>

                <div
                    v-for="(question, i) in accountability.questions"
                    :key="i"
                    :class="[question.hasOwnProperty('enabled') && question.enabled === false ? 'hidden' : '', trackRecord ? 'flex space-x-2' : '']"
                >
                    <div>
                        <div
                            v-if="activeChannel.progress_tracking_enabled && trackRecord"
                            class="flex justify-center h-full mr-2"
                        >
                            <div
                                class="flex divide-x border-t border-gray-300"
                                :class="question.role === 'layout' ? 'divide-gray-200' : 'divide-gray-300'"
                            >
                                <div
                                    v-for="(day, d) in trackRecordDays"
                                    :key="d"
                                    class="flex justify-center items-center w-5 "
                                >
                                    <div
                                        v-for="(sq, i) in activeChannel.accountability_questions_actions.questions.filter(q => q.uuid === question.uuid)"
                                    >
                                        <div
                                            class="text-xxs"
                                            :class="question.role === 'layout' ? 'opacity-25' : ''"
                                        >
                                            <i
                                                class="text-base md:text-sm"
                                                :class="trackRecord[day.date] && trackRecord[day.date][question.uuid]?.answer ? 'fa-regular fa-check text-myflowGreen' : ''"
                                            ></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="md:w-full pt-2"
                    >
                        <input-label
                            v-if="question.type !== 'todo' && question.type !== 'heading'"
                            :label="question.question"
                        ></input-label>
                        <h3 v-if="question.type === 'heading'">
                            {{ question.question }}
                        </h3>
                        <input-text
                            v-if="! activeChannel.progress_tracking_enabled"
                            v-model:content="accountability.questions[i].answer"
                            class="opacity-50"
                        />


                        <div v-if="activeChannel.progress_tracking_enabled" class="mb-2">
                            <input-number
                                v-if="question.type === 'number'"
                                v-model:content="accountability.questions[i].answer"
                            />

                            <input-text
                                v-else-if="question.type === 'text'"
                                v-model:content="accountability.questions[i].answer"
                            />

                            <div
                                v-else-if="question.type === 'todo'"
                                class="flex space-x-2 items-center"
                            >
                                <AccountabilityFormQuestionTypeTodo
                                    :question="question"
                                    @answerUpdated="accountability.questions[i].answer = $event"
                                />
                                <span class="md:flex md:items-center text-xs md:text-base">
                                    <span class="block md:inline">{{ question.question }}</span>
                                    <span class="block md:inline text-gray-400 text-xxs md:text-xs md:ml-1">({{ _mft('community:trackProgress.nPoints', {count: question.defaultAnswer}) }})</span>
                                </span>
                            </div>

                            <div
                                v-else-if="question.type === 'boolean'"
                                class="flex space-x-2"
                            >
                                <button-base
                                    :style-type="accountability.questions[i].answer === true ? 'primary' : 'secondary'"
                                    size="sm"
                                    @click="accountability.questions[i].answer = true"
                                >
                                    {{ _mft('shared:confirm.positive') }}
                                </button-base>
                                <button-base
                                    :style-type="accountability.questions[i].answer === false ? 'primary' : 'secondary'"
                                    size="sm"
                                    @click="accountability.questions[i].answer = false"
                                >
                                    {{ _mft('shared:confirm.negative') }}
                                </button-base>
                            </div>

                            <div
                                v-else-if="question.type === 'completion'"
                                class="flex items-center space-x-2 pb-2"
                            >
                                <label
                                    v-if="activeChannel.accountability_enabled"
                                   class="block mb-2"
                                >
                                    {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                                </label>

                                <progress-steps
                                    class="w-full"
                                    :class="activeChannel.accountability_enabled ? '' : 'mt-4'"
                                    :progress="accountability.questions[i].answer"
                                    :steps="progressOptions"
                                    @update:progress="accountability.questions[i].answer = $event.value"
                                />
                            </div>

                            <div
                                v-else-if="question.type === 'widget'"
                                class="flex items-center space-x-2 pb-2"
                            >
                                <div
                                    v-if="accountability.questions[i].answer"
                                    class="w-full flex flex-col md:items-center md:flex-row space-y-0 md:space-y-1 space-x-2"
                                >
                                    <div class="block w-full mt-1 p-2 border rounded-md bg-gray-50 cursor-default">
                                        {{ accountability.questions[i].answer }}
                                    </div>
                                    <div class="">
                                        <button-base
                                            size="sm"
                                            @click="accountability.questions[i].answer = null"
                                        >
                                            {{ _mft('shared:action.clear') }}
                                        </button-base>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="mt-1"
                                >
                                    <external-widget
                                        size="sm"
                                        class="bg-white"
                                        :widget-uuid="question.metadata?.widgetUuid"
                                        @update:resultString="accountability.questions[i].answer = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="activeChannel.progress_tracking_enabled"
                    class="flex justify-between space-x-2 font-bold mt-2 mb-4 pt-2 border-t"
                >
                    <span>
                        {{ _mft('community:post.totalScore') }}:
                    </span>
                    <span>
                        {{ postScore }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
