<script setup>
import { computed } from 'vue'

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
});

const fieldUuid = computed(() => props.content.uuid);
const title = computed(() => props.content.title);
const required = computed(() => props.content.required);
</script>

<template>
    <div class="relative">
        <input
            type="hidden"
            :name="`data[${uuid}][block_uuid]`"
            :value="fieldUuid"
        />
        <input-date
            :name="`data[${uuid}][value]`"
            :label="title"
            :required="required"
        />

        <page-builder-block-view-forms-sidebar
            v-if="content.showInstructions"
            :container-ref="containerRef"
            :uuid="uuid"
            :content="content"
        />
    </div>
</template>
