<script setup>
    import videojs from 'video.js';
    import {onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
    import 'video.js/dist/video-js.css';

    onMounted(() => {
        initPlayer();
    });

    onBeforeUnmount(() => {
        if (player.value) {
            player.value.dispose();
        }
    });

    const player = ref(null)
    const videoPlayer = ref(null)

    const props = defineProps({
        mp4: {
            type: String,
            default: null
        },
        hls: {
            type: String,
            default: null
        },
        poster: {
            type: String,
            default: null
        },
        autoplay: {
            type: Boolean,
            default: false
        }
    })

    const media = reactive({
        error: false,
        mp4: null,
        hls: null,
        poster: null,
    })

    const initPlayer = () => {
        media.mp4 = props.mp4;
        media.hls = props.hls;
        media.poster = props.poster;

        if (!media.mp4 && !media.hls) {
            media.error = true;
            return;
        }

        const videoSrc = media.hls
            ? media.hls
            : media.mp4;

        const videoPoster = media.poster
            ? media.poster
            : null;

        const videoType = media.hls
            ? 'application/x-mpegURL'
            : 'video/mp4';

        const options = {
            controls: true,
            autoplay: false,
            preload: 'auto',
            fluid: true,
            poster: videoPoster,
            inactivityTimeout: 150,
            playbackRates: [0.5, 1, 1.5, 2],
            sources: [
                {
                    src: videoSrc,
                    type: videoType
                }
            ]
        };

        player.value = videojs(videoPlayer.value, options);
    }

    watch(() => props.autoplay, (newVal) => {
        if (player.value) {
            props.autoplay ? player.value.play() : player.value.pause()
        }
    });
</script>
<template>
    <div class="w-full">
        <video
            v-if="!media.error"
            ref="videoPlayer"
            class="w-full overflow-hidden video-js myflow-player vjs-myflow aspect-video rounded-card"
            controls
            :autoplay="autoplay"
            :muted="autoplay"
        ></video>
        <!-- <span style="">{{ media }}</span> -->
        <div v-if="media.error" class="flex items-center justify-center w-full bg-gray-200 aspect-video rounded-card">
            <p>{{ _mft('error:error.generalOccurred') }}</p>
        </div>
    </div>
</template>
<style scoped>
.vjs-myflow.myflow-player {
  color: #ffffff;
}

:deep(.vjs-myflow.myflow-player.vjs-paused .vjs-big-play-button) {
    background-color: rgba(62,62,62,0.8);
    border: none;
    border-radius: 100%;
    width: 3em;
    height: 3em;
    margin-top: -1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.myflow-player .vjs-control-bar) {
    background-color: rgba(62,62,62,0.8);
    font-size: 1.5em;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 1.5em;
}

:deep(.myflow-player .vjs-playback-rate) {
    font-size: 10px;
}
:deep(.myflow-player .vjs-playback-rate .vjs-playback-rate-value) {
    display: flex;
    align-items: center;
}

.myflow-player.vjs-myflow .vjs-load-progress {
    background: #000;
}

.myflow-player.vjs-myflow .vjs-load-progress div {
    background-color: rgba(125,125,125);
}

/*
:deep(.myflow-player.vjs-myflow .vjs-volume-level, .myflow-player.vjs-myflow .vjs-play-progress.vjs-slider-bar) {
    background-color: rgb(255,255,255);
}
:deep(.myflow-player.vjs-myflow .vjs-slider-bar) {
    background-color: rgb(100,100,100);
}
*/

:deep(.myflow-player.vjs-myflow .vjs-poster img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

:deep(.vjs-myflow.myflow-player .vjs-big-play-button) {
    transition: opacity 0.25s;
}

:deep(.vjs-myflow.myflow-player .vjs-big-play-button:hover) {
    opacity: 0.9;
}

:deep(.vjs-myflow.myflow-player .vjs-big-play-button .vjs-icon-placeholder:before) {
    top: 50%;
    transform: translateY(-25%);
    font-size: 1.5em;
}
</style>
