<script setup>

import {ref, watch} from "vue";

const emit = defineEmits(['suggestionSelected'])

const props = defineProps({
    input: {
        type: String,
        required: true,
    }
})

const suggestions = ref([])

const loadCompletions = async () => {
    const input = props.input.map(item => item.value).join(',')

    const res = await axios.post(route('aiAssistant.aiTitleSuggestions'), {
        terms: input
    })

    suggestions.value = res.data.suggestions.split(',')
}

const selectSuggestion = (suggestion) => {
    emit('suggestionSelected', suggestion)
    suggestions.value = []
}

watch(() => props.input, () => {
    loadCompletions()
}, {deep: true})


</script>
<template>
    <div v-if="suggestions.length > 0">
        <p class="text-xs mt-1 mb-1">May we suggest also adding one of these titles?</p>
        <ul class="flex flex-wrap">
            <li
                v-for="(suggestion, i) in suggestions"
                :key="i"
                @click="selectSuggestion(suggestion)"
                class="mr-1 whitespace-nowrap text-xxs bg-gray-200 px-1 py-0.5 mb-1 rounded-md cursor-pointer hover:bg-gray-300"
            >
                {{ suggestion }}
            </li>
        </ul>
    </div>
</template>
