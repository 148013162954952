<script setup>
    import {computed, ref, watch} from "vue";
    import axios from "axios";
    import {onMounted} from "vue";
    import TaskItem from "./taskItem.vue";

    const emit = defineEmits(['markedSent'])

    const loading = ref(true);
    const tasks = ref([]);
    const currentEmailsPage = ref(1);
    const currentLinkedinPage = ref(1);
    const pageSize = 8;
    const editingEmail = ref(false)
    const editingMessage = ref(false)

    const fetchTasks = async () => {
        try {
            const response = await axios.get(route('assistant.dailyTasks'));
            tasks.value = response.data;
            loading.value = false
        } catch (error) {
        }
    };

    const updateEmailContent = async () => {
        await axios.post(route('assistant.updateBaseEmail'), {
            subject: tasks.value.emailSubject,
            content: tasks.value.emailBody
        });
    }

    const updateMessageContent = async () => {
        await axios.post(route('assistant.updateBaseMessage'), {
            messageBody: tasks.value.messageBody,
        });
    }

    const action = async () => {
        try {
            const response = await axios.get(route('assistant.dailyTasks'));
            tasks.value = response.data;
        } catch (error) {
        }
    };

    const paginatedEmailTasks = computed(() => {
        if (! tasks.value.emailTasks) {
            return [];
        }

        let from = (currentEmailsPage.value - 1) * pageSize;
        let to = currentEmailsPage.value * pageSize;

        return Object.values(tasks.value.emailTasks).slice(from, to);
    })

    const emailPageCount = computed(() => {
        if (! tasks.value.emailTasks) {
            return 0;
        }

        return Math.ceil(Object.values(tasks.value.emailTasks).length / pageSize);
    });

    const paginatedLinkedinTasks = computed(() => {
        if (! tasks.value.linkedinTasks) {
            return [];
        }

        let from = (currentLinkedinPage.value - 1) * pageSize;
        let to = currentLinkedinPage.value * pageSize;

        return Object.values(tasks.value.linkedinTasks).slice(from, to);
    })

    const linkedinPageCount = computed(() => {
        if (! tasks.value.linkedinTasks) {
            return 0;
        }

        return Math.ceil(Object.values(tasks.value.linkedinTasks).length / pageSize);
    });

    const stripHtml = (html) => {
        const temp = document.createElement('div');
        temp.innerHTML = html;
        return temp.textContent || temp.innerText || '';
    };

    const openEmail = (task) => {
        const subject = encodeURIComponent(tasks.value.emailSubject);
        const plainBody = stripHtml(tasks.value.emailBody);
        const body = encodeURIComponent(plainBody);
        const to = encodeURIComponent(task.email);

        const mailtoLink = `mailto:${to}?subject=${subject}&body=${body}`;
        window.open(mailtoLink, '_blank');
    };

    const markTaskSent = async (task) => {
        const res = await axios.post(route('assistant.markAsSent'), {
            list_lead_id: task.id
        });

        if (res.data.success) {
            task.done = true
            emit('markedSent', task)
        }
    }

    const markTaskSkipped = async (task) => {
        await axios.post(route('assistant.markAsSkipped'), {
            list_lead_id: task.id
        });
        task.done = true
    }

    watch(editingEmail, (newValue) => {
        if (newValue === false) {
            updateEmailContent()
        }
    })

    watch(editingMessage, (newValue) => {
        if (newValue === false) {
            updateMessageContent()
        }
    })

    onMounted(() => {
        fetchTasks();
    });

</script>


<template>
    <div>
        <div class="grid-cols-2 gap-8 md:grid">
            <div class="col-span-2">
                <card-tabbed>
                    <card-tabbed-tab
                        :title="paginatedEmailTasks.length > 0 ? 'Email ('+paginatedEmailTasks.length+')' : 'Email'"
                        value="email"
                        :active="true"
                    >
                        <h2 class="mb-4">New contacts to email</h2>
                        <div class="grid-cols-5 gap-8 lg:grid">
                            <div class="col-span-3">
                                <div
                                    v-if="loading"
                                    class="flex justify-center"
                                >
                                    <mf-spinner-medium
                                        class="w-12 h-6"
                                    />
                                </div>
                                <ul class="divide-y">
                                    <li
                                        v-if="! loading && paginatedEmailTasks.length === 0 && tasks.processedEmailTasks === 0"
                                        class="py-3 text-center text-gray-500"
                                    >
                                        We're currently gathering leads for you, check back soon.
                                    </li>
                                    <li
                                        v-if="! loading && paginatedEmailTasks.length === 0 && tasks.processedEmailTasks > 0"
                                        class="py-3 text-center text-gray-500"
                                    >
                                        You're all done for now, good job!
                                    </li>
                                    <li
                                        v-for="task in paginatedEmailTasks"
                                        :key="task.name"
                                    >
                                        <task-item
                                            :task="task"
                                            @mark-task-sent="markTaskSent"
                                            @mark-task-skipped="markTaskSkipped"
                                            @openEmail="openEmail"
                                        />
                                    </li>
                                </ul>

                                <div class="flex justify-center mt-4">
                                    <div class="flex items-center space-x-2">
                                        <button-base
                                            size="sm"
                                            :style-type="currentEmailsPage > 1 ? 'secondary' : 'disabled'"
                                            @click="currentEmailsPage = currentEmailsPage > 1 ? currentEmailsPage - 1 : currentEmailsPage"
                                        >
                                            <i class="fa-light fa-chevron-left"></i>
                                        </button-base>
                                        <div class="text-xs text-center text-gray-400 min-w-20">
                                            {{ pageSize*(currentEmailsPage-1)+1 }}-{{ pageSize*currentEmailsPage }} of {{ pageSize*emailPageCount }}
                                        </div>
                                        <button-base
                                            size="sm"
                                            :style-type="emailPageCount > currentEmailsPage ? 'secondary' : 'disabled'"
                                            @click="currentEmailsPage = emailPageCount > currentEmailsPage ? currentEmailsPage + 1 : currentEmailsPage"
                                        >
                                            <i class="fa-light fa-chevron-right"></i>
                                        </button-base>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <div class="flex justify-between p-1">
                                    <h5>Your base email</h5>
                                    <div class="flex gap-2">
                                        <button-base
                                            size="sm"
                                            :style-type="editingEmail ? 'success' : 'secondary'"
                                            @click="editingEmail = !editingEmail"
                                        >
                                            {{ editingEmail ? 'Done' : 'Edit' }}
                                        </button-base>
                                    </div>
                                </div>

                                <div class="p-4 mt-5 bg-gray-100 rounded-lg">
                                    <div class="flex items-center justify-between space-x-4">
                                        <span class="font-bold">Subject</span>
                                        <container-with-tooltip
                                            alignment="center"
                                            tooltip-class="mb-1 bg-white text-xs px-1 py-0.5 rounded shadow-md text-xss"
                                        >
                                            <template #tooltip>
                                                Copy to clipboard
                                            </template>

                                            <copyable-text
                                                :show-url="false"
                                                :copyable-text="tasks.emailSubject"
                                            />
                                        </container-with-tooltip>
                                    </div>
                                    <input-text
                                        v-if="editingEmail"
                                        v-model:content="tasks.emailSubject"
                                    />
                                    <div v-else>
                                        {{ tasks.emailSubject }}
                                    </div>

                                    <hr class="mt-4 mb-4">

                                    <div class="flex items-center justify-between space-x-4">
                                        <span class="font-bold">Content</span>
                                        <container-with-tooltip
                                            alignment="center"
                                            tooltip-class="mb-1 bg-white text-xs px-1 py-0.5 rounded shadow-md text-xss"
                                        >
                                            <template #tooltip>
                                                Copy to clipboard
                                            </template>

                                            <copyable-text
                                                :show-url="false"
                                                :copyable-text="tasks.emailBody"
                                            />
                                        </container-with-tooltip>
                                    </div>

                                    <input-editor
                                        v-if="editingEmail"
                                        v-model:content="tasks.emailBody"
                                    />
                                    <div
                                        v-else
                                        v-html="tasks.emailBody"
                                    >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </card-tabbed-tab>
                    <card-tabbed-tab
                        :title="paginatedLinkedinTasks.length > 0 ? 'LinkedIn ('+paginatedLinkedinTasks.length+')' : 'LinkedIn'"
                        value="linkedin"
                    >
                        <h2>Contacts to nudge on LinkedIn</h2>
                        <div class="grid-cols-5 gap-8 lg:grid">
                            <div class="col-span-3">
                                <div
                                    v-if="loading"
                                    class="flex justify-center"
                                >
                                    <mf-spinner-medium
                                        class="w-12 h-6"
                                    />
                                </div>
                                <ul class="divide-y">
                                    <li
                                        v-if="! loading && paginatedLinkedinTasks.length === 0 && tasks.processedLinkedinTasks === 0"
                                        class="py-3 text-center text-gray-500"
                                    >
                                        No contacts to nudge right now, check back in a day or two
                                    </li>
                                    <li
                                        v-if="! loading && paginatedLinkedinTasks.length === 0 && tasks.processedLinkedinTasks > 0"
                                        class="py-3 text-center text-gray-500"
                                    >
                                        You're all done for now, good job!
                                    </li>
                                    <li
                                        v-for="task in paginatedLinkedinTasks"
                                        :key="task.name"
                                    >
                                        <task-item
                                            :task="task"
                                            @mark-task-sent="markTaskSent"
                                            @mark-task-skipped="markTaskSkipped"
                                            @openEmail="openEmail"
                                        />
                                    </li>
                                </ul>

                                <div class="flex justify-center mt-4">
                                    <div class="flex items-center space-x-2">
                                        <button-base
                                            size="sm"
                                            :style-type="currentLinkedinPage > 1 ? 'secondary' : 'disabled'"
                                            @click="currentLinkedinPage = currentLinkedinPage > 1 ? currentLinkedinPage - 1 : currentLinkedinPage"
                                        >
                                            <i class="fa-light fa-chevron-left"></i>
                                        </button-base>
                                        <div class="text-xs text-center text-gray-400 min-w-20">
                                            {{ pageSize*(currentLinkedinPage-1)+1 }}-{{ pageSize*currentLinkedinPage }} of {{ pageSize*linkedinPageCount }}
                                        </div>
                                        <button-base
                                            size="sm"
                                            :style-type="linkedinPageCount > currentLinkedinPage ? 'secondary' : 'disabled'"
                                            @click="currentLinkedinPage = linkedinPageCount > currentLinkedinPage ? currentLinkedinPage + 1 : currentLinkedinPage"
                                        >
                                            <i class="fa-light fa-chevron-right"></i>
                                        </button-base>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <div class="flex justify-between p-1">
                                    <h5>Your base message</h5>
                                    <div class="flex gap-2">
                                        <button-base
                                            size="sm"
                                            :style-type="editingMessage ? 'success' : 'secondary'"
                                            @click="editingMessage = !editingMessage"
                                        >
                                            {{ editingMessage ? 'Done' : 'Edit' }}
                                        </button-base>
                                    </div>
                                </div>

                                <div class="p-4 mt-5 bg-gray-100 rounded-lg">
                                    <div class="flex items-center justify-between space-x-4">
                                        <span class="font-bold">Message</span>
                                        <container-with-tooltip
                                            alignment="center"
                                            tooltip-class="mb-1 bg-white text-xs px-1 py-0.5 rounded shadow-md text-xss"
                                        >
                                            <template #tooltip>
                                                Copy to clipboard
                                            </template>

                                            <copyable-text
                                                :show-url="false"
                                                :copyable-text="tasks.messageBody"
                                            />
                                        </container-with-tooltip>
                                    </div>

                                    <input-editor
                                        v-if="editingMessage"
                                        v-model:content="tasks.messageBody"
                                    />
                                    <div
                                        v-else
                                        v-html="tasks.messageBody"
                                    >
                                    </div>
                                </div>
                            </div>

                        </div>

                    </card-tabbed-tab>
                </card-tabbed>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
