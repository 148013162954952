<script setup>
import {ref} from "vue";

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
})

const showResponses = ref(false)

</script>

<template>
    <div
        v-if="field"
        class="col-span-6"
    >
        <card-basic
            class="!mb-0"
        >
            <div>
                <button-base
                    :style-type="showResponses ? 'base' : 'secondary'"
                    size="sm"
                    @click="showResponses = !showResponses"
                >
                    {{ showResponses ? _mft('form:statistics.hideResponses') : _mft('form:statistics.showResponses') }}
                </button-base>
            </div>
            <ul
                v-if="showResponses"
                class="mt-4"
            >
                <li
                    v-for="(entry, e) in field.responses"
                >
                    <template
                        v-if="entry && entry[1]"
                    >
                        <div
                            v-if="typeof entry[1] === 'object'"
                        >
                            {{ entry[1].join(', ') }}
                        </div>
                        <div
                            v-else
                        >
                            {{ entry[1] }}
                        </div>
                    </template>
                </li>
            </ul>
        </card-basic>
    </div>
</template>

<style scoped>

</style>
