<script setup>
    import CreateTask from "./createTask.vue";
    import {ref} from "vue";
    import IconChevron from "../icons/iconChevron.vue";

    const props = defineProps({
        taskList: {
            type: Object,
            required: true,
        },
        expanded: {
            type: Boolean,
            required: false,
            default: false,
        }
    })

    const addingTask = ref(false)
    const _taskList = ref(props.taskList)
    const _expanded = ref(props.expanded)

    const reloadTasklist = async () => {
        const res = await axios.get(route('api.task-list.show', props.taskList.id))
        _taskList.value = res.data.task_list
    }

    const onTaskCreated = () => {
        addingTask.value = false
        reloadTasklist()
    }

    const onTaskDeleted = () => {
        reloadTasklist()
    }
</script>

<template>
<div>
    <div class="flex justify-between space-x-2 mb-2">
        <h3>{{ taskList.name }}</h3>
        <button-transparent
            v-if="! _expanded"
            size="sm"
            @click="_expanded = ! _expanded"
        >
            <IconChevron class="w-5 h-5" />
        </button-transparent>
        <button-secondary
            v-if="_expanded"
            size="sm"
            @click="addingTask = ! addingTask"
        >
            {{ addingTask ? 'Cancel' : 'Add task' }}
        </button-secondary>
    </div>
    <div v-if="_expanded">
        <create-task
            v-if="addingTask"
            :task-list="_taskList"
            class="py-3 -mx-4 lg:-mx-6 px-4 lg:px-6 bg-gray-100 border-y"
            @created="onTaskCreated"
        ></create-task>
        <ul class="divide-y mt-2">
            <li
                v-for="(task, t) in _taskList.tasks"
                class="pt-3 pb-2 -mx-4 lg:-mx-6 px-4 lg:px-6"
            >
                <task
                    :task="task"
                    @deleted="onTaskDeleted"
                ></task>
            </li>
        </ul>
    </div>
</div>
</template>
