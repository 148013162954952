<script setup>
    import {onMounted, ref} from 'vue'
    import axios from 'axios'

    const taskLists = ref([])
    const addingTaskList = ref(false)
    const newTaskListName = ref('')

    const loadTaskLists = async () => {
        const res = await axios.get(route('api.task-list.index'))
        taskLists.value = res.data.task_lists
    }

    const createTaskList = async () => {
        await axios.post(route('api.task-list.store'), {
            name: newTaskListName.value
        })
        addingTaskList.value = false
        newTaskListName.value = ''
        await loadTaskLists()
    }

    onMounted(() => {
        loadTaskLists()
    })
</script>

<template>
    <div class="space-y-4">
        <div
            v-for="taskList in taskLists"
            :key="taskList.id"
        >
            <task-list
                :expanded="taskLists.length > 1 ? false : true"
                :taskList="taskList"
            ></task-list>
        </div>
        <div class="flex justify-center">
            <button-secondary
                v-if="! addingTaskList"
                size="sm"
                @click="addingTaskList = true"
            >
                Add task list
            </button-secondary>
            <div
                v-if="addingTaskList"
                class="flex space-x-2 items-center w-full px-4"
            >
                <input-text
                    v-model:content="newTaskListName"
                    autofocus
                    class="w-full !mb-0"
                ></input-text>
                <button-success
                    size="sm"
                    class="mt-1"
                    @click="createTaskList"
                >{{ _mft('shared:action.create') }}</button-success>
            </div>
        </div>

    </div>
</template>
