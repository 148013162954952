<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';

const communities = ref([]);

async function getCommunities() {
    const response = await axios.get(route('community.admin.api.index'));
    communities.value = response.data;
}

const archiveCommunity = async (communityId) => {
    await axios.post(route('community.admin.api.archiveCommunity', communityId));
    await getCommunities();
};

onMounted(() => {
    getCommunities();
});

defineExpose({
    getCommunities,
});

</script>

<template>
    <div>
        <p
            v-if="communities.length === 0"
            class="text-gray-400 mt-4 text-xs"
        >
            {{ _mft('community:communities.noCommunitiesYet') }}
        </p>
        <list-basic>
            <list-basic-item
                v-for="community in communities"
                :key="community.id"
            >
                <template #title>
                    {{ community.name }}
                </template>

                <template #actions>
                    <div class="flex space-x-1">
                        <button-more-options
                            size="sm"
                            alignment="right"
                        >
                            <button-more-options-option-with-confirmation
                                confirm-type="danger"
                                :confirm-text="_mft('shared:confirm.areYouSure')"
                                :confirm-button-label="_mft('community:archiveCommunity')"
                                :abort-button-label="_mft('shared:action.cancel')"
                                @confirmed="archiveCommunity(community.id)"
                                class="whitespace-nowrap"
                            >
                                {{ _mft('community:archiveCommunity') }}
                            </button-more-options-option-with-confirmation>
                        </button-more-options>
                        <a
                            class="text-center text-inherit"
                            :href="route('community.admin.show', community.id)"
                        >
                            <button-secondary
                                size="sm"
                            >
                                Manage
                            </button-secondary>
                        </a>
                    </div>
                </template>
            </list-basic-item>
        </list-basic>
    </div>
</template>
