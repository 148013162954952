<template>
    <file-pond
        ref="filepond"
        :class="previewClass"
        :name="name"
        :required="required"
        :accepted-file-types="acceptedFileTypes"
        :allow-file-encode="allowFileEncode"
        :allow-multiple="allowMultiple"
        :store-as-file="storeAsFile"
        :credits="credits"
        :max-files="maxFiles"
        :allow-file-size-validation="true"
        :max-file-size="maxFileSize"
        :allow-paste="false"
        :label-idle="labelIdle"
        :label-file-processing-complete="labelFileProcessingComplete"
        :label-file-processing-error="labelFileProcessingError"
        :label-file-processing="labelFileProcessing"
        :label-tap-to-cancel="labelTapToCancel"
        :label-tap-to-undo="labelTapToUndo"
        @updatefiles="updateFiles"
        @removefile="removeFile"
    />
</template>

<script>
    // Import FilePond
    import vueFilePond from 'vue-filepond';

    // Import plugins
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

    // Import styles
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    // Create FilePond component
    const FilePond = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview,
        FilePondPluginFileValidateSize,
        FilePondPluginFileEncode
    );

    export default {
        expose: ['clearFiles'],
        props: {
            name: String,
            required: {
                type: Boolean,
                default: false
            },
            acceptedFileTypes: {
                type: String,
                default: '*/*'
            },
            allowFileEncode: {
                type: Boolean,
                default: true
            },
            allowMultiple: {
                type: Boolean,
                default: false
            },
            storeAsFile: {
                type: Boolean,
                default: false
            },
            maxFiles: {
                type: Number,
                default: 1
            },
            credits: {
                default: ''
            },
            labelIdle: {
                type: String,
                default: 'Dra dina filer hit eller klicka <u>här</u> för att ladda upp'
            },
            labelFileProcessing: {
                type: String,
                default: _mft('form:filepond.uploading')
            },
            labelTapToCancel: {
                type: String,
                default: _mft('form:filepond.clickToCancel')
            },
            labelFileProcessingError: {
                type: String,
                default: _mft('error:error.generalOccurred')
            },
            labelFileProcessingComplete: {
                type: String,
                default: _mft('form:filepond.uploadComplete')
            },
            labelTapToUndo: {
                type: String,
                default: _mft('shared:undo')
            },
            showPreview: {
                type: Boolean,
                default: false
            },
            maxFileSize: {
                type: String,
                default: '50MB'
            }
        },
        data: function () {
            return {
            };
        },
        methods: {
            updateFiles(event) {
                this.$emit('updatefiles', event)
            },
            removeFile(event) {
                this.$emit('removedfile', event)
            },
            clearFiles() {
                this.$refs.filepond.removeFiles()
            }
        },
        mounted: function(){
        },
        computed: {
            previewClass() {
                return this.showPreview ? '' : 'no-preview'
            }
        },
        components: {
            FilePond,
        },
    };
</script>
<style scoped>
    :deep(.filepond--root){
        margin-bottom: 0;
    }
    :deep(.filepond--image-preview-wrapper) {
        @apply bg-myflowPurple
    }
    .no-preview:deep(.filepond--image-preview-wrapper * ) {
        display: none;
    }
    :deep(.filepond--drop-label label) {
        font-size: 12px;
    }
    :deep(.filepond--drop-label) {
        min-height: 50px;
    }
</style>
